import React from 'react';
import Grid from "@mui/material/Grid";
import FormModal from '../../components/FormModal';
import NewBatch from './Forms/NewBatch';
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, tomorrow, yesterday, dateFormat } from "../../utils/dateFunctions";
import { checkAccess } from '../../utils/roleFunctions';
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import OnShoreDisplay from './components/OnShoreDisplay';
import { CircularProgress } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import SelectAOCFarmWarehouse from '../../components/SelectAOCFarmWarehouse';
const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const OnShore = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [sortOption, setSortOption] = React.useState(0); // zero by onshore date, 1 by recently actioned
    const [data, setData] = React.useState(null);
    const [farm, setFarm] = React.useState(null);
    const [undoMode, setUndoMode] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const requiredEditAccess = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.EDIT };
    const requiredUndoAccess = { key: ACCESS_SECTIONS.UNDO, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    const [forceTask, setForceTask] = React.useState(null);

    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }


    const createBatch = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);

    }
    return (
        <React.Fragment>
            <FormModal open={openModal} handleClose={handleCloseModal} title={"Create New Crop For Deployment"} style={{ zIndex: 100000 }}>
                <NewBatch farm={farm} access={props.access} reload={toggleReload} handleClose={handleCloseModal} />
            </FormModal>
            <Grid container spacing={2} mb={1}>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <SelectAOCFarmWarehouse setState={setFarm} state={farm} access={props.access} />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={9} style={{ textAlign: 'right' }} >
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={sortOption}
                        onChange={(event, sel) => { sel ? setSortOption(1) : setSortOption(0); }}
                        aria-label="text alignment"
                    >
                        <ToggleButton key={1} value={1} >
                            <div style={{ whiteSpace: 'nowrap' }}>Sort by Recently Worked</div>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                {checkAccess(props.access, requiredEditAccess) ? <Grid item xs={12} >
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                            <Button onClick={createBatch} variant="contained" >Create New Slats For Deployment</Button>
                        </Grid>
                        {/*switch to toggle undo on and off*/}
                        {checkAccess(props.access, requiredUndoAccess) ? <Grid item xs={12} sm={12} md={6} lg={6} xl={9} style={{ textAlign: 'right' }} >
                            <Typography style={{ display: 'inline' }}>Enable Undo</Typography>
                            <Switch checked={undoMode} onChange={(event) => { setUndoMode(event.target.checked) }} name="header-fixed" />
                        </Grid> : null}
                    </Grid>
                </Grid> : null}
            </Grid>
            <OnShoreDisplay farm={farm} access={props.access} ableToTransfer reload={reload} sortOption={sortOption} undoEnabled={undoMode} />
        </React.Fragment >
    );
};

export default OnShore;
