import React from 'react';
import Div from "@jumbo/shared/Div";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { dateFormat } from '../utils/dateFunctions';
import { getAPIData } from '../utils/apiFunction';

const ETransferDisplay = (props) => {
    const transferId = props.transferId;
    const [transfer, setTransfer] = React.useState(null);

    React.useEffect(() => {
        if (props.transferId) {
            getAPIData('/Transfers/Get/' + props.transferId, setTransfer);
        }
    }, [props.transferId]);
    React.useEffect(() => {
        //does the transfer have multiple leases
        //multiple crops


    }, [transfer])
    console.log(transfer);

    return <Div>
        {transfer ? <Grid container spacing={0} mb={1}>
            <Grid item xs={12} >
                <Typography variant={"h3"}>{transfer.otr}</Typography>
            </Grid>
            <Grid item xs={12} >
                <Typography variant={"h4"}>From: {transfer.source?.myobid}</Typography>
            </Grid>
            <Grid item xs={12} >
                <Typography variant={"h4"}>To: {transfer.destination?.description} </Typography>
            </Grid>
            <Grid item xs={12} >
                <Typography variant={"h5"}>{dateFormat(transfer.dateReady)}</Typography>
            </Grid>
            <Grid item xs={12} >
                <Typography variant={"h5"}>PRN: {transfer.prn}</Typography>
            </Grid>
            <Grid item xs={12} >
                <Typography variant={"h5"}>Harvest Date: {dateFormat(transfer.harvestDate)}</Typography>
            </Grid>
            <br />
            <br />
            {transfer.coreTransferLines.map((line) => {
                return <Grid item xs={12} >
                    <Typography variant={"h6"}>{line.crop}</Typography>
                    <Typography variant={"h6"}>{line.stockItem.description}{(line.bags > 0 ? (' - ' + line.bags + ' Bags') : '')}{(line.bins > 0 ? (' - ' + line.bins + ' Bins') : '')} - {line.quantity} doz</Typography>
                </Grid>
            })}
        </Grid> : null}
    </Div>
}

export default ETransferDisplay;
