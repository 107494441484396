import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { getAPIData, getAPIDataParamsReturn } from '../../../utils/apiFunction';
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import Autocomplete from '@mui/material/Autocomplete';
import { checkAccess } from '../../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import OysterOrder from "../../../components/OysterOrder";
import { textfieldDateFormat, tomorrow, shiftWeek } from "../../../utils/dateFunctions";
import SupplierSelect from "../../../components/SupplierSelect";
import { statusList } from '../../../utils/constants/selectFields';


const AddPurchaseOrder = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [shipVia, setShipVia] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [supplier, setSupplier] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [promisedDate, setPromisedDate] = React.useState(tomorrow(new Date()));
    const [oysters, setOysters] = React.useState([{}]);
    const [tempOysters, setTempOysters] = React.useState(null);
    const [receival, setReceival] = React.useState(null);
    const [orderDate, setOrderDate] = React.useState(new Date());
    const id = props.data ? (props.data.id ? props.data.id : '') : '';
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    console.log(props?.fillData)
    React.useEffect(() => {
        const checkArr = (data) => {
            var retData = data;
            console.log(data)
            if (Array.isArray(data)) {
                retData = data[0];
                console.log(retData)
            }
            setReturnData(retData);
        }
        if (props.edit || props.completed || props.duplicate) {
            getAPIData('/PurchaseOrder/Get/' + id, checkArr);
            
            
        }

    }, [])

    React.useEffect(() => {
        console.log(returnData);

        if (returnData) {
            setStatus(returnData.status);
            setShipVia(returnData.shipVia);
            setOrderDate(new Date(returnData.orderDate));
            setPromisedDate(new Date(returnData.promisedDate));
            setSupplier(returnData.supplier);
            setDescription(returnData.comments);
            var oysterArr = [];
            if (returnData.orderInputLines) {
                for (var i = 0; i < returnData.orderInputLines.length; i++) {
                    var newOIL = { ...returnData.orderInputLines[i] };
                    newOIL.quantity = returnData.orderInputLines[i].ordered;
                    oysterArr.push(newOIL);
                }
            }
            setOysters([...oysterArr]);
            setTempOysters([...oysterArr]);

            if (returnData.supplier) {
                getAPIDataParamsReturn('/Receivals/Get/GetBySupplier', { supplierId: returnData.supplier.id, startDate: textfieldDateFormat(shiftWeek(new Date(returnData.orderDate), -8).start), endDate: textfieldDateFormat(shiftWeek(new Date(returnData.orderDate), 8).start) }).then((list) => {
                    setReceival(list.find((l) => { return l.purchaseOrderId === id }))
                });
            }
        }


    }, [returnData])

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!promisedDate) {
            errorMessages.push("You must enter a Promised Date");
        }
        if (!orderDate) {
            errorMessages.push("You must enter a Order Date");
        }
        if (!supplier) {
            errorMessages.push("You must select a Supplier");
        }
        if (!status) {
            errorMessages.push("You must select a Status");
        }
        if (!description || (description === '')) {
            errorMessages.push("You must enter a Description");
        }


        for (var i = 0; i < oysters.length; i++) {
            if (!oysters[i].quantity) {
                errorMessages.push("You must enter a Quantity for every Oyster Line");
            }
            if (!oysters[i].price) {
                errorMessages.push("You must enter a Price for every Oyster Line");
            }
            if (!oysters[i].warehouseId) {
                errorMessages.push("You must select a Warehouse for every Oyster Line");

            }
            if (!oysters[i].stockItem) {
                errorMessages.push("You must select a Stock Item for every Oyster Line");

            }
            if (oysters[i].priceVary && !oysters[i].variationReason) {
                errorMessages.push("You must enter a reason that the price varies from this customers default price");

            }
        }
        if (receival) {
            warningMessages.push("This Purchase Order has already been received. If you are changing the warehouse that needs to be reflected in the receival.");
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const deleteAll = async () => {

    }
    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var data = {
            SupplierId: supplier?.id,
            Status: status,
            OrderDate: textfieldDateFormat(orderDate),
            OrderType: 'PO',
            PromisedDate: textfieldDateFormat(promisedDate),
            ShipVia: shipVia,
            Description: description ? description : ''
        }
        console.log(data);
        if (props.edit) {
            var response = await adalApiFetch(fetch, uri + '/PurchaseOrder/Update/' + id + '?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        } else {
            var response = await adalApiFetch(fetch, uri + '/PurchaseOrder/Insert/?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        }
        var resp = await (response.json());
        var promArr = [];
        console.log(resp);
        //Oyster stock Items
        var reducedOysters = [];
        for (var i = 0; i < oysters.length; i++) {
            var oyLine = reducedOysters.findIndex((oy) => {
                if (oy.stockItem && oysters[i].stockItem) {
                    return ((oy.stockItem.id === oysters[i].stockItem.id) && (oy.price === oysters[i].price) && (oy.warehouseId === oysters[i].warehouseId));
                }
                return false;
            });
            if (oyLine >= 0) {
                reducedOysters[oyLine].quantity = Number(reducedOysters[oyLine].quantity ? reducedOysters[oyLine].quantity : 0) + Number(oysters[i].quantity ? oysters[i].quantity : 0);
            } else {
                reducedOysters.push({ ...oysters[i] });
            }
        }
        console.log(reducedOysters);
        promArr = reducedOysters.map((oy) => {
            var oyData = {
                StockItemId: oy.stockItem ? oy.stockItem.id : 0,
                Price: oy.price ? oy.price : 0,
                WarehouseId: oy.warehouseId ? oy.warehouseId : 0,
                VariationReason: oy.variationReason ? oy.variationReason : null,
                Quantity: oy.quantity ? oy.quantity : 0,
                ChangePrice: oy.changePrice ? oy.changePrice : false,
            }
            if (oy.id && !props.duplicate) {
                return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Update/' + oy.id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            } else {
                return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Insert/' + resp.id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            }
        })
        console.log(reducedOysters, tempOysters, oysters);

        if (tempOysters) {
            if (tempOysters && (tempOysters.length > 0)) {
                tempOysters.map((rl) => {
                    var exID = reducedOysters.find((oy) => { return oy.id && (oy.id === rl.id) });
                    if (!exID) {
                        return adalApiFetch(fetch, uri + '/PurchaseOrder/Line/Delete/' + rl.id, {
                            method: 'delete',
                        });
                    }
                })
            }
        }
        ///////////////////////////


        await Promise.all(promArr);


        setLoading(false);
        if (props.returnPO) {
            props.returnPO(resp);
        }
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                {/*<Typography variant={"h3"}>Confirm OTR Information</Typography>*/}
                <Grid container spacing={0} mb={1}>
                    <Grid item xs={12} sm={6} md={props.halfSize ? 6 : 4} lg={props.halfSize ? 4 : 3} xl={props.halfSize ? 4 : 3}>
                        <SupplierSelect frmrId={(returnData ? returnData.supplierId : (props.fillData ? props.fillData.supplierId : null))} state={supplier} setState={setSupplier} disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={props.halfSize ? 6 : 4} lg={props.halfSize ? 4 : 3} xl={props.halfSize ? 4 : 3}>

                        <DatePicker date={orderDate} setDate={setOrderDate} label="Pickup Date" disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={props.halfSize ? 6 : 4} lg={props.halfSize ? 4 : 3} xl={props.halfSize ? 4 : 3}>

                        <DatePicker date={promisedDate} setDate={setPromisedDate} label="Use Date" disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={props.halfSize ? 6 : 4} lg={props.halfSize ? 4 : 3} xl={props.halfSize ? 4 : 3}>
                        <StringSelect title={"Status"} input={(returnData ? returnData.status : null)} state={status} setState={setStatus} options={statusList} disabled={completed} default={0} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={props.halfSize ? 6 : 4} lg={props.halfSize ? 4 : 3} xl={props.halfSize ? 4 : 3}>
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Description"
                            value={description}
                            onChange={(event) => { setDescription(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rows={4} />
                    </Grid>
                    <Grid item xs={12} >
                        {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                        <OysterOrder halfSize={props.halfSize} customerId={supplier?.id} supplier oysters={oysters} starter={!(props.edit || props.duplicate || completed)} setOysters={setOysters} disabled={completed} access={props.access} />
                    </Grid>
                </Grid>

                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.returnDate ? props.handleClose(orderDate) : props.handleClose} />

            </Div>
        </Box >
    );
};
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}
const StringSelect = (props) => {
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (props.input) {
            var est = props.options.find((dr) => { return props.labelFunc ? (props.labelFunc(props.input) === props.labelFunc(dr)) : ((props.input) === (dr)) });
            console.log(est, props.options)
            props.setState(est);
            setValue(est);
        } else if (props.default > -1) {

            var est = props.options[props.default];
            props.setState(est);
            setValue(est);
        }
    }, [props.input])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => { return props.labelFunc ? props.labelFunc(option) : option }}
        disablePortal
        id="stat"
        options={props.options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}
    />);



}



export default AddPurchaseOrder;
