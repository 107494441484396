import React from 'react';
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Div from "@jumbo/shared/Div";
import { CardActions, ThemeProvider, Typography } from "@mui/material";
import { useJumboHeaderTheme, useJumboTheme } from "@jumbo/hooks";


const NotificationsDropdown = () => {
    const { theme } = useJumboTheme();
    const { headerTheme } = useJumboHeaderTheme();
    return (
        <ThemeProvider theme={theme}>
            <Link underline="hover" color="inherit" href={'https://aoc.elmotalent.com.au/'} target={'_blank'}>
                <JumboDdPopover
                    triggerButton={
                        <ThemeProvider theme={headerTheme}>
                            <JumboIconButton elevation={25}>
                                <div style={{ fontSize: '50%', pointerEvents: 'auto' }} >
                                ELMO
                                </div>
                            </JumboIconButton>
                        </ThemeProvider>
                    }
                    disableInsideClick
                >

                </JumboDdPopover>
            </Link>

        </ThemeProvider>
    );
};

export default NotificationsDropdown;
