import jsPDF from 'jspdf';
import title from '../images/PackingSlip-Title.PNG';
import shipconfirmed from '../images/ShipmentConfirmationTitle.PNG';
import Haccp from '../images/Haccp.png';
import { getAPIDataParamsReturn, getAPIDataReturn } from './apiFunction';
import { dateFormat } from './dateFunctions';
import { numberFormat } from './numberFunctions';
import { MYOBTOREAD } from './constants/warehouse';

const buildCustomerName = (name) => {
    //break up customer name if its longer than 30 characters at a space
    var out = [];
    var temp = '';
    name.split(' ').map((a) => {
        if ((temp + a).length > 30) {
            out.push(temp);
            temp = a;
        } else {
            temp += a + ' ';
        }
    })
    out.push(temp);
    return out;
}


const downloadPackingSlip = (order, confirmed, warehouse) => async (e) => {

    const ifUndef = (field) => {
        return field ? field : '';
    }

    e.stopPropagation();
    console.log(order.items);
    var orderId = order.orderNo;
    var customer = await getAPIDataParamsReturn('/Customers/GetById', { CustomerId: order.customer.id });
    var stockItems = await getAPIDataReturn('/StockItems/Get');
    var orderList = order.items.filter((a) => (a.location === warehouse));
    var shipmentId = orderList[0] ? (orderList[0].shipmentMYOBId ? orderList[0].shipmentMYOBId : '') : '';
    var customerPO = orderList[0] ? (orderList[0].customerPO ? orderList[0].customerPO : '') : '';
    console.log(orderList, confirmed);
    var prmArr = []
    //if (confirmed) {
    for (var i = 0; i < orderList.length; i++) {
        let item = orderList[i];
        prmArr.push(getAPIDataParamsReturn('/Fullfillment/Get', { SalesOrderLineId: item.id }));
    }
    //}
    var res = await Promise.all(prmArr);
    console.log(res, confirmed);
    for (var i = 0; i < res.length; i++) {
        var sumTotal = 0;
        for (var j = 0; j < res[i].length; j++) {
            res[i][j].code = orderList[i].code;
            res[i][j].quantity = (0 - res[i][j].quantity);
        }
    }
    var prnArr = res.reduce((a, b) => a.concat(b), []);
    //group by code and sum quantity
    var codeArr = [];
    for (var i = 0; i < prnArr.length; i++) {
        var currCode = prnArr[i].code;
        var currQty = prnArr[i].quantity;
        var found = codeArr.find((a) => (a.code === currCode));
        if (found) {
            found.quantity += currQty;
        } else {
            codeArr.push({ ...prnArr[i], quantity: currQty });
        }
    }
    prnArr = codeArr;

    //var prnArr = confirmed ? res.reduce((a, b) => a.concat(b), []) : orderList;
    // var PAi = new Image();
    // PAi.src = PA;
    // var MPCi = new Image();
    // MPCi.src = MPC;
    console.log(prnArr);
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, 297]
    });
    //if bigger than 18 addPage

    var fontothertitle = 10;
    var fontbig = 15;
    var fontbiggerText = 9;
    var fontText = 7;

    var titleI = new Image();
    titleI.src = title;
    var titleSC = new Image();
    titleSC.src = shipconfirmed;
    var haccpI = new Image();
    haccpI.src = Haccp;

    var lineWidthTight = 5;
    var lineWidth = 6
    var max = 18;
    var totalPages = Math.ceil(prnArr.length / max);
    var rightJusty = 125;
    var rightJustyEnd = 200;
    var line = 0;
    for (var i = 0; i < totalPages; i++) {
        if (i !== 0) {
            doc.addPage();
        }
        line = 0;
        doc.addImage(titleI, 'PNG', 0, line, 210, 56.44);
        line += 60;
        doc.setFontSize(fontbig);
        doc.text('Invoice To: ', 10, line);

        doc.text('Packing Slip Ref: ', rightJusty, line);
        doc.text(shipmentId ? shipmentId : '', rightJustyEnd, line, { align: 'right' });
        line += lineWidth;
        var tempLine = line;
        doc.setFontSize(fontbiggerText);
        console.log(customer)
        var customerNameArr = buildCustomerName(customer?.name);
        if (customer?.name) {
            //loop through customer name arr
            for (var x = 0; x < customerNameArr.length; x++) {
                doc.text(customerNameArr[x], 10, line);
                line += lineWidthTight;
            }

        }
        //doc.text(ifUndef(customer?.name), 10, line);
        //line += lineWidthTight;
        doc.text(ifUndef(customer?.billingAddress?.line1), 10, line);
        line += lineWidthTight;
        if (customer?.billingAddress?.line2) {
            doc.text(ifUndef(customer?.billingAddress?.line2), 10, line);
            line += lineWidthTight;
        }
        doc.text(ifUndef((customer?.billingAddress?.city + ' ' + customer?.billingAddress?.state + ' ' + customer?.billingAddress?.postcode)), 10, line);
        line += lineWidthTight;



        doc.setFontSize(fontText);
        line = tempLine;
        doc.text('Date:', rightJusty, line);
        doc.text(dateFormat(new Date()), rightJustyEnd, line, { align: 'right' });

        line += lineWidth;
        doc.text('Customer ID:', rightJusty, line);
        doc.text(customer.myobid, rightJustyEnd, line, { align: 'right' });
        line = 90;
        doc.setLineWidth(0.3)
        doc.rect(5, line, 200, 12);
        line += lineWidthTight;
        doc.setFontSize(fontothertitle);
        doc.text('SO NUMBER', 20, line, { align: 'center' });
        doc.text('CUST PO', 55, line, { align: 'center' });
        doc.text('SHIP VIA', 90, line, { align: 'center' });
        doc.text('CONTACT', 130, line, { align: 'center' });
        doc.text('WAREHOUSE', 185, line, { align: 'center' });

        line += lineWidthTight;

        doc.setFontSize(fontText);
        doc.text(orderId, 20, line, { align: 'center' });
        doc.text(String(ifUndef(customerPO)), 55, line, { align: 'center' });
        doc.text(order.shipVia ? order.shipVia : '', 90, line, { align: 'center' });
        doc.text('', 130, line, { align: 'center' });
        doc.text(MYOBTOREAD[orderList[0].location] ? MYOBTOREAD[orderList[0].location] : orderList[0].location, 185, line, { align: 'center' });

        doc.setFontSize(fontothertitle);
        line += lineWidth + 2;
        doc.text('DESCRIPTION', 10, line);
        doc.text('ESTUARY-PRN', 130, line, { align: 'center' });
        doc.text('QTY.', 175, line, { align: 'right' });
        doc.text('UOM', 200, line, { align: 'right' });
        doc.setFontSize(fontText);
        var totalQuantity = 0;
        for (var x = max * i; ((x < (max * (i + 1))) && (x < prnArr.length)); x++) {
            var currSI = stockItems.find((s) => (s.myobid === prnArr[x].code))
            console.log(currSI);
            totalQuantity += prnArr[x].quantity;
            //loop
            line += lineWidth;
            doc.text(ifUndef(currSI?.description), 10, line);
            //doc.text(prnArr[x].lotSerialNbr ? prnArr[x].lotSerialNbr : '', 130, line, { align: 'center' });
            doc.text(String(prnArr[x].quantity), 175, line, { align: 'right' });
            doc.text((currSI?.isProcessed ? 'DOZEN' : 'UNITS'), 200, line, { align: 'right' });
        }


        doc.setFontSize(11);

        doc.setLineWidth(0.3)
        doc.text('Receiver Name', 10, 227);
        doc.line(47, 227, 115, 227);
        doc.text('Temp.', 120, 227);
        doc.line(133, 227, 155, 227);

        doc.text('Receiver Signature', 10, 236);
        doc.line(47, 236, 115, 236);
        doc.text('Time', 120, 236);
        doc.line(133, 236, 155, 236);

        doc.setLineWidth(0.9)
        doc.rect(10, 240, 95, 50);
        doc.setFontSize(fontbig);
        line = 247;
        doc.text('SHIP TO:', 12, line);
        doc.text('Total Quantity: ' + numberFormat(totalQuantity) + ' Doz', 130, line);
        line += lineWidth + 1;

        doc.setFontSize(fontbig - 4);
        if (customer?.name) {
            //loop through customer name arr
            for (var x = 0; x < customerNameArr.length; x++) {
                doc.text(customerNameArr[x], 10, line);
                line += lineWidthTight;
            }

        }
        doc.text(ifUndef(customer?.shippingAddress?.line1), 12, line);
        line += lineWidthTight;
        doc.text(ifUndef(customer?.shippingAddress?.line2), 12, line);
        line += lineWidthTight;
        doc.text(ifUndef(ifUndef(customer?.shippingAddress?.city) + ' ' + ifUndef(customer?.shippingAddress?.state) + ' ' + ifUndef(customer?.shippingAddress?.postcode)), 12, line);




        doc.addImage(haccpI, 'PNG', 185, 270, 20, 20);
        doc.setFontSize(fontText);
        doc.text('Page ' + (i + 1) + ' of ' + totalPages, 193, 293);
    }






    doc.save('PackingSlip-' + orderId + '-' + new Date().toLocaleString() + '.pdf');
}


export default downloadPackingSlip