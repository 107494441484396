import React from 'react';
import Grid from "@mui/material/Grid";
import EstuaryStatus from './Tables/EstuaryStatus';
const Estuaries = () => {
    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <EstuaryStatus  />
                </Grid>
        </Grid>
        </React.Fragment >
    );
};



export default Estuaries;
