import React from 'react';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { getAPIDataReturn } from '../../utils/apiFunction';
import Receivals from '../Processing/Tables/Receivals';
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import ConfirmReceivalForm from '../Processing/Forms/ConfirmReceivalForm';
import SelectWarehouse from '../../components/SelectWarehouse';
const NewReceival = (props) => {
    console.log(props.searchParamId)
    const [completed, setCompleted] = React.useState(false);
    const [receival, setReceival] = React.useState(null);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [warehouse, setWarehouse] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(async () => {
        if (props.searchParamId) {
            if (!receival) {
                var rec = await getAPIDataReturn('/Receivals/Get/' + props.searchParamId);
                console.log(rec)
                if (rec) {

                    if (rec.receivalDate) {
                        setCompleted(true)
                    }
                    setReceival(rec);
                }
            }
        }
    }, [props.searchParamId])
    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setReceival(array[rowState.dataIndex]);
    }
    const deselectPO = (array) => (rowd, rowState) => {
        setReceival(null);
    }
    const handleClose = () => {
        if (props.searchParamId) {
            reWriteURL();
        }
        props.handleClose();
    }

    const reWriteURL = () => {
        console.log(window.location.search)
        var currSearch = window.location.search;
        var newSearch = currSearch.replace(/&ReceivalId=\d.*/, '');
        navigate(newSearch);
    }
    const selectId = (list) => {
        return list.filter((l) => {
            if (receival) {
                return l.id === receival.id;
            } else {
                return true;
            }
        })
    }
    return (
        <Container style={{ paddingTop: '20px' }}>
            <Grid container spacing={2} mb={1}>
                <Typography variant={'h2'}>Select Receival</Typography>
                <Grid item xs={12} >
                    <SelectWarehouse useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} />
                </Grid>
                {(receival) ? '' : <Grid item xs={12} >
                    <Receivals warehouseFilter={warehouse?.description} access={props.access} onClick={selectPO} startDate={startDate} endDate={endDate} notConfirmed />
                </Grid>}
                {(receival && !props.searchParamId) ? <Grid item xs={12} >
                    <Receivals access={props.access} onClick={deselectPO} startDate={startDate} endDate={endDate} filter={selectId} notConfirmed />
                </Grid> : ''}
                {(receival) ? <Grid item xs={12} >
                    <ConfirmReceivalForm data={receival} access={props.access} handleClose={handleClose} completed={completed}  />
                </Grid> : ''}
            </Grid>


        </Container>

    );
};

export default NewReceival;