import React from 'react';
import { TableRow, TableCell, TableHead } from "@mui/material";
import { useJumboTheme } from "@jumbo/hooks";
import { printColor, checkCols, shiftCol } from './CustomerOrderFunctions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';



const HeadRows = ({ summary, OrderColumns, showColumns, tempTitle, topLevelList, toggleSelectList }) => {
    const { theme } = useJumboTheme();
    const [selList, setSelList] = React.useState(false);

    var stickyCol = {
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper
    }
    var stickyColON = {
        position: "sticky",
        left: "240px",
        background: theme.palette.background.paper
    }

    var topLevel = [];
    var groups = [];
    for (var i = 0; i < OrderColumns.length; i++) {
        if (showColumns.includes(i)) {
            var oc = OrderColumns[i];
            if (!topLevel.find((g) => { return g.name === oc.topLevel })) {
                topLevel.push({ name: oc.topLevel, count: 0, cols: [], midCount: 0 });
            }
            if (!groups.find((g) => { return ((g.name === oc.name) && (g.location === oc.location) && (g.species === oc.species)) })) {
                groups.push({ name: oc.name, location: oc.location, species: oc.species, count: 0, cols: [] });
                topLevel[topLevel.length - 1].midCount = topLevel[topLevel.length - 1].midCount + 1;
            } 
            topLevel[topLevel.length - 1].count = topLevel[topLevel.length - 1].count + 1;
            topLevel[topLevel.length - 1].cols.push(i);
            groups[groups.length - 1].count = groups[groups.length - 1].count + 1;
            groups[groups.length - 1].cols.push(i);
        }

    }
    var headStyle = {
        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    }
    var maxHeight = {
    }
    const countToArr = (count, val) => {
        var arr = [];
        for (var i = 0; i < count; i++) {
            arr.push(val);
        }
        return arr;
    }
    const findCol = (list, nm) => {
        var tl = list.find((l) => { return l.name === nm });
        if (tl) {
            return tl.col;
        } else {
            return { r: 255, g: 255, b: 255 };
        }
    }

    var groupPrintArr = [];
    var cellPrintArr = [];
    var prevCol = {};
    var shiftColr = {};
    var inc = 0;
    var stickyRow = {
        position: "sticky",
        top: 0,
        zIndex: 1000,
        background: theme.palette.background.paper

    }
    return (<TableHead>
        <TableRow>
            {!summary ? <TableCell style={{ border: 'none' }}><div style={maxHeight}></div></TableCell> : null}
            <TableCell style={{ border: 'none' }}><div style={maxHeight}>{tempTitle ? tempTitle : ''}</div></TableCell>
            {!summary ? <TableCell style={{ border: 'none' }}><div style={maxHeight}></div></TableCell> : null}
            {!summary ? <TableCell style={{ border: 'none' }}><div style={maxHeight}></div></TableCell> : null}
            {topLevel.map((tl, ind) => {
                var colObj = findCol(topLevelList, tl.name);
                groupPrintArr = [...groupPrintArr, ...countToArr(tl.midCount, colObj)];
                cellPrintArr = [...cellPrintArr, ...countToArr(tl.count, colObj)];
                return <TableCell align="center" colspan={tl.count} style={{ ...headStyle, backgroundColor: printColor(colObj) }}><div style={maxHeight}>{tl.name}</div></TableCell>;
            })}
        </TableRow>
        <TableRow>
            {!summary ? <TableCell style={{ borderBottom: 'none' }} ></TableCell> : null}
            <TableCell style={{ borderBottom: 'none' }} ></TableCell>
            {!summary ? <TableCell style={{ borderBottom: 'none' }} ></TableCell> : null}
            {!summary ? <TableCell style={{ borderBottom: 'none' }} ></TableCell> : null}
            {groups.map((tl, ind) => {
                return <TableCell align="center" colspan={tl.count} style={{ ...headStyle, backgroundColor: printColor(groupPrintArr[ind]) }}><div style={maxHeight}>{tl.name}</div></TableCell>;
            })}
        </TableRow>
        <TableRow style={stickyRow}>
            {!summary ? <TableCell style={{ ...headStyle, ...stickyCol }}>{toggleSelectList ? <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={selList} onChange={(event) => { setSelList(event.target.checked); toggleSelectList(event.target.checked); }} />} label=""  labelPlacement="right" /> : null}</TableCell> : null}
            {!summary ? <TableCell style={{ ...headStyle, ...stickyCol }}>Customer</TableCell> : <TableCell style={{borderBottom:'1px solid black', backgroundColor:'transparent'}} ></TableCell>}
            {!summary ? <TableCell style={{ ...headStyle, ...stickyColON }}>Order No.</TableCell> : null}
            {!summary ? <TableCell style={headStyle}>Comment</TableCell> : null}
            {OrderColumns.map((tl, ind) => {
                if (checkCols([ind], showColumns)) {
                    var newCol = (cellPrintArr[inc]);
                    var pCol = newCol;
                    if (printColor(newCol) === printColor(prevCol)) {
                        shiftColr = shiftCol(shiftColr);
                        pCol = shiftColr;
                    } else {
                        shiftColr = pCol;

                    }
                    prevCol = newCol;
                    inc++;
                    return <TableCell align="center" style={{ ...headStyle, backgroundColor: printColor(pCol) }}>{tl.gradeCode + (tl.traySize ? (" (" + tl.traySize + ")") : '')}</TableCell>;
                } else {
                    return '';
                }

            })}
        </TableRow>
    </TableHead>)

}

export default HeadRows;
