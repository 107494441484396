import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getAPIData, getAPIDataParamsReturn, postApi, } from "../../../utils/apiFunction";
import { numberFormatwithNeg } from "../../../utils/numberFunctions";
import OystersGroup from "../../../components/OysterGroup";
import Div from "@jumbo/shared/Div";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import ControlledTextField from "../../../components/ControlledTextField";
import DatePicker from "../../../shared/DatePicker";
import CircularProgress from '@mui/material/CircularProgress';
import { textfieldDateFormat, shiftHours } from '../../../utils/dateFunctions';

const ReceivalForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [updateComplete, setUpdateCompleted] = React.useState(false);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [editInput, setEditInput] = React.useState(false);
    const [oystersOut, setOystersOut] = React.useState([{}]);
    const [outputsPassedGroup, setOutputsPassedGroup] = React.useState([]);
    const [stockItems, setStockItems] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const CompletedAndEdit = edit && completed;
    const CompletedSoDisabled = !edit && completed;
    const supplier = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.receival?.supplier?.name : props.selectedLine.supplier) : '';
    //const lotNbr = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.lotSerialNbr : props.selectedLine.lotSerialNbr) : '';
    const [lotNbr, setLotNbr] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [outputQuantity, setOutputQuantity] = React.useState(null);
    const processRunId = CompletedAndEdit ? props.selectedLine.id : props.selectedLine.processRunId
    const [oystersIn, setOystersIn] = React.useState([{}]);
    const [prn, setPrn] = React.useState(null);
    const [returnedLSNs, setReturnedLSNs] = React.useState(null);
    const [loadingLSN, setLoadingLSN] = React.useState(false);
    const [expiryDate, setExpiryDate] = React.useState(shiftHours(new Date(),(24*9)));


    React.useEffect(() => {
        getAPIData('/StockItems/GetProcessed', setStockItems);
    }, []);

    console.log(props.selectedLine);

    const getVariance = (inQuant, outQuant, perc) => {
        var totalOystersOut = outQuant;
        var diff = inQuant - totalOystersOut;
        if (perc) {
            return Math.abs((diff) / inQuant);
        } else {
            return diff;
        }
    }


    const validate = () => () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)

        
        if (!lotNbr) {
            errorMessages.push("You must enter a Lot Serial Number");
        }
       
        if (!props.warehouseId) {
            errorMessages.push("Ther is no warehouse selected. Please refresh page and try again.");

        }

        for (var i = 0; i < oystersIn.length; i++) {
            if (!oystersIn[i].quantity || (oystersIn[i].quantity <= 0)) {
                errorMessages.push("You must enter a quantity of oysters for input");
            }
            if (!oystersIn[i].stockItem) {
                errorMessages.push("You must select a stockitem for input");
            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const submit = async () => {
        var allOk = false;
        var respArr = [];
        setLoading(true);


        const params = {
            lotSerNbr: lotNbr,
            StockItemId: oystersIn[0].stockItem.id,
            ExpiryDate: textfieldDateFormat(new Date(expiryDate)),
            NewAmount: oystersIn[0].quantity,
            OldAmount: 0,
            WarehouseId: props.warehouseId,
        }
        console.log(params);
        respArr.push(await postApi('/Processing/AdjustQuantity', params));

        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            setLoading(false);
            setCompleted(true);
            setUpdateCompleted(true);
        } else {
            setError("Failed to submit");
        }

    }
    const enableInputEdit = () => {
        setEditInput(true);
    }
    const remSR = (name) => {
        if (name && name.description) {
            return name.description.replace('Sydney Rock', '');
        } else {
            return '';
        }
    }
    const searchPRN = () => {
        setLoadingLSN(true);
        getAPIDataParamsReturn('/Inventory/SearchPRN', { LotNbrSubString: prn }).then((rlsn) => {
            setReturnedLSNs(rlsn);
            if (rlsn.length === 1) {
                setLotNbr(rlsn[0]);
            }
            setLoadingLSN(false);
        });
    }
    const selectLSN = (LSN) => () => {
        setLotNbr(LSN);
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            {lotNbr ? <Div>
                <Typography variant={'h2'}>{"Lot Serial Number: " + lotNbr}</Typography>

            </Div> : <Div>

                {/*<TextField*/}
                {/*    disabled*/}
                {/*    id="outlined-required"*/}
                {/*    label="Supplier"*/}
                {/*    value={supplier}*/}
                {/*/>*/}
                <TextField
                    id="outlined-required"
                    label="PRN"
                    value={prn}
                    onChange={(e) => setPrn(e.target.value)}
                />
                <Button style={{ marginTop: '20px' }} onClick={searchPRN} variant="outlined" disabled={CompletedSoDisabled} >Search for Lot Serial Number</Button>
            </Div>}
            {!lotNbr ? (returnedLSNs ? returnedLSNs.map((rlsn) => {
                return <Button style={{ marginTop: '20px' }} onClick={selectLSN(rlsn)} variant="outlined" disabled={CompletedSoDisabled} >{rlsn}</Button>
            }) : (loadingLSN ? <CircularProgress /> : null)) : <Div>
                {/*//comments*/}
                {/*//processing time*/}
                {/*//no of staff on line*/}

                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} >
                        <TextField
                            disabled={CompletedSoDisabled}
                            id="outlined-multiline-static"
                            label="Comment"
                            value={comment}
                            onChange={(event) => { setComment(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rows={4} />
                        </Grid>
                    <Grid item xs={12} >
                        <Typography variant={'h3'}>{"Expiry Date"}</Typography>
                            <DatePicker label="Expiry Date" date={expiryDate} setDate={setExpiryDate} disabled={completed} />
                        </Grid>

                    <Grid item xs={12} >
                        {/*Icon button edit */}
                        <Typography variant={'h3'}>{"Output Quantity"}</Typography>
                        <OystersGroup processed={props.processed} starter={true} oysters={oystersIn} setOysters={setOystersIn} disabled={CompletedSoDisabled} single noTitle />
                    </Grid>


                    {CompletedAndEdit ?
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/*<FormGroup>*/}
                            {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                            {/*</FormGroup>*/}
                            <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer title={'Update'} completed={updateComplete} error={error} loading={loading} allowed={checkAccess(props.access, requiredAdminAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                            </div>
                        </Grid>
                        : <React.Fragment>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/*<FormGroup>*/}
                                {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                                {/*</FormGroup>*/}
                                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer title={'Create Stock'} completed={CompletedSoDisabled} error={error} loading={loading} allowed={checkAccess(props.access, requiredAdminAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                                </div>
                            </Grid>
                        </React.Fragment>}
                </Grid>
            </Div>}
        </Box >
    );
};


export default ReceivalForm;


