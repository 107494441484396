export const numberFormat = (num) => {
    if (Number(num)) {
        if (Number(num) > 0) {
            return thousandsFormat(String(num));
        } else {
            return '(' + thousandsFormat(String(Number(num) * -1)) + ')';
        }
    } else {
        return num;
    }

}

export const numberFormatwithNeg = (num) => {
    if (Number(num)) {
        if (Number(num) > 0) {
            return thousandsFormat(String(num));
        } else {
            return '-' + thousandsFormat(String(Number(num) * -1));
        }
    } else {
        return num;
    }

}


export const displayDozFormat = (oysterNum, withNeg) => {
    if (withNeg) {
        return numberFormatwithNeg(oysterNum) + ' ' + '(' + numberFormatwithNeg((oysterNum / 12).toFixed(0)) + ' doz)'
    } else {
        return numberFormat(oysterNum) + ' ' + '(' + numberFormat((oysterNum / 12).toFixed(0)) + ' doz)'
    }
}
export const numberFormatErrorToZero = (num) => {
    if (Number(num)) {
        if (Number(num) > 0) {
            return thousandsFormat(String(num));
        } else {
            return '(' + thousandsFormat(String(Number(num) * -1)) + ')';
        }
    } else {
        return 0;
    }

}

export const toPercFormat = (num) => {
    if (Number(num)) {
        return String((num * 100).toFixed(0)) + '%';
    } else {
        return 0;
    }

}

const thousandsFormat = (numString) => {
    //if num string contains decimal point get both sides
    var decimal = null;
    if (numString.includes('.')) {
        decimal = numString.slice(numString.indexOf('.'), numString.length);
        numString = numString.slice(0, numString.indexOf('.'));
    }

    if (numString.length > 3) {
        var numComs = Math.floor(numString.length / 3);
        var start = (numString.length % 3);

        for (var i = 0; i < numComs; i++) {
            if (i == 0) {
                if (start !== 0) {
                    numString = numString.slice(0, start) + ',' + numString.slice(start, numString.length);
                }

            } else {
                if (start === 0) {
                    numString = numString.slice(0, start - 1 + 3 * i + i) + ',' + numString.slice(start - 1 + 3 * i + i, numString.length);
                } else {
                    numString = numString.slice(0, start + 3 * i + i) + ',' + numString.slice(start + 3 * i + i, numString.length);
                }
            }
        }
    }
    if (decimal) {
        numString = numString + decimal;
    }
    return numString;
}

const testTF = () => {
    console.log(numberFormat('-12345678910'));
    console.log(numberFormat('-123456789'));
    console.log(numberFormat('-12345678'));
    console.log(numberFormat('-1234567'));
    console.log(numberFormat('-123456'));
    console.log(numberFormat('-12345'));
    console.log(numberFormat('-1234'));
    console.log(numberFormat('-123'));
    console.log(numberFormat('-10'));
    console.log(numberFormat('-1'));
    console.log(numberFormat('1.1'));
    console.log(numberFormat('1.32'));
    console.log(numberFormat('1.321'));
    console.log(numberFormat('1'));
    console.log(numberFormat('11'));
    console.log(numberFormat('101'));
    console.log(numberFormat('1000'));
    console.log(numberFormat('22222'));
    console.log(numberFormat('222224'));
    console.log(numberFormat('2222245'));
    console.log(numberFormat('22222456'));
    console.log(numberFormat('222224567'));
    console.log(numberFormat('2222245678'));
    console.log(numberFormat('22222456789'));
    console.log(numberFormat('22222456789.1'));
    console.log(numberFormat('22222456789.12'));
    console.log(numberFormat('22222456789.123'));
    console.log(numberFormat('22222456789.1234'));
}

