import { getAPIDataReturn } from "app/utils/apiFunction";



export async function getOysterGrades() {
    var grades = await getAPIDataReturn('/Grades/Get');
    return grades.map((grade) => {
        return { id: grade.id, speciesId: grade.species.id, speciesOrder: grade.species.sortOrder, Species: grade.species.description, Grade: grade.description, abv: (grade.species.code + grade.code), newAbv: (grade.species.code + grade.code), order: grade.sortOrder }
    });
}
export async function getSpeciesList() {
    var grades = await getAPIDataReturn('/Grades/Get');
    //get unique species
    var species = [...new Set(grades.map((grade) => { return grade.species.description }))];
    return species.map((sp) => { return { name: sp } });

}
export function buildSpeciesList(data) {
    var species = [...new Set(data.map((grade) => { return grade.Species }))];
    return species.map((sp) => { return { name: sp } });

}
export function gradeSort(data) {
    return data.sort((a, b) => {
        if (a.speciesOrder !== b.speciesOrder) {
            return a.speciesOrder - b.speciesOrder
        } else {
            return a.order - b.order
        }
    }
    )
}