import jsPDF from 'jspdf';
import 'jspdf-autotable'
import title from '../../images/OTRHeading.PNG';
import { numberFormat } from '../../utils/numberFunctions'
import { dateFormat } from '../../utils/dateFunctions'
import { adalApiFetch } from '../../../adalConfig';

const OTRPdf = async (data) => {
   
    const uri = process.env.REACT_APP_BASE_URL;
    // var PAi = new Image();
    // PAi.src = PA;
    // var MPCi = new Image();
    // MPCi.src = MPC;
    var QRResponse = await adalApiFetch(fetch, uri + '/QR/OTR?TransferId=' + data.id, {
        method: 'get',
    });
    const QR = await QRResponse.blob();
    console.log(data)
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, 297]
    });
    //if bigger than 18 addPage
    var smallerText = 10;
    var fontbig = 15;
    var fontbiggerText = 11;
    var fontText = 7;

    const QRurl = URL.createObjectURL(QR);
    var QRi = new Image();
    QRi.src = QRurl;

    var titleI = new Image();
    titleI.src = title;

    var lineWidthTight = 5;
    var lineWidth = 6
    var max = 18;
    var titleBarX = 10;
    var line = 0;
    var tempLine = line;
    doc.addImage(titleI, 'PNG', 0, line, 210, 52.62);
    //doc.setFontSize(fontText);
    //doc.text('OTR:', 28, 20);
    doc.setFontSize(fontbig);
    doc.setTextColor('red');
    doc.text(data.otr, 35, 21);
    doc.setTextColor(0, 0, 0);

    line = 60;
    doc.setFillColor(0, 0, 0);
    doc.setLineWidth(0.3)
    doc.rect(15, line, 180, 12, 'F');
    doc.rect(15, line, 180, 35);
    doc.setTextColor(255, 255, 255);
    line = line + 8;
    doc.setFontSize(fontbig);
    doc.text('Delivery Instructions ', 20, line);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(fontbiggerText);
    line = line + 9;
    var destinationName = data.destination?.description;
    console.log(destinationName)
    if (destinationName === 'LIVE PRODUCT') {
        destinationName = 'AOC BATEMANS BAY';
    } else if (destinationName === 'SYDNEY') {
        destinationName = 'AOC SYDNEY KINGSGROVE';
    }
    doc.text('To: ' + destinationName, 20, line);
    line = line + 7;
    doc.text('From: ' + data.source?.myobid, 20, line);
    line = line + 7;
    doc.text('Date: ' + dateFormat(new Date(data.dateReady)), 20, line);
    //10 lines
    var FtoF = false;
    console.log(data.destination?.isFarm, data.source?.isFarm)
    if (data.destination?.isFarm && data.source?.isFarm) {
        FtoF = true;
    }
    line = line + 15;
    doc.setFillColor(0, 0, 0);
    doc.setLineWidth(0.3);
    doc.rect(15, line, 180, 12, 'F');
    doc.rect(15, line, 180, 40);
    doc.setTextColor(255, 255, 255);
    line = line + 8;
    doc.setFontSize(fontbig);
    doc.text('Harvest Details', 20, line);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(fontbiggerText);
    line = line + 9;
    data.prn?doc.text('PRN: ' + data.prn, 20, line):console.log('noPRN');
    data.ivr ? doc.text('IVR: ' + data.ivr, 100, line) : console.log('noIVR');
    
    line = line + 7;
    var crops = [];
    data.coreTransferLines?.forEach((ctl) => {
        if (ctl.crop) {
            if (!crops.includes(ctl.crop)) {
                crops.push(ctl.crop)
            }
        }
    })
    if (crops.length === 1) {
        doc.text('Crop: ' + (data.crop ? data.crop : ''), 20, line);
    }
    //only one harvest date per otr
    doc.text('Harvest Date: ' + dateFormat(data.coreTransferLines?.[0]?.group?.shore?.date), 100, line);
    line = line + 7;
    doc.setFontSize(smallerText);
    doc.text('Estuary: ' + data.coreTransferLines?.[0]?.group?.previousLocation?.line?.lease?.estuary?.description, 20, line);
    doc.text('Harvest Area: ' + data.coreTransferLines?.[0]?.group?.previousLocation?.line?.lease?.harvestArea?.description, 100, line);
    line = line + 7;
    //check if there are multple lease names within coreTransferLines
    var leases = [];
    data.coreTransferLines?.forEach((ctl) => {
        if (ctl.group?.previousLocation?.line?.lease?.name && ctl.group?.previousLocation?.line?.lease?.number) {
            if (!leases.includes(ctl.group?.previousLocation?.line?.lease?.name + ' ' + ctl.group?.previousLocation?.line?.lease?.number)) {
                leases.push(ctl.group?.previousLocation?.line?.lease?.name + ' ' + ctl.group?.previousLocation?.line?.lease?.number)
            }
        }

    })
    
    if (leases.length===1) {
        doc.text('Lease: ' + leases[0], 20, line);
    }
    line = line + 11;
    var oys = data.coreTransferLines;
    var tenOys = [...oys];
    var oyList = [...oys];
    var extraOys = [];
    if (oyList.length > 10) {
        tenOys = [];
        tenOys = oyList.splice(0, 10);
        extraOys = [...oyList]
    }
    var headAr = ['Bags', 'Bins', 'Oyster Species and Grades', FtoF ? 'Total Quantity (Pcs)': 'Total Quantity (Doz)' ]
    var footAr = [tenOys.reduce((p, c) => (p + (Number(c.bags) ? Number(c.bags) : 0)), 0), tenOys.reduce((p, c) => (p + ((Number(c.bins) ? Number(c.bins) : 0))), 0), '', numberFormat(tenOys.reduce((p, c) => (p + (Number(c.quantity) ? Number(c.quantity) : 0)), 0))];
    var bodyAr = [];
    if ((leases.length>1) && (crops.length>1)) {
        headAr.push('Lease')
        footAr.push('')
        headAr.push('CropYear')
        footAr.push('')
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity), oy.group?.previousLocation?.line?.lease?.name, oy.crop])
    } else if ((leases.length > 1)) {
        headAr.push('Lease')
        footAr.push('')
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity), (oy.group?.previousLocation?.line?.lease?.name +' '+ oy.group?.previousLocation?.line?.lease?.number)])
    } else if ((crops.length > 1)) {
        headAr.push('CropYear')
        footAr.push('')
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity), oy.crop])
    } else {
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity)])
    }
    doc.autoTable({
        startY: (line),
        theme: 'striped',
        headStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
        footStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
        head: [headAr],
        body: bodyAr,
        foot: [footAr]
    })
    line = 278;
    doc.setFontSize(smallerText);
    doc.text('Sender\'s Signature:  _______________________________________', 15, line);
    line = line + 10;
    doc.text('Transport Company: _______________________________________', 15, line);

    doc.addImage(QRi, 'PNG', 150, line - 44, 50, 50);


    if (extraOys.length > 0) {
        doc.addPage();
        doc.autoTable({
            startY: (45),
            theme: 'striped',
            headStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
            footStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
            head: [['Bags', 'Bins', 'Oyster Species and Grades', FtoF ? 'Total Quantity (Pcs)' : 'Total Quantity (Doz)']],
            body: extraOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity)]),
            foot: [[extraOys.reduce((p, c) => (p + (Number(c.bags) ? Number(c.bags) : 0)), 0), extraOys.reduce((p, c) => (p + ((Number(c.bins) ? Number(c.bins) : 0))), 0), '', numberFormat(extraOys.reduce((p, c) => (p + (Number(c.quantity) ? Number(c.quantity) : 0)), 0))]]
        })
        line = 278;
        doc.setFontSize(smallerText);
        doc.text('Sender\'s Signature:  _______________________________________', 15, line);
        line = line + 10;
        doc.text('Transport Company: _______________________________________', 15, line);
    }

    doc.save(data.otr + '.pdf');
}



function buildDesc(oy) {
    console.log(oy)
    if (oy.stockItem) {
        if (oy.stockItem.species && oy.stockItem.grade) {
            return (oy.stockItem.species.description + ' ' + oy.stockItem.grade.description)
        } else {
            return oy.stockItem.description;
        }
    } else {
        return 'Unknown';
    }

}

export default OTRPdf;