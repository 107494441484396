import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SupplierPricing from './Tables/SupplierPricing';
import FormModal from '../../components/FormModal';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import PriceForm from './Forms/PriceForm';
const SupplierPricingDisp = (props) => {

    const [rowData, setRowData] = React.useState(null);
    const [newForm, setNew] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const requiredCreateAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.ADMIN };

    const reloadFunc = () => {
        setReload(!reload);
    }
    const handleClose = () => setOpen(false);
    const openPriceForm = (dataArr) => (rowD, rowState) => {
        setRowData(dataArr[rowState.dataIndex]);
        setNew(false);
        setOpen(true);
    }
    const createPriceForm = () => {
        setOpen(true);
        setNew(true);
        setRowData(null);
    }
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Add Supplier Pricing"} >
                <PriceForm new={newForm} data={rowData} access={props.access} reload={reloadFunc} handleClose={handleClose} SupplierPricing />
            </FormModal>
            <Grid container spacing={3.5}>
                {checkAccess(props.access, requiredCreateAccess) ? <Grid item xs={12}>
                    <Button onClick={createPriceForm} variant="contained" >Add Supplier Pricing</Button>
                </Grid> : null}
                <Grid item xs={12}>
                    <SupplierPricing onClick={openPriceForm} reload={reloadFunc} />
                </Grid>
        </Grid>
        </React.Fragment >
    );
};



export default SupplierPricingDisp;
