import React from 'react';
import Summary from './Tables/Summary';
import Detail from './Tables/Detail';
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { startOfWeek, endOfWeek, tomorrow, shiftWeek } from "../../utils/dateFunctions";
import { Locations } from "../../utils/constants/locations";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import WeeklySummary from './Tables/WeeklySummary';

const WeeklySummaryPage = () => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(tomorrow(endOfWeek(new Date())));
    const startDateMemo = React.useMemo(() => (startDate));
    const endDateMemo = React.useMemo(() => ((endDate)));
    const [location, setLocation] = React.useState(0);
    const [exReload, setExReload] = React.useState(false);
    const [inUse, setInUse] = React.useState(false);
    let timer;
    React.useEffect(() => {
        if (!inUse) {
            reloadFunc();
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [inUse]);

    const reloadFunc = () => {
        timer = !timer && setInterval(() => {
            console.log('TIMER')
            setExReload(stat=>!stat);
        }, 120000)
    }

  

    return (<div onMouseOver={() => { return !inUse ? setInUse(true) : null; }} onMouseLeave={() => { return inUse ? setInUse(false) : null; }}>
        <Grid container spacing={2} mb={1} >
            <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
                <WarehouseSelector location={location} setLocation={setLocation} />
            </Grid>
        </Grid>
        <Grid container spacing={3.5}>
            
            <Grid item xs={12}>
                <WeeklySummary location={location} startDate={startDateMemo} endDate={(endDateMemo)} externalReload={exReload} shrinkTable />
            </Grid>
        </Grid>
    </div >);

};



export default WeeklySummaryPage;
