import React from 'react';
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import MonthSelector from "../../shared/MonthSelector";
import { useSearchParams } from "react-router-dom";
import { checkAccess } from '../../utils/roleFunctions';
import DatePicker from "../../shared/DatePicker";
import { getAPIDataParams } from '../../utils/apiFunction';
import LocationSelector from '../../components/LocationSelect';

const ProcessingReport = (props) => {
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [processes, setProcesses] = React.useState([]);
    const [location, setLocation] = React.useState([0, 1]);
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const type = (searchParams.get("type"));
    React.useEffect(() => {

        //getAPIDataParams('/PurchaseOrder/GetNotReceived', setRPOs, { startDate });
    }, [startDate])
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                    <DatePicker date={startDate} setDate={setStartDate} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                    <LocationSelector setLocation={setLocation} location={location} />
                </Grid>

            </Grid>
            {type == 'ShuckerTotals' ? <ShuckerTotals/>: ''}
            {type == 'Live' ? <Live/>: ''}
            {type == 'Halfshell' ? <Halfshell /> : ''}

        </React.Fragment >
    );
};

const User = (props) => {
    return (<Card sx={{ mb: 4 }} style={{ textAlign: 'center' }} >

        <CardContent>

            <Div sx={{ flex: 1, }}>
                <Typography
                    variant={"h4"}
                    color={"common.black"}
                    mb={2}
                >{props.name + ' (' + props.table + ')'}</Typography>
                <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                >{"Current PRN: " + props.currPRN}</Typography>
                <Typography
                    variant={"h1"}
                    color={"text.secondary"}
                    sx={{ fontSize: '500%' }}
                >{props.total}</Typography>
            </Div>
        </CardContent>
    </Card >
    );
}

const ShuckerTotals = (props) => {
    return (<Grid container spacing={2} mb={1}>
        <Grid item xs={12}>
            <Typography
                variant={"h1"}
                mb={2}
                sx={{ textAlign: 'center' }}
            >{'Half Shell'}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='150' currPRN='54312' />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <User name='James Dunlop' table='5' total='1504' currPRN='54312' />
        </Grid>

    </Grid>)
}


//Total PRNS active
//PRNS completed
//total counted

const Live = (props) => {
    return <br />;
}

//Total PRNS active
//PRNS completed
// total shucked per grade
// total shucked
// leading shucker
const Halfshell = (props) => {
    return <br />;
}
export default ProcessingReport;


