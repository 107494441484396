import jsPDF from 'jspdf';
import { dateFormat, dateTimeFormat, undoDateFormat } from '../dateFunctions';
import { getAPIDataReturn } from '../apiFunction';


export const saveLabel = async (processRun, stockItem, totalUnits, totalQuantity, isStart) => {
    // var PAi = new Image();
    // PAi.src = PA;
    // var MPCi = new Image();
    // MPCi.src = MPC;
    //const makeSixDig = (num) => {
    //    var out = num?.toString();
    //    while (out.length < 6) {
    //        out = '0' + out;
    //    }
    //    return out;
    //}
    //var prCode = ((new Date().getMonth()) < 9 ? '0' : '') + (new Date().getMonth() + 1) + 'UU-' + makeSixDig(processRun.id) + '/' + (new Date().getDate() < 10 ? '0' : '') + new Date().getDate();

    console.log(processRun, stockItem, totalUnits, totalQuantity);
    var doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [160, 110]
    });
    const getProduct = (spec, halfshell) => {
        var out = '';
        switch (spec) {
            case 'PC':
                out = 'Pacific';
                break;
            case 'SR':
                out = 'Sydney Rock';
                break;
            case 'AG':
                out = 'Angasi';
                break;
            default:
                out = 'Oyster';
        }
        return out + ' ' + (halfshell ? 'Halfshell' : 'Live');
    }
    const ifUndef = (field) => {
        return field ? field : '';
    }
    var fontSml = 12;
    var fontOne = 22;
    var fontTwo = 17;
    var fontMassive = 45;
    var lineWidth = 8
    var line = 0;
    console.log(stockItem);
    line += lineWidth;
    doc.setFontSize(fontTwo);
    doc.text(ifUndef(processRun.coreInventory?.lotSerialNbr), 2, line)
    line += lineWidth;
    doc.text(ifUndef(processRun.coreInventory?.receival?.estuary?.description), 2, line)
    line += lineWidth;
    line += lineWidth;
    doc.setFontSize(fontMassive);
    doc.text(ifUndef(processRun.coreInventory?.lotSerialNbr).substring(9, 17), 2, line)
    if (processRun.benchDetails.description.length > 4) {
        doc.setFontSize(fontTwo);
        doc.text(ifUndef(processRun.benchDetails.description), 100, line)
    } else {
        doc.setFontSize(fontTwo - 6);
        doc.text('Bench:', 110, line - 14)
        doc.setFontSize(fontMassive + 7);
        doc.text(ifUndef(processRun.benchDetails.description), 110, line)
    }

    doc.setFontSize(fontTwo);
    line += lineWidth;
    line += lineWidth;
    doc.text("Shuck Time: " + dateTimeFormat(new Date()), 2, line)
    line += lineWidth;
    line += lineWidth;
    line += lineWidth;
    if (isStart) {
        doc.setFontSize(25);
    } else {
        doc.setFontSize(fontMassive);
    }
    doc.text(ifUndef(ifUndef(stockItem?.Species) + ' ' + ifUndef(stockItem?.Grade)), 2, line)
    doc.setFontSize(fontMassive);
    if (!isStart) {
        line += lineWidth;
        line += lineWidth;
        doc.text("Trays: " + totalUnits, 2, line)
    }
    line += lineWidth;
    line += lineWidth;
    doc.text("Doz: " + totalQuantity, 2, line)




    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank', "height=800,width=800");
}