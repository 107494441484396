import { getAPIDataReturn } from '../../utils/apiFunction';
import { getOysterGrades, gradeSort, OysterGradesNew } from "../../definitions/oysters";
export const locationMatch = (location) => {
    if ((location === "LIVEPRODCT") || (location === "HALFSHELL")) {
        return "Batemans Bay";
    } else if ((location === "SYDNEY")) {
        return "Sydney";
    } else if ((location === "CONTRACTOR")) {
        return "Contractor";
    }
}
export const seperateCols = () => {

}
export const buildColGroups = async (toList) => {
    var locations = [{ name: "Batemans Bay", abv: 'BBay' }, { name: "Sydney", abv: 'Syd' }, { name: "Contractor", abv: 'Contractor' }];
    var data = await getAPIDataReturn('/StockItems/GetProcessed');
    var grades = gradeSort(await getOysterGrades());
    var fullList = [];
    var displayStruc = [];
    for (var i = 0; i < data.length; i++) {
        var toplevnam = '';
        var grpname = '';
        if (!data[i].isHalfshell && !data[i].isFrozen) {
            if (data[i].isBagged) {
                toplevnam = 'Live Bagged' + ' (' + data[i]?.species?.description + ')';
                if (data[i]?.isPacific) {
                    grpname = 'Pacific Bagged';
                } else {
                    grpname = 'Bagged';
                }
            } else {
                toplevnam = 'Live Boxed' + ' (' + data[i]?.species?.description + ')';
                if (data[i]?.isPacific) {
                    grpname = 'Pacific Boxed';
                } else {
                    grpname = data[i]?.brand?.description + ' Boxed';
                }
            }
        } else if (data[i].isHalfshell) {
            toplevnam = data[i]?.brand?.description + ' ' + data[i].product + ' (' + data[i]?.species?.description + ')';
            if (data[i]?.isPacific) {
                grpname = 'Pacific ' + data[i]?.brand?.description + ' Half Shell';
            } else {
                grpname = data[i]?.brand?.description + ' ' + data[i].product;
            }
        } else {
            toplevnam = data[i].product + ' (' + data[i]?.species?.description + ')';
            grpname = data[i]?.species?.description + ' ' + data[i].product;
        }

        var tlN = displayStruc.findIndex((tl) => { return tl.name === toplevnam })
        if (tlN < 0) {//Live
            displayStruc.push({ name: toplevnam, groups: [] });
            tlN = displayStruc.length - 1;
        }
        var group = displayStruc[tlN].groups.findIndex((gr) => { return gr.name === (grpname + ' (' + locations[0].abv + ')') })
        if (group < 0) {
            locations.map((loc) => {
                displayStruc[tlN].groups.push({ name: (grpname + ' (' + loc.abv + ')'), codes: [] });
            })
            group = displayStruc[tlN].groups.length - 1 - (locations.length - 1);
        }
        for (var j = 0; j < locations.length; j++) {
            displayStruc[tlN].groups[group + j].codes.push({ location: locations[j].name, code: data[i].myobid, species: data[i].species, grade: data[i].grade, bagCount: data[i].bagCount, traySize: data[i].traySize });
            fullList.push({ location: locations[j].name, topLevel: toplevnam, name: (grpname + ' (' + locations[j].abv + ')'), gradeCode: (data[i]?.grade?.code + ' ' + (data[i].bagCount > 0 ? data[i].bagCount : '')), code: data[i].myobid, species: data[i].species?.code, grade: data[i].grade, bagCount: data[i].bagCount, traySize: data[i].traySize })
        }
    }
    function topLevelScore(val) {
        if (val.includes('Live Bagged')) {
            return 0;
        } else if (val.includes('Live Boxed')) {
            return 1;
        } else if (val.includes('Regular Half Shell')) {
            return 2;
        } else if (val.includes('Premium Half Shell')) {
            return 3;
        } else if (val.includes('Appelation Half Shell')) {
            return 4;
        } else if (val.includes('Frozen')) {
            return 5;
        } else {
            return 6;
        }
    }
    var sortList = fullList.sort((a, b) => {

        function getGradeScore(val) {
            return grades.find((oy) => { return oy.abv.substring((oy.abv.length - 2)) === val })?.order;
        }
        if ((topLevelScore(a.topLevel) === topLevelScore(b.topLevel)) && (a.topLevel === b.topLevel)) {
            if ((a.name) === (b.name)) {
                if ((getGradeScore(a?.grade?.code) === getGradeScore(b?.grade?.code))) {
                    return ((a.gradeCode) > (b.gradeCode)) ? -1 : 1;
                } else {
                    return (getGradeScore(a?.grade?.code) > getGradeScore(b?.grade?.code)) ? 1 : -1;
                }
            } else {
                return ((a.name) > (b.name)) ? 1 : -1;
            }
        } else {
            if (topLevelScore(a.topLevel) === topLevelScore(b.topLevel)) {
                return a.topLevel > b.topLevel ? 1 : -1;
            } else {
                return (topLevelScore(a.topLevel) > topLevelScore(b.topLevel)) ? 1 : -1;
            }
        }

    });
    return { sortList, displayStruc: displayStruc.sort((a, b) => { return ((topLevelScore(a.name) > topLevelScore(b.name)) ? 1 : -1) }) };

}


