import React from 'react';
import Grid from "@mui/material/Grid";
import Receivals from '../Processing/Tables/Receivals';
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import QAForm from '../Processing/Forms/QAform';
import SelectWarehouse from '../../components/SelectWarehouse';

const NewReceival = (props) => {
    const [receival, setReceival] = React.useState(null);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [warehouse, setWarehouse] = React.useState(null);

    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setReceival(array[rowState.dataIndex]);
    }
    const deselectPO = (array) => (rowd, rowState) => {
        setReceival(null);
    }
    const selectId = (list) => {
        return list.filter((l) => {
            if (receival) {
                return l.id === receival.id;
            } else {
                return true;
            }
        })
    }
    return (
        <Container style={{paddingTop:'20px'} }>
            <Grid container spacing={2} mb={1}>
                <Typography  variant={'h2'}>Select Receival</Typography>
                <Grid item xs={12} >
                    <SelectWarehouse useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} />
                </Grid>
                {( receival) ? '' : <Grid item xs={12} >
                    <Receivals warehouseFilter={warehouse?.description} access={props.access} onClick={selectPO} startDate={startDate} endDate={endDate} notQA/>
                </Grid>}
                {(receival ) ? <Grid item xs={12} >
                    <Receivals access={props.access} onClick={deselectPO} startDate={startDate} endDate={endDate} filter={selectId} notQA />
                </Grid> : ''}
                {(receival) ? <Grid item xs={12} >
                    <QAForm receival={receival} access={props.access} handleClose={props.handleClose}/>
                </Grid> : ''}

            </Grid>


        </Container>

    );
};

export default NewReceival;