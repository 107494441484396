import React from 'react';
import Modal from '@mui/material/Modal';
import Div from "@jumbo/shared/Div";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



const FormModal = (props) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        width: '90%',
        boxShadow: 24,
        maxHeight: '90%',
        p: 4,
        overflow: 'auto'
    };

    const exitIcon = {
        position: 'absolute',
        right: '1%',
        top: '1%',
    }
    if (props.fixedWidth) {
        style.width = '80%'
    }
    if (props.noWidth) {
        style.width = 'auto'
    }
    return (<Modal
        open={props.open}
        onClose={(event, reason) => { if (!(reason === "backdropClick")) { props.handleClose() } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClick={props.onClick?props.onClick:()=>{}}
    >
        <Div sx={style}>
            <IconButton style={exitIcon} aria-label="close" onClick={props.handleClose}>
                <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h5" component="h2">
                {props.title}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {props.children}
            </Typography>

        </Div>
    </Modal>);
}

export default FormModal;
