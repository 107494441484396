import React from 'react';
import Grid from "@mui/material/Grid";
import MUIDataTable from "mui-datatables";
import { FormControlLabel, Switch } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { dateTimeFormat, textfieldDateFormat } from '../../../utils/dateFunctions';
import FormModal from '../../../components/FormModal';
import { adalApiFetch } from '../../../../adalConfig';
import QAform from '../Forms/QAform';
import DataTableStandard from "../../../components/DataTableStandard";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { getAPIDataParamsReturn, getAPIDataReturn, getAPIData } from '../../../utils/apiFunction';
const Receivals = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [sizings, setSizings] = React.useState([]);
    const [shells, setShells] = React.useState([]);
    const [conditions, setConditions] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [tempCompData, setTempCompData] = React.useState([]);
    const [completedData, setCompletedData] = React.useState([]);
    const [tempReqData, setTempReqData] = React.useState([]);
    const [requiredData, setRequiredData] = React.useState([]);
    const [suppliers, setSuppliers] = React.useState({});
    const [rowData, setRowData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [isCompleted, setIsCompleted] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [edit, setEdit] = React.useState(false);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };



    const qAFormComp = (rowD, rowState) => {
        setRowData(tempCompData[rowState.dataIndex]);
        setOpen(true)
    }

    const optionsCompleted = {
        responsive: 'standard',
        selectableRowsHideCheckboxes: true,
        filterType: 'dropdown',
        onRowClick: qAFormComp
    };
    React.useEffect(() => {
        var cols = [
            {
                name: 'prn',
                label: 'PRN'
            },
            {
                name: 'supplier',
                label: 'Supplier',
                options: props.supplierFilter ? { filterList: [props.supplierFilter] } : {}
            },
            {
                name: 'date',
                label: 'Date',
                options: {
                    filter: false
                }
            },
            {
                name: 'sizing',
                label: 'Sizing'
            },
            {
                name: 'shell',
                label: 'Shell'
            },
            {
                name: 'condition',
                label: 'Condition'
            }];
        setColumns(cols);
    }, [props.supplierFilter]);

    React.useEffect(() => {
        const abortController = new AbortController();
        setRequiredData([]);
        setCompletedData([]);
        getData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        getAPIData('/QA/QASizeRating', setSizings, abortController);
        getAPIData('/QA/QAShellRating', setShells, abortController);
        getAPIData('/QA/QAConditionRating', setConditions, abortController);
        getAPIData('/farmerssuppliers', setSuppliers, abortController);
        return () => {
            abortController.abort();
        };
    }, [reload]);

    React.useEffect(() => {
        const abortController = new AbortController();
        setRequiredData([]);
        setCompletedData([]);
        getData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        return () => {
            abortController.abort();
        };
    }, [props.startDate, props.endDate]);
    React.useEffect(() => {
        setCompletedData(applyRatingInfo(tempCompData));
    }, [sizings, shells, conditions, tempCompData, suppliers]);
    //var getAPIResp = async (path) => {
    //    return adalApiFetch(fetch, uri + path).then((resp) => {
    //        return resp.json().then((data) => {
    //            return data;
    //        })
    //    })
    //}
    //var getAPIData = async (path, stateSet) => {
    //    const response = await adalApiFetch(fetch, uri + path);
    //    const data = await response.json();
    //    stateSet(data);
    //}
    var matchID = (list, id, selection) => {
        var obj = list.find((v) => {
            return v.id === id;
        })
        if (obj) {
            return obj[selection];
        } else {
            return ''
        }
    }
    var applyRatingInfo = data => {




        return data.map((dt) => {
            var supp = '';
            var suppObj = null;
            if (dt.receival) {
                if (suppliers.farmers) {
                    suppObj = suppliers.farmers.find((sp) => { return sp.id === dt.receival.supplierId });
                }
                if (suppObj) {
                    supp = suppObj.name;
                } else {
                    supp = dt.receival.supplierPredict;
                }
            }
            return {
                id: dt.id,
                receivalId: dt.receivalId,
                prn: dt.receival ? dt.receival.prn : '',
                date: dateTimeFormat(new Date(dt.dateCompleted)),
                sizing: matchID(sizings, dt.sizingRatingId, "name"),
                shell: matchID(shells, dt.shellReasonId, "rating"),
                supplier: supp,
                condition: matchID(conditions, dt.conditionReasonId, "rating")
            }
        })
    }
    var getData = async (start, end, abortController) => {
        setLoading(true);
        const data = await getAPIDataParamsReturn('/QA/Get' ,{
            start,
            end
        }, abortController);
        //fix get
        //filter out data with dates outside of range
        var newData = data.filter((dt) => {
            return (new Date(dt.dateCompleted) >= new Date(start)) && (new Date(dt.dateCompleted) <= new Date(end));
        });
        console.log(getData);
        var prmAr = [];
        newData.map((tcd) => {
            prmAr.push(getAPIDataReturn('/Receivals/Get/' + tcd.receivalId,abortController));
        })
        var recArr = await Promise.all(prmAr);
        console.log(recArr);
        var newArr = newData.map((tcd, ind) => {
            return {
                ...tcd,
                receival: recArr[ind]
            }
        })
        var sortData = newArr.sort((a, b) => {
            return new Date(a.dateCompleted) > new Date(b.dateCompleted) ? -1 : 1
        })
        setTempCompData(sortData);
        setLoading(false);


    }
    return (
        <Grid container spacing={3.5}>
            <FormModal open={open} handleClose={handleClose} title={"Quality Assurance Recieval Form"} >
                <QAform data={rowData} handleClose={handleClose} completed={!edit} edit={edit} access={props.access} reload={() => { setReload(!reload) }} />
            </FormModal>

            <Grid item xs={12}>
                <DataTableStandard
                    title={<div>{props.title?props.title:"Completed"}{(checkAccess(props.access, requiredAdminAccess) ? <FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={edit} onChange={(event) => { setEdit(event.target.checked) }} />} label={edit ? 'Edit Enabled' : 'Edit Disabled'} /> : '')}</div>}
                    data={completedData}
                    columns={columns}
                    options={optionsCompleted}
                    returnSearch={!loading}
                    reload={() => { setReload(!reload) }}
                />
            </Grid>
        </Grid>

    );
};

export default Receivals;
