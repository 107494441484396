import React from 'react';
import Grid from "@mui/material/Grid";
import FormModal from '../../../components/FormModal';
import Button from "@mui/material/Button";
import { postApi, getAPIDataParamsReturn } from '../../../utils/apiFunction';
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import OnShoreCard from './OnShoreCard';
import HistoryDisplay from './HistoryDisplay';
import { Box, CircularProgress, Typography } from '@mui/material';
const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const UndoModal = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));

    const handleCloseModal = () => {
        props.setOpen(false);
    }
    const reloadLines = async () => {
        if (props.reload) {
            if (props.startFeature) {
                await props.reload(props.startFeature?.properties?.warehouseId);
            } else {
                await props.reload();
            }

        }
    }

    return (

        <FormModal open={props.open} handleClose={handleCloseModal} title={'Undo'} style={{ zIndex: 100000 }}>
            <UndoDescription {...props} handleClose={handleCloseModal} reloadLines={reloadLines} />
        </FormModal>
    );
};
const MiniGroup = (props) => {

    return <div style={{ padding: '5px', width: '150px', border: '1px solid black', borderRadius: '5px' }} >
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <Typography variant="body1" >{props.data?.grade?.description}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" >{props.data?.unitType?.description}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" >Units: {props.data?.totalUnits}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" >Oys/Unit: {props.data?.average}</Typography>
            </Grid>
        </Grid>
    </div>
}
const Siblings = (props) => {
    const [siblings, setSiblings] = React.useState(null);
    React.useEffect(() => {
        if (props.GroupId && props.ParentId) {
            getAPIDataParamsReturn('/Farms/Groups/GetChildren', { GroupId: props.ParentId, OneLevel: false }).then((res) => {
                setSiblings(res.filter((c) => c.data?.id !== props.ParentId));
            });
        }
    }, [props.GroupId, props.ParentId]);
    return <Grid container spacing={0}>
        {siblings?.map((c) => {
            return <Grid item xs={12} md={6} lg={4} > <MiniGroup data={c.data} /></Grid>
        })}
    </Grid>
}
const WhatsBeingUndone = (props) => {
    const [successUndo, setSuccessUndo] = React.useState(false);
    const [failUndo, setFailUndo] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    var children = props.children.filter((c) => String(c.data.id) !== String(props.GroupId));
    var text = '';
    var description = '';
    var undoObj = {
        groupId: props.GroupId,
        parentId: null,
        locId: null,
        shoreId: null
    };
    var undoArr = [{ ...undoObj }];
    var canBeUndone = true;
    if (children?.length > 0) {
        undoArr[0].groupId = children[0].data.id;
        undoArr[0].parentId = props.GroupId;
        undoArr[0].shoreId = props.group?.shoreId;
        text = 'This will undo the grading that has come from this Group';
        description = <React.Fragment><div>The following groups will be removed:</div><Grid container spacing={0}>
            {children.map((c) => {
                return <Grid item xs={12} md={6} lg={4} > <MiniGroup data={c.data} /></Grid>
            })}
        </Grid></React.Fragment>

    } else {
        var parent = props.parents?.find((p) => String(p.id) === String(props.GroupId))?.parents?.[0]; //get first parent

        if (parent) {
            parent.data = { ...props.parents.find((p) => String(p.id) === String(parent.id))?.data };
            if (parent.type === 'Combine') {
                var prnts = props.parents.filter((p) => { return p.data?.id !== props.GroupId });
                undoArr = [];
                text = 'This will undo the Combine that made this group';
                description = <React.Fragment><div>The following groups will be recreated:</div><Grid container spacing={0}>
                    {prnts.map((c, k) => {
                        var newObj = { ...undoObj };
                        newObj.parentId = c.data?.id;
                        newObj.shoreId = c.data?.shoreId;
                        undoArr.push(newObj);
                        return <Grid item xs={12} md={6} lg={4} > <MiniGroup data={c.data} /></Grid>
                    })}
                </Grid> </React.Fragment>
            } else if (parent.type === 'Pickup') {
                undoArr[0].parentId = parent.data?.id;
                undoArr[0].locId = parent.data?.locationId;
                text = 'This will undo the Pickup of this group';
                console.log(parent);
                description = 'This Group will be returned to the location:\r\n' +
                    (parent.data?.location?.line?.lease?.name + ' - Row ' + parent.data?.location?.line?.lineNbr)
            } else if (parent.type === 'Deploy') {
                console.log(parent);
                undoArr[0].parentId = parent.data?.id;
                undoArr[0].shoreId = parent.data?.shoreId;
                text = 'This will undo the Deployment of this group';
                description = 'This Group will be returned to on shore';
            } else if (parent.type === 'Split') {
                undoArr[0].parentId = parent.data?.id;
                undoArr[0].shoreId = parent.data?.shoreId;
                text = 'This will undo the Split that made this group';
                description = <React.Fragment><div>The following groups will be removed:</div>
                    <Siblings ParentId={parent.data?.id} GroupId={props.GroupId} />
                    <div>The following group will be recreated:</div><MiniGroup data={parent?.data} /> </React.Fragment>
            } else if (parent.type === 'Grade') {
                undoArr[0].parentId = parent.data?.id;
                undoArr[0].shoreId = parent.data?.shoreId;
                text = 'This will undo the Grade of this group';
                description = <React.Fragment><div>The following groups will be removed:</div>
                    <Siblings ParentId={parent.data?.id} GroupId={props.GroupId} />
                    <div>The following group will be recreated:</div><MiniGroup data={parent?.data} /> </React.Fragment>
            } else {
                text = 'This Cannot be undone, contact IT';
                canBeUndone = false;
            }
        } else {
            text = 'This Cannot be undone, contact IT';
            canBeUndone = false;
        }
    }
    const undo = async () => {
        setLoading(true);
        var allRes = [];
        for (var i = 0; i < undoArr.length; i++) {
            var u = undoArr[i];
            allRes.push(await postApi('/Farms/Groups/Undo', { GroupId: u.groupId, OldGroupId: u.parentId, ShoreId: u.shoreId, LocationId: u.locId }));
        }
        await props.reloadLines();
        var allOk = true;
        for (var i = 0; i < allRes.length; i++) {
            if (allRes[i].ok) {
            } else {
                allOk = false;
            }
        }
        if (allOk) {
            setSuccessUndo(true);
        } else {
            setFailUndo(true);
        }
        setLoading(false);

}
const close = () => {
    props.handleClose();
}
return <div>
    <Typography variant="h2" >{text}</Typography>
    <Typography variant="h2" >{description}</Typography>
    {!successUndo && !failUndo ? (<Grid container spacing={2}>
        {canBeUndone ? <Grid item xs={12} md={6}>
            <Button onClick={undo} variant="contained" color="error">{loading ? <CircularProgress /> : 'Yes Undo'}</Button>
        </Grid> : null}
        <Grid item xs={12} md={6}>
            <Button onClick={close} variant="contained" color="primary">No, Go Back</Button>
        </Grid>
    </Grid>) : (
        successUndo ? <Button onClick={close} variant="contained" color="success">Success Click to Close</Button> :
            <Button onClick={close} variant="contained" color="error"> Undo has failed, contact IT</Button>
    )}
</div>
}


const UndoDescription = (props) => {
    const [parents, setParents] = React.useState(null);
    const [children, setChildren] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const [undoButtonSet, setUndoButtonSet] = React.useState(false);
    React.useEffect(() => {
        getAPIDataParamsReturn('/Farms/Groups/GetChildren', { GroupId: props.GroupId, OneLevel: false }).then((res) => {
            setChildren(res);
        });
        getAPIDataParamsReturn('/Farms/Groups/GetHistory', { GroupId: props.GroupId, OneLevel: true }).then((res) => {
            setParents(res);
            setGroup(res.find((p) => String(p.id) === String(props.GroupId))?.data);
        });
    }, [props.GroupId]);


    if (parents && children) {
        return <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h2" >
                        Are you sure you want to <b>Undo</b> the last action?
                    </Typography>
                    <WhatsBeingUndone reloadLines={props.reloadLines} handleClose={props.handleClose} group={group} parents={parents} children={children} GroupId={props.GroupId} />

                </Grid>
                <Grid item xs={12} md={6} >
                    {group ? <OnShoreCard StillOnWater={props.StillOnWater} cropCols={props.cropCols} isSel={false} selectedCard={() => { }} selected={null} group={group} canCombine={false} opacity={0.5} ind={0} selectFunc={() => { }} combineFunc={() => { }} gradeFunc={() => { }} splitFunc={() => { }} /> : null}
                </Grid>

            </Grid>
        </Box>

    } else {
        return <CircularProgress />
    }
}
export default UndoModal;
