import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CustomerPricing from './Tables/CustomerPricing';
import FormModal from '../../components/FormModal';
import PriceForm from '../Procurement/Forms/PriceForm';
const CustomerPricingDisp = (props) => {

    const [rowData, setRowData] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [newForm, setNew] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const reloadFunc = () => {
        setReload(!reload);
    }
    const handleClose = () => setOpen(false);
    const openPriceForm = (dataArr) => (rowD, rowState) => {
        setRowData(dataArr[rowState.dataIndex]);
        setOpen(true);
        setNew(false);
    }
    const createPriceForm = () => {
        setOpen(true);
        setNew(true);
        setRowData(null);
    }
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Add Customer Pricing"} >
                <PriceForm new={newForm} data={rowData} access={props.access} reload={reloadFunc} handleClose={handleClose} CustomerPricing/>
            </FormModal>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Button onClick={createPriceForm} variant="contained" >Add Customer Pricing</Button>
                </Grid>
                <Grid item xs={12}>
                    <CustomerPricing onClick={openPriceForm} reload={reloadFunc} />
                </Grid>
        </Grid>
        </React.Fragment >
    );
};



export default CustomerPricingDisp;
