import React from 'react';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

const StringSelect = (props) => {
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (props.input) {
            var est = props.options.find((dr) => { return props.labelFunc ? (props.labelFunc(props.input) === props.labelFunc(dr)) : ((props.input) === (dr)) });
            console.log(est, props.options)
            props.setState(est);
            setValue(est);
        } else if (props.default > -1) {

            var est = props.options[props.default];
            props.setState(est);
            setValue(est);
        }
        console.log(props.input)
    }, [props.input])

    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => { return props.labelFunc ? props.labelFunc(option) : option }}
        disablePortal
        id="stat"
        options={props.options}
        sx={{ width: props.width ? props.width:300 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}
    />);



}

export const SimpleStringSelect = (props) => {
    const [inputValue, setInputValue] = React.useState(props.state);

    return (<Autocomplete
        value={props.state}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => { return props.labelFunc ? props.labelFunc(option) : option }}
        disablePortal
        id="stat"
        options={props.options}
        sx={{ width: props.width ? props.width : 300 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}
    />);



}

export default StringSelect;