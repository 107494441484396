import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import Transfers from "../../components/Transfers/Transfers";
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { OTR_LOCATIONS } from '../../utils/constants/OTRselections';
import { checkAccess } from '../../utils/roleFunctions';
import { Locations } from "../../utils/constants/locations";
import Receivals from '../Processing/Tables/Receivals';
import FormModal from '../../components/FormModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import CreateOTR from '../../components/Transfers/CreateTransfer';
import SelectWarehouse from '../../components/SelectWarehouse';
const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';
const PurchaseOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [completed, setCompleted] = React.useState(false);
    const [edit, setEdit] = React.useState(true);
    const [data, setData] = React.useState(null);
    const [farm, setFarm] = React.useState(null);
    const [duplicate, setDuplicate] = React.useState(false);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const createFatoF = (searchParams.get("task") === "createFatoF" ? true : false);
    const createFatoFa = (searchParams.get("task") === "createFatoFa" ? true : false);
    const status = (searchParams.get("status"));
    const [open, setOpen] = React.useState((createFatoF || createFatoFa) ? true : false);
    const [forceTask, setForceTask] = React.useState(null);

    React.useEffect(() => {
        console.log(searchParams.get("task"));
        if (searchParams.get("task")) {
            setOpen(true);

        }
    }, [searchParams.get("task")])

    const handleClose = (date) => () => {
        if (date) {
            setStartDate(startOfWeek(new Date(date)));
            setEndDate(endOfWeek(new Date(date)));
        }
        setOpen(false);
        setEdit(false);
        setCompleted(false);
        setDuplicate(false);
        reloadFunc();
    }
    const reloadFunc = () => {
        setReload(!reload);
    }

    const createPO = (option) => () => {
        setForceTask(option)
        setOpen(true);
        setCompleted(false);
        setEdit(false);
        setData(null);
    }
    const updatePO = (array) => (rowd, rowState) => {
        console.log(array, rowState)
        setData(array[rowState.dataIndex]);
        setOpen(true);
        if (status === READY) {
            setEdit(true);
            setCompleted(false);
        } else {
            setEdit(false);
            setCompleted(true);
        }
    }

    var toL = OTR_LOCATIONS.FACTORY
    var from = OTR_LOCATIONS.FACTORY
    if ((forceTask === 'createFatoF') || (!forceTask && createFatoF)) {
        toL = OTR_LOCATIONS.FARM
        from = OTR_LOCATIONS.FACTORY
    } else if ((forceTask === 'createFatoFa') || (!forceTask && createFatoFa)) {
        toL = OTR_LOCATIONS.FACTORY
        from = OTR_LOCATIONS.FACTORY
    }


    //Ready PickedUp Received Cancelled
  
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose(null)} title={edit ? "UpdateOTR" : "Create OTR"} >
                <CreateOTR data={data} from={from} to={toL} internal access={props.access} handleClose={handleClose(null)} createOTR completed={completed} edit={edit} reload={reloadFunc} />
            </FormModal>
            {/*//Buttoins*/}
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <SelectWarehouse setState={setFarm} state={farm} access={props.access} />
                    </Grid>
            </Grid>

            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Button onClick={createPO("createFatoF")} variant="contained" style={{ margin: '5px 5px 5px 5px' }}>Create New Factory to Farm </Button>
                    <Button onClick={createPO("createFatoFa")} variant="contained" style={{ margin: '5px 5px 5px 5px' }}>Create New Factory to Factory </Button>

                </Grid>
                <Grid item xs={12}>
                    <Transfers warehouseId={farm?.id} startDate={startDate} endDate={(endDate)} asSource={true} asDestination={!(status === READY)} ready={status === READY} inTransit={status === PICKEDUP} received={status === RECEIVED} cancelled={status === CANCELLED} onClick={updatePO} reload={reload} reloadFunc={reloadFunc} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default PurchaseOrdersPage;
