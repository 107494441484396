import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow, textfieldDateFormat } from "../../utils/dateFunctions";
import { getAPIData, getAPIDataParams, getAPIDataParamsReturn } from "../../utils/apiFunction";
import { numberFormat } from '../../utils/numberFunctions';
import DatePicker from "../../shared/DatePicker";
import { useSearchParams } from "react-router-dom";
import { locationMatch, buildColGroups } from '../../utils/constants/orderItems';
import CustomerOrder from '../Processing/Tables/CustomerOrder';
import DraggablePurchaseOrder from '../Procurement/Tables/DraggablePurchaseOrders';
import CompletedRuns from '../Procurement/Tables/CompletedRuns';
import SelectWarehouse from '../../components/SelectWarehouse';
import { WAREHOUSEGROUPS, WAREHOUSEMATCHES } from "../../utils/constants/warehouse";
import { useJumboTheme } from "@jumbo/hooks";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TableRow, Table, TableCell, TableHead, TableBody, Typography } from '@mui/material';
import HeadRows from '../../components/CustomerOrder/HeadRows';
import { checkCols, shiftCol, findbyTopLevel, generateValues, printColor, getColFromCol, findbyName } from '../../components/CustomerOrder/CustomerOrderFunctions';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const DailyPlan = (props) => {
    const { theme } = useJumboTheme();
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [location, setLocation] = React.useState(null);
    const [display, setDisplay] = React.useState(0);
    const [dailyGoals, setDailyGoals] = React.useState(null);
    const [tomorrowGoals, setTomorrowGoals] = React.useState(null);
    const [OrderColumns, setOrderColumns] = React.useState(null);
    const [showTomorrow, setShowTomorrow] = React.useState(false);

    var showColumns = []; //only cahgne in geneterate body data
    const [topLevelList, setTopLevelList] = React.useState([]);
    const productPalette = theme.palette.oysterProducts;


    //use memo to save startdate tomorrow
    var tomorrowDate = useMemo(() => { return tomorrow(startDate) }, [startDate]);

    const reloadPage = () => {
        setReload(!reload);
    }
    const getOrderCols = async () => {
        var getCols = await (await buildColGroups(true));
        setOrderColumns(getCols.sortList);
        var cols = [productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F];
        setTopLevelList(getCols?.displayStruc.map((tl, ind) => {

            return {
                name: tl.name,
                col: generateValues(cols[ind]),
                cols: findbyTopLevel(tl.name, getCols.sortList)
            }
        }));
    }

    React.useEffect(() => {
        if (location && startDate && !isNaN(startDate.valueOf())) {
            getAPIDataParamsReturn('/Processing/GetDailyGoals', { Date: textfieldDateFormat(startDate), WarehouseId: location?.id }).then((dgs) => {
                setDailyGoals(dgs);
            });
            getAPIDataParamsReturn('/Processing/GetDailyGoals', { Date: textfieldDateFormat(tomorrow(startDate)), WarehouseId: location?.id }).then((dgs) => {
                setTomorrowGoals(dgs);
            });
            getOrderCols();
        }
    }, [location, startDate, reload]);
    console.log(OrderColumns);
    if ((OrderColumns) && (dailyGoals) && (tomorrowGoals) && location) {
        for (var i = 0; i < OrderColumns.length; i++) {
            if (OrderColumns[i].location === locationMatch(location.myobid)) {
                showColumns.push(i);
                //if (dailyGoals.find((dg) => (dg.stockItem?.myobid === OrderColumns[i].code))) {
                //    showColumns.push(i);
                //} else if (showTomorrow && (tomorrowGoals.find((dg) => (dg.stockItem?.myobid === OrderColumns[i].code)))) {
                //    showColumns.push(i);
                //}
            }
        }
        var liveCols = [...findbyName("Half Shell (BBay)", OrderColumns), ...findbyName("Half Shell (Syd)", OrderColumns), ...findbyName("Half Shell (Contractor)", OrderColumns)];
        //filter showcoloumns by livecols
        console.log(liveCols);
        showColumns = showColumns.filter((sc) => (liveCols.includes(sc)));

        console.log(showColumns)
    }



    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DatePicker date={startDate} setDate={setStartDate} label="Date" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <SelectWarehouse access={props.access} enforceAccess={true} state={location} setState={setLocation} label="Date" all useCache selectOne={(inv) => { return inv === 'SYDNEY' }} />
                </Grid>
                <Grid item xs={12}>
                    <BodyRows showColumns={showColumns} OrderColumns={OrderColumns} dailyGoals={dailyGoals} topLevelList={topLevelList} tomorrowGoals={tomorrowGoals} showTomorrow={showTomorrow} setShowTomorrow={setShowTomorrow} location={location} />
                </Grid>
                {location ? null : <Typography variant={'h1'} style={{ padding: '50px' }}><ArrowUpwardIcon />Please Choose a Warehouse<ArrowUpwardIcon /></Typography>}
            </Grid>
        </React.Fragment >
    );
};

const BodyRows = ({ showColumns, OrderColumns, dailyGoals, tomorrowGoals, topLevelList, showTomorrow, setShowTomorrow, location }) => {
    const { theme } = useJumboTheme();
    const productPalette = theme.palette.oysterProducts;

    var stickyCol = {
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper
    }

    var custSize = {
        width: '240px',
        maxHeight: '70px',
        overflow: 'auto',
    }

    const colorMixer = (base, added) => {
        var mix = { r: 0, g: 0, b: 0, a: 0 };
        mix.a = 1 - (1 - added.a) * (1 - base.a); // alpha
        mix.r = Math.round((added.r * added.a / mix.a) + (base.r * base.a * (1 - added.a) / mix.a)); // red
        mix.g = Math.round((added.g * added.a / mix.a) + (base.g * base.a * (1 - added.a) / mix.a)); // green
        mix.b = Math.round((added.b * added.a / mix.a) + (base.b * base.a * (1 - added.a) / mix.a)); // blue
        return 'rgba(' + mix.r + ',' + mix.g + ',' + mix.b + ',' + mix.a + ')';
    }
    const DropButton = () => {
        return <Button
            style={{ marginLeft: '5px' }}
            aria-label="expand row"
            size="small"
            variant='outlined'
            onClick={() => setShowTomorrow(!showTomorrow)}
        >
            {showTomorrow ? 'Hide Tomorrow' : 'Show Tomorrow'}
        </Button>
    }

    const orgDG = React.useMemo(() => {
        //group by stockitem.isPremium then by stockitem.gradeId then by stockitem.traySize
        //structure is {grade: {premium: {traySize: [dailygoal]}}}
        var dg = {};
        dailyGoals?.forEach((d) => {
            if (d.stockItem.isHalfshell) {
                var prodCode = (d.stockItem.description.includes('WIP') ? 'W' : ( d.stockItem.isOysterSmiths ? 'P' :'R'));
                var tSize = (prodCode === 'W' ? (d.stockItem.description.includes('1doz') ? '1' : '2.5') : String(d.stockItem.traySize));
                if (!dg[d.stockItem.gradeId]) {
                    dg[d.stockItem.gradeId] = {};
                }
                if (!dg[d.stockItem.gradeId][prodCode]) {
                    dg[d.stockItem.gradeId][prodCode] = {};
                }
                if (!dg[d.stockItem.gradeId][prodCode][tSize]) {
                    dg[d.stockItem.gradeId][prodCode][tSize] = [];
                }
                dg[d.stockItem.gradeId][prodCode][tSize].push(d);
            }
        });
        return dg;
    }, [dailyGoals]);

    const ifUndef = (val) => {
        if (val === undefined) {
            return 0;
        } else {
            return val;
        }
    }
    const cellStyle = {
        textAlign: 'center',
        border: '1px solid black'
    }
    var cols = [productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F, productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F];
    console.log(orgDG)
    return (<Table>
        <TableHead>
            <TableRow  >
                <TableCell>
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    var gradeDesc = dailyGoals.find((dg) => (dg.stockItem.gradeId === Number(gId)))?.stockItem.grade?.description;
                    var specDesc = dailyGoals.find((dg) => (dg.stockItem.gradeId === Number(gId)))?.stockItem.species?.description;
                    return <React.Fragment>
                        <TableCell colSpan={4} style={{ ...cellStyle, background: cols[i] }} >
                            {specDesc + ' ' + gradeDesc}
                        </TableCell>

                    </React.Fragment>
                }) : null}
            </TableRow>
            <TableRow  >
                <TableCell style={{ borderBottom: '1px solid black' }} >
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    
                    return <React.Fragment>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }} >
                            {'AOC'}
                        </TableCell>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }}>
                            {'Oystersmiths'}

                        </TableCell>
                    </React.Fragment>
                }) : null}
            </TableRow>


        </TableHead>
        <TableBody>
            <TableRow  >
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }} >
                    Orders by Product
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    return <React.Fragment>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }} >
                            {ifUndef(orgDG[gId]['R'] ? Object.keys(orgDG[gId]['R']).reduce((acc, cur) => { return acc + orgDG[gId]['R'][cur].reduce((p, c) => { return p + c.orders }, 0) }, 0) : 0)}
                        </TableCell>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }} >
                            {ifUndef(orgDG[gId]['P'] ? Object.keys(orgDG[gId]['P'])?.reduce((acc, cur) => { return acc + orgDG[gId]['P'][cur].reduce((p, c) => { return p + c.orders }, 0) }, 0) : 0)}
                        </TableCell>
                    </React.Fragment>
                }) : null}
            </TableRow>
            <TableRow  >
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }}>
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    return <React.Fragment>
                        <TableCell style={{ ...cellStyle, fontWeight: 'bold', background: cols[i] }}>
                            1 doz
                        </TableCell>
                        <TableCell style={{ ...cellStyle, fontWeight: 'bold', background: cols[i] }}>
                            2.5 doz
                        </TableCell>
                        <TableCell style={{ ...cellStyle, fontWeight: 'bold', background: cols[i] }} >
                            1 doz
                        </TableCell>
                        <TableCell style={{ ...cellStyle, fontWeight: 'bold', background: cols[i] }} >
                            2.5 doz
                        </TableCell>
                    </React.Fragment>
                }) : null}
            </TableRow>
            <TableRow>
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }}>
                    Orders by Tray Size
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    return <React.Fragment>
                        <TableCell style={{ ...cellStyle, background: cols[i] }} >
                            {ifUndef(orgDG[gId]['R']?.['1']?.reduce((acc, cur) => { return acc + cur.orders }, 0))}
                        </TableCell>
                        <TableCell style={{ ...cellStyle, background: cols[i] }}>
                            {ifUndef(orgDG[gId]['R']?.['2.5']?.reduce((acc, cur) => { return acc + cur.orders }, 0))}
                        </TableCell>
                        <TableCell style={{ ...cellStyle, background: cols[i] }}>
                            {ifUndef(orgDG[gId]['P']?.['1']?.reduce((acc, cur) => { return acc + cur.orders }, 0))}
                        </TableCell>
                        <TableCell style={{ ...cellStyle, background: cols[i] }}>
                            {ifUndef(orgDG[gId]['P']?.['2.5']?.reduce((acc, cur) => { return acc + cur.orders }, 0))}
                        </TableCell>
                    </React.Fragment>
                }) : null}
            </TableRow>
            <TableRow style={{ borderBottom: '2px solid black' }} >
                <TableCell style={{ ...cellStyle, fontWeight: 'bold', borderBottom: '4px solid black' }}>
                    Total Orders
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    return <TableCell colSpan={4} style={{ ...cellStyle, background: cols[i], borderBottom: '4px solid black' }}>
                        {ifUndef(Object.keys(orgDG[gId]).reduce((p, c) => { return p + (c === 'W' ? 0 : Object.keys(orgDG[gId][c]).reduce((pc, cr) => { return pc + orgDG[gId][c][cr].reduce((pa, ca) => { return pa + ca.orders }, 0) }, 0)) }, 0))}
                    </TableCell>
                }) : null}
            </TableRow>
            <TableRow style={{ fontWeight: 'bold' }} >
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }}>
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    return <React.Fragment>
                        <TableCell style={{ ...cellStyle, fontWeight: 'bold', background: cols[i] }} colSpan={2} >
                            1 doz
                        </TableCell>
                        <TableCell style={{ ...cellStyle, fontWeight: 'bold', background: cols[i] }} colSpan={2} >
                            2.5 doz
                        </TableCell>

                    </React.Fragment>
                }) : null}
            </TableRow>
            <TableRow>
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }}>
                    On Hand
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => (
                    <React.Fragment>
                        <TableCell style={{ ...cellStyle, background: cols[i] }} colSpan={2} >
                        </TableCell>
                        <TableCell style={{ ...cellStyle, background: cols[i] }} colSpan={2} >
                        </TableCell>
                    </React.Fragment>
                )) : null}
            </TableRow>

            <TableRow>
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }}>
                    Processed
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    return <React.Fragment>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }}>
                            {ifUndef(orgDG[gId]['W']?.['1']?.reduce((acc, cur) => { return acc + cur.processed }, 0))}
                        </TableCell>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }}>
                            {ifUndef(orgDG[gId]['W']?.['2.5']?.reduce((acc, cur) => { return acc + cur.processed }, 0))}
                        </TableCell>

                    </React.Fragment>
                }) : null}
            </TableRow>
            <TableRow>
                <TableCell style={{ ...cellStyle, fontWeight: 'bold' }}>
                    Remaining
                </TableCell>
                {orgDG ? Object.keys(orgDG).map((gId, i) => {
                    var pro1doz = ifUndef(orgDG[gId]['W']?.['1']?.reduce((acc, cur) => { return acc + cur.processed }, 0));
                    var pro2doz = ifUndef(orgDG[gId]['W']?.['2.5']?.reduce((acc, cur) => { return acc + cur.processed }, 0));
                    var reg1doz = ifUndef(orgDG[gId]['R']?.['1']?.reduce((acc, cur) => { return acc + cur.orders }, 0))
                    var reg2doz = ifUndef(orgDG[gId]['R']?.['2.5']?.reduce((acc, cur) => { return acc + cur.orders }, 0))
                    var pr1doz = ifUndef(orgDG[gId]['P']?.['1']?.reduce((acc, cur) => { return acc + cur.orders }, 0))
                    var pr2doz = ifUndef(orgDG[gId]['P']?.['2.5']?.reduce((acc, cur) => { return acc + cur.orders }, 0))
                    return <React.Fragment>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }}>
                            {reg1doz + pr1doz - pro1doz}
                        </TableCell>
                        <TableCell colSpan={2} style={{ ...cellStyle, background: cols[i] }}>
                            {reg2doz + pr2doz - pro2doz}

                        </TableCell>

                    </React.Fragment>
                }) : null}
            </TableRow>

        </TableBody>
    </Table>)

}
export default DailyPlan;


