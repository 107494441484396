import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { dateTimeFormat } from "../../../utils/dateFunctions";
import Div from "@jumbo/shared/Div";
import DateSelector from "../../../shared/DateSelector";
import { adalApiFetch } from '../../../../adalConfig';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import DropZone from "../../../components/DropZone";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { dataURItoBlob } from '../../../utils/imageFunctions';
import StaffMember from "../../../components/StaffMember";
import { CircularProgress } from '@mui/material';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import SelectTextField from '../../../components/SelectTextField';

const unknownStock = { id: 99999999999, species: { description: '' }, grade: { description: 'Discard' } };

const QAForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [stockItem, setStockItem] = React.useState(null);
    const [conditions, setConditions] = React.useState([]);
    const [conditionGroups, setConditionGroups] = React.useState([]);
    const [condition, setCondition] = React.useState(null);
    const [conditionGroup, setConditionGroup] = React.useState(null);
    const [shellBarnacles, setShellBarnacles] = React.useState([]);
    const [shellBarnacle, setShellBarnacle] = React.useState(null);
    const [shellBarnacleGroup, setShellBarnacleGroup] = React.useState(null);
    const [shellBarnacleGroups, setShellBarnacleGroups] = React.useState([]);
    const [otherShellDesc, setOtherShellDesc] = React.useState(props.data ? props.data.otherShellDesc : '');
    const [otherConditionDesc, setOtherConditionDesc] = React.useState(props.data ? props.data.otherConditionDesc : '');
    const [haveImage, setHaveImage] = React.useState(props.edit ? true : false);
    const [showCamera, setShowCamera] = React.useState(false);
    const [sizes, setSizes] = React.useState([]);
    const [imageUrl, setImageUrl] = React.useState('');
    const [sizing, setSizing] = React.useState(null);
    const id = props.receival ? null : (props.data ? props.data.id : null);
    const [prn, setPRN] = React.useState(props.receival ? (props.receival.prn) : '');
    const [date, setDate] = React.useState(props.receival ? (props.receival.pickupDate) : '');
    const receivalId = props.receival ? props.receival.id : (props.data ? props.data.receivalId : '');
    const [supplier, setSupplier] = React.useState(props.receival ? props.receival.supplierPredict : '');
    const vertical = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const Swal = useSwalWrapper();
    const [driver, setDriver] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const [rank, setRank] = React.useState(null);
    const [ranks, setRanks] = React.useState([]);
    const [comment, setComment] = React.useState(null)
    const [receival, setReceival] = React.useState(null);
    const [loadedQA, setLoadedQA] = React.useState(null);
    const [stockItems, setStockItems] = React.useState(null);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    console.log(loadedQA)

    const addDiscard = (list) => {
        list.push(unknownStock);
        setStockItems(list);
    }
    React.useEffect(() => {
        getAPIData('/Receivals/Get/' + receivalId, setReceival);
        if (props.data) {
            if (!props.receival) {
                getAPIData('/QA/Get/' + id, setLoadedQA);
            }
        } else if (completed && props.receival) {
            getAPIData('/QA/GetByReceival/' + receivalId, setLoadedQA);
        }
        getAPIData('/StockItems/GetUnprocessed', addDiscard);
        getAPIData('/QA/Rank/Get', setRanks);
        getAPIData('/QA/QASizeRating', setSizes);
        getAPIData('/QA/QAShellRating', reduceChoices(setShellBarnacles, setShellBarnacleGroups));
        getAPIData('/QA/QAConditionRating', reduceChoices(setConditions, setConditionGroups));
    }, []);
    React.useEffect(() => {
        if (receival) {
            setSupplier(receival.supplierPredict);
            setDate(dateTimeFormat(receival.pickupDate));
            setPRN(receival.prn);
        }
    }, [receival]);

    React.useEffect(() => {
        console.log(loadedQA);
        if (loadedQA) {
            setImageUrl(loadedQA.imageUrl);
            setComment(loadedQA.comment);
        }
    }, [loadedQA]);
    React.useEffect(() => {
        if (loadedQA) {
            var sizeRating = sizes.find((sz) => { return sz.id === loadedQA.sizingRatingId });
            if (sizeRating) {
                setSizing(sizeRating);
            }
        }
    }, [sizes, loadedQA]);
    React.useEffect(() => {
        if (loadedQA) {
            var shell = shellBarnacles.find((sz) => { return sz.id === loadedQA.shellReasonId });
            if (shell) {
                var shellGroup = shellBarnacleGroups.find((sz) => { return shell.rating === sz.name });
                if (shellGroup) {
                    setShellBarnacleGroup(shellGroup);
                    setShellBarnacle(shell);
                }
            }
        }
    }, [shellBarnacles, shellBarnacleGroups, loadedQA]);
    React.useEffect(() => {
        console.log(loadedQA)
        if (loadedQA) {
            var cond = conditions.find((sz) => { return sz.id === loadedQA.conditionReasonId });
            if (cond) {
                var condGroup = conditionGroups.find((sz) => { return cond.rating === sz.name });
                if (condGroup) {
                    setConditionGroup(condGroup);
                    setCondition(cond);
                }
            }
        }
    }, [conditions, conditionGroups, loadedQA]);


    var getAPIData = async (path, stateSet) => {
        const response = await adalApiFetch(fetch, uri + path);
        const data = await response.json();
        stateSet(data);
    }
    const reduceChoices = (setState, setGroupState) => (list) => {
        setState(list);
        var groupList = [];
        var groupNames = [];
        for (var i = 0; i < list.length; i++) {
            if (!groupNames.includes(list[i].rating)) {
                groupList.push({ id: list[i].id, name: list[i].rating, action: list[i].action, colour: list[i].colour });
                groupNames.push(list[i].rating);
            }
        }
        setGroupState(groupList);
    }
    const filterChoice = (list, group) => {

        return list.filter((a) => {
            if (a.rating === group.name) {
                return true;
            }
        });
    }
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!sizing) {
            errorMessages.push("You must select a Size");
        }
        if (!shellBarnacle) {//  || (shellBarnacle.length < 1)) {
            errorMessages.push("You must select a Shell Rating");

        }
        if (!condition) { // || (condition.length < 1)) {
            errorMessages.push("You must select a Condition");

        }
        if (!stockItem) { // || (condition.length < 1)) {
            errorMessages.push("You must select a Grade");
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else {
            setLoading(true);
            setCompleted(true);
            submit();
        }
    }
    const EditImage = () => {
        if (haveImage) {
            return <div><div>Click to Remove</div><img src={imageUrl} width="25%" onClick={() => { setHaveImage(!haveImage) }} /></div>

        } else {
            return <DropZone files={files} setFiles={setFiles} />
        }
    }

    const submit = async () => {
        var data = {
            receivalId: receivalId,
            sizingRatingId: sizing.id,
            shellReasonId: shellBarnacle.id,//shellBarnacle.map((a) => { return a.id }),
            ConditionReasonId: condition.id,//condition.map((a) => { return a.id }),
            StaffMemberId: driver ? driver.id : '1',
            OtherShellDesc: otherShellDesc ? otherShellDesc : null,
            OtherConditionDesc: otherConditionDesc ? otherConditionDesc : null,
        }
        if (stockItem.id !== unknownStock.id) {
            data.StockItemId = stockItem?.id
        }
        if (rank) {
            data.RankId = rank.id;
        }
        if (comment) {
            data.Comment = comment;
        }

        //image upload
        var response = null;
        if (id) {

            response = await adalApiFetch(fetch, uri + '/QA/Post/' + id + '?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        } else {
            response = await adalApiFetch(fetch, uri + '/QA/Post?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        }
        const qaRec = await response.json();
        if (qaRec && qaRec.id) {
            var formdata = new FormData();
            console.log(files);
            if (files[0]) {
                formdata.append(files[0].name, files[0], files[0].name);


            } else if (imageUrl) {
                var blob = dataURItoBlob(imageUrl);
                formdata.append("QA.jpg", blob);
            }
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            var response = await adalApiFetch(fetch, uri + '/QA/ImageUpload/' + qaRec.id, requestOptions);
        }
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    const handleTakePhoto = (dataUri) => {
        setImageUrl(dataUri);
        setHaveImage(true);
    }

    //memoise the stockitem filter
    const firstStockItem = React.useMemo(() => {
        return stockItems?.find((a) => {
            if (a.id === props.receival?.receivalLines?.[0]?.stockItemId) {
                return true;
            }
        });
    }, [stockItems, props.receival]);
    //console.log();
    //console.log([...(stockItems?(firstStockItem ? stockItems?.filter((ogn) => { return (receival?.receivalLines ? (receival?.receivalLines?.find((r) => (r.stockItemId === ogn.id))) : true) }) : stockItems):[]), unknownStock]);

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <TextField
                    disabled
                    id="outlined-required"
                    label="PRN"
                    value={prn}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    disabled
                    id="outlined-required"
                    label="Supplier"
                    value={supplier}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextField
                    disabled
                    id="outlined-required"
                    label="Date"
                    value={date}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Div>
            <Div>
                <StaffMember staffId={loadedQA?.staffMemberId} driver={driver} setDriver={setDriver} disabled={completed} />
                <h3 style={{ fontWeight: 450 }}>Grade of QA Sample</h3>
                <SelectTextField disabled={stockItems ? completed : false} checkID={loadedQA?.stockItemId !== null ? loadedQA?.stockItemId : unknownStock.id} state={stockItem} setState={setStockItem} list={(stockItems ? (firstStockItem ? stockItems?.filter((ogn) => { return (receival?.receivalLines ? (receival?.receivalLines?.find((r) => (r.stockItemId === ogn.id))) : true) || (ogn.id === unknownStock.id) }) : stockItems) : [])} nameFunc={(g) => (g.species.description + ' ' + g.grade?.description)} />
                <ButtonGroupSelector exclusive state={sizing} setState={setSizing} title={"Sizing"} list={sizes} disabled={completed} />
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={6} sm={6} md={6} lg={12} xl={12}>
                        <ButtonGroupSelector exclusive disabled={completed} state={shellBarnacleGroup} setState={setShellBarnacleGroup} title={"Shell/Barnacle"} list={shellBarnacleGroups} extraFunc={() => { setShellBarnacle(null) }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={12} xl={12} style={vertical ? { paddingTop: "65px" } : {}}>
                        {shellBarnacleGroup ? <ButtonGroupSelector exclusive={true} disabled={completed} state={shellBarnacle} setState={setShellBarnacle} list={filterChoice(shellBarnacles, shellBarnacleGroup)} /> : ''}
                        {(shellBarnacleGroup && (shellBarnacleGroup.name === 'Other')) ? <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Comments"
                            value={otherShellDesc}
                            onChange={(event) => { setOtherShellDesc(event.target.value) }}
                            multiline
                            rows={4}
                        /> : ''}
                    </Grid>
                </Grid>
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={6} sm={6} md={6} lg={12} xl={12}>
                        <ButtonGroupSelector exclusive disabled={completed} state={conditionGroup} setState={setConditionGroup} title={"Condition"} list={conditionGroups} extraFunc={() => { setCondition(null) }} />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={12} xl={12} style={vertical ? { paddingTop: "65px" } : {}}>
                        {conditionGroup ? <ButtonGroupSelector exclusive={true} disabled={completed} state={condition} setState={setCondition} list={filterChoice(conditions, conditionGroup)} /> : ''}
                        {(conditionGroup && (conditionGroup.name === 'Other')) ? <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Comments"
                            value={otherConditionDesc}
                            onChange={(event) => { setOtherConditionDesc(event.target.value) }}
                            multiline
                            rows={4}
                        /> : ''}
                    </Grid>
                </Grid>
                <h3 style={{ fontWeight: 450 }}>Rank</h3>
                <Grid container spacing={2} mb={1}>
                    <SelectTextField disabled={completed} checkID={loadedQA?.rankId !== null ? loadedQA?.rankId : null} state={rank} setState={setRank} list={ranks.sort((a,b)=>(a.rank-b.rank))} nameFunc={(g) => (g.description)} />
                </Grid>
                <h3 style={{ fontWeight: 450 }}>Comment</h3>
                <Grid container spacing={2} mb={1}>
                    <TextField disabled={completed} id="outlined-multiline-static" onCh label="" value={comment}
                        onChange={(event) => { setComment(event.target.value) }} multiline rows={2} />
                </Grid>

                {!completed || props.edit ? <Button onClick={() => { setShowCamera(!showCamera); }} >{showCamera ? "Hide Camera" : "Show Camera"}</Button> : null}
                {(!completed || props.edit) && showCamera ? <Camera
                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    isMaxResolution={true}
                /> : null}
                {!completed || props.edit ? <EditImage /> : <Grid container spacing={2} mb={1}><Grid item xs={12} sm={12} md={12} lg={6} xl={6}><InnerImageZoom src={imageUrl} /></Grid></Grid>}
                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                    <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                </div>

            </Div>
        </Box >
    );
};

const ButtonGroupSelector = (props) => {
    const vertical = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const updateButtonGroup = (setFunc) => (event, value) => {
        if (props.extraFunc) {
            props.extraFunc();
        }
        setFunc(value);
    };
    if (props.list.length === 1) {
        props.setState(props.list[0]);
    }
    return (
        <React.Fragment>
            <h3 style={{ fontWeight: 450 }}>{props.title}</h3>
            <ToggleButtonGroup
                disabled={props.disabled}
                orientation={vertical ? "vertical" : "horizontal"}
                value={props.state}
                exclusive={props.exclusive}
                onChange={updateButtonGroup(props.setState)}
                aria-label="text alignment"
            >
                {props.list.map((option) => {
                    var tempStyle = { backgroundColor: option.colour, margin: '10px' };
                    if (!Array.isArray(props.state)) {
                        if (props.state && (props.state.id === option.id)) {
                            tempStyle = { border: '3px solid black', backgroundColor: option.colour };
                        }
                    } else {
                        var currentSelection = [...props.state];
                        if (currentSelection && (currentSelection.find((a) => { return a.id === option.id }))) {
                            tempStyle = { border: '3px solid black', backgroundColor: option.colour };
                        }
                    }
                    return (
                        <ToggleButton style={tempStyle} key={option.id} value={option} >
                            <div>
                                <div style={{ textDecoration: 'underline' }} >{option.name}</div>
                                <div>{option.action}</div>
                            </div>
                        </ToggleButton>
                    )
                }
                )}


            </ToggleButtonGroup>
        </React.Fragment>)
}


export default QAForm;
