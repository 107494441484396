import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { isWithinMinDifference } from "../../../utils/dateFunctions";
import Div from "@jumbo/shared/Div";
import { adalApiFetch } from '../../../../adalConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import DropZone from "../../../components/DropZone";
import DriverSelect from "../../../components/DriverEntry";
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { GetAllCacheDataReturn, addToCache } from "../../../utils/cacheFunctions";
import { checkAccess } from '../../../utils/roleFunctions';


const ReceivalForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [temp, setTemp] = React.useState(null);
    const [driver, setDriver] = React.useState(props.driver);
    const [otrCorrect, setOtrCorrect] = React.useState(false);
    const [hasOTR, setHasOTR] = React.useState(true);
    const [cacheDriver, setCacheDriver] = React.useState(null);
    const [driverComment, setDriverComment] = React.useState('');
    const id = props.data ? props.data.id : '';
    const date = props.data ? (props.data.orderDate) : '';
    const orderNo = props.data ? props.data.myobid : '';
    const supplier = props.data ? props.data.name : '';
    const vertical = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const Swal = useSwalWrapper();
    const [files, setFiles] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const cacheNameTemp = 'NewRecTemp';
    const cacheNameDriver = 'NewRecDriver';


    React.useEffect(() => {
        if (driver) {
            addToCache({ value: driver, timeStamp: (new Date().getTime()) }, cacheNameDriver);
        }
    }, [driver])
    React.useEffect(async () => {
        const timediff = 20;
        var tempCache = await GetAllCacheDataReturn(cacheNameTemp)
        var driverCache = await GetAllCacheDataReturn(cacheNameDriver)
        if (tempCache && isWithinMinDifference(new Date(tempCache.timeStamp), timediff,new Date())) {
            setTemp(tempCache.value);
        }
        if (driverCache && isWithinMinDifference(new Date(driverCache.timeStamp), timediff, new Date())) {
            setCacheDriver(driverCache.value);
        }
    }, [])

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!temp) {
            errorMessages.push("You must enter a Temperature");
        }
        if (!Number(temp) && (Number(temp) !== 0)) {
            errorMessages.push("The Temperature is entered incorrectly. It must be a number");

        } else {
            if ((temp) > 99) {
                errorMessages.push("The Temperature is too high.");
            }
        }

        if (files.length < 1) {
            errorMessages.push("You must attach an OTR Image");

        }
        if (!driver) {
            errorMessages.push("You must select a Driver");

        }
       

        if (!driverComment && !otrCorrect) {
            errorMessages.push("If the OTR doesn't match the delivery you must provide a comment");

        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else {
            submit();
        }
    }
    const submit = async () => {

        //image upload

        //var response = await adalApiFetch(fetch, uri + '/QA/Post?' + new URLSearchParams({
        //    ...data
        //}), {
        //    method: 'post',
        //});

        //const qaRec = await response.json();
        setLoading(true);
        var formdata = new FormData();
        formdata.append(files[0].name, files[0], files[0].name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        var response = null;
        if (props.data) {
            response = await adalApiFetch(fetch, uri + '/Receivals/PostAndLinkToPurchaseOrder/' + props.data.id, requestOptions);
        } else {
            response = await adalApiFetch(fetch, uri + '/Receivals/Post', requestOptions);
        }
        console.log(response);
        if (response.ok) {
            const decodeimageResp = await response.json();
            const data = {
                DriverId: driver.id,
                Temperature: temp,
                isOTRCorrect: otrCorrect,
                DriverComment: driverComment,
                NotAnOtr: !hasOTR
            }
            var responseDet = await adalApiFetch(fetch, uri + '/Receivals/PostFromDriver/' + decodeimageResp.id + '?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        } else {
            setError('That OTR already exists.\r\n Check receivals list or contact admin');
        }


        setLoading(false);
        setCompleted(true);
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                <TextField
                    disabled
                    id="outlined-required"
                    label="Order No."
                    value={orderNo}
                />
                <TextField
                    disabled
                    id="outlined-required"
                    label="Supplier"
                    value={supplier}
                />
                <TextField
                    disabled
                    id="outlined-required"
                    label="Date"
                    value={date}
                />
            </Div>
            <Div>


                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <DriverSelect driverId={cacheDriver?.id} driver={driver} setDriver={setDriver} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={hasOTR} onChange={(event) => { setHasOTR(event.target.checked); }} />} label="Do you have an AOC OTR?" />
                        </FormGroup>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {!completed ? <DropZone files={files} setFiles={setFiles} /> : <img src={props.data ? uri + props.data.imageUrl : ''} />}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 style={{ fontWeight: 450 }}>Temperature</h3>
                        <TextField
                            id="outlined-required"
                            label={"Temperature \u00B0\u0043"}
                            value={temp}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => { setTemp(event.target.value); addToCache({ value: event.target.value, timeStamp: (new Date().getTime()) }, cacheNameTemp); }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <h3 style={{ fontWeight: 450 }}>Does the OTR match the delivery?</h3>
                        <FormControlLabel control={<Switch checked={otrCorrect} onChange={(event) => { setOtrCorrect(event.target.checked) }} />} label={otrCorrect ? 'Yes' : 'No'} />
                        {!otrCorrect ? <TextField
                            disabled={props.disabled}
                            id="outlined-multiline-static"
                            label="Comments"
                            value={driverComment}
                            onChange={(event) => { setDriverComment(event.target.value) }}
                            multiline
                            rows={4}
                        /> : ''}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                            <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                        </div>
                    </Grid>
                </Grid>
            </Div>
        </Box >
    );
};


export default ReceivalForm;


