import React from 'react';
import { dateFormat, textfieldDateFormat, startOfMonth, endOfMonth } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { getAPIDataReturn } from "../../../utils/apiFunction";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import { Table, TableBody, TableRow, TableCell, Avatar, TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { CircularProgress } from "@mui/material";
import { useJumboTheme } from "@jumbo/hooks";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import TextField from "@mui/material/TextField";
import MessageIcon from '@mui/icons-material/Message';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Star } from "@mui/icons-material";
import { adalApiFetch } from '../../../../adalConfig';
import AddAgendaItem from '../Forms/AddAgendaItem';
import { checkAccess } from '../../../utils/roleFunctions';
import FormModal from '../../../components/FormModal';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";

const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };



const PurchaseOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selData, setSelData] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [organiseSel, setOrganiseSel] = React.useState(0);
    const [reload, setReload] = React.useState(false);
    const [receivals, setReceivals] = React.useState(null);
    const [procurement, setProcurement] = React.useState(null);
    const [unlock, setUnlock] = React.useState(false);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setProcurement(arrayMove(procurement, oldIndex, newIndex));
    };
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        console.log('jijaai')
        setProcurement([]);
        if (props.farmId) {
            getProcurementData(textfieldDateFormat(startOfMonth(props.monthDate)), textfieldDateFormat(endOfMonth(props.monthDate)), props.farmId)//props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
        }
    }, [props.location, props.monthDate, props.reload, props.farmId]);

    var getProcurementData = async (startDate, endDate, farmId) => {
        setLoading(true);

        var data = await getAPIDataReturn(('/Receivals/Get/GetBySupplier?' + new URLSearchParams({
            supplierId: farmId,
            startDate,
            endDate
        })));
        setReceivals(data);
    }
    const unlockLock = (stat) => () => {
        setUnlock(stat);
    }
    const handleClose = () => {
        setOpen(false);
        setEdit(true);
        setSelData(null);
    }
    const selectItem = (dta) => () => {
        setOpen(true);
        setEdit(true);
        setSelData(dta);
    }
    const createA = () => {
        setOpen(true);
        setSelData(null);
    }
    const doesInclude = (arr, item, attr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[0][attr] === item[attr]) {
                return true;
            }
        }
        return false;
    }
    
    return <JumboCardQuick
        title={<span>Financials</span>}
        wrapperSx={{
            p: 0,
            backgroundColor: 'background.paper',
            zIndex: 0,
            '&:last-child': {
                pb: 0
            }
        }}
    >
        <TableContainer>
            <Table>
              <TableRow style={{ zIndex: 1000, }} >
                    <TableCell sx={{ pl: 3 }}>
                        <Typography variant={"h6"} mb={0}>Operating Expenses</Typography>

                    </TableCell>
                    <TableCell sx={{ pl: 3 }}>
                    </TableCell>
                    <TableCell sx={{ pl: 3 }}>
                    </TableCell>

                </TableRow>
            </Table>
        </TableContainer>
    </JumboCardQuick>
};

export default PurchaseOrders;
/*
 * 
 * Operating Expenses -- Total
Wages & Salary
Overtime
Other Costs

Staff & Payroll -- Total
Licences & Fees
Hire of Plant, Equip & Venues
Tools& Minor Equipment
Prop. P&E Repairs & maintenance
Water Infrastructure Repairs & Maintenance
Cleaning & Rubbish Removal
Landline Phones & Internet
Electricity & Gas
Council Rates
Safety Compliance

Site Costs -- Total
MV Furl
MV Repairs & Maintenance
MV Insurance, Rego & CTP

Travel -- Total

IT & Office -- Total
*/