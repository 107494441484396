import React from 'react';
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import StaffMember from "../../../components/StaffMember";
import ControlledTextField from '../../../components/ControlledTextField';
import { postApi, getAPIDataParams } from "../../../utils/apiFunction";

const CompleteForDay = (props) => {
    const Swal = useSwalWrapper();
    const [remaining, setRemaining] = React.useState(null);
    const [complete, setComplete] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [outs, setOuts] = React.useState(null);

    React.useEffect(() => {
        getAPIDataParams('/Processing/GetProcessingOutputs', setOuts, { ProcessRunId: props.selectedBench.id });
    }, [props.selectedBench]);
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)

        //output must be less than = to available stock
        if ((outs.length === 0) && ((remaining === null) || (remaining === 0))) {
            errorMessages.push('There are no outputs for this stock, Adjust stock to zero to complete');
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const submit = async (finished) => {
        var allOk = false;
        var resp = null;
        setLoading(true);


        if (complete) {
            const endparams = {
                processRunId: props.selectedBench.id
            }
            resp = await postApi('/Processing/EndProcessing', endparams);
        } else {
            //update input total the complete 
            const params = {
                ProcessRunId: props.selectedBench.id,
                ProductionLineId: props.selectedBench.processLineId,
                Amount: props.selectedBench.quantity - remaining,
                Bins: props.selectedBench.bins,
                Bags: props.selectedBench.bags,
                Staff: props.selectedBench.staff,
                StaffMemberId: props.selectedBench.staffMember.id
            }
            await postApi('/Processing/UpdateProcess', params).then(async (out) => {

                const endparams = {
                    processRunId: props.selectedBench.id
                }
                resp = await postApi('/Processing/EndProcessing', endparams)
            });
        }
        if (resp.ok) {
            props.handleClose();
        } else {
            setError("Failed to submit");
        }

    }
    return <Grid container spacing={2} mb={1}>
        <Grid item xs={12}>
            <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={complete} onChange={(event) => { setComplete(event.target.checked); }} />} label="This Input is Completed" disabled={props.disabled} labelPlacement="right" />
        </Grid>
        {!complete ? <Grid item xs={12}>
            <Typography variant={'h5'}>Amount Remaining</Typography>
            <ControlledTextField label="Add to Input" type="number" name="add" state={remaining} setState={setRemaining} number />
        </Grid> : null}
        <Grid item xs={12}>
            {!loading && outs ? <Button variant="contained" color="primary" style={{ whiteSpace: 'nowrap', height: '50px', margin: 0 }} onClick={validate} handleClose={props.handleClose} >Complete</Button> : <CircularProgress />}
        </Grid>
    </Grid>

}


export default CompleteForDay;
