import React from 'react';
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { getAPIData } from '../../../utils/apiFunction';


const SupplierPricing = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const [stockItems, setStockItems] = React.useState([]);
    
    React.useEffect(() => {
        if (stockItems.length > 1) {

        var cols = [
            {
                name: 'name',
                label: 'Name',
                options: props.farmerFilter ? { filterList: [props.farmerFilter] } : {}
            },
        ]
            cols = [...cols,...stockItems.map((si) => {
                return {
                    name: si.description,
                    label: si.description,
                    options: {
                        filter: false
                    }
                }
            })];

        setColumns(cols);
        }
    }, [stockItems])

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: '600px',
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { })

    };
    React.useEffect(() => {
        getAPIData('/StockItems/GetUnprocessed', setStockItems);
        setProcurement([]);
        getProcurementData();
    }, [props.reload]);

    var getProcurementData = async () => {
        setLoading(true);
        const response = await adalApiFetch(fetch, uri + '/SupplierPricing/Get');
        const data = await response.json();
        console.log(data)
        var supps = {};
        for (var i = 0; i < data.length; i++) {
            if (supps[data[i].supplierId]) {
                supps[data[i].supplierId].push(data[i]);
            } else {
                supps[data[i].supplierId] = [data[i]];
            }
        }
        var suppArr = [];
        var arrInd = 0;
        for (const [key, value] of Object.entries(supps)) {
            for (var i = 0; i < value.length; i++) {
                if (i == 0) {
                    suppArr[arrInd] = { name: value[i].supplier?.name, detail: value, supplierId:value[i].supplierId};
                }
                suppArr[arrInd][value[i].stockItem?.description] = value[i].price;
            }
            arrInd++;
        }

        console.log(suppArr)
        setLoading(false);
        setProcurement(suppArr);
    }
    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={"Supplier Pricing"}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reload}
        />
    );
};

export default SupplierPricing;
