import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getAPIData, getAPIDataReturn } from '../utils/apiFunction';
import { adalApiFetch } from '../../adalConfig';
import { Typography } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";


const DriverSelect = (props) => {
    const [drivers, setDrivers] = React.useState([]);
    const [driver, setDriver] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [driverOption, setDriverOption] = React.useState('AOC');
    const Swal = useSwalWrapper();

    React.useEffect(() => {
        if (props.driverId&&inputValue==='') {
            changeDriver({ target: { value:  drivers.find((dr) => { return (dr.id === props.driverId) })  } });
        }
    }, [drivers]);


    const sortNames = (driv) => {
        var sortDriv = driv.sort((a, b) => {
            return a.firstname > b.firstname ? 1 : -1;
        })
        setDrivers(sortDriv);
    }
    React.useEffect(() => {
        if (driverOption === "AOC") {
            getAPIData('/Drivers/Get/AOC', sortNames);
        } else {
            getAPIData('/Drivers/Get/ThirdParty', sortNames);

        }
    }, [driverOption]);
    const changeDriver = (event) => {
        console.log(event.target.value)
        setDriver(event.target.value);
        props.setDriver(event.target.value);
    }
    const createNewExternal = () => {
    const uri = process.env.REACT_APP_BASE_URL;
        Swal.fire({
            html: <div style={{ fontSize: '200%' }}>{inputValue}</div>,
            icon: 'question',
            title: 'Are you sure want to create the new company:',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue',
            cancelButtonText: 'No, I\'ll edit it',

        }).then(async (result) => {
            if (result.value) {
                var data = {
                    CompanyName: inputValue,
                }
                var response = await adalApiFetch(fetch, uri + '/Drivers/Post/AddThirdParty?' + new URLSearchParams({
                    ...data
                }), {
                    method: 'post',
                });
                var newExList = await getAPIDataReturn('/Drivers/Get/ThirdParty');
                var newEx = newExList.find((dr) => { return dr.company === inputValue });
                sortNames(newExList);
                if (newEx) {
                    changeDriver({ target: { value: newEx } });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }
    console.log(drivers, driver,driver,props.driver)
    return (
        <React.Fragment>
            <h3 style={{ fontWeight: 450 }}>Driver</h3>
            <ToggleButtonGroup
                disabled={props.disabled}
                style={{ margin: '8px' }}
                color="primary"
                value={driverOption}
                exclusive
                onChange={(event) => { setDriver(null); props.setDriver(null); setDriverOption(event.target.value) }}
                aria-label="Platform"
            >
                <ToggleButton value="AOC">AOC</ToggleButton>
                <ToggleButton value="External">External</ToggleButton>
            </ToggleButtonGroup>
            {driverOption === 'AOC' ? <TextField
                id="outlined-select-currency"
                select
                disabled={props.disabled}
                value={driver}
                onChange={changeDriver}
            >
                {drivers.map((option) => (
                    <MenuItem key={option.id} value={option}>
                        {option.firstname + " " + option.surname}
                    </MenuItem>
                ))}
            </TextField> :
                <React.Fragment>
                    <Autocomplete
                        style={{display:'inline'} }
                        value={driver}
                        freeSolo
                        defaultValue={null}
                        disabled={props.disabled}
                        onChange={(event, newValue) => {
                            changeDriver({ target: { value: newValue } });
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            var exists = drivers.find((dr) => { return (dr.company === newInputValue) });
                            if (exists) {
                                changeDriver({ target: { value: exists } });
                            }
                        }}
                        getOptionLabel={(option) => option.company}
                        disablePortal
                        id="externalDriver"
                        options={drivers}
                        renderInput={(params) => <TextField {...params} label="Driver" />}
                    />
                    {((!driver) && (inputValue!==''))?<Button variant="contained" color="error" onClick={createNewExternal}>Create New</Button>:null}
                </React.Fragment >}
        </React.Fragment >
    )
}

export default DriverSelect;
