import React from 'react';
import styled from "@emotion/styled";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import IconButton from "@mui/material/IconButton";
import { getOysterGrades,gradeSort } from "../../../definitions/oysters";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { TableCell } from "@mui/material";
import { adalApiFetch } from '../../../../adalConfig';
import { getAPIDataParamsReturn } from '../../../utils/apiFunction';
import DataTableStandard from "../../../components/DataTableStandard";
import TooltipDescription from "../../../components/TooltipDescription";
import { headerRender } from '../../../utils/renderFunctions';
import { textfieldDateFormat } from '../../../utils/dateFunctions';
const Summary = (props) => {

    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [grades, setGrades] = React.useState([]);
    const reloadFunc = () => {
        setReload(!reload);
    }
    const options = {
        filterType: 'checkbox',
        selectableRowsHideCheckboxes: true,
        filter: false,
        responsive: 'standard',
    };
    var columns = [{
        name: 'label',
        label: ' '
    }];
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    var oysterCols = grades.map((og) => {

        return {
            name: og.abv.toLowerCase(),
            label: og.Grade,
            species: og.Species,
            options: {
                customHeadRender: headerRender(og.Species),
                setCellProps: () => ({
                    align: "right"
                })
            }
        }
    })
    var total = [{
        name: 'total',
        label: 'Total',
        options: {
            customHeadRender: (obj) => { return <TableCell style={{ backgroundColor: 'lightgrey' }}><div >{obj.label}</div></TableCell> },
            setCellProps: () => ({
                align: "right"
            })
        }

    }];
    columns = [...columns, ...oysterCols, ...total];
    console.log(props.externalReload)
    React.useEffect(() => {
        console.log('Yep remouunting')
        console.log(props.externalReload)
    }, [props.externalReload]);
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        console.log(textfieldDateFormat(props.startDate) + '    ' + textfieldDateFormat(props.endDate));
        var today = new Date();
        var firstDate = props.startDate;
        if ((today > props.startDate) && (today < props.endDate)) {
            firstDate = today;
        }
        if (grades.length > 0) {
            getProcurementData(props.location, (props.oldInfo ? textfieldDateFormat(props.startDate) : textfieldDateFormat(firstDate)), textfieldDateFormat(props.endDate), abortController);
        }
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, reload, props.externalReload, grades]);

    var getProcurementData = async (Location, StartDate, EndDate, abortController) => {
        var data = null;
        if (props.oldInfo) {
            data = await getAPIDataParamsReturn('/ProcurementDashboard/StaticWeeklyProcurement', {
                StartDate,
            }, abortController);
        } else {
            data = await getAPIDataParamsReturn('/ProcurementDashboard/DailyProcurement', {
                Location,
                StartDate,
                EndDate
            }, abortController);
        }
        //const data = await response.json();
        console.log(data);
        var newData = [];
        //function buildDataLine(name, obj) {
        //    var tempObj = obj;
        //    tempObj["type"] = name;
        //    return tempObj;
        //}
        newData.push(data.opening);
        var salesObj = { label: data.salesOrders.label };
        grades.map((og) => {
            var total = data.salesOrders[og.abv.toLowerCase()] - data.shipments[og.abv.toLowerCase()];
            salesObj[og.abv.toLowerCase()] = total;
        })
        salesObj["total"] = data.salesOrders["total"] - data.shipments["total"];
        newData.push(salesObj);
        newData.push(data.purchaseOrders);
        if (props.location !== 0) {
            newData.push(data.transfers);
        }
        newData.push(data.closing);
        setProcurement(newData);
    }



    return (
        <DataTableStandard
            data={procurement}
            title={<div>Procurement Summary<TooltipDescription title={"This table presents the expected oyster sales and procurement for the selected week and how that impacts our stock on hand (SOH). The totals are based on not yet completed Purchase orders and Sales orders."} /></div>}
            columns={columns}
            options={options}
            reload={reloadFunc}
            numberFormat
        />
    );
};



export default Summary;
