/*
 *Process stock works for: 
 * recieval to inventory (counted)
 * recival to half shell (fr sydney) - non ideal flow
 * inventory to half shell
 * 
 *Needs to be able to be started - added to - finished
 */


import React from 'react';
import Grid from "@mui/material/Grid";
import PurchaseOrders from '../Procurement/Tables/PurchaseOrders';
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { startOfDay, endOfDay } from "../../utils/dateFunctions";
import ProcessStockForm from '../Processing/Forms/ProcessStockForm';

const NewReceival = (props) => {
    const [purchaseOrder, setPurchaseOrder] = React.useState(null);
    const [rec, setRec] = React.useState(true);
    const [hasPurchaseOrder, setHasPurchaseOrder] = React.useState(true);
    const [startDate, setStartDate] = React.useState(startOfDay(new Date()));
    const [endDate, setEndDate] = React.useState(endOfDay(new Date()));
    const handleChange = (event) => {
        setHasPurchaseOrder(event.target.checked);
        if (event.target.checked === false) {
            setPurchaseOrder(null);
        }
    };
    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setPurchaseOrder(array[rowState.dataIndex]);
    }
    const deselectPO = (array) => (rowd, rowState) => {
        setPurchaseOrder(null);
    }
    const selectId = (list) => {
        return list.filter((l) => {
            if (purchaseOrder) {
                return l.id === purchaseOrder.id;
            } else {
                return true;
            }
        })
    }
    return (
        <Container style={{paddingTop:'20px'} }>
            <Grid container spacing={2} mb={1}>
                <Typography variant={'h2'}>{rec ? "Select Receival" : "Select Inventory Item"}</Typography>
                
                <Grid item xs={12} >
                    <ProcessStockForm data={purchaseOrder} access={props.access} handleClose={props.handleClose}/>
                </Grid> 

            </Grid>


        </Container>

    );
};

export default NewReceival;