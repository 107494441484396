import React from 'react';
import { getAPIData } from "../../utils/apiFunction";
import DataTableStandard from "../../components/DataTableStandard";
import { useSearchParams } from "react-router-dom";

const PurchaseOrdersPage = (props) => {

    const [cropCols, setCropCols] = React.useState([]);
    const [newColour, setNewColour] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    var type = searchParams.get("type");

    const hexToHSL = (hex) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
        var r = parseInt(result[1], 16);
        var g = parseInt(result[2], 16);
        var b = parseInt(result[3], 16);

        r /= 255;
        g /= 255;
        b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        s = s * 100;
        s = Math.round(s);
        l = l * 100;
        l = Math.round(l);
        h = Math.round(360 * h);

        var colorInHSL = { h: h, s: s, l: l };
        return colorInHSL;
    }
    function hslToHex(h, s, l) {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }
    const organiseCols = (cols) => {
        var sCols = cols.sort((a, b) => {
            return hexToHSL(a.colour).h > hexToHSL(b.colour).h ? 1 : -1
        }).map((cc) => { return { ...cc, name: cc.crop ? cc.crop : cc.description } });
        setCropCols(sCols);
        var bigDiff = 0;
        var indexofBigDiff = -1;
        for (var i = 0; i < sCols.length; i++) {
            if (i < sCols.length - 1) {
                var h1 = hexToHSL(sCols[i].colour).h;
                var h2 = hexToHSL(sCols[i + 1].colour).h;
                var hDiff = h2 - h1;
                if (hDiff > bigDiff) {
                    bigDiff = hDiff;
                    indexofBigDiff = i;
                }
            }
        }
        var newH = hexToHSL(sCols[indexofBigDiff].colour).h + (bigDiff / 2);
        var newS = hexToHSL(sCols[indexofBigDiff].colour).s;
        var newL = hexToHSL(sCols[indexofBigDiff].colour).l;
        setNewColour(hslToHex(newH,newS,newL));

    }

    React.useEffect(() => {
        const getData = async () => {
            setLoading(true);
            if (type) {
                await getAPIData('/Farms/Lookups/' + type, organiseCols);
            } else {
                await getAPIData('/Farms/Lookups/BatchColours', organiseCols);
            }
            setLoading(false);
        }
        getData();
    }, [type]);
    var columns = [
        {
            name: 'name',
            label: 'Name'
        },
        {
            name: 'colour',
            label: 'Colour',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <span style={{ backgroundColor: cropCols[dataIndex].colour, padding: '10px', paddingRight: '50px' }} >{cropCols[dataIndex].colour}</span>
                },
            }
        }
    ];

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        responsive: 'standard',
        rowsPerPage: 1000,
    };

    return (
        <React.Fragment>
            <span  >Suggested New Color (do not trust):</span><span style={{ backgroundColor: newColour, padding:'10px' }} >{newColour}</span>
        <DataTableStandard
            title={'Colours'}
            data={cropCols}
            columns={columns}
            options={options}
            returnSearch={!loading}
        />
        </React.Fragment>
    );
};


export default PurchaseOrdersPage;
