import React from 'react';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { WAREHOUSEGROUPS } from "../utils/constants/warehouse";

const WarehouseToggle = (props) => {
    const [cache, setCache] = React.useState(null);

    const switchWH = (event,newselection) => {
        GetAllCacheData(setCache, 'warehouse')
        props.setLocation(newselection)
    }
    return (
        <ToggleButtonGroup
            color="primary"
            exclusive={props.multipleChoices?false:true}
            disabled={props.disabled}
            value={props.location}
            onChange={switchWH}
            aria-label="text alignment"
        >
            <ToggleButton key={1} value={WAREHOUSEGROUPS.BBay} >
                <div  >Batemans Bay</div>
            </ToggleButton>
            <ToggleButton key={0} value={WAREHOUSEGROUPS.Sydney} >
                <div  >Sydney</div>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}
export default WarehouseToggle;