import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { startOfWeek, endOfWeek, textfieldDateFormat } from "../../../utils/dateFunctions";
import { getAPIDataReturn, getAPIDataParamsReturn, postApi, getAPIData, getAPIDataParams } from "../../../utils/apiFunction";
import { numberFormatwithNeg } from "../../../utils/numberFunctions";
import OystersGroup from "../../../components/OysterGroup";
import Div from "@jumbo/shared/Div";
import { saveLabel } from '../../../utils/processing/labels';
import { adalApiFetch } from '../../../../adalConfig';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { GetAllCacheDataReturn, addToCache } from "../../../utils/cacheFunctions";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import DropZone from "../../../components/DropZone";
import { isWithinMinDifference } from "../../../utils/dateFunctions";
import SelectTextField from "../../../components/SelectTextField";
import ControlledTextField from "../../../components/ControlledTextField";
import DriverSelect from "../../../components/DriverEntry";
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import SelectInventoryForm from "./SelectInventoryForm";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import StaffMember from "../../../components/StaffMember";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { number } from 'prop-types';
import { WAREHOUSEMATCHES } from '../../../utils/constants/warehouse';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import FormModal from '../../../components/FormModal';
import CircularProgress from '@mui/material/CircularProgress';

const ReceivalForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const hasStarted = !(props.selectedLine?.processingStatus === 'Not started');
    const [completed, setCompleted] = React.useState(props.completed);
    const [updateComplete, setUpdateCompleted] = React.useState(false);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [editInput, setEditInput] = React.useState(false);
    const [currentOutputs, setCurrentOutputs] = React.useState(null);
    const [staffMember, setStaffMember] = React.useState(null);
    const [stockItems, setStockItems] = React.useState(null);
    const [scheduleList, setScheduleList] = React.useState(props.scheduleList);
    var recData = props.data ? props.data : {};
    const Swal = useSwalWrapper();
    const [equipment, setEquipment] = React.useState(null);
    const [quantity, setQuantity] = React.useState(null);
    const [bags, setBags] = React.useState(null);
    const [equipmentList, setEquipmentList] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const CompletedAndEdit = edit && completed;
    const CompletedSoDisabled = !edit && completed;
    const supplier = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.receival?.supplier?.name : props.selectedLine.supplier) : '';
    const lotNbr = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.lotSerialNbr : props.selectedLine.lotSerialNbr) : '';
    const [comment, setComment] = React.useState(CompletedAndEdit ? props.selectedLine?.comment : (hasStarted ? props.selectedLine?.processRun?.comment : null));
    const [workers, setWorkers] = React.useState(CompletedAndEdit ? (props.selectedLine?.staff) : (hasStarted ? props.selectedLine?.processRun?.staff : null));
    const [inventory, setInventory] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [reloadStaff, setReloadStaff] = React.useState(false);
    const [staffMemberId, setStaffMemberId] = React.useState(null);
    const [gotCache, setGotCache] = React.useState(false);
    console.log(props.selectedBench)
    const cacheName = 'IncHSCACHE';
    const getVariance = (oysIn, oysOut, perc) => {
        var totalOystersOut = 0;
        for (var i = 0; i < oysOut.length; i++) {
            totalOystersOut += oysOut[i].quantity ? oysOut[i].quantity : 0;
        }
        var diff = oysIn[0]?.quantity - totalOystersOut;
        if (perc) {
            return Math.abs((diff) / oysIn[0]?.quantity);
        } else {
            return diff;
        }
    }

    React.useEffect(() => {
        console.log(props.selectedLine);
        getAPIDataParamsReturn('/Processing/GetLastProcessRun', { ProcessLineId: props.selectedBench?.benchDetails?.id }).then((processRun) => {
            setStaffMemberId(processRun.staffMemberId);
        })
        GetAllCacheDataReturn(cacheName).then((inv) => {
            if (inv && inv.inv) {
                if (isWithinMinDifference(new Date(inv.timeStamp), 20, new Date())) {
                    getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: inv.inv.warehouseId, IncludeMYOB: false, ExpiryDate: inv.inv.expiryDate, LotNbrSubString: inv.inv.lotSerialNbr, StockItemId: inv.inv.stockItemId, IncludeZero: true }).then((iv) => {
                        setGotCache(true);
                        var matchInv = iv.find((i) => { return i.expiryDate === inv.inv.expiryDate });
                        if (matchInv.portalQuantity > 0) {
                            setInventory(matchInv);
                        } else {
                            addToCache({ inv: null, timeStamp: (new Date().getTime()) }, cacheName);
                        }
                    }).catch((e) => {
                        setGotCache(true);
                    });
                } else {
                    setGotCache(true);
                    addToCache({ inv: null, timeStamp: (new Date().getTime()) }, cacheName);
                }
            } else {
                setGotCache(true);
            }
        }).catch(() => {
            setGotCache(true);
        });
    }, [])
    const validate = (finished) => () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)
        //if (!equipment.allowMultiple) {
        //    var matchSchedule = scheduleList?.find((s) => s.processRun?.processLineId === equipment.id);
        //    if (matchSchedule) {
        //        if (matchSchedule?.id !== props.selectedLine?.id) {
        //            errorMessages.push("There is already a process running on this line");
        //        }
        //    }
        //}
        if (!inventory) {
            errorMessages.push("You must select an item of inventory");
        }
        if (inventory?.portalQuantity < quantity) {
            errorMessages.push("You have entered more oysters than are available in inventory");
        }
        if (!props.selectedBench) {
            errorMessages.push("You must select the line you are using");
        }

        if (!staffMember) {
            errorMessages.push("You must select a staff member");
        }






        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit(finished);
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit(finished);
        }
    }
    const submit = async (finished) => {
        if (props.loadingBench) {
            props.loadingBench();
        }
        var allOk = false;
        var respArr = [];
        setLoading(true);


        if (props.endProcess) {
            const endparams = {
                processRunId: props.selectedBench.id
            }
            respArr.push(await postApi('/Processing/EndProcessing', endparams));
        }
        const params = {
            ExpiryDate: textfieldDateFormat(new Date(inventory?.expiryDate)),
            LotSerialNbr: inventory?.lotSerialNbr,
            StockItemId: inventory?.stockItem?.id,
            ProductionLineId: props.selectedBench.benchDetails?.id,
            Amount: quantity,
            Bins: 0,
            Bags: bags ? bags : 0,
            Staff: 1,
            StaffMemberId: staffMember.id
        }
        respArr.push(await postApi('/Processing/StartProcessing', params));
        saveLabel({ ...props.selectedBench, coreInventory: inventory }, { Species: inventory?.stockItem?.description }, 0, quantity, true);


        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            setLoading(false);
            setCompleted(true);
            setUpdateCompleted(true);
        } else {
            setError("Failed to submit");
        }


    }
    const enableInputEdit = () => {
        setEditInput(true);
    }
    const remSR = (name) => {
        if (name && name.description) {
            return name.description.replace('Sydney Rock', '');
        } else {
            return '';
        }
    }
    const updateQuantity = (value) => {
        setQuantity(value);
    }
    const updateBags = (value) => {
        if (inventory && inventory.stockItem) {
            setQuantity(value * inventory.stockItem.bagCount);
        }
        setBags(value);
    }

    const selectNewPRN = (list) => (value, indexs) => {
        var dataIndex = indexs.dataIndex;
        var rowIndex = indexs.rowIndex;
        setInventory(list[dataIndex]);
        addToCache({ inv: list[dataIndex], timeStamp: (new Date().getTime()) }, cacheName);
        //complete current PRN selection
        //link this PRN to bench
        //reload form
    }
    const removeInventory = () => {
        setInventory(null);
        setQuantity(null);
        setBags(null);
    }
    const openAddStaffForm = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const setNewStaff = (staff) => {
        handleClose();
        setReloadStaff(!reloadStaff);
        setStaffMemberId(staff.id);

    }
    const bayDefault = [0, 1, 0, 0];
    const sydDefault = [0, 1, 0];
    var defaultCode = WAREHOUSEMATCHES.Syd.includes(props.warehouse.myobid) ? sydDefault : bayDefault;
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <FormModal open={open} handleClose={handleClose} title={"Add Staff Member"} noWidth >
                <AddStaffForm access={props.access} setNewStaff={setNewStaff} warehouseId={props.warehouse?.id} />
            </FormModal >

            <Div>
                {/*//comments*/}
                {/*//processing time*/}
                {/*//no of staff on line*/}

                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StaffMember staffId={staffMemberId} driver={staffMember} setDriver={setStaffMember} disabled={CompletedSoDisabled} noTitle reload={reloadStaff} />
                        <Button aria-label="AddStaff" onClick={openAddStaffForm} disabled={props.disabled} variant='contained' style={{
                            top: '20%',
                        }} >
                            <AddIcon />
                        </Button>
                        {/*//add new staff memmber*/}
                    </Grid>
                    <Typography variant={'h3'} style={{ right: '10%', position: 'absolute', fontSize: '550%' }} >{props.selectedBench?.benchDetails?.description}</Typography>

                    <Grid item xs={12} >
                        {/*Icon button edit */}


                        <Typography variant={'h3'}>{"Input Stock"}</Typography>
                        {inventory ?
                            <Grid container spacing={2} mb={1}>
                                <Grid item xs={12} >
                                    <Typography variant={'h5'}>{inventory.lotSerialNbr}</Typography>
                                    <Typography variant={'h5'}>{inventory.stockItem?.description}</Typography>
                                    <Typography variant={'h5'}>{"Remaining: " + inventory.portalQuantity + "doz"}</Typography>
                                    <Grid container spacing={2} mb={1}>
                                        <ControlledTextField key={'bg' + props.key} title={props.showAsUnits ? 'Units' : "Bags"} state={bags} setState={updateBags} disabled={props.disabled} number />
                                        <ControlledTextField key={'qua' + props.key} title="Quantity (doz)" state={quantity} setState={updateQuantity} disabled={props.disabled} number />
                                        <Typography variant={'h2'} style={{ marginTop: '20px', marginRight: '40px', marginLeft: '40px' }} >OR</Typography>
                                        <Button aria-label="remove" variant="contained" onClick={removeInventory} disabled={props.disabled}  >
                                            Select Different Inventory
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : (!gotCache ? <CircularProgress /> : <SelectInventoryForm onClick={selectNewPRN} access={props.access} warehouseId={props.warehouseId} typeDefault={defaultCode[0]} productDefault={defaultCode[1]} containerDefault={defaultCode[2]} speciesDefault={defaultCode[3]} hideHalfshell={true} hideFrozen={true} />)}

                    </Grid>



                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/*<FormGroup>*/}
                        {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                        {/*</FormGroup>*/}
                        <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                            <SubmitButtonDisplayer title={'Start Processing'} completed={CompletedSoDisabled} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                        </div>
                    </Grid>
                </Grid>
            </Div>
        </Box >
    );
};


export default ReceivalForm;


const AddStaffForm = (props) => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const submitFunc = async () => {
        setLoading(true);
        var params = {
            Firstname: firstName,
            Surname: lastName,
            IsCurrent: true,
            isShucker: true,
            warehouseId: props.warehouseId
        }
        var resp = await postApi('/StaffMember/Add', params);
        if (resp.ok) {
            setLoading(false);
            var newStaff = await resp.json();
            props.setNewStaff(newStaff);
        } else {
            setLoading(false);
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Grid container spacing={2} mb={1}>
                <Grid xs={12}>
                    <ControlledTextField key={'fn' + props.key} title="First Name" state={firstName} setState={setFirstName} disabled={props.disabled} />
                    <ControlledTextField key={'ln' + props.key} title="Last Name" state={lastName} setState={setLastName} disabled={props.disabled} />
                    <SubmitButtonDisplayer title={'Add Staff Member'} completed={false} error={null} loading={loading} allowed={true} submitFunc={submitFunc} />
                </Grid>
            </Grid>
        </Box>);
}