import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../shared/DatePicker";
import useMediaQuery from '@mui/material/useMediaQuery';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import TextField from "@mui/material/TextField";
import { getAPIData } from '../../utils/apiFunction';
import Typography from '@mui/material/Typography';
import { delIcon } from "../../themes/myStyles";
import { adalApiFetch } from '../../../adalConfig';
import { checkAccess } from '../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../components/SubmitButtonDisplayer";
import OystersGroupTransfer from "../OysterGroupTransfer";
import { textfieldDateFormat, dateTimeFormat, dateFormat } from "../../utils/dateFunctions";
import OTRLabels from '../../components/Transfers/CreateOTRLabelsfromGroup';
import ControlledTextField from "../../components/ControlledTextField";
import CropYearSelect from "../../components/CropYearTransferSelect";
import SelectWarehouse from "../../components/SelectWarehouse";
import SelectAOCFarmWarehouse from '../../components/SelectAOCFarmWarehouse';
import { OTR_LOCATIONS } from '../../utils/constants/OTRselections';
import LeaseSelect from "../../components/LeaseSelect";
import createOTRPDF from './CreateOTRPDF';
import GetAppIcon from '@mui/icons-material/GetApp';

const ConfirmRec = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const sinObjArr = [{}];
    const empArr = [];
    const [completed, setCompleted] = React.useState(props.completed);
    const [toWarehouse, setToWarehouse] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [receival, setReceival] = React.useState(false);
    const [oysters, setOysters] = React.useState([{}]);
    const [POId, setPOId] = React.useState(null);
    const [OTR, setOTR] = React.useState(null);
    const [PRN, setPRN] = React.useState(null);
    const [harvestDate, setHarvestDate] = React.useState(null);
    const [estuary, setEstuary] = React.useState(null);
    const [harvestArea, setHarvestArea] = React.useState(null);
    const [lease, setLease] = React.useState(null);
    const [leases, setLeases] = React.useState(null);
    const [multipleCropYear, setMultipleCropYear] = React.useState(false);
    const [multipleLeases, setMultipleLeases] = React.useState(false);
    const [crop, setCrop] = React.useState(null);
    const [cropYearCode, setCropYearCode] = React.useState(null);
    const [fromW, setFromW] = React.useState(props.from);
    const [toW, setToW] = React.useState(props.to);
    const [temperature, setTemperature] = React.useState(null);
    const [staffMember, setStaffMember] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [pickupDate, setPickUpDate] = React.useState(new Date());
    const [ivr, setIvr] = React.useState(null);
    const id = props.data ? props.data.id : '';
    const receivalId = props.data ? props.data.id : '';
    const [fromWarehouse, setFromWarehouse] = React.useState(null);
    //const supplier = props.data ? props.data.supplierPredict : '';
    const vertical = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const Swal = useSwalWrapper();
    const [driver, setDriver] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    React.useEffect(() => {
        if (props.data) {
            getAPIData('/Transfers/Get/' + id, setReturnData);
        }

    }, [])
    React.useEffect(() => {
        if (returnData) {
            setOTR(returnData.otr);
            setPRN(returnData.prn);
            setIvr(returnData.ivr);
            setHarvestDate(new Date(returnData.harvestDate));
            setToW(returnData?.destination?.isFarm ? OTR_LOCATIONS.FARM : OTR_LOCATIONS.FACTORY)
            setFromW(returnData?.source?.isFarm ? OTR_LOCATIONS.FARM : OTR_LOCATIONS.FACTORY)
            if (returnData.leaseId) {
            } else {
                setMultipleLeases(true);
            }

            if (returnData.crop) {
                setCrop(returnData.crop);
            } else {
                setMultipleCropYear(true);
            }
            setComment(returnData.comment);
        }
    }, [returnData])

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        for (var i = 0; i < oysters.length; i++) {
            if (!oysters[i].stockItem) {
                errorMessages.push("You must select a Grade for each Line");
            }
        }
        if (multipleCropYear) {
            for (var i = 0; i < oysters.length; i++) {
                if (!oysters[i].crop) {
                    errorMessages.push("You must select a Crop Year for each stock item");
                }
            }
        } else if (!crop) {
            errorMessages.push("You must select a Crop Year");

        }
        if (multipleLeases) {
            for (var i = 0; i < oysters.length; i++) {
                if (!oysters[i].lease) {
                    errorMessages.push("You must select a Lease for each stock item");
                }
            }
        } else if (!lease) {
            errorMessages.push("You must select a Lease");
        }
        if (!harvestDate) {
            errorMessages.push("You must enter a Harvest Date");
        }
        if (!PRN) {
            errorMessages.push("You must enter a PRN");
        }

        if (!toWarehouse) {
            errorMessages.push("You must select a Destination");
        }
        if (!fromWarehouse) {
            errorMessages.push("You must select a Supplier");
        }
        if (totalQuantity(oysters) < 1) {
            warningMessages.push("Are you sure the Quantity is correct");
        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const createPDF = async (otr, tId) => {

      

        var data = {
            date: pickupDate ? dateFormat(pickupDate) : null,
            OTR: otr,
            PRN: PRN,
            HarvestDate: harvestDate ? dateFormat(harvestDate) : null,
            IVR: ivr ? ivr : 0,

        }
        if (multipleLeases) {
            data.multipleLeases = true;
            data.Estuary = oysters ? oysters[0]?.lease?.estuary : '';
            data.HarvestArea = oysters ? oysters[0]?.lease?.harvestArea : '';
        } else {
            data.multipleLeases = false;
            data.Estuary = lease ? lease.estuary : '';
            data.HarvestArea = lease ? lease.harvestArea : '';
            data.Lease = lease ? lease.displayName : '';
        }
        if (multipleCropYear) {
            data.multipleCropYear = true;

        } else {
            data.multipleCropYear = false;
            data.CropYear = crop;
        }
        if (fromW === OTR_LOCATIONS.FARM) {
            data.from = fromWarehouse ? fromWarehouse.name : 0;
        } else {
            data.from = fromWarehouse ? fromWarehouse.description : 0;
        }
        if (toW === OTR_LOCATIONS.FARM) {
            data.to = toWarehouse ? toWarehouse.name : 0;
        } else {
            data.to = toWarehouse ? toWarehouse.description : 0;
        }
        await createOTRPDF(data, oysters, tId);

    }
    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var data = {
            PRN: PRN,
            HarvestDate: harvestDate ? textfieldDateFormat(harvestDate) : null,
            IVR: ivr ? ivr : 0,
            Comment: comment,
           
        }
        if (!multipleCropYear && crop) {
            data.Crop = crop;
        }
        if (!multipleLeases && lease) {
            data.LeaseId = lease.id;
        }
        if (fromW === OTR_LOCATIONS.FARM) {
            data.SourceWarehouseId = fromWarehouse ? fromWarehouse.warehouseId : 0;
        } else {
            data.SourceWarehouseId = fromWarehouse ? fromWarehouse.id : 0;
        }
        if (toW === OTR_LOCATIONS.FARM) {
            data.DestinationWarehouseId = toWarehouse ? toWarehouse.warehouseId : 0;
        } else {
            data.DestinationWarehouseId = toWarehouse ? toWarehouse.id : 0;
        }
        if (props.edit) {
            var response = await adalApiFetch(fetch, uri + '/Transfers/Post/'+id+'?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        } else {
            var response = await adalApiFetch(fetch, uri + '/Transfers/Post?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });

        }
        const newTransfer = await response.json();

        var promArr = [];

        var reducedOysters = oysters;
        promArr = reducedOysters.map((oy) => {
            var oyData = {
                CoreTransferId: newTransfer.id,
                stockItemId: oy.stockItem ? oy.stockItem.id : null,
                Bags: oy.bags ? oy.bags : 0,
                Bins: oy.bins ? oy.bins : 0,
                Quantity: oy.quantity ? oy.quantity : 0,
                Crop: multipleCropYear ? (oy.crop ? oy.crop : null) : (crop ? crop : null),
                LeaseId: multipleLeases ? (oy.lease ? oy.lease.id : null) : (lease ? lease.id : null),
            }
            if (oy.id) {
                return adalApiFetch(fetch, uri + '/Transfers/Line/Post/' + oy.id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            } else {
                return adalApiFetch(fetch, uri + '/Transfers/Line/Post?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            }
        })

        if (returnData) {
            if (returnData.coreTransferLines && (returnData.coreTransferLines.length > 0)) {
                returnData.coreTransferLines.map((rl) => {
                    var exID = oysters.find((oy) => { return oy.id === rl.id });
                    if (!exID) {
                        return adalApiFetch(fetch, uri + '/Transfers/Line/Delete/' + rl.id, {
                            method: 'delete',
                        });
                    }
                })
            }
        }


        if (props.createOTR) {
            promArr.push(createPDF(newTransfer.otr, newTransfer.id));
        }
        await Promise.all(promArr);

        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {completed ? <IconButton style={delIcon} aria-label="Download" onClick={() => { createPDF(OTR,id) }}>
                    <GetAppIcon />
                </IconButton> : ''}
                {completed ? <Button style={{ ...delIcon, top: '12%' }} aria-label="Download" onClick={() => { OTRLabels(returnData, true,true) }}>
                    Get Labels
                </Button> : ''}
                <Grid container spacing={2} mb={1}>


                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Grid container spacing={0} mb={1}>


                            {/*<Grid item xs={12} >*/}
                            {/*    <DatePicker label="PickUp Date" date={pickupDate} setDate={setPickUpDate} disabled={completed} />*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {(fromW === OTR_LOCATIONS.FACTORY) ?
                                    <SelectWarehouse cleanDisp useCache warehouseId={returnData ? returnData.sourceId : null} state={fromWarehouse} setState={setFromWarehouse} disabled={completed} title="From" /> :
                                    <SelectAOCFarmWarehouse warehouseId={returnData ? returnData.sourceId : null} normal  setState={setFromWarehouse} state={fromWarehouse} access={props.access} disabled={completed} title="From" />}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {(toW === OTR_LOCATIONS.FACTORY) ?
                                    <SelectWarehouse cleanDisp useCache warehouseId={returnData ? returnData.destinationId : null} state={toWarehouse} setState={setToWarehouse} disabled={completed} title="To" /> :
                                    <SelectAOCFarmWarehouse warehouseId={returnData ? returnData.destinationId : null} normal showAll setState={setToWarehouse} state={toWarehouse} access={props.access} disabled={completed} title="To" />}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ControlledTextField title="PRN" state={PRN} setState={setPRN} disabled={completed} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePicker label="Harvest Date" date={harvestDate} setDate={setHarvestDate} disabled={completed} />
                            </Grid>
                            {((fromW === OTR_LOCATIONS.FARM) && fromWarehouse && fromWarehouse.isInternal) ? <React.Fragment>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <LeaseSelect onlyHA={toW === OTR_LOCATIONS.FACTORY} estId={returnData?.leaseId} warehouseId={fromWarehouse ? fromWarehouse.warehouseId : null} state={lease} setState={setLease} disabled={multipleLeases || completed} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <FormGroup>
                                        <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox checked={multipleLeases} onChange={(event) => { setMultipleLeases(event.target.checked); }} />} label="Multiple Leases?" disabled={completed} />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <CropYearSelect supplier={fromWarehouse} estId={cropYearCode} state={crop} setState={setCrop} disabled={multipleCropYear || completed} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <FormGroup>
                                        <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox checked={multipleCropYear} onChange={(event) => { setMultipleCropYear(event.target.checked); }} />} label="Multiple Crop Years?" disabled={completed} />
                                    </FormGroup>
                                </Grid>
                            </React.Fragment> : null}
                            {((toW === OTR_LOCATIONS.FARM)) ? <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ControlledTextField title="IVR" state={ivr} setState={setIvr} disabled={completed} />
                            </Grid> : null}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                <TextField
                                    disabled={completed}
                                    id="outlined-multiline-static"
                                    label="Comments"
                                    value={comment}
                                    onChange={(event) => { setComment(event.target.value) }}
                                    multiline
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    rows={4} />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} >
                        {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                        {returnData && returnData.coreTransferLines ? <OystersGroupTransfer onlyHA={toW === OTR_LOCATIONS.FACTORY} reduceItems sameType={fromW === toW} supplierId={fromWarehouse ? (fromW === OTR_LOCATIONS.FARM ? (fromWarehouse.warehouseId) : (fromWarehouse.id)) : null} passedGroup={returnData ? (returnData.coreTransferLines?.length > 0 ? returnData.coreTransferLines : null) : null} starter={!(props.edit || completed)} setOysters={setOysters} disabled={completed} cropYear={multipleCropYear} lease={multipleLeases} /> : null}
                    </Grid>
                    <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

                </Grid>
            </Div>
        </Box >
    );
};
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}




export default ConfirmRec;
