import React from 'react';
import JumboLayoutProvider from "@jumbo/components/JumboLayout/JumboLayoutProvider";
import JumboContentLayoutProvider from "@jumbo/components/JumboContentLayout/JumboContentLayoutProvider";
import {useJumboApp} from "@jumbo/hooks";
import {LAYOUTS} from "./utils/constants/layouts";
import { GetAllCacheData, addToCache } from "./utils/cacheFunctions";
import {config} from "./config/main";
import { useJumboTheme, useJumboSidebarTheme, useJumboHeaderTheme } from "@jumbo/hooks";
import { mainTheme as defaultTheme } from "./themes/main/default";
import { sidebarTheme as defaultSidebar } from "./themes/sidebar/default";
import { headerTheme as defaultHeader } from "./themes/header/default";
import { mainTheme as theme1 } from "./themes/main/theme1";
import { sidebarTheme as theme1Sidebar } from "./themes/sidebar/theme1";
import { headerTheme as theme1Header } from "./themes/header/theme1";

const AppLayout = (props) => {
    const [darkMode, setDarkMode] = React.useState(false);
    const { activeLayout } = useJumboApp();
    const { setTheme } = useJumboTheme();
    const { setHeaderTheme } = useJumboHeaderTheme();
    const { sidebarTheme, setSidebarTheme } = useJumboSidebarTheme();
    if (!activeLayout) {
        throw Error("AppLayout > No activeLayout is set.");
    }
    React.useEffect(() => {
        GetAllCacheData(setDarkMode, 'darkMode');

    },[])
    React.useEffect(() => {
        if (darkMode) {
            setTheme(theme1);
            setSidebarTheme(theme1Sidebar);
            setHeaderTheme(theme1Header);
            addToCache('dark', 'darkMode');
        } else {
            setTheme(defaultTheme);
            setSidebarTheme(defaultSidebar);
            setHeaderTheme(defaultHeader);
            addToCache('light', 'darkMode');
        }
    }, [darkMode]);


    
    const LayoutComponent = React.useMemo(() => {
        const layoutIndex = LAYOUTS.findIndex(layout => layout.name === activeLayout);

        if (layoutIndex >=0 ) {
            return LAYOUTS[layoutIndex].component;
        }

        throw Error("No activeLayout is set yet.");
    }, [activeLayout]);

    return (
        <JumboLayoutProvider>
            <LayoutComponent darkMode={{ darkMode, setDarkMode }}>
                <JumboContentLayoutProvider
                    layoutOptions={config.defaultContentLayout}
                >
                    {props.children}
                </JumboContentLayoutProvider>
            </LayoutComponent>
        </JumboLayoutProvider>
    );
};

export default AppLayout;
