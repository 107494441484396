import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import ProcessingLogs from './Tables/ProcessingLogs';
import { useSearchParams } from "react-router-dom";
import FormModal from '../../components/FormModal';
import ProcessStockForm from './Forms/ProcessStockForm';
import ToggleButton from '@mui/material/ToggleButton';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import LocationSelector from '../../components/LocationSelect';
const Bbay = "LIVEPRODCT";
const Sydney = "SYDNEY";
const ProcessingLogLanding = (props) => {
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [noOTR, setNoOTR] = React.useState(false);
    const [selection, setSelection] = React.useState([0, 1]);
    const [location, setLocation] = React.useState([0, 1]);
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const live = (searchParams.get("type") === "Live" ? true : false);
    console.log(location)
    
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={props.disabled}
                        value={selection}
                        onChange={(ev, val) => setSelection(val)}
                        aria-label="text alignment"
                    >
                        <ToggleButton key={0} value={0} >
                            <div  >In Progress</div>
                        </ToggleButton> 
                         <ToggleButton key={1} value={1} >
                            <div  >Completed</div>
                        </ToggleButton> 
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <LocationSelector setLocation={setLocation} location={location}/>
                </Grid>
            </Grid>
            <Grid container spacing={3.5}>
                {selection.includes(0)  ? <Grid item xs={12}>
                    <InProcess location={location === 0 ? Sydney : Bbay} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} live={live}/>
                </Grid> : ''}
                {selection.includes(1)  ? <Grid item xs={12}>
                    <CompletedProcess location={location === 0 ? Sydney : Bbay} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} live={live} />
                </Grid> : ''}
            </Grid>
        </React.Fragment >
    );
};


export default ProcessingLogLanding;



const InProcess = (props) => {
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);

    const handleClose = () => setOpen(false);
    
   
    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }
    
    

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Process Stock Form " + (props.live ? "(Live)" : "(Halfshell)")} >
                <ProcessStockForm {...props} data={rec} handleClose={handleClose} edit />
            </FormModal>
            <ProcessingLogs filter={locationFilter(props.location)} title={props.live ? "In Progress Live" : "In Progress Halfshell"}  {...props} onClick={selectPO} edit reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) }} />
        </React.Fragment>
    )
}

const locationFilter = (location) => (list) => {
    return list.filter((l) => {
        //if(l.location)
        return l;
    })
}
const CompletedProcess = (props) => {
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const [edit, setEdit] = React.useState(false);
    const handleClose = () => setOpen(false);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Process Stock Form "+(props.live?"(Live)":"(Halfshell)")} >
                <ProcessStockForm {...props} data={rec} handleClose={handleClose} completed edit={edit} />
            </FormModal>
            {checkAccess(props.access, requiredAdminAccess) ? <FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={edit} onChange={(event) => { setEdit(event.target.checked) }} />} label={edit ? 'Edit Enabled' : 'Edit Disabled'} /> : ''}
            <ProcessingLogs filter={locationFilter(props.location)} title={props.live ? "Completed Live" : "Completed Halfshell"} {...props} completed edit={edit} onClick={selectPO} reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) } } />
        </React.Fragment>
    )
}
