import React from 'react';
import { CircularProgress, Paper, Table, TableBody, TextField, Button, Typography } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import Div from "@jumbo/shared/Div";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { tableTheme } from '../themes/tableTheme';
import { ChromePicker } from 'react-color';
import SelectTextField from "./SelectTextField";
import { SelectTextFieldNew } from "./SelectTextField";
import { TableCell, TableRow, TableHead } from "@mui/material";
const EditableTable = (props) => {
    const type = props.type ? props.type : {};
    const newData = () => {
        var copyData = [...props.data];
        copyData.push({ new: true, edited: true });
        props.setData(copyData);
        setTimeout(() => {
            const scrollingElement = (document.scrollingElement || document.body);
            scrollingElement.scrollTop = scrollingElement.scrollHeight;
        }, 300);
    }

    return (
        <React.Fragment>
            <h2>{props.title}</h2>
            <Div>
                <Button variant="contained" onClick={newData}> New Row</Button>
            </Div>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            {props.columns ? props.columns.map((col, ind) => {

                                return (<TableCell key={"col" + ind}>
                                    {col.label}
                                </TableCell>)
                            }) : ''}
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data ? props.data.map((sh, index) => {
                            return <EditableTableRow key={sh.id + 'etr'} index={index} rowData={sh} columns={props.columns} type={type} updateChanges={props.updateChanges} addNew={props.addNew} />
                        }) : ''}
                    </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    );
}

const notNullorUndef = (val) => {
return val !== null && val !== undefined;
}

const EditableTableRow = (props) => {
    const [val, setVal] = React.useState(props.rowData);
    const [edited, setEdited] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);

    React.useEffect(() => {
        setVal(props.rowData);
    }, [props.rowData]);
    const changeValue = (key) => (event) => {
        var cpyVal = { ...val };
        if (props.columns.find((cl) => { return cl.key === key })?.controller) {
            if (cpyVal.matchID !== event.id) {
                cpyVal.matchID = event.id;
            } 
        }
        cpyVal[key] = props.type[key] === 'colour' ? event.hex : event;
        if (notNullorUndef(val[key]) && notNullorUndef(cpyVal[key])) {
            if (val[key] !== cpyVal[key]) {
                setEdited(true);
            }
        }
        setVal(cpyVal);
    }
    const updateChanges = () => {
        setLoading(true);
        props.updateChanges(val).then(() => {
            setCompleted(true);
        })
    }
    const addNew = () => {
        setLoading(true);
        props.addNew(val).then(() => {
            setCompleted(true);
        })
    }
    const SubmitButtons = () => {
        if (completed) {
            return "Completed";
        } else if (loading) {
            return <CircularProgress />
        } else {
            if (!val["new"]) {
                return <Button variant="contained" disabled={!edited} onClick={updateChanges}> Submit Changes</Button>
            } else {
                return <Button variant="contained" onClick={addNew}> Add New Row</Button>
            }
        }


    }
    console.log(val)
    return <TableRow key={val.id}>
        {props.columns ? props.columns.map((col, ind) => {
            return (<TableCell key={ind + ";" + val.id}>
                {!props.type[col.key] ? val[col.key] : ''}
                {props.type[col.key] === 'text' ? <EditableTableField
                    value={val[col.key]}
                    setValue={changeValue(col.key)}
                /> : ''}
                {props.type[col.key] === 'disabled' ? <SelectTextField checkID={val.matchID ? val.matchID : null} title="" state={null} setState={changeValue(col.key)} list={col.list} nameFunc={(op) => { return op[col.key] }} disabled /> : ''}
                {props.type[col.key] === 'disabledColor' ? <SelectTextField style={{ backgroundColor: val[col.key] ? val[col.key][col.key]:'' }} checkID={val.matchID ? val.matchID : null} title="" state={null} setState={changeValue(col.key)} list={col.list} nameFunc={(op) => { return op[col.key] }} disabled /> : ''}
                {props.type[col.key] === 'colour' ? <ColorPicker color={val[col.key]}
                    onChangeComplete={changeValue(col.key)} /> : ''}
                {props.type[col.key] === 'select' ? <SelectTextField checkID={val.matchID ? val.matchID : null} title="" state={val[col.key]} setState={changeValue(col.key)} disabled={false} list={col.list} nameFunc={col.nameFunc ? col.nameFunc : ((op) => { return op[col.key] })} /> : ''}
                {props.type[col.key] === 'newselect' ? <SelectTextFieldNew title="" state={val[col.key]} setState={changeValue(col.key)} disabled={false} list={col.list} nameFunc={col.nameFunc ? col.nameFunc : ((op) => { return op[col.key] })} valueFunc={col.valueFunc} /> : ''}
                {props.type[col.key] === 'boolean' ? <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={val[col.key]} onChange={(event) => { changeValue(col.key)(event.target.checked); }} />} label="" disabled={props.disabled} labelPlacement="right" /> : ''}
            </TableCell>)
        }) : ''}
        <TableCell key={"t;" + val.id}>
            <SubmitButtons item={val} />
        </TableCell>
    </TableRow>
}
const ColorPicker = (props) => {
    const [edit, setEdit] = React.useState(props.value);
    if (!edit) {
        return <EditableTableField
            style={{ backgroundColor: props.color }}
            value={props.color}
            disabled
        //onClick={() => { setEdit(true) }}
        />

    } else {

        return <ChromePicker
            color={props.color}
            onChangeComplete={props.onChangeComplete}
        />

    }
}


const EditableTableField = (props) => {
    const [val, setVal] = React.useState(props.value);
    const changeValue = (event) => {
        //setVal(event.target.value);
        props.setValue(event.target.value);
    }
    return <TextField
        id="outlined-required"
        style={props.style}
        disabled={props.disabled}
        value={props.value}
        onChange={changeValue}
        onClick={props.onClick}
    />
}
export default EditableTable;