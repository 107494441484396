import React from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";


const SelectTextField = (props) => {
    const [inVal, setInVal] = React.useState(props.state);
    const handleChange = (event) => {
        props.setState(event.target.value);
        setInVal(event.target.value);
    }
    React.useEffect(() => {
        if (props.checkID && props.list) {
            var matched = props.list.find((dr) => { return (dr.id === props.checkID) });
            if (matched) {
                props.setState(matched);
            }
                setInVal(matched);
        }
    }, [props.list, props.checkID])
    return <TextField
        id="outlined-select-currency"
        select
        key={props.key}
        label={props.title}
        disabled={props.disabled}
        value={inVal}
        onChange={handleChange}
        InputLabelProps={{
            shrink: true,
        }}
        style={props.style}
    >

        {props.list ? props.list.map((option) => (
            <MenuItem key={option.id} value={props.valueFunc ? props.valueFunc(option) : option}>
                {props.nameFunc ? props.nameFunc(option) : option.name}
            </MenuItem>
        )) : []}
    </TextField>
}
export default SelectTextField;

export const SelectTextFieldNew = (props) => {

    const handleChange = (event) => {
        props.setState(props.list.find((l) => { return (props.valueFunc ? props.valueFunc(l) : l) === event.target.value }));
    }
    var inVal = React.useMemo(() => {
        if (props.checkID && props.list) {
            var matched = props.list.find((dr) => { return (dr.id === props.checkID) });
            if (matched) {
                props.setState(matched);
            }
            return matched;
        } else {
            return props.state;
        }
    }, [props.list, props.checkID, props.state]);
    return <TextField
        id="outlined-select-currency"
        select
        key={props.key}
        label={props.title}
        disabled={props.disabled}
        value={(props.valueFunc ? props.valueFunc(inVal) : inVal) || ''}
        onChange={handleChange}
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            style: props.style
        }}
        style={props.style}
    >

        {props.list ? props.list.map((option) => (
            <MenuItem key={option.id} value={props.valueFunc ? props.valueFunc(option) : option} >
                {props.nameFunc ? props.nameFunc(option) : option.name}
            </MenuItem>
        )) : []}
    </TextField>
}