import React from 'react';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { postApi } from '../utils/apiFunction';

const DeleteCurrentRun = (props) => {
    const Swal = useSwalWrapper();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [addQuantity, setAddQuantity] = React.useState(null);
    const [inv, setInv] = React.useState(null);

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)

        //output must be less than = to available stock
        if (props.outputs.processRunOutputs.length > 0) {
            errorMessages.push("You must remove all outputs before deleting");
        }
        warningMessages.push("Are you sure you want to delete?");


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: '',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, Go Back',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
        console.log(props.selectedBench)
    const submit = async (finished) => {
        var allOk = false;
        var respArr = [];
        setLoading(true);

        const params = {
            ProcessRunId: props.selectedBench?.id,
            ProductionLineId: props.selectedBench?.processLineId,
            Amount: 0,
            Bins: 0,
            Bags: 0,
            Staff: props.selectedBench?.staff,
            StaffMemberId: props.selectedBench?.staffMemberId
        }
        respArr.push(await postApi('/Processing/UpdateProcess', params));
        const endparams = {
            processRunId: props.selectedBench.id
        }
        respArr.push(await postApi('/Processing/EndProcessing', endparams));
        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            props.handleClose();
        } else {
            setError("Failed to submit");
        }

    }
    return <React.Fragment>
        <Typography variant={'h5'}>Are you sure you want to delete this run?</Typography>
        {loading ? <CircularProgress /> : <Button variant="contained" color="error" style={{ whiteSpace: 'nowrap', height: '50px', margin: 0 }} onClick={validate} handleClose={props.handleClose} >DELETE</Button>}

    </React.Fragment>
}
export default DeleteCurrentRun;