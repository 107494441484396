import React from 'react';
import { getOysterGrades,gradeSort } from "../../../definitions/oysters";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { tableTheme } from '../../../themes/tableTheme';
import { TableCell, TableRow } from "@mui/material";
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import DayToggle from "../../../components/DayToggle";
import { headerRender } from '../../../utils/renderFunctions';
import TooltipDescription from "../../../components/TooltipDescription";
import { numberFormat } from '../../../utils/numberFunctions';
import { textfieldDateFormat, generateTextDays, generateDates, yesterday, dayCompare } from '../../../utils/dateFunctions';
import { useJumboTheme } from "@jumbo/hooks";
import { getAPIDataParamsReturn } from '../../../utils/apiFunction';

const Detail = (props) => {
    const { theme } = useJumboTheme();
    var bgCol = theme.palette.oyster.AN;
    const trafficLight = [{ color: theme.palette.trafficLight.red, status: 'Calculated' }, { color: theme.palette.trafficLight.orange, status: 'Not all manual inventories have been added' }, { color: theme.palette.trafficLight.green, status: 'Manually Entered Inventory' }]
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [closingTLight, setClosingTLight] = React.useState([]);
    const [inventoryTLight, setInventoryTLight] = React.useState([]);
    const [recData, setRecData] = React.useState(null);
    const [showTitleAdd, setShowTitleAdd] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const [daySelection, setDaySelection] = React.useState([generateDates(props.startDate, props.endDate).findIndex((vl) => { return dayCompare(vl, new Date()) })]);
    const [reload, setReload] = React.useState(true);
    const reloadFunc = () => {
        setReload(!reload);
    }
    console.log(procurement);
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, reload, daySelection,props.externalReload]);
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    React.useEffect(() => {
        if (recData) {
            organizeData(recData);
            setClosingTLight(recData.closingTrafficLight);
            setInventoryTLight(recData.inventoryTrafficLight);
        }
    }, [recData]);
    //React.useEffect(() => {
    //    organizeData(recData);
    //}, [daySelection]);
    const options = {
        filter: false,
        fixedHeader: true,
        tableBodyMaxHeight: '450px',
        selectableRowsHideCheckboxes: true,
        rowsPerPage: 42,
        customRowRender: ((data, dataIndex, rowIndex) => {
            console.log(inventoryTLight, closingTLight)
            //if (data[1] === undefined) {
            //    return <TableRow key={Math.random()} style={{
            //        background: "rgb(2, 0, 36)",
            //        background: "linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(124, 203, 193, 0.47942927170868344) 0%, rgba(0, 212, 255, 1) 100%)"
            //    }} class="MuiTableRow-root css-16f542l-MuiTableRow-root MuiTableRow-hover tss-mwfche-MUIDataTableBodyRow-root tss-mwfche-MUIDataTableBodyRow-root">
            //        {data.map((c) => {
            //            return <TableCell key={Math.random()}>{numberFormat(c)}</TableCell>
            //        })}</TableRow>
            //} else
            if (rowIndex === 0) {
                return <TableRow key={Math.random()} style={{
                    background: trafficLight[inventoryTLight]?.color,
                }} class="MuiTableRow-root css-16f542l-MuiTableRow-root MuiTableRow-hover tss-mwfche-MUIDataTableBodyRow-root tss-mwfche-MUIDataTableBodyRow-root">
                    {data.map((c) => {
                        if (!isNaN(Number(c))) {
                            return <TableCell key={Math.random()} style={{ textAlign: 'right' }}>{numberFormat(c)}</TableCell>
                        } else {
                            return <TableCell key={Math.random()} >{numberFormat(c)}</TableCell>
                        }
                    })}</TableRow>
            }
            else if (rowIndex === (procurement.length - 1)) {
                return <TableRow key={Math.random()} style={{
                    background: trafficLight[closingTLight]?.color,
                }} class="MuiTableRow-root css-16f542l-MuiTableRow-root MuiTableRow-hover tss-mwfche-MUIDataTableBodyRow-root tss-mwfche-MUIDataTableBodyRow-root">
                    {data.map((c) => {
                        if (c === "Closing") {
                            console.log(!(generateDates(props.startDate, props.endDate)[daySelection] > new Date()), ((closingTLight ? closingTLight[daySelection] : 0) === 2))
                            return <TableCell key={Math.random()} >{(!(generateDates(props.startDate, props.endDate)[daySelection] > yesterday(new Date())) || ((closingTLight ? closingTLight[daySelection] : 0) === 2)) ? "Closing" : "Variance"}</TableCell>
                        } else if (!isNaN(Number(c))) {
                            return <TableCell key={Math.random()} style={{ textAlign: 'right' }}>{numberFormat(c)}</TableCell>
                        } else {
                            return <TableCell key={Math.random()} >{numberFormat(c)}</TableCell>
                        }
                    })}</TableRow>
            }
            else {
                return <TableRow key={Math.random()} class="MuiTableRow-root css-16f542l-MuiTableRow-root MuiTableRow-hover tss-mwfche-MUIDataTableBodyRow-root tss-mwfche-MUIDataTableBodyRow-root">
                    {data.map((c) => {
                        if (!isNaN(Number(c))) {
                            return <TableCell key={Math.random()} style={{ textAlign: 'right' }}>{numberFormat(c)}</TableCell>
                        } else {
                            return <TableCell key={Math.random()} >{numberFormat(c)}</TableCell>
                        }
                    })}
                </TableRow>
            }
        })
    };
    var columns = [{
        name: 'label',
        label: ' ',

    }];
    var oysterCols = grades.map((og) => {
        console.log(og)
        return {
            name: og.abv.toLowerCase(),
            label: og.Grade,
            options: {
                customHeadRender: headerRender(og.Species),

            }
        }
    })
    var total = [{
        name: 'total',
        label: 'Total',
        options: {
            customHeadRender: (obj) => { return <TableCell style={{ backgroundColor: 'lightgrey' }}><div >{obj.label}</div></TableCell> },

        }

    }];
    columns = [...columns, ...oysterCols, ...total];
    var getProcurementData = async (location, startDate, endDate, abortController) => {
        var params = '';
        if (daySelection.length > 1) {
            params = {
                Location: location,
                startDate: textfieldDateFormat(generateDates(startDate, endDate)[daySelection[0]]),
                endDate: textfieldDateFormat(generateDates(startDate, endDate)[daySelection[daySelection.length - 1]])
            }
        } else {
            params = {
                Location: location,
                startDate: textfieldDateFormat(generateDates(startDate, endDate)[daySelection[0]])
            }
        }
        const data = await getAPIDataParamsReturn('/ProcurementDashboard/DailyProcurement', params, abortController);
        console.log(data);
        setRecData(data);

    }
    var organizeData = (data) => {
        var newData = [];
        newData.push(data.opening);
        newData.push(data.salesOrders);
        newData.push(data.purchaseOrders);
        if (props.location !== 0) {
            newData.push(data.transfers);
        }
        newData.push(data.closing);
        setProcurement(newData);
    }
    const closeTitle = (stat) => {
        setShowTitleAdd(stat);
    }
    const tooltitle = <div>
        <div>This table shows the oyster sales and procurement for the selected day/s. If the day is current or in the future it is the expected values, if it is in the past it is the actual values completed on that day.</div>
        <br />
        <div>
            <span style={{ backgroundColor: theme.palette.trafficLight.red }}>Red</span> indicates both inventories havent been added
        </div>
        <div>
            <span style={{ backgroundColor: theme.palette.trafficLight.orange }}>Orange</span> indicates one inventory has been added
        </div>
        <div><span style={{ backgroundColor: theme.palette.trafficLight.green }}>Green</span> indicates both inventories have been added
        </div>
    </div>
    return (
        <DataTableStandard
            data={procurement}
            title={<div><div style={showTitleAdd ? { paddingTop: '15px' } : {}}>Daily Procurement<TooltipDescription title={tooltitle} /></div>{showTitleAdd ? <DayToggle nextWeek={props.nextWeek} lastWeek={props.lastWeek} daySelection={daySelection} setDaySelection={setDaySelection} days={generateTextDays(props.startDate, props.endDate)} /> : ''}</div>}
            columns={columns}
            options={options}
            actionOnClick={closeTitle}
            reload={reloadFunc}
        />
    );
};

export default Detail;
