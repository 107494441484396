import React from 'react';
import Grid from "@mui/material/Grid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import { getAPIData, getAPIDataParams } from '../../utils/apiFunction';
import { textfieldDateFormat } from "../../utils/dateFunctions";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import MonthSelector from "../../shared/MonthSelector";
import { DateTime } from 'luxon'
import { useSearchParams } from "react-router-dom";
import { checkAccess } from '../../utils/roleFunctions';
import Agenda from './Report/Agenda';
import FarmReport from './Report/FarmReport';
import Financials from './Report/Financials';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SelectAOCFarmWarehouse, { getAOCWarehouses } from '../../components/SelectAOCFarmWarehouse';

const MonthlyReport = (props) => {
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(DateTime.now());
    const [jsStartDate, setJSStartDate] = React.useState(new Date());
    const [view, setView] = React.useState(0);
    const [report, setReport] = React.useState(null);
    const [inDoz, setInDoz] = React.useState(true);
    const [newReport, setNewReport] = React.useState(true);
    const [ReportOpen, setReportOpen] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [aocFarms, setAOCFarms] = React.useState([]);
    const [YTD, setYTD] = React.useState(false);
    const [allFarms, setAllFarms] = React.useState(false);
    const [allowedFarmList, setAllowedFarmList] = React.useState(false);
    //React.useEffect(() => {
    //    const setGroups = (inp) => {
    //        var aocfarmlist = inp.farmers?.filter((f) => { return f.isInternal });
    //        setFarm(aocfarmlist[0]);
    //        setAOCFarms(aocfarmlist);
    //    }
    //    getAPIData('/farmerssuppliers', setGroups);
    //}, []);
    React.useEffect(() => {
        setJSStartDate(startDate.toJSDate());
    }, [startDate]);

    React.useEffect(() => {
        getAOCWarehouses(props.access, props.showAll, setAllowedFarmList);
    }, []);
    React.useEffect(() => {
        if (farm) {
            getAPIDataParams('/Reports/FRM/GetReportId', setReport, { Date: textfieldDateFormat(startDate.toJSDate()), warehouseId: farm?.warehouseId })
        }
    }, [startDate, farm]);

    console.log(startDate)
    const submitReport = () => {

    }
   
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={6}>
                    {!allFarms?<SelectAOCFarmWarehouse setState={setFarm} state={farm} access={props.access} /> :<Typography style={{ display: 'inline' }} variant={"h2"} mb={0}>Summary of All Farms</Typography>}
                   
                    {allowedFarmList.length > 0 ? <ToggleButton color="primary" style={{ marginLeft: '20px' }} key={0} selected={allFarms} onChange={() => {
                        setAllFarms(!allFarms);
                    }}>
                        <div style={{ whiteSpace: 'nowrap' }} > All Farms</div>
                    </ToggleButton> : null}

                </Grid>

                <Grid item xs={12} sm={12} md={6} style={{ textAlign: "right" }}>
                    {/*<Button onClick={submitReport} variant="contained" style={{ marginBottom: '5px' }}>{"Submit Report for " + startDate.monthLong}</Button>*/}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    {!YTD ? <MonthSelector date={startDate} setDate={setStartDate} /> : <Typography style={{ display: 'inline' }} variant={"h2"} mb={0}>Financial Year to Date</Typography>}
                    <span style={{ margin: '15px' } }>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            disabled={props.disabled}
                            value={YTD}
                            onChange={(event, sel) => { sel ? setYTD(true) : setYTD(false); }}
                            aria-label="text alignment"
                        >
                            <ToggleButton key={0} value={true} >
                                <div style={{ whiteSpace: 'nowrap' }}>YTD</div>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </span>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={view}
                        onChange={(event, sel) => { setView(sel) }}
                        aria-label="text alignment"
                    >
                        <ToggleButton key={0} value={0} >
                            <div style={{ whiteSpace: 'nowrap' }}>Farm Report</div>
                        </ToggleButton>
                        <ToggleButton key={1} value={1} >
                            <div  >Agenda</div>
                        </ToggleButton>
                        {/*<ToggleButton key={2} value={2} >*/}
                        {/*    <div  >Financials</div>*/}
                        {/*</ToggleButton>*/}
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Pcs</Typography>
                        <Switch checked={inDoz} onChange={(event) => setInDoz(event.target.checked)} name="header-fixed" />
                        <Typography>Doz</Typography>
                    </Stack>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >*/}
                {/*    <Stack direction="row" spacing={1} alignItems="center">*/}
                {/*        <Typography>Old</Typography>*/}
                {/*        <Switch checked={newReport} onChange={(event) => setNewReport(event.target.checked)} name="header-fixed" />*/}
                {/*        <Typography>New</Typography>*/}
                {/*    </Stack>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    {view === 0 ? <FarmReport newReport={newReport} YTD={YTD} inDoz={inDoz} unlock farmId={farm?.id} warehouseId={farm?.warehouseId} access={props.access} monthDate={jsStartDate} reportId={report} allFarms={allFarms} allowedFarmList={allowedFarmList} /> : null}
                    {view === 1 ? <Agenda unlock warehouseId={farm?.warehouseId} access={props.access} monthDate={jsStartDate} /> : null}
                    {/*{view === 2 ? <Financials unlock warehouseId={farm?.warehouseId} access={props.access} monthDate={jsStartDate} /> : null}*/}
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default MonthlyReport;


