import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Receivals from '../Processing/Tables/Receivals';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import Typography from "@mui/material/Typography";
import {
    Card,
    CardContent,
    TextField
} from "@mui/material";
import { useJumboTheme } from "@jumbo/hooks";


const names = [
    {
        name: "Sean",
        num: "A1"
    },
    {
        name: "May",
        num: "A2"
    },
    {
        name: "Steve",
        num: "A3"
    },
    {
        name: "Sarah",
        num: "A4"
    },
    {
        name: "Jeff",
        num: "A5"
    },
    {
        name: "Ken",
        num: "B2"
    },
    {
        name: "Leo",
        num: "B3"
    },
    {
        name: "Coleen",
        num: "B4"
    },
    {
        name: "Joe",
        num: "D1"
    },
    {
        name: "Sophie",
        num: "D7"
    },
    {
        name: "Cole",
        num: "E1"
    },

    {
        name: "Kath",
        num: "E2"
    }
]
const LandingPage = (props) => {
    const [open, setOpen] = React.useState(true);
    const [number, setNumber] = React.useState('');
    const [PRN, setPRN] = React.useState(null);
    const handleClose = () => setOpen(false);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const { theme } = useJumboTheme();
    const [name, setName] = React.useState(null);
    const changeCol = (nm) => () => {
        setName(nm);
    }
    
    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setPRN(array[rowState.dataIndex]);
    }
    const typeFunc = (num) => () => {
        setNumber(number + String(num));
    }
    const clear = () => {
        setName('');
        setPRN(null);
    }
    return (
        <div>
            <Typography variant={"h1"} mb={3}>Match PRN to Shucker</Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>

                            <TextField
                                disabled
                                id="outlined-required"
                                label="PRN"
                                value={PRN?PRN.prn:''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Typography variant={"h3"} mb={3}>{name}</Typography>

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Receivals access={props.access} onClick={selectPO} startDate={startDate} endDate={endDate} notConfirmed />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                                
                            <Div
                                sx={{
                                    mx: -.75
                                }}
                            >
                                <Grid container spacing={2}>
                                    {names.map((nm) => {
                                        return (<Grid item xs={12} md={3} lg={3}>
                                            <User name={nm.name} num={nm.num} sel={nm.name === name} changeCol={changeCol} />
                                        </Grid>
                                        )
                                    })}

                                </Grid>
                            </Div>
                            <JumboCardQuick bgColor={'#E44A77'} onClick={clear}>
                                <Div sx={{ display: "flex", alignItems: 'center' }}>
                                    <Div sx={{ ml: 2, flex: 1 }}>
                                        <Typography color={'common.white'} variant={"h2"} mb={.5}>Enter</Typography>
                                    </Div>
                                </Div>
                            </JumboCardQuick>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </div >
    );
};

export default LandingPage;



const User = (props) => { 
    return (<Card sx={{ mb: 4 }} onClick={props.changeCol(props.name)} style={{ backgroundColor: props.sel ? 'grey' : 'white' } } >
       
        <CardContent>
           
                <Div sx={{ flex: 1, }}>
                    <Typography
                        variant={"h6"}
                        color={"common.black"}
                        mb={2}
            >{props.name }</Typography>
                    <Typography
                        variant={"body1"}
                        color={"text.secondary"}
            >{props.num }</Typography>
                </Div>
        </CardContent>
    </Card >
        );
    }