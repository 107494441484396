import React from 'react';
import JumboNavIdentifier from "@jumbo/components/JumboVerticalNavbar/JumboNavIdentifier";
import { useJumboLayoutSidebar } from "@jumbo/hooks";
import { SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import List from "@mui/material/List";
import { userInfo } from '../../../adalConfig';
import { getUsersAccess, checkAccess } from '../../../app/utils/roleFunctions';
import PropTypes from 'prop-types';

const JumboVerticalNavbar = ({ items, translate }) => {
    const { sidebarOptions } = useJumboLayoutSidebar();
    const [access, setAccess] = React.useState(getUsersAccess(userInfo()));
    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);
    //React.useEffect(async() => {
    //    var userDeets = await authContext.getCachedUser();
    //    setAccess(getUsersAccess(userDeets));

    //},[])


    return (
        <List
            disablePadding
            sx={{
                mr: isMiniAndClosed ? 0 : 2,
                pb: 2
            }}
        >
            {
                items.map((item, index) => {
                    if (checkAccess(access, item.access)) {
                        return <JumboNavIdentifier translate item={item} key={index} />
                    }
                })
            }
        </List>
    );
};

JumboVerticalNavbar.defaultProps = {
    items: PropTypes.array,
    translate: false
};

export default JumboVerticalNavbar;