import React from 'react';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const WarehouseSelector = (props) => {
    return (<FormControl>
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event) => props.setLocation(Number(event.target.value))}
            value={props.location}

        >
            <FormControlLabel value={0} control={<Radio />} label="All Warehouses" />
            <FormControlLabel value={1} control={<Radio />} label="Cranbrook" />
            <FormControlLabel value={2} control={<Radio />} label="Kingsgrove" />
            <FormControlLabel value={3} control={<Radio />} label="Contractor" />
        </RadioGroup>
    </FormControl>);
}
export default WarehouseSelector;