import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "./routes";
import { useJumboRoutes } from "@jumbo/hooks";
import { checkAccess } from "./utils/roleFunctions";
const AppRoutes = (props) => {
    var routeList = routes;

    //const appRoutes = useJumboRoutes(routeList);
    return (
        <Routes>
            {routeList.map((gRoute, index) => {
                const searchAvailable = () => {

                }
                var element = gRoute.element;
                if (props.blank) {
                    element = (<div></div>);
                }
                if (checkAccess(props.access, gRoute.access)) {
                    return <Route key={index} path={gRoute.path} element={React.cloneElement(element, { access:props.access })} />
                } else {
                    console.log(gRoute.path);
                    if (gRoute.path === '/') {
                        for (var i = 0; i < routeList.length; i++) {
                            if (checkAccess(props.access, routeList[i].access)) {
                                return <Route key={index} path={'/'} element={React.cloneElement(routeList[i].element, { access: props.access })} />
                            }
                        }
                        return <Route key={index} path={gRoute.path} element={<div>You are not authorized</div>} />
                    } else {
                        return <Route key={index} path={gRoute.path} element={<div>You are not authorized</div>} />
                    }

                }

            })}
        </Routes>
    );
};
export default AppRoutes;
