import React from 'react';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import { MYOBTOREAD } from '../utils/constants/warehouse'


const SelectWarehouse = (props) => {
    const [warehouse, setWarehouse] = React.useState([]);
    const [value, setValue] = React.useState(props.state ? props.state : null);
    const [cache, setCache] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const filterVals = (list) => {
        var newList = [...list];
        if (props.access && props.enforceAccess) {
           newList = newList.filter((a) => {
                return props.access.find((b) => {return b.key === MYOBTOREAD[a.myobid] });
            });
            console.log(newList);
        }
        setWarehouse(newList.sort((a, b) => {
            return a.description > b.description ? 1 : -1;
        }))
    }
    React.useEffect(() => {
        if (props.warehouseId) {
            if (props.notThisId) {
                var estI = warehouse.findIndex((dr) => { return (dr.id === props.warehouseId) });
                var wHouse = null;
                if (estI > 0) {
                    wHouse = warehouse[estI - 1];
                } else {
                    if (warehouse[estI + 1]) {
                        wHouse = warehouse[estI + 1];
                    }
                }
                props.setState(wHouse);
                setValue(wHouse);
            } else {
                var est = warehouse.find((dr) => { return (dr.id === props.warehouseId) });
                props.setState(est);
                setValue(est);
            }
        }
    }, [warehouse, props.warehouseId])
    React.useEffect(() => {
        if (cache && !props.warehouseId && !value && !props.disabled) {
            var est = warehouse.find((dr) => { return (dr.id === cache.id) });
            if (est) {
                props.setState(est);
                setValue(est);
            }
        }
    }, [cache, warehouse])
    React.useEffect(() => {
        if (props.useCache) {
            GetAllCacheData(setCache, 'warehouse')
        }
        if (props.all) {
            const remUnwanted = (filt) => (all) => {
                if (props.selectOne) {
                    return filt(all.filter((a) => { return props.selectOne(a.myobid) }));
                } else {
                    return filt(all.filter((a) => { return (a.myobid === "LIVEPRODCT") || (a.myobid === "SYDNEY") || (a.myobid === "HALFSHELL") }));
                }
            }
            getAPIData('/Warehouses/Get', remUnwanted(filterVals));

        } else {
            const changeDescription = (filt) => (vals) => {
                if (props.cleanDisp) {
                    return filt(vals.map((c) => {
                        var n = { ...c };
                        if (n.myobid === 'LIVEPRODCT') {
                            n.description = 'BATEMANS BAY'
                        }
                        return n;
                    }));

                }
                return filt(vals);
            }
            getAPIData('/Warehouses/GetForReceival', changeDescription(filterVals));

        }
    }, [])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
            if (props.useCache) {
                addToCache(newValue, 'warehouse');
            }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option?.description}
        disablePortal
        id="warebox"
        options={warehouse}
        sx={props.auto ? {} : { width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.title ? props.title : "Warehouse"} />}
    />);



}
export default SelectWarehouse;