import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { startOfWeek, shiftWeek } from "../../../utils/dateFunctions";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { delIcon } from "../../../themes/myStyles";
import { getAPIData, getAPIDataReturn, getAPIDataParams } from '../../../utils/apiFunction';
import { adalApiFetch } from '../../../../adalConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import DropZone from "../../../components/DropZone";
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { checkAccess } from '../../../utils/roleFunctions';
import { CircularProgress } from '@mui/material';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import OystersGroup from "../../../components/OysterGroup";
import { textfieldDateFormat, dateTimeFormat } from "../../../utils/dateFunctions";
import StaffMember from "../../../components/StaffMember";
import ControlledTextField from "../../../components/ControlledTextField";
import CropYearSelect from "../../../components/CropYearSelect";
import SelectTextField from "../../../components/SelectTextField";
import SupplierSelect from "../../../components/SupplierSelect";
import SelectWarehouse from "../../../components/SelectWarehouse";
import PurchaseOrderSelect from "../../../components/PurchaseOrderSelect";
import EstuaryStatusSelect from "../../../components/EstuaryStatusSelect";
import ETransferDisplay from "../../../components/ETransferDisplay";
import FormModal from '../../../components/FormModal';
import POSelectForm from './POSelectForm';

const ConfirmRec = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const sinObjArr = [{}];
    const empArr = [];
    const [completed, setCompleted] = React.useState(props.completed);
    const [warehouse, setWarehouse] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [receival, setReceival] = React.useState(false);
    const [oysters, setOysters] = React.useState([{}]);
    const [POId, setPOId] = React.useState(null);
    const [OTR, setOTR] = React.useState(null);
    const [PRN, setPRN] = React.useState(null);
    const [harvestDate, setHarvestDate] = React.useState(null);
    const [estuary, setEstuary] = React.useState(null);
    const [harvestArea, setHarvestArea] = React.useState(null);
    const [lease, setLease] = React.useState(null);
    const [multipleCropYear, setMultipleCropYear] = React.useState(false);
    const [cropYear, setCropYear] = React.useState(null);
    const [cropYearCode, setCropYearCode] = React.useState(null);
    const [temperature, setTemperature] = React.useState(null);
    const [staffMember, setStaffMember] = React.useState(null);
    const [receivalComments, setReceivalComments] = React.useState(null);
    const [pickupDate, setPickUpDate] = React.useState(props.data ? (props.data.pickupDate) : '');
    const id = props.data ? props.data.id : '';
    const receivalId = props.data ? props.data.id : '';
    const [supplier, setSupplier] = React.useState(null);
    //const supplier = props.data ? props.data.supplierPredict : '';
    const vertical = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const Swal = useSwalWrapper();
    const [driver, setDriver] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    const [transfer, setTransfer] = React.useState(null);
    React.useEffect(() => {
        getAPIDataReturn('/Receivals/Get/' + id).then((data) => {
            if (data.transferId) {
                getAPIData('/Transfers/Get/' + data.transferId, setTransfer);
            }
            setReturnData(data);
        });

    }, [])
    React.useEffect(() => {
        console.log(returnData);
        if (returnData) {
            setOTR(returnData.otr);
            setPRN(returnData.prn);
            setHarvestDate(new Date(returnData.harvestDate));
            setLease(returnData.lease);
            //setCropYear(returnData.cropYear);
            setTemperature(returnData.tempInbound);
            setReceivalComments(returnData.receivalComment);
            setPickUpDate(dateTimeFormat(returnData.pickupDate));
            var crpYear = null;
            var oysterArr = [];
            var multi = false;
            for (var i = 0; i < returnData.receivalLines.length; i++) {
                oysterArr.push(returnData.receivalLines[i]);

                if (crpYear && (crpYear !== returnData.receivalLines[i].cropYear)) {
                    setMultipleCropYear(true);
                    multi = true;
                } else {
                    crpYear = returnData.receivalLines[i].cropYear;
                }
            }
            if (!multi) {
                setCropYearCode(crpYear);
            }
            setOysters(oysterArr.length > 0 ? [...oysterArr] : [{}]);
        }
    }, [returnData])
    console.log(oysters)

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!multipleCropYear && !cropYear && supplier && supplier.isInternal) {
            errorMessages.push("You must select a Crop Year for AOC Farms");
        }
        if (multipleCropYear) {
            for (var i = 0; i < oysters.length; i++) {
                console.log(oysters)
                if (!oysters[i].cropYear) {
                    errorMessages.push("You must select a Crop Year for each stock item");
                }
            }
        }

        for (var i = 0; i < oysters.length; i++) {
            if (!oysters[i].stockItem) {
                errorMessages.push("You must select a Grade for each Line");
            }
        }
        if (!staffMember) {
            errorMessages.push("You must select a Staff Member");
        }
        if (!temperature) {
            errorMessages.push("You must enter a Temperature");
        }
        if (!Number(temperature) && (Number(temperature) !== 0)) {
            errorMessages.push("The Temperature is entered incorrectly. It must be a number");

        } else {
            if ((temperature) > 99) {
                errorMessages.push("The Temperature is too high.");
            }
        }
        if (!OTR) {
            errorMessages.push("You must enter an OTR");
        }
        if (!PRN) {
            errorMessages.push("You must enter a PRN");
        }
        if (!warehouse) {
            errorMessages.push("You must select a Warehouse");
        }
        if (!POId && !supplier) {
            errorMessages.push("You must select a Purchase Order or the Supplier");
        }
        if (totalQuantity(oysters) < 1) {
            warningMessages.push("Are you sure the Quantity is correct");
        }
        if (transfer && (!receivalComments || receivalComments === '')) {
            var transferTotal = 0;
            var receivalTotal = 0;
            for (var i = 0; i < transfer.coreTransferLines.length; i++) {
                transferTotal = transferTotal + transfer.coreTransferLines[i].quantity;
            }
            for (var i = 0; i < oysters.length; i++) {
                receivalTotal = receivalTotal + oysters[i].quantity;
            }
            if (transferTotal !== receivalTotal) {
                errorMessages.push("You must enter a reason why the transfer differs from the receival. Put this in the comment box");
            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const deleteAll = async () => {

    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        console.log(POId);
        var data = {
            PurchaseOrderId: POId ? POId.id : 0,
            SupplierId: supplier ? supplier.id : 0,
            OTR: OTR,
            PRN: PRN,
            HarvestDate: harvestDate ? textfieldDateFormat(harvestDate) : null,
            EstuaryId: estuary ? estuary.id : 0,
            HarvestAreaId: harvestArea ? harvestArea.id : 0,
            WarehouseId: warehouse ? warehouse.id : 0,
            Lease: lease,
            //CropYear: cropYear ? cropYear.code : 0,
            Temperature: temperature,
            StaffMemberId: staffMember ? staffMember.id : null,
            ReceivalComments: receivalComments

        }
        //image upload
        var response = await adalApiFetch(fetch, uri + '/Receivals/Post/' + id + '?' + new URLSearchParams({
            ...data
        }), {
            method: 'post',
        });
        var promArr = [];
        var reducedOysters = (oysters);
        promArr = reducedOysters.map((oy) => {
            var oyData = {
                receivalId: id,
                stockItemId: oy.stockItem ? oy.stockItem.id : null,
                Bags: oy.bags ? oy.bags : 0,
                Bins: oy.bins ? oy.bins : 0,
                Quantity: oy.quantity ? oy.quantity : 0,
                CropYear: multipleCropYear ? (oy.cropYear ? oy.cropYear : null) : (cropYear ? cropYear.code : null),
            }
            if (oy.id) {
                return adalApiFetch(fetch, uri + '/ReceivalLines/Post/' + oy.id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            } else {
                return adalApiFetch(fetch, uri + '/ReceivalLines/Post?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            }
        })
        if (returnData) {
            if (returnData.receivalLines && (returnData.receivalLines.length > 0)) {
                returnData.receivalLines.map((rl) => {
                    var exID = oysters.find((oy) => { return oy.id === rl.id });
                    if (!exID) {
                        return adalApiFetch(fetch, uri + '/ReceivalLines/Delete/' + rl.id, {
                            method: 'delete',
                        });
                    }
                })
            }
        }




        promArr.push(response.json());



        for (var i = 0; i < files.length; i++) {
            if (files[i]) {
                var formdata = new FormData();
                formdata.append(files[i].name, files[i], files[i].name);
                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };
                promArr.push(await adalApiFetch(fetch, uri + '/Receivals/ImageUpload/' + id, requestOptions));
            }
        }


        await Promise.all(promArr);

        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        {returnData?.transferId ? <ETransferDisplay transferId={returnData.transferId} /> : <img src={returnData?.imagePath} alt='otr' width="100%" />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Typography variant={"h3"}>Confirm OTR Information</Typography>
                        <Grid container spacing={0} mb={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    disabled
                                    id="outlined-required"
                                    label="Receival Id"
                                    value={receivalId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextField
                                    disabled
                                    id="outlined-required"
                                    label="Pickup Date"
                                    value={pickupDate}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                                <ControlledTextField title="OTR" state={OTR} setState={setOTR} disabled={completed} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ControlledTextField title="PRN" state={PRN} setState={setPRN} disabled={completed} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                                <SelectWarehouse useCache warehouseId={returnData ? returnData.warehouseId : null} state={warehouse} setState={setWarehouse} disabled={completed} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <PurchaseOrderSelect POID={returnData ? returnData.purchaseOrderId : null} state={POId} setState={setPOId} disabled={completed} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>

                                <SupplierSelect frmrId={POId ? POId.supplierId : (returnData ? returnData.supplierId : null)} state={supplier} setState={setSupplier} disabled={POId || completed} />
                            </Grid>
                            {supplier && supplier.isInternal ? <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <FormGroup>
                                    <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox checked={multipleCropYear} onChange={(event) => { setMultipleCropYear(event.target.checked); }} />} label="Multiple Crop Years?" disabled={completed} />
                                </FormGroup>
                            </Grid> : ''}
                            {supplier && supplier.isInternal && !multipleCropYear ? <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <CropYearSelect supplier={supplier} estId={cropYearCode} state={cropYear} setState={setCropYear} disabled={completed} />
                            </Grid> : ''}

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <DatePicker label="Harvest Date" date={harvestDate} setDate={setHarvestDate} disabled={completed} />
                            </Grid>




                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <EstuaryStatusSelect estId={returnData ? returnData.estuaryId : null} state={estuary} setState={setEstuary} disabled={completed} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectTextField checkID={returnData ? returnData.harvestAreaId : null} title="Harvest Area" state={harvestArea} setState={setHarvestArea} disabled={!estuary || completed} list={estuary ? estuary.harvestAreas : []} nameFunc={(op) => { return op.description }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ControlledTextField title="Lease No." state={lease} setState={setLease} disabled={completed} />
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Typography variant={"h3"}>Add Staff Information</Typography>

                        <Grid container spacing={0} mb={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                <StaffMember staffId={returnData ? returnData.staffMemberId : null} driver={staffMember} setDriver={setStaffMember} disabled={completed} noTitle />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                <ControlledTextField title="Temperature" state={temperature} setState={setTemperature} disabled={completed} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                <TextField
                                    disabled={completed}
                                    id="outlined-multiline-static"
                                    label="Comments"
                                    value={receivalComments}
                                    onChange={(event) => { setReceivalComments(event.target.value) }}
                                    multiline
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    rows={4} />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        {(returnData && returnData.extraImages ? returnData.extraImages.map((im) => {
                            return <img src={im} width="200px" />
                        }) : '')}
                        {!completed ? <DropZone files={files} setFiles={setFiles} multiple /> : ''}

                    </Grid>
                    <Grid item xs={12} >
                        {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                        {returnData ? <OystersGroup supplier={supplier} oysters={oysters} starter={!(props.edit || completed || returnData?.receivalLines?.length > 0)} setOysters={setOysters} disabled={completed} cropYear={multipleCropYear && supplier?.isInternal} /> : null}
                    </Grid>
                    <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

                </Grid>
            </Div>
        </Box >
    );
};
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}








export default ConfirmRec;
