import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DataTableStandard from "../DataTableStandard";
import { Button, IconButton, FormControlLabel, Switch } from "@mui/material";
import { dateTimeFormat, textfieldDateFormat } from '../../utils/dateFunctions';
import { adalApiFetch } from '../../../adalConfig';
import Typography from '@mui/material/Typography';
import { getAPIData } from '../../utils/apiFunction';
import FormModal from '../FormModal';
import { useJumboTheme } from "@jumbo/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import { dateSortLong } from '../../utils/sortFunctions';
import { buildSummary } from '../../utils/transferFunctions';

const Transfers = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [tableData, setTableData] = React.useState([]);
    const [holdData, setHoldData] = React.useState([]);
    const [rowData, setRowData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openRec, setOpenRec] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [suppliers, setSuppliers] = React.useState({});
    const [columns, setColumns] = React.useState([]);
    const [edit, setEdit] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleCloseRec = () => setOpenRec(false);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

    var urlPath = '';
    var tableTitle = 'Receivals';
    var dateField = '';
    var dateName = '';
    if (props.ready) {
        urlPath = '/GetReady';
        tableTitle = 'Ready for Pickup';
        dateField = 'dateReady';
        dateName = 'Ready Date';
    } else if (props.inTransit) {
        urlPath = '/GetInTransit';
        tableTitle = 'In Transit';
        dateField = 'datePickedUp';
        dateName = 'Pick Up Date';
    } else if (props.received) {
        urlPath = '/GetReceivedBySource';
        tableTitle = 'Received Transfers';
        dateField = 'dateReceived';
        dateName = 'Date Received';
    } else if (props.cancelled) {
        urlPath = '/GetCancelled';
        tableTitle = 'Cancelled Transfers';
        dateField = 'dateCancelled';
        dateName = 'Cancelled Date';
    }



   
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,

        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(tableData) : () => { },

        ...props.extraOptions
    };
    
    React.useEffect(() => {
        var cols = [
            {
                name: dateField,
                label: dateName,
                options: {
                    sortCompare: dateSortLong,
                    sortDescFirst: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return value?dateTimeFormat(value):'';
                    }
                }
            },
            {
                name: 'otr',
                label: 'OTR'
            },
            {
                name: 'prn',
                label: 'PRN'
            },
            {
                name: 'sourceString',
                label: 'Source',
            },
            {
                name: 'quickSummary',
                label: 'Oysters',
            },
            {
                name: 'destinationString',
                label: 'Destination',
            }
           

        ];
        setColumns(cols);
    }, [props]);
  
    React.useEffect(() => {
        setTableData([]);
        if (props.warehouseId) {
            getData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
        }
    }, [props.startDate, props.endDate, props.reload, props.ready, props.inTransit, props.cancelled, props.recByDestination, props.recBySource, props.warehouseId]);
    React.useEffect(() => {
        getAPIData('/farmerssuppliers', setSuppliers);

    }, []);
    React.useEffect(() => {
        var newData = holdData.map((dt) => {
            var cpyDT = { ...dt };
            var sourc = null;
            var dest = null;
            if (suppliers.farmers) {
                if (cpyDT.source && cpyDT.source.isFarm) {
                    sourc = suppliers.farmers.find((sp) => { return sp.warehouseId === cpyDT.sourceId });

                } else if (cpyDT.source && (cpyDT.source.description==='LIVE PRODUCT')) {
                    sourc = {name:"BATEMANS BAY"};
                }
                if (cpyDT.destination&&cpyDT.destination.isFarm) {
                    dest = suppliers.farmers.find((sp) => { return sp.warehouseId === cpyDT.destinationId });
                } else if (cpyDT.destination && (cpyDT.destination.description === 'LIVE PRODUCT')) {
                    dest = { name: "BATEMANS BAY" };
                }
            }
            cpyDT.quickSummary = buildSummary(cpyDT);
            cpyDT.sourceString = sourc ? sourc.name : (cpyDT.source ? cpyDT.source.description : '');
            cpyDT.destinationString = dest ? dest.name : (cpyDT.destination ? cpyDT.destination.description : ''); 
            return cpyDT;
        })
        setTableData(newData);

    }, [holdData, suppliers]);
    const openNewReceival = () => {
        setOpenRec(true);
    }
    var getData = async (startDate, endDate) => {
        setLoading(true);
        var params = {
                startDate,
                endDate,
        }
        if (props.asSource) {
            params.SourceId = props.warehouseId
        }
        if (props.asDestination) {
            params.DestinationId = props.warehouseId
        }
        const response = await adalApiFetch(fetch, uri + '/Transfers' + urlPath + '?' + new URLSearchParams(params));
        if (response.ok) {
            const data = await response.json();
            var sortData = data.sort((a, b) => {
                return new Date(a.pickUpDate) > new Date(b.pickupDate) ? 1 : -1
            });
            setLoading(false);

            setHoldData(sortData);
        }
    }
    if (props.title) {
        tableTitle = props.title;
    }
    return (

        <React.Fragment >


            <DataTableStandard
                title={<div><Typography variant={"h3"} style={{ display: 'inline' }}>{tableTitle}</Typography></div>}
                data={props.filter ? props.filter(tableData) : tableData}
                columns={columns}
                options={options}
                returnSearch={!loading}
                reload={props.reloadFunc}
            />
        </React.Fragment>
    );
};

export default Transfers;

