import React from 'react';
import Grid from "@mui/material/Grid";
import FormModal from '../../components/FormModal';
import NewBatch from './Forms/NewBatch';
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, tomorrow, yesterday, dateFormat } from "../../utils/dateFunctions";

import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';

import HistoryDisplay from './components/HistoryDisplay';
const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const OnShore = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [farm, setFarm] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const id = (searchParams.get("GroupId"));
    const children = (searchParams.get("Children"));
    const [forceTask, setForceTask] = React.useState(null);

    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }


    const createBatch = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);

    }
    console.log(id)
    return (
        <HistoryDisplay GroupId={id} Children={children} />
    );
};

export default OnShore;
