import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DataTableStandard from "../../../components/DataTableStandard";
import { Button, IconButton, FormControlLabel, Switch } from "@mui/material";
import { dateFormat, textfieldDateFormat } from '../../../utils/dateFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import { CircularProgress } from "@mui/material";
import Typography from '@mui/material/Typography';
import { getAPIDataReturn } from '../../../utils/apiFunction';
import FormModal from '../../../components/FormModal';
import { useJumboTheme } from "@jumbo/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { dateSortLong } from '../../../utils/sortFunctions';

const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

const Agenda = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [tableData, setTableData] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openRec, setOpenRec] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [suppliers, setSuppliers] = React.useState({});
    const [columns, setColumns] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,

        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(tableData) : () => { },
        rowsPerPage:1000,
        ...props.extraOptions
    };
    React.useEffect(() => {
        var cols = [{
            name: 'date',
            label: 'Date Entered',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return tableData[dataIndex]?.date?dateFormat(tableData[dataIndex].date):'';
                },
            }
        },
        {
            name: 'description',
            label: 'Item',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <div style={{ maxHeight: '100px', overflow: 'auto' }}>{tableData[dataIndex]?.description}</div>
                },

            }
        },
        {
            name: 'discussion',
            label: 'Discusssed',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <div style={{ maxHeight: '100px', overflow: 'auto' }}>{tableData[dataIndex]?.discussion}</div>
                },

            }
        },
        {
            name: 'outcome',
            label: 'Outcome',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <div style={{ maxHeight: '100px', overflow: 'auto' }}>{tableData[dataIndex]?.outcome}</div>
                },

            }
        },
        {
            name: 'accountable',
            label: 'Accountable',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return <div style={{ maxHeight: '100px', overflow: 'auto' }}>{tableData[dataIndex]?.accountable}</div>
                },

            }
        },
        {
            name: 'resolutionDate',
            label: props.live ? ' ' : 'Date Completed',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return tableData[dataIndex]?.resolutionDate ? dateFormat(tableData[dataIndex].resolutionDate) : <MCButton id={tableData[dataIndex]?.id} reloadFunc={props.reloadFunc }/>
                },
            }

        },

        ];
        setColumns(cols);
    }, [tableData]);

    const MCButton = (props) => {
        const [loadingBut, setLoadingBut] = React.useState(false);
        const [successBut, setSuccessBut] = React.useState(false);

        const markComplete = (id) => async (e) => {
            e.stopPropagation();
            setLoadingBut(true);
            var response = await adalApiFetch(fetch, uri + '/Reports/FRM/Agenda/Complete?Id=' + id, {
                method: 'post',
            });
            setLoadingBut(false);
            setSuccessBut(true);
            props.reloadFunc();
        }
        return < Button onClick={markComplete(props.id)} variant="contained" style={{ marginBottom: '5px' }}> {loadingBut ? <CircularProgress style={{ color: 'white' }} /> : (successBut?'Completed':"Mark Complete")}</Button>
    }
    React.useEffect(() => {
        setTableData([]);
        getData();
    }, [props.reload, props.warehouseId, props.monthDate]);
    const openNewReceival = () => {
        setOpenRec(true);
    }
    var getData = async (startDate, endDate) => {
        setLoading(true);

        var response = null
        if (props.warehouseId) {
            if (props.live) {
                response = await getAPIDataReturn('/Reports/FRM/Agenda/GetOpen?WarehouseId=' + props.warehouseId);

            } else {
                if (props.monthDate) {
                    response = await getAPIDataReturn('/Reports/FRM/Agenda/GetByMonth?WarehouseId=' + props.warehouseId + '&Date=' + textfieldDateFormat(props.monthDate));
                } else {
                    response = [];
                }
            }
        } else {
            response = [];
        }

        setLoading(false);
        setTableData(response.sort((a, b) => {return new Date(a.date)>new Date(b.date)?-1:1 }));


    }

    return (<DataTableStandard
        title={<div><Typography variant={"h3"} style={{ display: 'inline' }}>Agenda Items</Typography></div>}
        data={props.filter ? props.filter(tableData) : tableData}
        columns={columns}
        options={options}
        returnSearch={!loading}
        reload={props.reloadFunc}
    />
    );
};

export default Agenda;
