import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from './redux/store';
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import { authContext } from '../adalConfig';
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import {getUsersAccess } from './utils/roleFunctions'
import { mainTheme as theme1 } from "./themes/main/theme1";
import { sidebarTheme as theme1Sidebar } from "./themes/sidebar/theme1";
import { headerTheme as theme1Header } from "./themes/header/theme1";
//import { useMsal } from "@azure/msal-react";
import { userInfo } from '../adalConfig';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});



const store = configureStore();



function App() {
    const [user, setUser] = React.useState(userInfo());
    const [access, setAccess] = React.useState(getUsersAccess(user));

    return (
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <BrowserRouter history={history}>
                    <AppProvider>
                         <JumboApp activeLayout={config.activeLayout}>
                            <JumboTheme init={config.theme}>
                                <JumboRTL>
                                    <JumboDialogProvider>
                                        <JumboDialog />
                                        <SnackbarProvider
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            maxSnack={3}>

                                            <AppLayout access={access} >
                                                <Suspense
                                                    fallback={
                                                        <Div
                                                            sx={{
                                                                display: 'flex',
                                                                minWidth: 0,
                                                                alignItems: 'center',
                                                                alignContent: 'center',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <CircularProgress sx={{ m: '-40px auto 0' }} />
                                                        </Div>
                                                    }
                                                >
                                                    <AppRoutes access={access} />
                                                </Suspense>
                                            </AppLayout>

                                        </SnackbarProvider>
                                    </JumboDialogProvider>
                                </JumboRTL>
                            </JumboTheme>
                        </JumboApp>
                    </AppProvider>
                </BrowserRouter >
            </Provider >
        </QueryClientProvider >
    );
}

export default App;



/*
 * 
 * 

*/