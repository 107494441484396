import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { getAPIData, getAPIDataParams, postApi } from '../../../utils/apiFunction';
import { textfieldDateFormat } from '../../../utils/dateFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import ControlledTextField from "../../../components/ControlledTextField";
import SelectTextField from "../../../components/SelectTextField";
import { getMapState, findLocation, cropSupplier, extractGroups } from "../utils/mapFunction";
import { useJumboTheme } from "@jumbo/hooks";
import ChooseBatch from "./ChooseBatch";


const SelectionWindow = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [warehouse, setWarehouse] = React.useState({ warehouseId: props.startFeature?.properties?.warehouseId });
    const [batch, setBatch] = React.useState(props.savedGroup ? props.savedGroup : null);
    const [error, setError] = React.useState(null);
    const [crop, setCrop] = React.useState(props.startFeature?.properties?.CropLabel);
    //const [cropYearCode, setCropYearCode] = React.useState(props.crop);
    const [total, setTotal] = React.useState(null);
    const [item, setItem] = React.useState(null);
    const [notWarehouse, setNotWarehouse] = React.useState(false);
    const [notEnoughUnits, setNotEnoughUnits] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [groups, setGroups] = React.useState(null);
    const [discussed, setDiscussed] = React.useState(null);
    const [outcome, setOutcome] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [accountable, setAccountable] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);

    var pickup = props.startFeature?.properties?.Crop !== 'Empty';

    React.useEffect(() => { //loading in saved units
        if (!props.endFeature) {
            if (props.savedUnits) {
                if (!pickup) {
                    checkWithRow(Number(props.savedUnits))
                }
            } else {
                setTotal(0);//reset count on change line if no saved units
            }
        }
    }, [props.savedUnits, props.startFeature, props.endFeature]);
    React.useEffect(() => { //Controlled by clicking on squares
        if (props.startFeature && props.endFeature) {
            setTotal(Math.abs(props.startFeature?.properties?.rowPos - props.endFeature?.properties?.rowPos) + 1);
            setGroups(extractGroups(props.map, props.startFeature?.properties?.rowPos, props.endFeature?.properties?.rowPos, props.endFeature?.properties?.LineId));
        }
    }, [props.startFeature, props.endFeature]);
    React.useEffect(() => {
        if (!pickup) {
            if (batch) {
                if (total) {
                    if (batch.totalUnits < total) {
                        checkWithRow(batch.totalUnits);

                    }
                } else {
                    if (!props.savedUnits) {
                        checkWithRow(batch.totalUnits);
                    }

                }
            }
        }
    }, [batch]);
    React.useEffect(() => {
        if (!pickup) {
            if (batch && props.startFeature) {
                if (batch.warehouseId === props.startFeature?.properties?.warehouseId) {
                    setNotWarehouse(false);
                } else {
                    setNotWarehouse(true);
                }
                if (total > batch.totalUnits) {
                    setNotEnoughUnits(true);
                } else {
                    setNotEnoughUnits(false);
                }
            }
        }
    }, [batch, props.startFeature, props.endFeature]);
    React.useEffect(() => {
        if (!pickup) {
            if (batch) {
                if (total > batch.totalUnits) {
                    setNotEnoughUnits(true);
                } else {
                    setNotEnoughUnits(false);
                }
            }
        }
    }, [batch, total]);
    const checkWithRow = (total) => { // control selected screen display by updating total
        if (!props.startFeature?.properties?.overflow) {
            props.moveBagsForTotal(total);
        }
        setTotal(total);
    }
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (total <= 0) {
            errorMessages.push('Total must be more that 0')
        }
        if (!batch && !pickup) {
            errorMessages.push('You must select a group to deploy');
        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const adjustPos = (pos) => {
        return Number(pos) + 1;
    }
    const findStartandEndOfBatch = (row, rowPos) => {
        var currSumPos = 0;
        for (var i = 0; i < row.length; i++) {
            currSumPos += (row[i].len);
            if ((currSumPos - 1) >= rowPos) {
                return [currSumPos - row[i].len, currSumPos - 1];
            }
        }
        return [currSumPos - row[i - 1].len, currSumPos - 1];
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        if (pickup) {
            var prmArr = [];
            for (var i = 0; i < groups.length; i++) {
                var startPos = adjustPos(groups[i].startPos);
                var endPos = adjustPos(groups[i].endPos);
                if (adjustPos(groups[i].startPos) > adjustPos(groups[i].endPos)) {
                    startPos = adjustPos(groups[i].endPos);
                    endPos = adjustPos(groups[i].startPos);
                }
                prmArr.push(postApi('/Farms/Groups/Pickup', { GroupId: groups[i]?.row?.props.GroupId, StartPos: startPos, EndPos: endPos, EntireBatch: groups[i].wholeGroup, Date: textfieldDateFormat(date) }));
            }
            var resArr = await Promise.all(prmArr);
            var er = false;
            for (var i = 0; i < resArr.length; i++) {
                if (!resArr[i].ok) {
                    er = true;
                }
            }
            if (!er) {
                setCompleted(true);
            } else {
                setError("Error Text");
            }
        } else {
            var startPos = adjustPos(props.startFeature?.properties?.rowPos);
            var endPos = adjustPos(props.endFeature?.properties?.rowPos);
            //If the end position is not a number, it means that the user has selected the only one bag in the row and the row is overflowing
            if (isNaN(endPos) && props.bagOverflow) {
                var states = getMapState(props.map, 'selected', ('row' + props.startFeature?.properties.LineId));
                var [start, end] = findStartandEndOfBatch(states.row, props.startFeature?.properties?.rowPos);

                startPos = adjustPos(start);
            } else {
                if (adjustPos(props.startFeature?.properties?.rowPos) > adjustPos(props.endFeature?.properties?.rowPos)) {
                    startPos = adjustPos(props.endFeature?.properties?.rowPos);
                    endPos = adjustPos(props.startFeature?.properties?.rowPos);
                }
            }
            var res = await postApi('/Farms/Groups/Deploy', { GroupId: batch?.id, LineId: props.startFeature?.properties?.LineId, StartPos: startPos, TotalUnits: total, Date: textfieldDateFormat(date) });
            if (res.ok) {
                setCompleted(true);
            } else {
                setError("Error Text");
            }
        }
        if (props.stopDeploying) {
            props.stopDeploying();
        }
        if (props.reloadLeaseLines) {
            await props.reloadLeaseLines(props.startFeature?.properties?.warehouseId, props.startFeature?.properties?.LeaseId);
        }
        setLoading(false);

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
                background: theme.palette.background.paper,
                zIndex: '1000',
                position: 'absolute',
                width: '270px',
                paddingTop: '15px'
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                {/*<Typography variant={"h3"} style={{ textAlign: 'center' }}>Pick up</Typography>*/}
                <Grid container spacing={0} mb={1}>

                    <Grid item xs={12} style={{ paddingLeft: '20px' }}>

                        <DatePicker date={date} setDate={setDate} label="Date" disabled={completed} />
                    </Grid>
                    {pickup ?
                        <Grid item xs={12}>
                            <Typography variant={"h5"} style={{ textAlign: 'center' }}>{crop}</Typography>
                            {/*{groups ? groups.map((grp) => {*/}
                            {/*    console.log(grp)*/}
                            {/*    return <Typography variant={"h5"} style={{ textAlign: 'center' }}>{grp?.row?.props.GroupId}</Typography>*/}
                            {/*}):null}*/}
                            <Grid container spacing={0} mb={2}>
                                {groups ? groups.length == 1 ?
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography variant={"h5"} style={{ textAlign: 'center' }}>{groups[0].row?.props?.Grade}</Typography>
                                    </Grid> :
                                    groups.map((grp) => {
                                        return <React.Fragment >
                                            <Grid item xs={12} sm={10} style={{ paddingLeft: '15px' }}>
                                                <Typography variant={"h5"} >{grp.row?.props?.Grade}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} >
                                                <Typography variant={"h5"} >{grp.endPos - grp.startPos + 1}</Typography>
                                            </Grid>
                                        </React.Fragment>
                                    })
                                    : null}
                                < Grid item xs={12} style={{ textAlign: 'center' }}>
                                    < Typography variant={"h5"} >{groups ? groups[0]?.row?.props?.UnitType : ''}</Typography>
                                </Grid>
                            </Grid>
                        </Grid> : null}
                    {!pickup ? <Grid item xs={12} style={{ textAlign: 'center' }} >
                        <ChooseBatch state={batch} setState={setBatch} disabled={completed} access={props.access} warehouseId={props.startFeature?.properties?.warehouseId} />
                    </Grid> : null}
                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                        <ControlledTextField noDecimal title="Total Units" state={total} setState={checkWithRow} disabled={completed} number />
                    </Grid>
                    <Grid item xs={12}>

                        <Typography variant={"h6"} style={{ textAlign: 'center' }}>{"Units: " + (props.startFeature?.properties?.rowPos + 1) + " -> " + (props.endFeature ? (props.endFeature?.properties?.rowPos + 1) : '')}</Typography>

                    </Grid>
                    {props.bagOverflow ? <Grid item xs={12}>
                        <Typography variant={"h6"} style={{ textAlign: 'center' }}>Will create space for extra bags</Typography>
                    </Grid> : ''}
                    <Grid item xs={12} style={{ textAlign: 'center' }} >
                        {(props.correctValue && !notWarehouse && !notEnoughUnits) ?
                            <SubmitButtonDisplayer title={pickup ? 'Pick Up' : 'Deploy'} completed={completed} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} /> :
                            (notWarehouse ? 'Can\'t deploy unit from different warehouse' : (notEnoughUnits ? 'Not enough units in the group' : 'There isn\'t enough units on the lease'))}
                    </Grid>
                </Grid>

            </Div>
        </Box >
    );
};




export default SelectionWindow;
