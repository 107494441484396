import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import OysterOrder from "../../../components/OysterOrder";
import { textfieldDateFormat, startOfDay, tomorrow } from "../../../utils/dateFunctions";



const AddPurchaseOrder = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [shipVia, setShipVia] = React.useState(null);
    const [description, setDescription] = React.useState(null);
    const [status, setStatus] = React.useState(null);
    const [orderType, setOrderType] = React.useState(null);
    const [tempOrderType, setTempOrderType] = React.useState(null);
    const [customer, setCustomer] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [shipmentDate, setShipmentDate] = React.useState(null);
    const [oysters, setOysters] = React.useState([{}]);
    const [tempOysters, setTempOysters] = React.useState(null);
    const [nonStock, setNonStock] = React.useState([]);
    const [tempNonStock, setTempNonStock] = React.useState(null);
    const [orderDate, setOrderDate] = React.useState(new Date());
    const [frequency, setFrequency] = React.useState(1);
    const [seriesStart, setSeriesStart] = React.useState((startOfDay(new Date())));
    const [seriesEnd, setSeriesEnd] = React.useState(null);
    const [weekDays, setWeekDays] = React.useState([false, false, false, false, false, false, false]);
    const id = props.id ? props.id : '';
    const [supplier, setSupplier] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    const [returnSeries, setReturnSeries] = React.useState(null);
    React.useEffect(() => {
        const checkArr = (data) => {
            var retData = data;
            if (Array.isArray(data)) {
                retData = data[0];
            }
            setReturnData(retData);
        }
            getAPIData('/SalesOrder/Get/' + id, checkArr);
    }, [])
    const numToBool = (numArr) => {
        var boolArr = []
        for (var i = 0; i < numArr.length; i++) {
            if (numArr[i]) {
                boolArr.push(i);
            }
        }
        return boolArr;
    }
 
    React.useEffect(() => {
        console.log(returnData);

        if (returnData) {
            setCustomer(returnData.customer);
            var oysterArr = [];
            var nonStockArr = [];
            if (returnData.salesOrderLines) {
                for (var i = 0; i < returnData.salesOrderLines.length; i++) {
                    console.log(returnData.salesOrderLines[i].stockItemId)
                    if (returnData.salesOrderLines[i].stockItemId) {
                        oysterArr.push(returnData.salesOrderLines[i]);
                    } else {
                        nonStockArr.push(returnData.salesOrderLines[i])
                    }
                }
            }
            setOysters([...oysterArr]);
            setTempOysters([...oysterArr]);

            setNonStock([...nonStockArr]);
            setTempNonStock([...convertToStockObj(nonStockArr)]);

        }
    }, [returnData])

    const validate = () => {
        console.log(nonStock)
        var warningMessages = [];
        var errorMessages = [];

         

        for (var i = 0; i < oysters.length; i++) {
            if (!oysters[i].quantity) {
                errorMessages.push("You must enter a Quantity for every Oyster Line");

            }
            if (!oysters[i].price) {
                errorMessages.push("You must enter a Price for every Oyster Line");

            }
            if (!oysters[i].warehouseId) {
                errorMessages.push("You must select a Warehouse for every Oyster Line");

            }
            if (!oysters[i].stockItem) {
                errorMessages.push("You must select a Stock Item for every Oyster Line");

            }
            if (oysters[i].priceVary && !oysters[i].variationReason) {
                errorMessages.push("You must enter a reason that the price varies from this customers default price");

            }
        }
        for (var i = 0; i < nonStock.length; i++) {
            if (!nonStock[i].quantity) {
                errorMessages.push("You must enter a Quantity for every Non Stock Item Line");

            }
            if (!nonStock[i].price) {
                errorMessages.push("You must enter a Price for every Non Stock Item Line");

            }
            if (!nonStock[i].warehouseId) {
                errorMessages.push("You must select a Warehouse for every Non Stock Item Line");

            }
            if (!nonStock[i].stockItem) {
                errorMessages.push("You must select a Stock Item for every Non Stock Item Line");

            }
        }
       
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    
    const submit = async () => {
        setLoading(true);
        setCompleted(true);

       
        var promArr = [];
        
        //Oyster stock Items
        var reducedOysters = [];
        for (var i = 0; i < oysters.length; i++) {
            var oyLine = reducedOysters.findIndex((oy) => {
                if (oy.stockItem && oysters[i].stockItem) {
                    return ((oy.stockItem.id === oysters[i].stockItem.id) && (oy.price === oysters[i].price) && (oy.warehouseId === oysters[i].warehouseId));
                }
                return false;
            });
            if (oyLine >= 0) {
                reducedOysters[oyLine].quantity = Number(reducedOysters[oyLine].quantity ? reducedOysters[oyLine].quantity : 0) + Number(oysters[i].quantity ? oysters[i].quantity : 0);
            } else {
                reducedOysters.push({ ...oysters[i] });
            }
        }
        promArr = reducedOysters.map((oy) => {
            var oyData = {
                StockItemId: oy.stockItem ? oy.stockItem.id : 0,
                Price: oy.price ? oy.price : 0,
                WarehouseId: oy.warehouseId ? oy.warehouseId : 0,
                VariationReason: oy.variationReason ? oy.variationReason : null,
                Quantity: oy.quantity ? oy.quantity : 0,
                changePrice: oy.changePrice ? oy.changePrice : false,
            }
            if (oy.id) {
                return adalApiFetch(fetch, uri + '/SalesOrder/Line/Update/' + oy.id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            } else {
                return adalApiFetch(fetch, uri + '/SalesOrder/Line/Insert/' + id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            }
        })
        if (tempOysters) {
            if (tempOysters && (tempOysters.length > 0)) {
                tempOysters.map((rl) => {
                    var exID = reducedOysters.find((oy) => { return oy.id && (oy.id === rl.id) });
                    if (!exID) {
                        return adalApiFetch(fetch, uri + '/SalesOrder/Line/Delete/' + rl.id, {
                            method: 'delete',
                        });
                    }
                })
            }
        }
        ///////////////////////////
        //Non stock Items
        var reducedItems = [];
        for (var i = 0; i < nonStock.length; i++) {
            var oyLine = reducedItems.findIndex((oy) => {
                if (oy.stockItem && nonStock[i].stockItem) {
                    return ((oy.stockItem.id === nonStock[i].stockItem.id) && (oy.price === nonStock[i].price) && (oy.warehouseId === nonStock[i].warehouseId));
                }
                return false;
            });
            if (oyLine >= 0) {
                reducedItems[oyLine].quantity = Number(reducedItems[oyLine].quantity ? reducedItems[oyLine].quantity : 0) + Number(nonStock[i].quantity ? nonStock[i].quantity : 0);
            } else {
                reducedItems.push({ ...nonStock[i] });
            }
        }
        promArr = reducedItems.map((oy) => {
            var oyData = {
                NonStockItemId: oy.stockItem ? oy.stockItem.id : 0,
                Price: oy.price ? oy.price : 0,
                WarehouseId: oy.warehouseId ? oy.warehouseId : 0,
                VariationReason: oy.variationReason ? oy.variationReason : null,
                Quantity: oy.quantity ? oy.quantity : 0,
                changePrice: oy.changePrice ? oy.changePrice : false,

            }
            if (oy.id) {
                return adalApiFetch(fetch, uri + '/SalesOrder/Line/Update/' + oy.id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            } else {
                return adalApiFetch(fetch, uri + '/SalesOrder/Line/Insert/' + id + '?' + new URLSearchParams({
                    ...oyData
                }), {
                    method: 'post',
                });
            }
        })
        if (tempNonStock) {
            if (tempNonStock && (tempNonStock.length > 0)) {
                tempNonStock.map((rl) => {
                    var exID = reducedItems.find((oy) => { return oy.id && (oy.id === rl.id) });
                    if (!exID) {
                        return adalApiFetch(fetch, uri + '/SalesOrder/Line/Delete/' + rl.id, {
                            method: 'delete',
                        });
                    }
                })
            }
        }
        ////////////////////////////////////////////////////////////////////////

        await Promise.all(promArr);


        setLoading(false);
        if (props.reloadFunc) {
            props.reloadFunc();
        }

    }
    
    console.log(tempOysters,oysters)
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                 <Grid item xs={12} >
                        {/*need to initialies the oyster groups as empty when inputing the passedGroup*/}
                    <OysterOrder customerId={customer?.id} processed oysters={oysters} starter={false} setOysters={setOysters} disabled={completed}  />
                    <OysterOrder nonStock customerId={customer?.id} processed oysters={nonStock} starter={false} setOysters={setNonStock} disabled={completed}  />
                </Grid>

                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

            </Div>
        </Box >
    );
};

const convertToStockObj = (list) => {
    console.log('k')
    return list.map((l) => {
        var k = { ...l };
        k.stockItem = l.nonStockItem;
        k.stockItemId = l.nonStockItemId;
        return k;
    })
}



export default AddPurchaseOrder;
