import { TableCell,TableRow } from "@mui/material";
import React from 'react';
import { useJumboTheme } from "@jumbo/hooks";

export const headerRender = (spec) => (obj) => {
    const { theme } = useJumboTheme();
    var bgCol = theme.palette.oyster.AN;
    var title = false;
    var space = <div style={{ visibility: 'hidden'}} >space</div>;
    var header = '';
    if (spec === 'Sydney Rock') {
        bgCol = theme.palette.oyster.SR;
        if (obj.label === "Bistro") {
            header = <div style={{
                whiteSpace: 'nowrap',
                textDecoration: 'underline',
                position: 'absolute',
                top: '10px',
                left: '0px',
                zIndex: '1000'
            } }>Sydney Rock</div>;
            title = true;
        }

    } else if ((spec === 'Pacific')) {
        bgCol = theme.palette.oyster.PC;
        if (obj.label === "Large") {
            header = <div style={{
                whiteSpace: 'nowrap', textDecoration: 'underline',
                position: 'absolute',
                top: '10px',
                left: '12px',
                zIndex: '1000' }}>Pacific</div>;
            title = true;
        }

    } else if ((spec === 'Angasi')) {
        header = <div style={{
            whiteSpace: 'nowrap', textDecoration: 'underline', position: 'absolute',
            top: '10px',
            left: '12px',
            zIndex: '1000' }}>Angasi</div>
            title = true;
    }
    var style = {
        position: '-webkit - sticky',
        position: 'sticky',
        top: '0px',
        color: theme.palette.oyster.contrastText,
        zIndex: title?'1000':'100',
        backgroundColor: bgCol,
    }
    return <TableCell style={style}>{space}{header}<div >{obj.label}</div></TableCell>
}

export const bodyRender = (spec) => (obj) => {
    console.log(obj)
    var bgCol = '#B1AFFF';
    if (spec === 'Sydney Rock') {
        bgCol = '#C8FFD4';

    } else if ((spec === 'Pacific')) {
        bgCol = '#B8E8FC';

    }
    var style = {
        position: '-webkit - sticky',
        position: 'sticky',
        top: '0px',
        zIndex: '100',
        backgroundColor: bgCol
    }
    return <TableCell style={style}>{obj.label}</TableCell>
}


//function to choose whether text should be white or black based on color background
export const textColorContrast = (bgColor, lightColor, darkColor) => {
    if (bgColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        return (L > 0.179) ? darkColor : lightColor;
    }
    return '#000';
}