import React from 'react';

import { adalApiFetch } from '../../../adalConfig';
import { getAPIData } from "../../utils/apiFunction"
import EditableTable from '../../components/EditableTable';

const SettingsQA = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [shells, setShells] = React.useState([]);
    const [shellList, setShellList] = React.useState([]);
    const [columns, setColumns] = React.useState([{ label: "ID", key: 'id', list: [] },
    { label: "Rating", key: 'rating', list: [], controller: true },
    { label: "Description", key: 'description', list: [] },
    { label: "Action", key: 'action', list: [] },
    { label: "Colour", key: 'colour', list: [] }]);
    React.useEffect(() => {
        getAPIData('/QAMaintenance/GetShellRatings', setShells);
    }, []);
    React.useEffect(() => {
        var cpyCols = [...columns];
        var ratAr = [];
        var shellAr = [];
        if (shells) {
            for (var i = 0; i < shells.length; i++) {
                ratAr.push({ ...shells[i], matchID: shells[i].id});
                for (var j = 0; j < shells[i].qaShellReasons.length; j++) {
                    shellAr.push({ ...shells[i].qaShellReasons[j], matchID: shells[i].qaShellReasons[j].shellRatingId, rating: {} });
                }
            }
            for (var i = 0; i < cpyCols.length; i++) {
                cpyCols[i].list = ratAr;
            }
        }
        setShellList(shellAr);
        setColumns(cpyCols);
    }, [shells]);
    var types = {
        rating: "select",
        description: "text",
        name: "text",
        action: "disabled",
        colour: "disabledColor",
    }

    const addNewShell = async (item) => {
        console.log(item)
        var data = {
            RatingId: item.rating?.id,
            Description: item.description,
        }
        var response = await adalApiFetch(fetch, uri + '/QAMaintenance/AddShellRating?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
        });
        return response;
    }

    const updateChanges =async (item) => {
        console.log(item)
        var data = {
            Id: item.id,
            RatingId: item.rating && item.rating.id? item.rating.id: item.shellRatingId,
            Description: item.description,
        }
        var response = await adalApiFetch(fetch, uri + '/QAMaintenance/UpdateShellRating?' + new URLSearchParams({
            ...data
        }), {
            method: 'post',
        });
        return response;

    }
    return <EditableTable title={"Shell Ratings"} data={shellList} setData={setShellList} columns={columns} type={types} addNew={addNewShell} updateChanges={updateChanges} />
};



export default SettingsQA;
