import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const TooltipDescription = (props) => {
    const StyleTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 350,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));
    return <StyleTooltip title={props.title} >
        <IconButton aria-label="Tooltip">
            <InfoSharpIcon sx={{ fontSize: 15 }} />
        </IconButton>
    </StyleTooltip>
}

export default TooltipDescription;
