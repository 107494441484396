import React from 'react';
import { getAOCWarehouses } from '../../components/SelectAOCFarmWarehouse';
import { WAREHOUSE_ACCESS } from '../../utils/constants/warehouse';
import { CircularProgress } from '@mui/material';



const SharepointViewer = (props) => {
    const [loading, setLoading] = React.useState(true);
    const [farmList, setFarmList] = React.useState(null);
    React.useEffect(() => {
        const getW = async () => {
            setLoading(true);
            await getAOCWarehouses(props.access, false, setFarmList);
            setLoading(false);
        }
        getW();
    }, []);
    React.useEffect(() => {
        if (farmList) {
            console.log(WAREHOUSE_ACCESS, farmList)
            var url = 'https://ausoyster.sharepoint.com/sites/DocumentCentre'
            //var win = window.open(, '_blank');
            if (farmList.length === 1) {
                url = 'https://ausoyster.sharepoint.com/sites/' + WAREHOUSE_ACCESS[farmList[0].warehouse]
            }
            var win = window.open(url, '_blank');
            win.focus();
        }
    }, [farmList]);

    if (loading) {
        return <CircularProgress />
    } else {
        return <div></div>
    }
};


export default SharepointViewer;


