import React from 'react';
import Grid from "@mui/material/Grid";
import InventoryHistory from './Tables/InventoryHistory';
import { CircularProgress, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

const InventoryDash = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [reload, setReload] = React.useState(true);
    const reloadFunc = () => {
        setReload(!reload);
    }
   
    return (
        <React.Fragment>
            <Grid container spacing={3.5}>

                <Grid item xs={12}>
                    <InventoryHistory reload={reload}   access={props.access} /> 
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default InventoryDash;
