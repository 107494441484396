import React from 'react';
import QAform from "../pages/Processing/Forms/QAform";
import FormModal from "./FormModal";
import VerifiedIcon from '@mui/icons-material/Verified';
import ConfirmReceivalForm from "../pages/Processing/Forms/ConfirmReceivalForm";
import IconButton from "@mui/material/IconButton";
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { WAREHOUSEGROUPS } from "../utils/constants/warehouse";

export const ViewQAButton = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const openFunc = (e) => {
        e.stopPropagation();
        setOpen(true);
    }
    const stopProp = (e) => {
        e.stopPropagation();
    }
    return <React.Fragment>
        <FormModal onClick={stopProp} open={open} handleClose={handleClose} title={"Quality Assurance Recieval Form"} >
            <QAform data={{ id: props.id, receivalId: props.recId }} handleClose={handleClose} completed={true} edit={false} access={props.access} />
        </FormModal>
        <IconButton aria-label="cci" onClick={openFunc}>
            <VerifiedIcon sx={{ fontSize: 10 }} />
        </IconButton>
    </React.Fragment>
}

export const ViewReceivalButton = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const openFunc = () => {
        setOpen(true);
    }
    return <React.Fragment>
        <FormModal open={open} handleClose={handleClose} title={"Confirm Recieval Form"} >
            <ConfirmReceivalForm data={{ id: props.id }} access={props.access} handleClose={handleClose} reload={() => { props.setReload(!props.reload) }} completed={!props.edit} edit={props.edit} />
        </FormModal>
        <IconButton aria-label="cci" onClick={openFunc}>
            <MoveToInboxIcon sx={{ fontSize: 10 }} />
        </IconButton>
    </React.Fragment>
}
