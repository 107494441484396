import React from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from "@mui/material/ToggleButton";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
const DayToggle = (props) => {

    const [selection, setSelection] = React.useState(props.daySelection);
    const [mOn, setMOn] = React.useState(false);
    const [mDown, setMDown] = React.useState(null);

    const mouseUp = (sel) => {
        var selArr = [];
        if (sel === mDown) {
            selArr = [sel];
        } else {
            var a = 0;
            var b = 0;
            if (mDown < sel) {
                a = mDown;
                b = sel;
            } else {
                a = sel;
                b = mDown;
            }
            for (var i = a; i <= b; i++) {
                selArr.push(i);
            }
        }

        setMOn(false);
        setSelection(selArr);
        props.setDaySelection(selArr);
    }
    const mouseDown = (sel) => {
        setMDown(sel);
        setMOn(true);
        setSelection([sel])
    }
    const mouseO = (sel) => {
        if (mOn) {
            var selArr = [];
            if (sel === mDown) {
                selArr = [sel];
            } else {
                var a = 0;
                var b = 0;
                if (mDown < sel) {
                    a = mDown;
                    b = sel;
                } else {
                    a = sel;
                    b = mDown;
                }
                for (var i = a; i <= b; i++) {
                    selArr.push(i);
                }
            }
            setSelection(selArr);
        }
    }
    return <React.Fragment>
        <ToggleButtonGroup
            color="primary"
            disabled={props.disabled}
            value={selection}
            aria-label="text alignment"
        >
            {props.days?.map((dy, ind) => {

                return <ToggleButton onPointerUp={(event) => { mouseUp(ind) }} onPointerOver={(event) => { mouseO(ind) }} onPointerDown={(event) => { mouseDown(ind) }} key={ind} value={ind} >
                    <div  >{dy}</div>
                </ToggleButton>
            })}
        </ToggleButtonGroup><IconButton aria-label="remove" style={{ marginTop: '20px' }} onClick={props.nextWeek} disabled={props.disabled}>
            <KeyboardDoubleArrowRightIcon />
        </IconButton></React.Fragment>
}
export default DayToggle;