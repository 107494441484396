import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Div from "@jumbo/shared/Div";
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import menus from "../../../layouts/shared/sidebars/Sidebar/menus";
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ControlledTextField from "../../../components/ControlledTextField";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import { textfieldDateFormat } from "../../../utils/dateFunctions";
import StringSelect from "../../../components/StringSelect";
import { userInfo } from '../../../../adalConfig';


const AddUserRequest = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    console.log(props.data)
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = React.useState(props.data ? props.data.user : userInfo());
    const [description, setDescription] = React.useState(props.data ? props.data.description : null);
    const [status, setStatus] = React.useState(null);
    const [sections, setSections] = React.useState([{ label: "Other" }, ...menus]);
    const [section, setSection] = React.useState(null);
    const [title, setTitle] = React.useState(props.data ? props.data.title : null);
    const [date, setDate] = React.useState(null);
    const [priority, setPriority] = React.useState(null);
    const id = props.data ? props.data.id : '';
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    
   
    //    React.useEffect(() => {
    //    const checkArr = (data) => {
    //        var retData = data;
    //        if (Array.isArray(data)) {
    //            retData = data[0];
    //        }
    //        setReturnData(retData);
    //    }
    //    if (props.edit || props.completed) {
    //        //getAPIData('/SalesOrder/Get/' + id, checkArr);
    //    }
    //}, [])

    //React.useEffect(() => {
    //    console.log(returnData);

    //    if (returnData) {
    //        //setStatus(returnData.status);
    //        //setOrderDate(new Date(returnData.orderDate));
    //        //setShipmentDate(new Date(returnData.shipmentDate));
    //        //var orderTypeTempVal = salesOrderTypes.find((sot) => { return sot.value === returnData.orderType; });
    //        //setOrderType({ ...orderTypeTempVal });
    //        //setTempOrderType({ ...orderTypeTempVal });
    //        //setCustomer(returnData.customer);
    //        //setDescription(returnData.description);


    //    }
    //}, [returnData])

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];

        if (title === null || title === '') {
            errorMessages.push('Title is required');
        }
        if (!section) {
            errorMessages.push('Section is required');
        }
        if (description === null || description === '') {
            errorMessages.push('Description is required');
        }
        if (!priority) {
            errorMessages.push('Priority is required');
        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var data = {
            User: user.userName,
            Section: section.label,
            Title: title,
            Description: description,
            Priority: priority?.gid
        }
        console.log(data, id);
        if (props.edit) {
            var response = await adalApiFetch(fetch, uri + '/Asana/Update/' + id + '?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        } else {

            var response = await adalApiFetch(fetch, uri + '/Asana/Insert/?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        }
        var resp = await (response.json());

        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                {/*<Typography variant={"h3"}>Confirm OTR Information</Typography>*/}
                <Grid container spacing={0} mb={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            disabled
                            id="outlined-required"
                            label="User"
                            value={props.data?props.data.user :(user ? user.profile ? (user.profile.given_name + ' ' + user.profile.family_name) : user.userName : null)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ControlledTextField title="Title" state={title} setState={setTitle} disabled={completed} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StringSelect title={"Section"} input={props.data ? sections.find((a) => (a.label === props.data.section)) : null} state={section} setState={setSection} options={sections} disabled={completed} default={null} labelFunc={(a) => a.label} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <StringSelect title={"Priority"} input={props.data ? sections.find((a) => (a.label === props.data.priority)) : null} state={priority} setState={setPriority} options={[{ label: "Low", gid: "1202992713351317" }, { label: "Medium", gid: "1202992713351316" }, { label: "High", gid:"1202992713351315" }]} disabled={completed} default={null} labelFunc={(a) => a.label} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Description"
                            value={description}
                            onChange={(event) => { setDescription(event.target.value) }}
                            multiline
                            style={{ width: '70%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rows={4} />
                    </Grid>

                </Grid>

                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

            </Div>
        </Box >
    );
};








export default AddUserRequest;
