import React from 'react';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import { textfieldDateFormat, startOfWeek, shiftWeek } from '../utils/dateFunctions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import POSelectForm from '../pages/Processing/Forms/POSelectForm';
import FormModal from './FormModal';
import Button from "@mui/material/Button";

const EstuaryStatusSelect = (props) => {
    const [ests, setEsts] = React.useState([]);
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');
    const filterVals = (list) => {
        setEsts(list.sort((a, b) => {
            return a.description > b.description ? 1 : -1;
        }))
    }
    React.useEffect(() => {

        if (props.estId) {
            var est = ests.find((dr) => { return (dr.id === props.estId) });
            props.setState(est);
            setValue(est);
        }
    }, [ests, props.estId])

    React.useEffect(() => {
        const matchEstf = (est) => {
            var estNameList = ests.filter((pc) => {
                if (est) {
                    if (pc.description.includes(est)) {
                        return true;
                    }
                }
                return false;
            });
            if (estNameList.length > 0) {
                console.log(estNameList[0])
                return estNameList[0];
            } else {
                return null;
            }


        }
        if (!props.estId) {
            if (Array.isArray(props.estString)) {
            } else {
                var matchEst = matchEstf(props.estString);
                if (matchEst) {
                    setValue(matchEst);
                    props.setState(matchEst);
                }
            }
        }

    }, [ests, props.estString])

    React.useEffect(() => {
        getAPIData('/Estuaries/Get', filterVals);
    }, [])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.description}
        disablePortal
        id="estuarybox"
        options={ests}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Estuary" />}
    />);



}

export default EstuaryStatusSelect;