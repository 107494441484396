import React from 'react';
import { useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import CardContent from "@mui/material/CardContent";
import Receivals from '../Processing/Tables/Receivals';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import FormModal from '../../components/FormModal';
const LandingPage = (props) => {
    const [open, setOpen] = React.useState(true);
    const [number, setNumber] = React.useState('');
    const [PRN, setPRN] = React.useState(null);
    const handleClose = () => setOpen(false);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));


    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setPRN(array[rowState.dataIndex]);
    }
    const typeFunc = (num) => () => {
        setNumber(number + String(num));
    }
    const clear = () => {
        setNumber('');
    }
    return (
        <div>
            <Typography variant={"h1"} mb={3}>Record PRNs Entering Room</Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>

                            <TextField
                                disabled
                                id="outlined-required"
                                label="PRN"
                                value={PRN?PRN.prn:''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={3.5}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        disabled
                                        id="outlined-required"
                                        label="Total Bags"
                                        value={number}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <JumboCardQuick bgColor={'#6f42c1'} onClick={clear}>
                                        <Div sx={{ display: "flex", alignItems: 'center' }}>
                                            <Div sx={{ ml: 2, flex: 1 }}>
                                                <Typography color={'common.white'} variant={"h2"} mb={.5}>Clear</Typography>
                                            </Div>
                                        </Div>
                                    </JumboCardQuick>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Receivals access={props.access} onClick={selectPO} startDate={startDate} endDate={endDate} notConfirmed />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={3.5}>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={9} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={8} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={7} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={6} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={5} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={4} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={3} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={2} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={1} />
                                </Grid>
                                <Grid item xs={4}>
                                    <ButtonNum type={typeFunc} num={0} />
                                </Grid>
                                <Grid item xs={8}>
                                    <JumboCardQuick bgColor={'#E44A77'} onClick={clear}>
                                        <Div sx={{ display: "flex", alignItems: 'center' }}>
                                            <Div sx={{ ml: 2, flex: 1 }}>
                                                <Typography color={'common.white'} variant={"h2"} mb={.5}>Enter</Typography>
                                            </Div>
                                        </Div>
                                    </JumboCardQuick>
                                </Grid>


                            </Grid>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </div >
    );
};
const ButtonNum = (props) => {


    return (<JumboCardQuick bgColor={'#23BCBA'} onClick={props.type(props.num)}>
        <Div sx={{ display: "flex", alignItems: 'center' }}>
            <Div sx={{ ml: 2, flex: 1 }}>
                <Typography color={'common.white'} variant={"h2"} mb={.5}>{props.num}</Typography>
            </Div>
        </Div>
    </JumboCardQuick>);
}
export default LandingPage;