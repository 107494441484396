import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SelectTextField from '../../components/SelectTextField';
import { getAPIData, getAPIDataParams } from '../../utils/apiFunction';
import { DateTime } from 'luxon'
import Agenda from './Tables/Agenda';
import AddAgendaItem from './Forms/AddAgendaItem';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import SelectAOCFarmWarehouse from '../../components/SelectAOCFarmWarehouse';
import FormModal from '../../components/FormModal';
import { supplierToMatch } from '../../utils/constants/warehouse'

const AgendaPage = (props) => {
    const [startDate, setStartDate] = React.useState(DateTime.now());
    const [jsStartDate, setJSStartDate] = React.useState(DateTime.now());
    const [farm, setFarm] = React.useState(null);
    const [aocFarms, setAOCFarms] = React.useState([]);
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [selData, setSelData] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [procurement, setProcurement] = React.useState(null);
    var FarmKey = supplierToMatch(farm?.name)?.access;
    const requiredEditAccess = { key: FarmKey, value: ACCESS.EDIT };
    const reloadFunc = () => {
        setReload(!reload);
    }

   

    const handleClose = () => {
        setOpen(false);
        setEdit(false);
        setSelData(null);
    }
    const selectItem = (allData) => (line,sel) => {
        setOpen(true);
        setEdit(true);
        setSelData(allData[sel.dataIndex]);
    }
    const createA = () => {
        setOpen(true);
        setSelData(null);
    }
    
    React.useEffect(() => {
        setJSStartDate(startDate.toJSDate());
    }, [startDate]);
    
    

    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={6}>
                    <SelectAOCFarmWarehouse setState={setFarm} state={farm} access={props.access} />
                </Grid>
                    <Grid item xs={12}>
                    {checkAccess(props.access, requiredEditAccess) ? <Button onClick={createA} variant="contained" style={{ marginBottom: '5px' }}>Create Agenda Item</Button> : null}
                    </Grid >
                    <Grid item xs={12}>

                        <FormModal open={open} handleClose={handleClose} title={edit ? 'Edit Agenda Item' : (completed ? "View Agenda Item" : "Create Agenda Item")} noWidth>
                        <AddAgendaItem data={selData} warehouseId={farm?.warehouseId} access={props.access} reload={reloadFunc} handleClose={handleClose} edit={edit} completed={completed}  />
                        </FormModal>

                    <Agenda warehouseId={farm?.warehouseId} access={props.access} monthDate={jsStartDate} live reload={reload} reloadFunc={reloadFunc} onClick={selectItem}/>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default AgendaPage;


