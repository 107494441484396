import React from 'react';
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { numberFormat } from "../../../utils/numberFunctions";
import { getAPIData, handlePagination } from "../../../utils/apiFunction";
import MUIDataTable from "mui-datatables";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import WebhookIcon from '@mui/icons-material/Webhook';
import EditIcon from '@mui/icons-material/Edit';
import { useJumboTheme } from "@jumbo/hooks";
import { CircularProgress, Typography } from "@mui/material";
import { TableCell, TableRow, TableFooter } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { reverseSort } from '../../../utils/sortFunctions';
import { salesOrderAndShipmentsTypes, statusList } from '../../../utils/constants/selectFields';
import getShipSaleData from '../Function/GetShipSaleList';

const CANCELLED = "Cancelled";
const CANCELED = "Canceled";
const CLOSED = "Closed";
const BACKORDER = "Back Order";
const COMPLETED = "Completed";
const SHIPPING = "Shipping";
const TRANSFERTYPE = 'TR';
const TRANSFERTYPELONG = 'Transfer';
const INVOICED = 'Invoiced';
const CONFIRMED = 'Confirmed';
const OPEN = 'Open';



const SalesOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const { theme } = useJumboTheme();
    const quantityLabel = 'Total Oyster Quantity';
    React.useEffect(() => {
        var cols = [
            {
                name: 'orderDate',
                label: 'Order Date',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        return filter(procurement)[dataIndex]?.orderDate ? dateFormat(filter(procurement)[dataIndex].orderDate) : '';
                    },
                }
            },
            {
                name: 'shipmentDate',
                label: 'Shipment Date',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        return filter(procurement)[dataIndex]?.shipmentDate ? dateFormat(filter(procurement)[dataIndex].shipmentDate) : '';
                    },
                }
            },
            {
                name: 'customer',
                label: 'Customer'
            },
            {
                name: 'originalQty',
                label: 'Original QTY'
            },
            {
                name: 'shippedQty',
                label: 'Shipped Qty'
            },
            {
                name: 'salesOrderId',
                label: 'Sales Order Id',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                        var proc = filter(procurement);
                        return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=SO&OrderNbr=' + proc[dataIndex]?.salesOrderId} target='_blank'>{proc[dataIndex]?.salesOrderId}</Link>
                    },
                }

            },
            {
                name: 'shipmentId',
                label: 'Shipment Id',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                        var proc = filter(procurement);
                        return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO302000&ShipmentNbr=' + proc[dataIndex]?.shipmentId} target='_blank'>{proc[dataIndex]?.shipmentId}</Link>
                    },
                }
            }
        ]

        setColumns(cols);
    }, [props.startDate, props.endDate, procurement])

    const filter = (data) => {
        //shipment date within props.startDate and props.endDate
        return data.filter((row) => {
            return new Date(row.shipmentDate) >= new Date(props.startDate) && new Date(row.shipmentDate) <= new Date(props.endDate);
        }
        );
    }
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        rowsPerPage: 1000,
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),
    }
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        getAPIData('/Fullfillment/CheckForErrors', setProcurement);
        return () => {
            abortController.abort();
        };
    }, [props.location, props.reload]);


    return (
        <DataTableStandard
            data={filter(procurement)}
            title={'Shipment Errors'}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reloadFunc}
            shrink={true}

        />
    );
};

export default SalesOrders;
