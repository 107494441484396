import React, { useMemo } from 'react';
import Div from "@jumbo/shared/Div";
import { getAPIDataReturn, getAPIDataParamsReturn, postApi, getAPIData, getAPIDataParams } from "../../../utils/apiFunction";
import DataTableStandard from "../../../components/DataTableStandard";
import Button from '@mui/material/Button';
import { dateFormat } from '../../../utils/dateFunctions';
import { dateSort } from '../../../utils/sortFunctions';
import { TableFooter, TableRow, TableCell } from '@mui/material';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import ControlledTextField from '../../../components/ControlledTextField';

const SelectInventoryForm = (props) => {
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [search, setSearch] = React.useState(null);
    const [columns, setColumns] = React.useState([]);
    var recData = props.data ? props.data : {};
    const Swal = useSwalWrapper();
    const [inventory, setInventory] = React.useState(null);
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredMasterAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MASTER };
    const requiredSpecialAcess = { key: ACCESS_SECTIONS.SPECIALACCESS, value: ACCESS.EDIT };

    const doSearch = async (str) => {
        setLoading(true);
        setInventory(null);
        console.log(str);
        getAPIDataParamsReturn('/Inventory/PRNHistory', { LotNbrSubString: str }).then((data) => {
            setLoading(false);
            setInventory(data.map((dt) => {
                return {
                    ...dt,
                    stockItemName: remSS(dt.stockItem?.description),
                    expiryDateFormat: dt.expiryDate ? dateFormat(new Date(dt.expiryDate)) : null,
                    portalQuantity: dt.quantity,
                    warehouseName: dt.warehouse?.description,
                    initialInventory: dt.initialInventory ? 1 : 0,
                }

            }));
        });
    }

    const remSS = (name) => {
        if (name) {
            return name.replace('Single Seed', '');
        } else {
            return '';
        }
    }




    const reloadFunc = () => {
        setReload(!reload);
        doSearch(search)
    }
    React.useEffect(() => {
        var cols = [];

        cols.push({
            name: 'expiryDateFormat',
            label: 'Use by Date',
            options: {
                sortCompare: dateSort,
                sortDescFirst: true,
                filterType: 'multiselect'
            }
            //options: props.dayToggle ? { filterList: daySelection !== null ? [dateFormat(generateDates(props.startDate, props.endDate)[daySelection])] : [] } : {}
        });


        cols.push({
            name: 'lotSerialNbr',
            label: 'Lot Serial Nbr',

        });


        cols.push({
            name: 'stockItemName',
            label: 'StockItem',
        });
        cols.push({
            name: 'portalQuantity',
            label: 'Quantity (Doz)',

        });
        cols.push({
            name: 'eventType',
            label: 'Event',

        });
        cols.push({
            name: 'eventId',
            label: 'Event Id',

        });
        cols.push({
            name: 'initialInventory',
            label: 'Initial Inventory',

        });
        cols.push({
            name: 'warehouseName',
            label: 'Warehouse',

        });
        cols.push({
            name: 'status',
            label: 'Status',

        });
        cols.push({
            name: 'timestamp',
            label: 'TimeStamp',

        });

        setColumns(cols);
    }, [])

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        rowsPerPage: 1000,
        responsive: 'standard',
        //onRowClick: props.onClick && (!myobDiff) ? props.onClick(invStock) : (() => { }),

    };
    options.customTableBodyFooterRender = (opts) => {
        var total = opts.data.reduce((acc, val) => {return acc + val.data[3]}, 0);
        const paddColStyle = {
            color: '#000',
            paddingTop: '1px',
            paddingBottom: '1px'
        }
        return (
            <TableFooter >
                <TableRow style={{ fontWeight: 'bold' }}>
                    {opts.columns.map((col, index) => {
                        console.log(index)
                        if (index === 0) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                Total
                            </TableCell>
                        } else if (index === 3) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                {total}
                            </TableCell>
                        } else {
                           return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                            </TableCell>
                        }
                    })}
                </TableRow>
            </TableFooter >
        )
    }

    return (
        <Div>
            <ControlledTextField label="Search" state={search} setState={setSearch} />
            <Button onClick={() => { doSearch(search) }}>Search</Button>
            <DataTableStandard
                data={inventory ? inventory : []}
                title={"Inventory"}
                columns={columns}
                options={options}
                returnSearch={!loading}
                reload={reloadFunc}
                shrink={true}
            />
        </Div>
    );
};


export default SelectInventoryForm;


