const PROPOSED = "Proposed";
const ONHOLD = "On Hold";
const OPEN = "Open";
const CANCELLED = "Canceled";
export const statusList = [
    ONHOLD,
    CANCELLED
]
export const SOstatusList = [
    PROPOSED,
    ONHOLD,
    OPEN,
    CANCELLED
]

const SALESORDER = { name: "Sales Order", value: 'SO' };
const PURCHASEORDER = { name: "Purchase Order", value: 'PO' };
const TRANSFER = { name: "Transfer", value: 'TR' };
const SHIPMENT = { name: "Shipment", value: 'Shipment' };
const SHIPMENTTRANSFER = { name: "Transfer", value: 'Transfer' };
export const salesOrderTypes = [
    SALESORDER,
    TRANSFER
]
export const salesOrderAndShipmentsTypes = [
    SALESORDER,
    TRANSFER,
    SHIPMENT,
    SHIPMENTTRANSFER
]
export const purchaseOrderTypes = [
    PURCHASEORDER,
    TRANSFER
]