import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { startOfWeek, endOfWeek, textfieldDateFormat } from "../../../utils/dateFunctions";
import ControlledTextField from "../../../components/ControlledTextField";
import Div from "@jumbo/shared/Div";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { adalApiFetch } from '../../../../adalConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAPIData } from '../../../utils/apiFunction';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import StaffMember from "../../../components/StaffMember";
import SelectWarehouse from "../../../components/SelectWarehouse";
import { checkAccess } from '../../../utils/roleFunctions';
import { getOysterGrades,gradeSort } from 'app/definitions/oysters';


const PriceForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [staffMember, setStaffMember] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [currStockItems, setcurrStockItems] = React.useState([]);
    const [date, setDate] = React.useState(props.date ? props.date : new Date());
    const [comments, setComments] = React.useState(null);
    const [stockItems, setStockItems] = React.useState([]);
    const [currentStock, setCurrentStock] = React.useState(null);
    const [returnData, setReturnData] = React.useState(null);
    const [warehouse, setWarehouse] = React.useState(null);
    const [grades, setGrades] = React.useState([]);
    const [currentEntries, setCurrentEntries] = React.useState(null);
    const id = props.data ? props.data.id : '';
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };

    React.useEffect(() => {
        const checkArr = (data) => {
            var retData = data;
            console.log(data)
            if (Array.isArray(data)) {
                retData = data[0];
                console.log(retData)
            }
            setReturnData(retData);
        }
        getOysterGrades().then((data) => {
            setGrades(data);
            setStockItems(gradeSort(data));
            if (props.new) {
                setCurrentStock({ ...formOyObj(data) });
            }
        });
        if (!props.new) {
            getAPIData('/ManualInventory/Get/' + id, checkArr);
        }
    }, []);

    React.useEffect(() => {


    }, [])

    React.useEffect(async () => {
        const response = await adalApiFetch(fetch, uri + '/ManualInventory/Get?' + new URLSearchParams({
            startDate: textfieldDateFormat(date),
            endDate: textfieldDateFormat(date)
        }));
        const data = await response.json();
        setCurrentEntries(data.map((dt) => { return dt.warehouseId }));
    }, []);

    React.useEffect(() => {

        if (returnData && grades.length>0) {
            setCurrentStock({ ...buildFromData(grades, returnData) });
            setComments(returnData.notes);
            setDate(new Date(returnData.date));
        }
    }, [returnData, grades])
    const buildFromData = (oyG, rd) => {
        var newObj = {};
        for (var i = 0; i < oyG.length; i++) {
            newObj[oyG[i].abv] = rd[oyG[i].abv.toLowerCase()];
        }
        return newObj;
    }
    const formOyObj = (oyG) => {
        var newObj = {};
        for (var i = 0; i < oyG.length; i++) {
            newObj[oyG[i].abv] = null;
        }
        return newObj;
    }

    const updateStock = (key) => (val) => {
        var stockObj = { ...currentStock };
        stockObj[key] = val;
        setCurrentStock(stockObj);
    }

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!staffMember) {
            errorMessages.push("You must enter a Staff Member");
        }
        if (props.new && currentEntries.includes(warehouse?.id)) {
            errorMessages.push("Today's entry for this Warehouse already exists.");

        }
        console.log(currentStock);
        for (var i = 0; i < Object.keys(currentStock).length; i++) {
            if (currentStock[Object.keys(currentStock)[i]] % 1 !== 0) {
                errorMessages.push("Can't have .5 as an inventory log. i.e " + Object.keys(currentStock)[i]);

            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else {
            submit();
        }
    }
    const submit = async () => {


        setLoading(true);
        const setNtoZ = (cs) => {
            var cleanObj = {};
            for (var i = 0; i < Object.keys(cs).length; i++) {
                cleanObj[Object.keys(cs)[i]] = cs[Object.keys(cs)[i]] ? cs[Object.keys(cs)[i]] : 0;
            }
            return cleanObj;
        }
        var postData = {
            Date: textfieldDateFormat(date),
            EnteredBy: staffMember?.id,
            WarehouseId: warehouse?.id,
            Notes: comments,
            ...setNtoZ(currentStock),

        }
        if (props.data) {
            await adalApiFetch(fetch, uri + '/ManualInventory/Update/' + id + '?' + new URLSearchParams(postData), {
                method: 'post'
            })
        } else {
            await adalApiFetch(fetch, uri + '/ManualInventory/Insert?' + new URLSearchParams(postData), {
                method: 'post'
            })
        }


        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }
    }


    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >

            <Div>
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12}>
                        <StaffMember staffId={returnData ? returnData.staffMemberId : null} driver={staffMember} setDriver={setStaffMember} disabled={completed} noTitle />
                        <SelectWarehouse useCache warehouseId={returnData ? returnData.warehouseId : null} state={warehouse} setState={setWarehouse} disabled={completed} />
                    </Grid>
                </Grid>

                <h3 style={{ fontWeight: 450 }}>Stock</h3>
                <Grid container spacing={2} mb={1}>
                    {stockItems.map((og, ind) => {
                        var specTitle = '';
                        if ((ind === 0) || (og.Species != stockItems[ind - 1].Species)) {
                            specTitle = <Grid item xs={12}><h4 style={{ fontWeight: 450 }}>{og.Species}</h4></Grid>
                        }
                        return <React.Fragment>
                            {specTitle}
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>

                                <ControlledTextField key={'oyc' + ind} title={og.Species + ' ' + og.Grade} state={currentStock ? currentStock[og.abv] : null} setState={updateStock(og.abv)} disabled={props.disabled} number />
                            </Grid>
                        </React.Fragment>
                    })}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Comments"
                            value={comments}
                            onChange={(event) => { setComments(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rows={4} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                            <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                        </div>
                    </Grid>
                </Grid>
            </Div>
        </Box >
    );
};


export default PriceForm;


