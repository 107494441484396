import React from 'react';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import { textfieldDateFormat, startOfWeek, shiftWeek } from '../utils/dateFunctions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import POSelectForm from '../pages/Processing/Forms/POSelectForm';
import FormModal from './FormModal';
import Button from "@mui/material/Button";

const LeaseSelect = (props) => {
    const [ests, setEsts] = React.useState([]);
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');
    const filterVals = (list) => {
        setEsts(list.filter((l) => {
            if (props.onlyHA) {
                if (l.harvestArea) {
                    return true;    
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }).sort((a, b) => {
            return a.description > b.description ? 1 : -1;
        }))
    }
    React.useEffect(() => {
        console.log(ests, props.estId)

        if (props.estId && ests) {
            console.log(ests,props.estId)
            var est = ests.find((dr) => { return (dr.id === props.estId) });
            if (est) {
                props.setState(est);
                setValue(est);
            }
        }
    }, [ests, props.estId])


    React.useEffect(() => {
        if (props.warehouseId) {
            getAPIData('/Transfers/GetLeases/' + props.warehouseId, filterVals);
        }
    }, [props.warehouseId])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option.displayName}
        disablePortal
        id="estuarybox"
        options={ests}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Lease" />}
    />);



}

export default LeaseSelect;