import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Receivals from '../Processing/Tables/Receivals';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import Typography from "@mui/material/Typography";
import {
    Card,
    CardContent,
    TextField
} from "@mui/material";
import { useJumboTheme } from "@jumbo/hooks";

const LandingPage = (props) => {
    const [open, setOpen] = React.useState(true);
    const [number, setNumber] = React.useState('');
    const [PRN, setPRN] = React.useState(null);
    const handleClose = () => setOpen(false);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const { theme } = useJumboTheme();
    const [name, setName] = React.useState(null);
    const changeCol = (nm) => () => {
        setName(nm);
    }

    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setPRN(array[rowState.dataIndex]);
    }
    const typeFunc = (num) => () => {
        setNumber(number + String(num));
    }
    const clear = () => {
        setName('');
        setPRN(null);
    }
    const ffLines = [
        {
            Customer: "Customer A",
            Type: "Pacific",
            Grade: "Buffet",
            Total: "20 Doz",
            Location1: "E2",
            Location2: "E3"
        },
        {
            Customer: "Customer B",
            Type: "Sydney Rock",
            Grade: "Bistro",
            Total: "20 Doz",
            Location1: "D1",
            Location2: ""
        },
        {
            Customer: "Customer C",
            Type: "Sydney Rock",
            Grade: "Plate",
            Total: "10 Doz",
            Location1: "H1",
            Location2: "H2"
        }
        , {
            Customer: "Customer D",
            Type: "Pacific",
            Grade: "Standard",
            Total: "20 Doz",
            Location1: "C1",
            Location2: ""
        },
        {
            Customer: "Customer E",
            Type: "Sydney Rock",
            Grade: "Bottle",
            Total: "40 Doz",
            Location1: "A1",
            Location2: "A2"
        }

    ]
    return (
        <div>
            <Typography variant={"h1"} mb={3}>Fulfilment</Typography>
                    <Grid container spacing={0}>
                       
                        <Typography
                            variant={"h4"}
                            color={"common.black"}
                            mb={2}
                            marginTop={"50px" }
                            marginLeft={"50px" }
                        >Next Customer</Typography>
                        {ffLines.map((ff,ind) => {
                            return (<Grid item xs={12}>
                                <FullfilmentLines Customer={ff.Customer} Type={ff.Type} Grade={ff.Grade} Total={ff.Total} Location1={ff.Location1} Location2={ff.Location2} />
                                {ind === 0 ? (<Typography
                                    variant={"h4"}
                                    color={"common.black"}
                                    mb={2}
                                    marginTop={"50px"}
                                    marginLeft={"50px"}
                                > Then ...</Typography>):'' }
                            </Grid>);
                        })}


                    </Grid>
        </div >
    );
};

export default LandingPage;



const FullfilmentLines = (props) => {
    const Swal = useSwalWrapper();
    const sweetAlerts = () => {
        Swal.fire({
            icon: "question",
            title: "",
            text: 'Are you sure you want to complete this order?',
        });
    }

    return (<Card sx={{ mb: 4 }} onClick={sweetAlerts } style={{ backgroundColor: props.sel ? 'grey' : 'white' }} >

        <CardContent>

            <Div sx={{ flex: 1, }}>

                <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                >{props.num}</Typography>
                <Grid container spacing={3.5}>
                    <Grid item xs={4}>
                        <Typography
                            variant={"h6"}
                            color={"common.black"}
                            mb={2}
                        >{props.Customer}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            variant={"h6"}
                            color={"common.black"}
                            mb={2}
                        >{props.Type}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            variant={"h6"}
                            color={"common.black"}
                            mb={2}
                        >{props.Grade}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            variant={"h6"}
                            color={"common.black"}
                            mb={2}
                        >{props.Total}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography
                            variant={"h6"}
                            color={"common.black"}
                            mb={2}
                        >{props.Location1}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography
                            variant={"h6"}
                            color={"common.black"}
                            mb={2}
                        >{props.Location2}</Typography>
                    </Grid>
                </Grid>


            </Div>
        </CardContent>
    </Card >
    );
}