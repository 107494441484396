import * as React from 'react';
import TextField from '@mui/material/TextField';
import { textfieldDateFormat } from "../utils/dateFunctions";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

const DatePickerOutline = (props) => {
    return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                <DatePicker format="DD/MM/YYYY" label={props.label} value={dayjs(props.date)}
                    onChange={(newValue) => props.setDate(newValue.toDate())} disabled={props.disabled} />
                </DemoContainer>
            </LocalizationProvider>
    )
}

export default DatePickerOutline;

export const DatePickerAutoOpen = (props) => {
    const [open, setOpen] = React.useState(true);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker format="DD/MM/YYYY" label={props.label} value={dayjs(props.date)} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}
                    onChange={(newValue) => { props.setDate(newValue.toDate()) }} disabled={props.disabled} />
            </DemoContainer>
        </LocalizationProvider>
    )
}