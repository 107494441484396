import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import Typography from '@mui/material/Typography';
import { checkAccess } from '../../../utils/roleFunctions';
import { Table, TableContainer, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import FormModal from '../../../components/FormModal';
import AddPurchaseOrder from '../../Procurement/Forms/AddPurchaseOrder';
import PurchaseOrderSelect from "../../../components/PurchaseOrderSelect";
import ETransferDisplay from "../../../components/ETransferDisplay";
import { dateFormat } from '../../../utils/dateFunctions';



const MatchPO = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const [POId, setPOId] = React.useState(null);
    const [PO, setPO] = React.useState(null);
    const [retPO, setRetPO] = React.useState(null);
    const [data, setData] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openInset, setOpenInset] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    const id = props.data ? props.data.id : '';

    React.useEffect(() => {
        if (POId) {
            getAPIData('/PurchaseOrder/Get/' + POId.id, (data) => { setPO(data ? data[0] : null) });
        }
    }, [reload, POId])

    console.log(id)
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!POId) {
            errorMessages.push("You must select a Purchase Order");
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const deleteRec = () => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to DELETE',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue',
            cancelButtonText: 'No',

        }).then(async result => {
            if (result.value) {
                setLoading(true);
                setCompleted(true);
                console.log(POId);

                var promArr = [];
                //image upload
                promArr.push(await adalApiFetch(fetch, uri + '/Receivals/Delete/' + id, {
                    method: 'post',
                }))


                await Promise.all(promArr);

                setLoading(false);
                if (props.reload) {
                    props.reload();
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }
    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        console.log(POId);
        var data = {
            PurchaseOrderId: POId ? POId.id : 0,
        }
        var promArr = [];
        //image upload
        promArr.push(await adalApiFetch(fetch, uri + '/Receivals/LinkToPurchaseOrder/' + id + '?' + new URLSearchParams({
            ...data
        }), {
            method: 'post',
        }))


        await Promise.all(promArr);

        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    const handleClose = () => () => {
        setData(null);
        setEdit(false);
        setOpen(false);
    }
    const reloadFunc = () => {
        setReload(!reload);
    }
    const createPO = () => {
        setOpenInset(true);
        setEdit(false);
        setData(null);
    }

    const updatePO = (data) => (e) => {
        setData(data);
        setOpen(true);
        setEdit(true);

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <FormModal open={open} handleClose={handleClose()} title={edit ? "Update Purchase Order" : "Create New Purchase Order"} >
                <AddPurchaseOrder data={data} access={props.access} reload={reloadFunc} returnDate={true} handleClose={handleClose} edit={edit} completed={completed} returnPO={setRetPO} />
            </FormModal>
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        {props.data.transferId ? <ETransferDisplay transferId={props.data.transferId} /> : <img src={props.data ? props.data.imagePath : ''} width="100%" />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>

                        <Typography variant={"h3"}>Match Purchase Order <Button variant="contained" color="error" sx={{ position: 'absolute', right: '4%' }} onClick={deleteRec}> Delete Receival</Button></Typography>

                        <Grid container spacing={0} mb={1}>

                            <Grid item xs={12} >
                                <PurchaseOrderSelect reload={reload} pickupDate POID={returnData ? returnData.purchaseOrderId : (retPO ? retPO.id : null)} state={POId} setState={setPOId} disabled={completed} />
                            </Grid>
                            {!POId ? <Grid item xs={12}  >
                                <Typography variant={"h3"}>If no PO exists:</Typography>
                                <Button onClick={createPO} variant="contained" >Create New Purchase Order</Button>
                                {openInset ? <div style={{ border: '1px solid black', padding: '5px' }}>
                                    <AddPurchaseOrder halfSize={true} data={data} access={props.access} reload={reloadFunc} returnDate={true} handleClose={handleClose} edit={edit} completed={completed} returnPO={setRetPO} />
                                </div> : null}
                            </Grid> : null}
                            <Grid item xs={12} >
                                {PO ? (
                                    <React.Fragment>
                                        <Typography variant={"h4"}>{PO ? ("PO Supplier: " + PO?.supplier?.name) : ''}</Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Type
                                                        </TableCell>
                                                        <TableCell>
                                                            Quantity (Doz)
                                                        </TableCell>
                                                        <TableCell>
                                                            Warehouse
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody  >
                                                    {PO?.orderInputLines?.map((OL) => {
                                                        return (<TableRow>
                                                            <TableCell>
                                                                {OL.stockItem.description}
                                                            </TableCell>
                                                            <TableCell sx={{ textAlign: 'right' }}>
                                                                {OL.ordered}
                                                            </TableCell>
                                                            <TableCell>
                                                                {OL.warehouse ? OL.warehouse.description : ''}
                                                            </TableCell>
                                                        </TableRow>)
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </React.Fragment>) : null}
                            </Grid>
                            <Grid item xs={12} >
                                {PO ? <Button variant="contained" onClick={updatePO(POId)}>Update Purchase Order</Button> : null}
                            </Grid>


                        </Grid>


                    </Grid>


                    <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

                </Grid>
            </Div>
        </Box >
    );
};
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}






export default MatchPO;
