


export const findLocation = (row, rowPos) => {
    var currSumPos = 0;
    if (rowPos >= 0) {
        for (var i = 0; i < row.length; i++) {
            currSumPos += (row[i].len);
            if ((currSumPos - 1) >= rowPos) {
                return i;
            }
        }
    }
    return -1;
}

export const getMapState = (currMap, source, id) => {
    return currMap.getFeatureState({
        source: source,
        id: id,
    });
}

 export const setMapState = (currMap, source, id, obj) => {
    currMap.setFeatureState({
        source: source,
        id: id,
    }, obj);
}

//create function that combines Crop supplier and is hatched into string
export const cropSupplier = (crop, supplier, isHatched) => {
    var cropSupplier = crop;
    if ((isHatched === true) || (isHatched === false)) {
        cropSupplier += isHatched ? '-HAT' : '-WLD';
    }
    if (supplier !== 'Empty') {
        cropSupplier += ' ' + supplier;
    }
    //will be EMPTY if all are empty
    return cropSupplier;
};


export const extractGroups = (map, startPos, endPos, lId) => {
    var states = getMapState(map, 'selected', ('row' + lId));
    if (states.row) {
        var startInd = findLocation(states.row, startPos);
        var endInd = findLocation(states.row, endPos);
        if ((startInd >= 0) && (endInd >= 0)) {

            var high = 0;
            var low = 0;
            var stp = 0;
            var enp = 0;
            if (startInd > endInd) {
                high = startInd;
                low = endInd;
                stp = endPos;
                enp = startPos;
            } else {
                high = endInd;
                low = startInd;
                stp = startPos;
                enp = endPos;
            }
            if (high === low) {
                if (startPos > endPos) {
                    stp = endPos;
                    enp = startPos;
                } else {
                    enp = endPos;
                    stp = startPos;
                }
            }
            var grps = [];
            var currSTP = stp;
            var currENP = 0;
            for (var j = 0; j < low; j++) {
                currENP = currENP + (states.row[j].len);
            }
            if (high === low) {
                grps.push({ row: states.row[low], startPos: stp, endPos: enp, wholeGroup: (enp - stp + 1) === states.row[high].len });
            } else {

                for (var i = low; i <= high; i++) {

                    if (i === low) {
                        currSTP = stp;
                        currENP = currENP + (states.row[i].len) - 1;
                    } else if (i === high) {
                        currSTP = currENP + 1;
                        currENP = enp;
                    } else {
                        currSTP = currENP + 1;
                        currENP = currENP + (states.row[i].len);
                    }
                    grps.push({ row: states.row[i], startPos: currSTP, endPos: currENP, wholeGroup: (currENP - currSTP + 1) === states.row[i].len });
                }
            }
            return grps;
        }
    }
    return [];
}