import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow, yesterday, dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { Button } from '@mui/material';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Div from "@jumbo/shared/Div";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import { displayDozFormat, numberFormat } from '../../../utils/numberFunctions';
import { AccountTree } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { textColorContrast } from "../../../utils/renderFunctions";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { modifyTotalOysters } from '../utils/onShoreFunction';
import { checkAccess } from '../../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';


const OnShoreCard = (props) => {
    var grp = props.group;
    const requiredEditAccess = { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.EDIT };

    var ind = props.ind;
    var opacity = props.opacity;
    var canCombine = props.canCombine;
    var isSel = props.isSel;
    var date = props.StillOnWater ? new Date(grp?.location?.deployed) :new Date(grp?.shore?.date);
    var average = grp?.average;
    var totalUnits = grp?.totalUnits;
    var hasParents = grp?.parents?.length > 0;
    var parents = grp?.parents;
    const dateToCol = (date, opacity) => {
        var warningCols = ['rgba(255, 105, 0,' + opacity + ')', 'rgba(255, 147, 0,' + opacity + ')', 'rgba(255, 167, 0,' + opacity + ')', 'rgba(255, 244, 0,' + opacity + ')', 'rgba(163, 255, 0,' + opacity + ')', 'rgba(104, 221, 0,' + opacity + ')', 'rgba(44, 186, 0,' + opacity + ')'];
        //red to green
        var daysAgo = Math.floor(((new Date().getTime()) - date.getTime()) / (1000 * 60 * 60 * 24));
        if (daysAgo >= 0) {
            if (daysAgo > 6) {
                return 'rgba(255, 0, 0,' + opacity + ')';
            }
            return warningCols[6 - daysAgo];
        } else {
            return warningCols[6];
        }
    }
    const batchtoCol = (batch) => {
        return props.cropCols?.find((cc) => { return cc.crop === batch })?.colour;
    }
    var backColor = dateToCol(date, opacity);
    if (props.incompleteGrade) {
        //set backcolor to striped white and backcolor
        backColor = 'repeating-linear-gradient(45deg, white, white 10px, ' + backColor + ' 10px, ' + backColor + ' 20px)';
        for (var i = 0; i < props.group.children.length; i++) {
            var child = props.group.children[i];
            var newVal = modifyTotalOysters({ totalUnits: totalUnits, average: average }, { totalUnits: child.totalUnits, average: child.average });
            totalUnits = (newVal.totalUnits).toFixed(0);
            average = newVal.average;
        }
    }

    if (hasParents) {
        var newParents = [];
        var ids = [];
        //combine groups that have the same average, previous location and grade
        for (var i = 0; i < parents.length; i++) {
            if (!ids.includes(parents[i].id)) {
                ids.push(parents[i].id)
                var nP = { ...parents[i] };
                for (var j = 0; j < parents.length; j++) {
                    if (!ids.includes(parents[j].id)) {
                        if ((parents[i].average === parents[j].average) && (parents[i].unitType?.id === parents[j].unitType?.id)) {
                            nP.totalUnits += parents[j].totalUnits;
                            ids.push(parents[j].id);
                        }
                    }
                }
                newParents.push(nP);
            }
        }
        if (newParents.length > 1) {
            parents = newParents;
        } else {
            hasParents = false;
            parents = null;
        }
    }
    const TextDisplay = ({ grp, tU, av, isParent }) => {
        return <React.Fragment><Typography variant={"h5"} mb={.5}>{grp.grade?.description}</Typography>
            <Typography variant={"h5"} mb={.5}>{grp.unitType?.description}</Typography>
            {grp?.previousLocation?.line?.lease?.name ? <Typography variant={"h5"} mb={.5}>{grp?.previousLocation?.line?.lease?.name} - Row {grp?.previousLocation?.line?.lineNbr}</Typography> : null}

            {grp?.note ? <Typography variant={"h5"} mb={.5}>{grp?.note} </Typography> : null}
            {
                !isParent && isSel ? <IconButton aria-label="cci" onClick={props.noteEdit(ind)}>
                    <EditIcon />
                </IconButton> : null
            }
            {
                !isParent && isSel ? <IconButton aria-label="cci" onClick={props.addPhoto(ind)}>
                    <CameraAltIcon />
                </IconButton> : null
            }
            {!isParent ? <br /> : null}

            <Typography style={{ fontWeight: 'bold' }} variant={"h5"} mb={.5}>Total Units: {tU}</Typography>
            <Typography variant={"h5"} mb={.5}>Oys./Unit: {displayDozFormat(av)}</Typography>
        </React.Fragment>
    }
    return (
        <Card style={{ background: backColor, maxWidth: '450px' }} onClick={props.selectedCard(ind)}>
            <CardContent style={{ position: 'relative' }} >
                <IconButton aria-label="add" onClick={props.openHistoryModal} style={{
                    position: 'absolute',
                    right: '1%',
                    top: '1%'
                }} >
                    <AccountTree />
                </IconButton>
                {checkAccess(props.access, requiredEditAccess) && props.undoEnabled ? <IconButton aria-label="add" onClick={props.openUndoModal} style={{
                    position: 'absolute',
                    left: '1%',
                    top: '1%'
                }} >
                    <ReplayIcon />
                </IconButton> : null}

                <Div sx={{ ml: 2, flex: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography style={{ textAlign: 'center' }} variant={"h3"} mb={.5}>{date?dateFormat(date):''} {props.incompleteGrade ? '(INCOMPLETE Grade)' : ''}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ backgroundColor: batchtoCol(grp.cropLabel), color: textColorContrast(batchtoCol(grp.cropLabel), '#FFF', '#000'), margin: '2px', border: '2px solid black', paddingLeft: '1px' }} variant={"h4"} mb={.5}>{grp.cropLabel}</Typography>
                            {hasParents ? parents.map((parent, i) => {
                                return <div style={{ margin: '3px', padding: '3px', marginTop: '20px', border: '1px solid black' }} ><TextDisplay grp={parent} tU={parent.totalUnits} av={parent.average} isParent={true} /></div>
                            }) : <TextDisplay grp={grp} tU={totalUnits} av={average} isParent={false} />}
                            {hasParents ? <Typography style={{ fontWeight: 'bold', paddingTop: '10px' }} variant={"h5"} mb={.5}>Total Oysters: {displayDozFormat(average * totalUnits)}</Typography> : null}
                        </Grid>
                        <Grid item xs={12}>
                            {!hasParents && !props.incompleteGrade && !grp.needGrading && isSel && props.select ? <Button onClick={props.selectFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Select</Button> : null}
                        </Grid>
                        {(props.selected !== null) ? <Grid item xs={12} style={{ borderTop: '1px solid black' }}>
                            {!props.incompleteGrade && !grp.needGrading && !isSel && canCombine ? <Button onClick={props.combineFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Add to Combine</Button> : null}
                            {!hasParents && !props.incompleteGrade && !grp.needGrading && isSel && !props.select ? <Button onClick={props.deployFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Deploy</Button> : null}
                            {isSel ? <Button onClick={props.gradeFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Grade</Button> : null}
                            {!hasParents && !props.incompleteGrade && !grp.needGrading && isSel ? <Button onClick={props.splitFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Split</Button> : null}
                            {!props.incompleteGrade && props.transferLegal && !grp.needGrading && isSel && props.ableToTransfer && !props.transSel ? <Button onClick={props.transferFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Transfer</Button> : null}
                            {!props.incompleteGrade && props.transferLegal && !grp.needGrading && props.transSel && !props.transSelOn ? <Button onClick={props.transferFunc(ind)} style={{ margin: '5px' }} variant={"contained"}>Add to Transfer</Button> : null}
                        </Grid> : null}
                    </Grid>
                </Div>
            </CardContent>
        </Card>)
}

export default OnShoreCard;