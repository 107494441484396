import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


const SubmitButtonDisplayer = (props) => {
    if (props.allowed) {
        if (!props.error) {
            if (props.loading) {
                return <CircularProgress />
            } else {
                if (props.completed) {
                    return <Button style={{ width: '300px', height: '50px', maxWidth: '90%' }} variant="contained" color="success" onClick={props.handleClose}>{props.successMessage ? props.successMessage : "Success! Click to Close"}</Button>
                } else {
                    return <Button style={{ width: '300px', height: '50px', maxWidth: '90%' }} variant="contained" onClick={props.submitFunc}>{props.title ? props.title : "Submit"}</Button>

                }
            }
        } else {
            return <Button style={{ width: '400px', height: '50px', maxWidth: '90%' }} variant="contained" color="error" onClick={props.handleClose}>{props.errorMessage?props.errorMessage:props.error}</Button>

        }
    } else {
        return <div>Not Authorised to Submit</div>
    }

}

export default SubmitButtonDisplayer;
