import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import Receivals from './Tables/Receivals';
import { useSearchParams } from "react-router-dom";
import FormModal from '../../components/FormModal';
import ConfirmReceivalForm from './Forms/ConfirmReceivalForm';
import MatchPO from './Forms/MatchPO';
import ToggleButton from '@mui/material/ToggleButton';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import { tomorrow } from '../../utils/dateFunctions';
import QAForm from './Forms/QAform';
import SelectWarehouse from "../../components/SelectWarehouse";
import MYOBdisplay from "./Forms/ForMYOB";
import AddOTR from './Forms/AddOTR';

const InventoryDash = (props) => {
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [noOTR, setNoOTR] = React.useState(false);
    const [warehouse, setWarehouse] = React.useState(null);
    const [selection, setSelection] = React.useState([0, 1, 2, 3]);
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const open = (searchParams.get("status") === "Open" ? true : false);
    const completed = (searchParams.get("status") === "Completed" ? true : false);
    const pickedup = (searchParams.get("status") === "PickedUp" ? true : false);

    const reportLength = (len) => {
        console.log(len)
        if (len > 0) {
            setNoOTR(true);
        } else {
            setNoOTR(false);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={props.disabled}
                        value={selection}
                        onChange={(ev, val) => setSelection(val)}
                        aria-label="text alignment"
                    >
                        {open ? <ToggleButton key={1} value={1} >
                            <div  >Qualilty Assurance</div>
                        </ToggleButton> : ''}
                        {open ? <ToggleButton key={0} value={0} >
                            <div  >Confirmation</div>
                        </ToggleButton> : ''}
                        

                        {!open ? <ToggleButton key={2} value={2} >
                            <div  >Internal</div>
                        </ToggleButton> : ''}
                        {!open ? <ToggleButton key={3} value={3} >
                            <div  >External</div>
                        </ToggleButton> : ''}

                    </ToggleButtonGroup>
                </Grid>
                {open ? <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <SelectWarehouse useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} />

                </Grid> : null}
            </Grid>
            <Grid container spacing={3.5}>
                {/*{open ? <Grid item xs={12} sm={12} md={12}>*/}
                {/*    <div style={{display:(noOTR?'block':'none')} }>*/}
                {/*        <OTRRequired startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} reportLength={reportLength}/>*/}
                {/*    </div>*/}
                {/*</Grid> : ''}*/}
                {selection.includes(1) && open ? <Grid item xs={12} sm={12} md={6}>
                    <NotQAReceival warehouseFilter={warehouse?.description} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} />
                </Grid> : ''}
                {selection.includes(0) && open ? <Grid item xs={12} sm={12} md={6}>
                    <NotConfirmedReceival warehouseFilter={warehouse?.description} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} />
                </Grid> : ''}
                {!open && !completed && !pickedup ? <Grid item xs={12}>
                    <ForMyobReceival internal={selection.includes(2)} external={selection.includes(3)} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} forMyob />
                </Grid> : ''}
                {completed ? <Grid item xs={12}>
                    <CompletedReceival internal={selection.includes(2)} external={selection.includes(3)} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} />
                </Grid> : ''}
                {pickedup ? <Grid item xs={12}>
                    <PickUpReceival internal={selection.includes(2)} external={selection.includes(3)} startDate={startDate} endDate={tomorrow(endDate)} access={props.access} reload={reload} setReload={setReload} />
                </Grid> : ''}
            </Grid>
        </React.Fragment >
    );
};


export default InventoryDash;

const PickUpReceival = (props) => {
    const [edit, setEdit] = React.useState(false);
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);

    const handleClose = () => setOpen(false);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };


    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    
    var extraOp = {

    }

    return (
        <React.Fragment>
            {checkAccess(props.access, requiredAdminAccess) ? <FormModal open={open} handleClose={handleClose} title={"View Receival"} >
                <MatchPO data={rec} access={props.access} handleClose={handleClose} reload={() => { props.setReload(!props.reload) }} />
                {/*<MYOBdisplay data={rec} {...props} handleClose={handleClose} edit={edit} reload={() => { props.setReload(!props.reload) }} completed />*/}
            </FormModal> : ""}
             <Receivals  {...props} onClick={selectPO} extraOptions={extraOp} pickedup reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) }} /> 
        </React.Fragment>
    )
}

const CompletedReceival = (props) => {
    const [edit, setEdit] = React.useState(false);
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);

    const handleClose = () => setOpen(false);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };


    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    const supplierFilter = (list) => {
        return list.filter((l) => {
            if ((props.internal) && (l.isInternal)) {
                return true;
            }
            if ((props.external) && (!l.isInternal)) {
                return true;
            }
        })
    }

    var extraOp = {

    }

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"View Receival"} >
                <MYOBdisplay data={rec} {...props} handleClose={handleClose} edit={edit} reload={() => { props.setReload(!props.reload) }} completed />
            </FormModal>
            {/*{checkAccess(props.access, requiredAdminAccess) ? <FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={edit} onChange={(event) => { setEdit(event.target.checked) }} />} label={edit ? 'Edit Enabled' : 'Edit Disabled'} /> : ''}*/}
            <Receivals  {...props} onClick={selectPO} filter={supplierFilter} extraOptions={extraOp} completed reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) }} />
        </React.Fragment>
    )
}

const ForMyobReceival = (props) => {
    const [edit, setEdit] = React.useState(false);
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);

    const handleClose = () => setOpen(false);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };


    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    const supplierFilter = (list) => {
        return list.filter((l) => {
            if ((props.internal) && (l.isInternal)) {
                return true;
            }
            if ((props.external) && (!l.isInternal)) {
                return true;
            }
        })
    }

    var extraOp = {

    }


    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Confirm Recieval Form"} >
                <MYOBdisplay data={rec} {...props} handleClose={handleClose} edit={edit} reload={() => { props.setReload(!props.reload) }} />
            </FormModal>
            {checkAccess(props.access, requiredAdminAccess) ? <FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={edit} onChange={(event) => { setEdit(event.target.checked) }} />} label={edit ? 'Edit Enabled' : 'Edit Disabled'} /> : ''}
            <Receivals  {...props} onClick={selectPO} filter={supplierFilter} extraOptions={extraOp} forMyob reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) }} />
        </React.Fragment>
    )
}


const NotConfirmedReceival = (props) => {
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const handleClose = () => setOpen(false);

    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Confirm Recieval Form"} >
                <ConfirmReceivalForm data={rec} access={props.access} handleClose={handleClose} reload={() => { props.setReload(!props.reload) }} />
            </FormModal>
            <Receivals {...props} notConfirmed onClick={selectPO} reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) }} />
        </React.Fragment>
    )
}
const NotQAReceival = (props) => {
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const handleClose = () => setOpen(false);

    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Confirm Recieval Form"} >
                <QAForm receival={rec} handleClose={handleClose} access={props.access} reload={() => { props.setReload(!props.reload) }} />
            </FormModal>
            <Receivals {...props} notQA onClick={selectPO} reload={props.reload} reloadFunc={() => { props.setReload(!props.reload) }} />
        </React.Fragment>
    )
}

const OTRRequired = (props) => {
    const [rec, setRec] = React.useState(null);
    const [open, setOpen] = React.useState(null);
    const handleClose = () => setOpen(false);

    const selectPO = (array) => (rowd, rowState) => {
        setRec(array[rowState.dataIndex]);
        setOpen(true);
    }

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Add OTR"} >
                <AddOTR receival={rec} handleClose={handleClose} access={props.access} reload={() => { props.setReload(!props.reload) }} />
            </FormModal>
            <Receivals {...props} noOTR onClick={selectPO} reload={props.reload} reportLength={props.reportLength} reloadFunc={() => { props.setReload(!props.reload) }} />
        </React.Fragment>
    )
}
