import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import UserRequests from './Tables/UserRequests';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from "@mui/material/ToggleButton";
import { userInfo } from '../../../adalConfig';
import FormModal from '../../components/FormModal';
import { Button } from '@mui/material';
import AddUserRequest from './Forms/AddUserRequest';
const PurchaseOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [selection, setSelection] = React.useState(0)
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [user, setUser] = React.useState(userInfo);
    const [userSelect, setUserSelect] = React.useState(0);
    const [userFilter, setUserFilter] = React.useState(null);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const [compTasks, setCompTasks] = React.useState(false);

   
    React.useEffect( () => {
        updateFilter(userSelect)
    }, [user,userSelect]);

    const handleClose = () => setOpen(false);
    const reloadFunc = () => {
        setReload(!reload);
    }

    const createPO = () => {
        setOpen(true);
        setEdit(false);
        setData(null);
    }
    const updatePO = (array) => (rowd, rowState) => {
        console.log(array, rowState)
        setData(array[rowState.dataIndex]);
        setOpen(true);
        setEdit(true);
    }
    const changeCompTs = (val) => {
        if (val === 0) {
            setCompTasks(false);
        } else {
            setCompTasks(true);
        }
    }
    const updateFilter = (val) => {
        setUserFilter(val===0 ? user?.userName : null);
    }
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Create New Request"} >
                <AddUserRequest completed={edit} data={data} access={props.access} reload={reloadFunc} handleClose={handleClose} />
            </FormModal>


            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Button onClick={createPO} variant="contained" >Create New Request</Button>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={selection}
                        onChange={(ev, val) => { setSelection(val); changeCompTs(val) }}
                        aria-label="text alignment"

                    >
                        <ToggleButton key={0} value={0} >
                            <div  >In Progress</div>
                        </ToggleButton>
                        <ToggleButton key={1} value={1} >
                            <div  >Completed</div>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={userSelect}
                        onChange={(ev, val) => { setUserSelect(val); }}
                        aria-label="text alignment"

                    >
                        <ToggleButton key={0} value={0} >
                            <div  >Me</div>
                        </ToggleButton>
                        <ToggleButton key={1} value={1} >
                            <div  >All</div>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12}>
                    <UserRequests userFilter={userFilter} location={location} reload={reload} reloadFunc={reloadFunc} startDate={startDate} endDate={endDate} access={props.access} onClick={updatePO} dashboard compTasks={compTasks} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default PurchaseOrdersPage;
