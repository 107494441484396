import React from 'react';
import ControlledTextField from "./ControlledTextField";
import SelectTextField from "./SelectTextField";
import Grid from "@mui/material/Grid";
import { getAPIData } from '../utils/apiFunction';
import CropYearSelect from '../components/CropYearTransferSelect';
import LeaseSelect from '../components/LeaseSelect';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import RemoveIcon from '@mui/icons-material/Remove';

const OystersGroup = (props) => {
    const [stockItems, setStockItems] = React.useState(null);
    const [leases, setLeases] = React.useState(null);
    const [allStockItems, setAllStockItems] = React.useState(null);
    const [reduceItems, setReduceItems] = React.useState(null);
    const [oysterList, setOysterList] = React.useState(props.starter ? [{}] : []);
    React.useEffect(() => {
        var path = '/StockItems/GetUnprocessed';
        if (props.reduceItems) {
            path = '/StockItems/Get';
        } else if (props.processed) {
            path = '/StockItems/GetProcessed';
        } else {
            path = '/StockItems/GetUnprocessed';
        }
        getAPIData(path, props.reduceItems ? setAllStockItems : setStockItems);
    }, [])

    const filterLeases = (list) => {
        setLeases(list.filter((l) => {
            if (props.onlyHA) {
                if (l.harvestArea) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }).sort((a, b) => {
            return a.description > b.description ? 1 : -1;
        }))
    }

    React.useEffect(() => {
        if (props.reduceItems && props.supplierId) {
            getAPIData('/Transfers/GetStockItems/' + props.supplierId, setReduceItems);
        }
        if (props.supplierId) {
            getAPIData('/Transfers/GetLeases/' + props.supplierId, filterLeases);
        }
    }, [props.supplierId]);
    React.useEffect(() => {
        if (reduceItems && allStockItems) {
            var newSI = [...allStockItems.filter((si) => {
                return reduceItems.find((ri) => {
                    return ((ri.stockItemId === si.id) && (props.sameType === ri.toSame));
                })
            })];
            setStockItems(newSI);
        }
    }, [reduceItems,allStockItems]);
   
    React.useEffect(() => {
        if (props.passedGroup && stockItems && leases) {
            var builtLines = props.passedGroup.map((pg) => {
                var cpySI = { ...pg };
                var matchSI = stockItems.find((si) => { return si.id === pg.stockItemId })
                if (matchSI) {
                    cpySI.stockItem= matchSI;
                }
                var matchLease = leases.find((si) => { return si.id === pg.leaseId })
                if (matchLease) {
                    cpySI.lease = matchLease;
                }
                return cpySI;
            });
            setOysterList(builtLines);
        }
    }, [stockItems,leases, props.passedGroup])
    React.useEffect(() => {
        props.setOysters(oysterList);
    }, [oysterList])
    const addLine = () => {
        var oysterListCPY = [...oysterList];
        oysterListCPY.push({});
        setOysterList(oysterListCPY);
    }
    const removeLine = () => {
        var oysterListCPY = [...oysterList];
        oysterListCPY.pop({});
        setOysterList(oysterListCPY);
    }
    const setLine = (lineVal, ind) => {
        var oysterListCPY = [...oysterList];
        oysterListCPY[ind] = lineVal;
        setOysterList(oysterListCPY);
    }
    return (<React.Fragment>
        {!props.noTitle ? <Grid item xs={12} >
            <h3 style={{ fontWeight: 450 }}>Oysters</h3>
        </Grid> : ''}
        {oysterList.map((oL, ind) => {
            return <OysterLine key={ind} data={oL} items={stockItems} leases={leases} setLine={setLine} index={ind} disabled={props.disabled} cropYear={props.cropYear} lease={props.lease} supplier={props.supplier} />;
        })}
        {!props.single ? <React.Fragment><Stack direction="row" spacing={1}>
            <IconButton aria-label="add" onClick={addLine} disabled={props.disabled}>
                <AddIcon />
            </IconButton>
            <IconButton aria-label="remove" onClick={removeLine} disabled={props.disabled}>
                <RemoveIcon />
            </IconButton>
        </Stack>
            <ControlledTextField title="Total Quantity (doz)" state={totalQuantity(oysterList)} disabled number />
        </React.Fragment> : ''}

    </React.Fragment>)
}
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c?.quantity ? c.quantity : 0) }, 0)
}
const OysterLine = (props) => {
    const [first, setFirst] = React.useState(true);
    const [stockItem, setStockItem] = React.useState(props.data ? props.data.stockItem : null);
    const [bags, setBags] = React.useState(props.data ? props.data.bags : null);
    const [bins, setBins] = React.useState(props.data ? props.data.bins : null);
    const [quantity, setQuantity] = React.useState(props.data ? props.data.quantity : null);
    const [crop, setCrop] = React.useState(props.data ? props.data.crop : null);
    const [lease, setLease] = React.useState(props.data ? props.data.lease : null);

    React.useEffect(() => {
        if (first) {
            setFirst(false);
            if (props.data && props.data.quantity) {
                setQuantity(props.data.quantity);
            }
        }
    }, []);
    React.useEffect(() => {
        setLease(props.data ? props.data.lease : null);
    }, [props.data?.lease]);
    React.useEffect(() => {
        if (!first) {
            if (stockItem) {
                var quant = (bags ? bags : 0) * stockItem.bagCount + (bins ? bins : 0) * stockItem.binCount;
                setQuantity(quant);
                props.setLine({ bags, bins, stockItem, quantity: quant, crop, lease }, props.index);
            }
        }
    }, [bins, bags, stockItem, crop, lease])
    React.useEffect(() => {
        if (stockItem) {
            props.setLine({ bags, bins, stockItem, quantity: Number(quantity), crop, lease}, props.index);
        }
    }, [quantity])
    const remSS = (inp) => {
        if (inp.description.includes('Single Seed')) {
            return inp.description.replace('Single Seed', '');
        } else {
            return inp.description;
        }
    }
    return <Grid key={props.index} container spacing={2} mb={1} style={{ borderBottom: '1px solid lightgrey' }}>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SelectTextField key={'si' + props.index} title="Stock Item" state={stockItem} nameFunc={remSS} list={props.items} setState={setStockItem} disabled={props.disabled} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <ControlledTextField key={'bg' + props.index} title="Bags" state={bags} setState={setBags} disabled={props.disabled} number />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <ControlledTextField key={'bi' + props.index} title="Bins" state={bins} setState={setBins} disabled={props.disabled} number />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <ControlledTextField key={'qua' + props.index} title="Quantity (doz)" state={quantity} setState={setQuantity} disabled={props.disabled} number />
        </Grid>
        {props.lease ? <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <SelectTextField  title="Lease" state={lease} list={props.leases} setState={setLease} nameFunc={(inp) => {return inp.displayName} } disabled={props.disabled} />

        </Grid> : ''}
        {props.cropYear ? <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
            <CropYearSelect supplier={props.supplier} state={crop} setState={setCrop} disabled={props.disabled} /> 

        </Grid> : ''}


    </Grid>
}
export default OystersGroup;