import React from 'react';
import Grid from "@mui/material/Grid";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import IconButton from '@mui/material/IconButton';
import { checkAccess } from '../../../utils/roleFunctions';
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import Table from '@mui/material/Table';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Link from '@mui/material/Link';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';
import ConfirmReceivalForm from './ConfirmReceivalForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { adalApiFetch, userInfo } from '../../../../adalConfig';
import Paper from '@mui/material/Paper';

const MYOBdisplay = (props) => {
    const requiredEditAccess = { key: ACCESS_SECTIONS.MYOB_PROCUREMENT, value: ACCESS.ADMIN };
    const requiredApprovalAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.ADMIN };
    const [loading, setLoading] = React.useState(false);
    const [completed, setCompleted] = React.useState(props.completed ? true : false);
    const [error, setError] = React.useState(false);
    const [ifVariance, setIfVaraiance] = React.useState(true);
    const [view, setView] = React.useState(false);
    var needHigherApproval = false;
    const uri = process.env.REACT_APP_BASE_URL;
    const Swal = useSwalWrapper();
    const [myobData, setMyobData] = React.useState([]);
    const [user, setUser] = React.useState(userInfo());
    React.useEffect(() => {
        setView(props.edit || props.completed);
    }, [props.edit]);
    const titleStyle = {
        margin: 0,
        fontSize: "1.5rem",
        fontFamily: "NoirPro, Arial",
        fontWeight: 500,
        lineHeight: 1.6,
        paddingLeft: '24px',
        position: 'relative',
        paddingTop: '10px'
    }
    const arrow = {
        position: 'absolute',
        right: '4%',

    }

    const handleClick = () => {
        setView(!view);
    }
    const Collapsable = () => {
        if (view) {
            return (
                <IconButton onClick={handleClick}>
                    <ExpandLessIcon />
                </IconButton>
            );

        } else {
            return (
                <IconButton onClick={handleClick}>
                    <ExpandMoreIcon />
                </IconButton>
            );
        }
    }
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!props.data?.estuaryId) {
            errorMessages.push("This Receival is missing the Estuary, Please update the receival");
        } else if (!props.data?.harvestAreaId) {
            errorMessages.push("This Receival is missing the Harvest Area, Please update the receival");
        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submitToMyob();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submitToMyob();
        }
    }
    const submitToMyob = async () => {
        setLoading(true);
        var data = {
            ReceivalId: props.data ? props.data.id : '',
            ApprovalNeeded: needHigherApproval,
            Approver: user.userName
        }

        var response = await adalApiFetch(fetch, uri + '/MYOB/PushReceivalToMYOB?' + new URLSearchParams({
            ...data
        }), {
            method: 'get',
        });

        if (response.ok) {
            const myob = await response.json();
            setMyobData(myob);
            setLoading(false);
            setCompleted(true);
        } else {
            setLoading(false);
            setError(true);
        }
        if (props.reload) {
            props.reload();
        }
    }
    var variations = [];
    var recComment = '';
    var doesPriceVar = false;
    if (props.data) {
        console.log(props.data)
        recComment = props.data.receivalComment;
        if (recComment === 'null') {
            recComment = '';
        }
        variations = props.data?.poVariations;
        variations.map((v) => {
            if ((v.variation && (v.variation !== '') && (v.variation !== 'null'))) {
                doesPriceVar = true;
            }
        })
    }
    var hasTransfer = false;
    if (props.data?.transferId) {
        hasTransfer = true;
        variations.map((v) => {
            if (v.receipt !== v.transfer) {
                needHigherApproval = true;
            }
        });
    }
    //check if variations transfer and receipt are equal


    return (
        <Grid container spacing={2} mb={1}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                {!completed ? (variations.length > 0 ?
                    (<div>
                        <Typography variant={"h3"}>Variance from Purchase Order</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Receival</TableCell>
                                    {hasTransfer ? <TableCell>Transfer</TableCell> : null}
                                    <TableCell>Purchase Order</TableCell>
                                    {doesPriceVar ? <TableCell>Price Variation Reason</TableCell> : null}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {variations.map((v) => {
                                    var priceVar = false;
                                    priceVar = (v.variation && (v.variation !== '') && (v.variation !== 'null')) ? true : false;

                                    return (<TableRow>
                                        <TableCell>{v.stockItem}</TableCell>
                                        <TableCell>{v.receipt}</TableCell>
                                        {hasTransfer ? <TableCell>{v.transfer}</TableCell> : null}
                                        <TableCell>{v.purchaseOrder}</TableCell>
                                        {priceVar ? <TableCell>{v.variation}</TableCell> : (doesPriceVar ? <TableCell></TableCell> : null)}
                                    </TableRow>);
                                })}
                            </TableBody>
                        </Table>

                    </div>) : (<div>
                        <Typography variant={"h3"}>No Purchase Order</Typography>

                    </div>)) : <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            {doesPriceVar ? <TableCell>Price Variation Reason</TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {variations.map((v) => {
                            var priceVar = false;
                            priceVar = (v.variation && (v.variation !== '') && (v.variation !== 'null')) ? true : false;

                            return (<TableRow>
                                <TableCell>{v.stockItem}</TableCell>
                                {priceVar ? <TableCell>{v.variation}</TableCell> : (doesPriceVar ? <TableCell></TableCell> : null)}
                            </TableRow>);
                        })}
                    </TableBody>
                </Table>}
            </Grid>
            {myobData.length > 0 ? <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                <Grid container spacing={2} mb={1}>
                    {myobData.map((md) => {
                        return <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Button style={{ width: '300px', height: '50px', maxWidth: '90%' }} variant="contained" component={Link} href={md.url} target="_blank" >{md.description}</Button>
                        </Grid>
                    })}
                    <Grid item xs={12}>
                        <Typography variant={"h3"} style={{ textDecoration: 'underline' }} > Please complete purchase order for internal farms.</Typography>
                    </Grid>
                </Grid>
            </Grid> : null}
            <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                {recComment && (recComment !== '') ? <React.Fragment><Typography variant={"h4"}>Receival Confirmation Notes:</Typography>
                    <div>{recComment}</div></React.Fragment> : null}
            </Grid>
            <Grid item xs={12}>
                {props.data && props.data.alerts && (props.data.alerts.length > 0) ? <React.Fragment><Typography variant={"h4"}>Data Errors:</Typography>{props.data.alerts.map((a) => {
                    return <div style={{ color: 'red', fontSize: '150%' }}>{a}</div>
                })}</React.Fragment> : null}
            </Grid>
            {!props.edit ? <Grid item xs={12} >
                {needHigherApproval ? <div style={{ color: 'red', fontSize: '150%' }}>Transfer does not match Receival, This requires approval.</div> : null}
                {!completed && error ? <div style={{ color: 'red', fontSize: '150%' }}>There has been a error pushing this to MYOB. Alert IT</div> : null}
                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, (needHigherApproval ? requiredApprovalAccess : requiredEditAccess))} submitFunc={validate} handleClose={props.handleClose} title="Approve and Submit to MYOB" successMessage="Request submitted! Click to Close" error={error} errorMessage="Failed to Submit"/> 
            </Grid> : <Grid item xs={12} >
                <Typography variant={"h4"}>In Editing Mode, disable editing to submit</Typography>
            </Grid>}
            <Grid item xs={12}>
                <Paper>
                    <div style={arrow}><Collapsable /></div>
                    <div onClick={handleClick} style={titleStyle} >{view ? "Receival" : "View Receival"}</div>
                    {view ? <ConfirmReceivalForm data={props.data} access={props.access} handleClose={props.handleClose} reload={props.reload} completed={!props.edit} edit={props.edit} /> : ''}
                </Paper>
            </Grid>
        </Grid>
    )
}

export default MYOBdisplay;
