import { undoDateFormat, undoLongDateFormat } from './dateFunctions';
export const reverseSort = (order) => (obj1, obj2) => {
    var multipler = (order === 'asc') ? 1 : -1;
    return obj1.data > obj2.data ? (multipler * -1) : (multipler * 1);
}

export const dateSort = (order) => (obj1, obj2) => {
    var multipler = (order === 'asc') ? -1 : 1;
    return new Date(undoDateFormat(obj1.data)) > new Date(undoDateFormat(obj2.data)) ? (multipler * -1) : (multipler * 1);
}

export const dateSortLong = (order) => (obj1, obj2) => {
    var multipler = (order === 'asc') ? -1 : 1;
    return new Date(undoLongDateFormat(obj1.data)) > new Date(undoLongDateFormat(obj2.data)) ? (multipler * -1) : (multipler * 1);
}



export const colRemoval = (data, cls, exceptions) => {
    var newCols = [];
    for (var i = 0; i < cls.length; i++) {
        var test = false;
        if (!exceptions.includes(cls[i].name)) {
            for (var j = 0; j < data.length; j++) {

                if ((data[j][cls[i].name] !== 0) && (data[j][cls[i].name] !== null) && (data[j][cls[i].name] !== undefined)) {
                    test = true;
                }
            }
        } else {
            test = true;
        }
        if (test) {
            newCols.push(cls[i]);
        }
    }
    return newCols;
}