import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { startOfWeek, endOfWeek } from "../../../utils/dateFunctions";
import ControlledTextField from "../../../components/ControlledTextField";
import Div from "@jumbo/shared/Div";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add'; 
import RemoveIcon from '@mui/icons-material/Remove';
import { adalApiFetch } from '../../../../adalConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAPIData } from '../../../utils/apiFunction';
import DriverSelect from "../../../components/DriverEntry";
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import Stack from '@mui/material/Stack';
import SupplierSelect from "../../../components/SupplierSelect";
import CustomerSelect from "../../../components/CustomerSelect";
import SelectTextField from "../../../components/SelectTextField";
import { checkAccess } from '../../../utils/roleFunctions';
import { Typography } from '@mui/material';


const PriceForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [entityId, setEntityId] = React.useState(props.new ? null : (props.CustomerPricing ? props.data?.customerId : props.data?.supplierId));
    const [priceList, setPriceList] = React.useState(props.data?props.data.detail.map((dt) => { return { id: dt.stockItemId, price: dt.price } }):[]);
    const [currStockItems, setcurrStockItems] = React.useState([]);
    const [customer, setCustomer] = React.useState(props.data ? props.data.name : null);
    const [stockItems, setStockItems] = React.useState([]);
    const [newStockIt, setNewStockIt] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    React.useEffect(() => {
        if (props.SupplierPricing) {
            getAPIData('/StockItems/GetUnprocessed', setStockItems);

        } else if (props.CustomerPricing) {
            getAPIData('/StockItems/GetProcessed', setStockItems);

        }
    }, [])

    React.useEffect(() => {
        if (stockItems) {
            var availItems = [];
            for (var i = 0; i < stockItems.length; i++) {
                if (priceList.find((pl) => { return pl.id === stockItems[i].id })) {
                    availItems.push(stockItems[i]);
                }
            }
            setcurrStockItems(availItems);
        }
    }, [stockItems])
    const updatePList = (key) => (val) => {
        var list = [...priceList];
        var ind = priceList.findIndex((pl) => { return pl.id === key })
        if (ind >= 0) {
            list[ind].price = val;
        } else {
            list.push({ id: key, price: Number(val) });
        }
        setPriceList(list);
    }

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        if (props.new && !customer) {
            errorMessages.push("You must enter a "+ (props.CustomerPricing?"Customer":"Supplier"));
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else {
            submit();
        }
    }
    const submit = async () => {

        //image upload

        //var response = await adalApiFetch(fetch, uri + '/QA/Post?' + new URLSearchParams({
        //    ...data
        //}), {
        //    method: 'post',
        //});

        //const qaRec = await response.json();
        setLoading(true);
        var proms = [];
        for (var i = 0; i < priceList.length; i++) {
            if (props.SupplierPricing) {
                proms.push(adalApiFetch(fetch, uri + '/SupplierPricing/Post?' + new URLSearchParams({
                    SupplierId: props.new?customer.id: entityId,
                    StockItemId: priceList[i].id,
                    Price: priceList[i].price
                }), {
                    method: 'post'
                }));
            } else {
                proms.push(adalApiFetch(fetch, uri + '/CustomerPricing/Post?' + new URLSearchParams({
                    CustomerId: props.new ? customer.id : entityId,
                    StockItemId: priceList[i].id,
                    Price: priceList[i].price
                }), {
                    method: 'post'
                }));
            }
            
        }
        await Promise.all(proms);


        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }
    }
    const addLine = () => {
        var cpy = [...currStockItems];
        if (newStockIt) {
            var cpyStock = { ...newStockIt, new: true };
            cpy.push(cpyStock);
            setcurrStockItems(cpy);
        }
        setNewStockIt(null);
    }
    const remLine=(id) => () => {
        var cpy = [...currStockItems];
        var stockInd = cpy.findIndex((cp) => { return cp.id === id });
        cpy.splice(stockInd, 1);
        setcurrStockItems(cpy);

        var list = [...priceList];
        var ind = priceList.findIndex((pl) => { return pl.id === id })
        if (ind >= 0) {
            list.splice(ind, 1);
        } 
        setPriceList(list);
    }
    
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {!props.new ?
                    <TextField
                        disabled
                        id="outlined-required"
                        label="Customer"
                        value={customer}
                    /> : (
                        props.CustomerPricing?
                            <CustomerSelect frmrId={null} state={customer} setState={setCustomer} disabled={completed} /> :
                            <SupplierSelect frmrId={null} state={customer} setState={setCustomer} disabled={completed} />

                    )}
            </Div>
            <Div>


                <h3 style={{ fontWeight: 450 }}>Prices</h3>
                <Grid container spacing={2} mb={1}>
                    {!props.CustomerPricing ? stockItems.map((og, ind) => {
                        return <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                            <ControlledTextField key={'oyc' + ind} title={og.description} state={priceList.find((pl) => { return pl.id === og.id })?.price} setState={updatePList(og.id)} disabled={props.disabled} number />
                        </Grid>
                    }) : currStockItems.map((csi, ind) => {
                        return <Grid container spacing={2} mb={1}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Typography variant={'h3'} style={{padding:'15px'} }>{csi.description}</Typography>
                            </Grid>
                            <Grid item xs={11} sm={11} md={5} lg={5} xl={5}>
                                <ControlledTextField key={'oyc' + ind} state={priceList.find((pl) => { return pl.id === csi.id })?.price} setState={updatePList(csi.id)} disabled={props.disabled} number />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                {csi.new ? <IconButton aria-label="remove" onClick={remLine(csi.id)} disabled={props.disabled}>
                                    <RemoveIcon />
                                </IconButton> : ''}
                            </Grid>
                        </Grid>
                    })}
                    {props.CustomerPricing ? <Stack direction="row" spacing={1}>
                        <SelectTextField key={'si' + props.index} title="Add Stock Item" state={newStockIt} nameFunc={(a) => a.description} list={stockItems} setState={setNewStockIt} disabled={props.disabled} />
                        <IconButton aria-label="add" onClick={addLine} disabled={props.disabled}>
                            <AddIcon />
                        </IconButton>
                        
                    </Stack> : ''}
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                            <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                        </div>
                    </Grid>
                </Grid>
            </Div>
        </Box >
    );
};


export default PriceForm;


