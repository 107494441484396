import React from 'react';
import Avatar from "@mui/material/Avatar";
//import {authUser} from "./fake-db";
import { ListItemIcon, ListItemText, ThemeProvider, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import LogoutIcon from '@mui/icons-material/Logout';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ListItemButton from "@mui/material/ListItemButton";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import { useJumboTheme } from "@jumbo/hooks";
import { useNavigate } from "react-router-dom";
import {  useMsal } from "@azure/msal-react";
import { userInfo } from '../../../../adalConfig';


const AuthUserDropdown = (props) => {
    // const mutation = useAuthSignOut(auth);
    const { instance } = useMsal();
    const [user, setUser] = React.useState(userInfo());
    const navigate = useNavigate();
    const { theme } = useJumboTheme();

    const authUser = {};
    
    const onLogout = () => {
        instance.logoutRedirect();
    }

    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={authUser.profile_pic}
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer',pointerEvents:'auto' }}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={authUser.profile_pic} alt={user ? user.profile ? user.profile.name : '' : ''} sx={{ width: 60, height: 60, mb: 2 }} />
                    <Typography variant={"h5"}>{user ? user.name : ''}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{user ? user.profile ? user.profile.name : '' : ''}</Typography>
                </Div>
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {/*<ListItemButton>*/}
                        {/*    <ListItemIcon sx={{minWidth: 36}}>*/}
                        {/*        <PersonOutlineIcon/>*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText primary="Profile" sx={{my: 0}}/>*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton>*/}
                        {/*    <ListItemIcon sx={{minWidth: 36}}>*/}
                        {/*        <EditOutlinedIcon/>*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText primary="Edit Profile" sx={{my: 0}}/>*/}
                        {/*</ListItemButton>*/}
                        {/*<ListItemButton>*/}
                        {/*    <ListItemIcon sx={{minWidth: 36}}>*/}
                        {/*        <RepeatOutlinedIcon/>*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText onClick={() => navigate("/samples/content-layout")} primary="Switch User"*/}
                        {/*                  sx={{my: 0}}/>*/}
                        {/*</ListItemButton>*/}
                        <ThemeSwitcher darkMode={props.darkMode} />

                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};


const ThemeSwitcher = (props) => {
    


    return (
        <ListItemButton onClick={() => { props.darkMode.setDarkMode(!props.darkMode.darkMode) }} >

            <ListItemIcon sx={{ minWidth: 36 }}>

                {!props.darkMode.darkMode ? <DarkModeIcon /> : <LightModeIcon />}
            </ListItemIcon>
            <ListItemText primary={!props.darkMode.darkMode ? "Toggle Dark Mode" : "Toggle Light Mode"} sx={{ my: 0 }} />
        </ListItemButton>
    )
}



export default AuthUserDropdown;
