import React from 'react';
import { dateFormat, textfieldDateFormat, generateTextDays, generateDates } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import DayToggle from "../../../components/DayToggle";
import { numberFormat } from '../../../utils/numberFunctions';


const UserRequests = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [daySelection, setDaySelection] = React.useState(2);
    const [procurement, setProcurement] = React.useState([]);

    React.useEffect(() => {
        var cols = [{
            name: 'user',
            label: 'Requested By',
            options: props.userFilter ? { filterList: [props.userFilter] } : { filterList: [] }

        },
        {
            name: 'section',
            label: 'Section',
        },
        {
            name: 'title',
            label: 'Title',
        },

        {
            name: 'description',
            label: 'Description',
        },
        {
            name: 'date',
            label: 'Date'
        },
        {
            name: 'status',
            label: 'Status'
        },
        {
            name: 'priority',
            label: 'Priority'
        },
        ]
        setColumns(cols);
    }, [props.farmerFilter, daySelection, procurement, props.userFilter])


    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: '600px',
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { })

    };
    React.useEffect(() => {
        setProcurement([]);
        getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
    }, [props.location, props.startDate, props.endDate, props.reload, props.compTasks]);

    var getProcurementData = async (location, start, end) => {
        setLoading(true);
        const response = await adalApiFetch(fetch, uri + '/Asana/MyTasks?completed=' + (props.compTasks ? true : false));
        const data = await response.json();
        console.log(JSON.parse(data))
        var tasks = [];
        try {
            tasks = JSON.parse(data).data;
        } catch {
            tasks = [];
        }

        setLoading(false);
        if (props.compTasks) {
            tasks = tasks.filter((t) => t.completed)
        }
        const taskSort = (a, b) => {
            if (a.status === b.status) {
                return new Date(a.created_at) < new Date(b.created_at) ? -1 : 1
            } else {
                return a.status > b.status ? -1 : 1
            }
        }
        setProcurement(tasks.map((t) => {
            var newT = { ...t };
            newT.status = t.completed ? "Completed" : (newT.memberships ? (newT.memberships[0] ? newT.memberships[0].section?.name : "Received") : "Received");
            newT.title = t.name;
            newT.description = t.html_notes?.replace(/<\/?[^>]+(>|$)/g, "");
            newT.date = dateFormat(t.created_at);
            newT.user = t.custom_fields?.find((cf) => cf.gid === "1203762208570865")?.text_value;
            newT.section = t.custom_fields?.find((cf) => cf.gid === "1203762208570867")?.text_value;
            var priority = (t.custom_fields?.find((cf) => cf.gid === "1202992713351314")?.enum_value?.name);
            newT.priority = priority ? priority : "None";

            return newT;
        }).sort(taskSort));
    }
    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={"User Requests"}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reloadFunc}
        />
    );
};

export default UserRequests;
