import React from 'react';

import { getAPIData, getAPIDataReturn, postApi } from "../../utils/apiFunction"
import EditableTable from '../../components/EditableTable';
import { ToggleButton } from '@mui/material';
import { column } from 'stylis';

const SettingsQA = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [staff, setStaff] = React.useState([]);
    const [onlyCurrent, setOnlyCurrent] = React.useState(true);
    const [columns, setColumns] = React.useState([{ label: "ID", key: 'id', list: [] },
    { label: "FirstName", key: 'firstname', list: [] },
    { label: "Surname", key: 'surname', list: [] },
    { label: "Is Current", key: 'isCurrent', list: [] },
    { label: "Is Shucker", key: 'isShucker', list: [] },
        { label: "Warehouse", key: 'warehouse', list: [], nameFunc: (item) => item?.description, valueFunc: (item) => item?.id }]);
    React.useEffect(() => {
        if (onlyCurrent) {
            getAPIData('/StaffMember/Get/Current', setStaff);
        } else {
            getAPIData('/StaffMember/Get', setStaff);
        }


    }, [onlyCurrent]);
    React.useEffect(() => {
        getAPIDataReturn('/Warehouses/Get').then((wh) => {
            var newCols = [...columns];
            var whList = (wh.filter((a) => { return (a.myobid === "LIVEPRODCT") || (a.myobid === "SYDNEY") || (a.myobid === "HALFSHELL") }))
            newCols[5].list = whList;
            setColumns(newCols);
        });
    }, []);
    var types = {
        firstname: "text",
        surname: "text",
        isCurrent: "boolean",
        isShucker: "boolean",
        label: "select",
        warehouse: "newselect"
    }

    const addNewStaff = async (item) => {
        console.log(item)
        var params = {
            Firstname: item.firstname,
            Surname: item.surname,
            IsCurrent: item.isCurrent,
            IsShucker: item.isShucker,
            WarehouseId: item.warehouse?.id,
        }
        var response = await postApi('/StaffMember/Add', params);
        return response;
    }

    const updateChanges = async (item) => {
        console.log(item)
        var params = {
            Firstname: item.firstname,
            Surname: item.surname,
            IsCurrent: item.isCurrent,
            IsShucker: item.isShucker,
            WarehouseId: item.warehouse?.id,
        }
        var response = await postApi('/StaffMember/Update/' + item.id, params);

        return response;

    }
    console.log(columns)
    return <EditableTable
        title={<div>{"Staff Members"} < ToggleButton color='primary' value="check" selected={onlyCurrent} onChange={() => { setOnlyCurrent(!onlyCurrent) }}>Only Current</ToggleButton></div>}
        data={staff.sort((a,b) => { return a.warehouseId-b.warehouseId}) }
        setData={setStaff}
        columns={columns}
        type={types}
        addNew={addNewStaff}
        updateChanges={updateChanges}
    />
};



export default SettingsQA;
