import React from 'react';
import { dateFormat, textfieldDateFormat, startOfMonth, endOfMonth, startOfNextMonth, shiftMonth } from "../../../utils/dateFunctions";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getAPIDataReturn, getAPIData, getAPIDataParamsReturn } from "../../../utils/apiFunction";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import { adalApiFetch,userInfo } from '../../../../adalConfig';
import { Table, TableBody, TableRow, TableCell, Avatar, TableHead } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import Typography from "@mui/material/Typography";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import TextField from "@mui/material/TextField";
import MessageIcon from '@mui/icons-material/Message';
import IconButton from "@mui/material/IconButton";
import FormModal from '../../../components/FormModal';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { numberFormatErrorToZero as numberFormat, toPercFormat } from '../../../utils/numberFunctions';
import { OrganiseMovements } from "../../../utils/oysterMateFunctions";
import { modifyTotalOysters } from '../../OysterPL8/utils/onShoreFunction';

const PUTOUT = "Deploy";
const PICKEDUP = "Pickup";
const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
const SLATS = 'Slats';
const SLATS_PER_PACK = 100;
const APPROX_OYSTER_PER_SLAT = 120;

const dozToPiece = (num, doz, alreadyDoz) => {
    var newNum = num;
    if (doz) {
        if (alreadyDoz) {
            newNum = num;
        } else {
            newNum = num / 12;

        }
    } else {
        if (alreadyDoz) {
            newNum = num * 12;
        } else {
            newNum = num;
        }
    }
    return isNaN(newNum) ? 0 : newNum.toFixed(0);
}

const PurchaseOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;

    const [user, setUser] = React.useState(userInfo());
    const [showOTR, setShowOTR] = React.useState(true);
    const [showSpatOTR, setShowSpatOTR] = React.useState(true);
    const [showOC, setShowOC] = React.useState(true);
    const [showOCM, setShowOCM] = React.useState(true);
    const [showER, setShowER] = React.useState(true);
    const [selData, setSelData] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [organiseSel, setOrganiseSel] = React.useState("By Batch");
    const [organiseSpatSel, setOrganiseSpatSel] = React.useState("By Batch");
    const [organiseTotalsSel, setOrganiseTotalsSel] = React.useState("By Year");
    const [organiseTotalsMovSel, setOrganiseTotalsMovSel] = React.useState("By Year");
    const [organiseER, setOrganiseER] = React.useState("By Year");
    const [reload, setReload] = React.useState(false);

    const [unlock, setUnlock] = React.useState(false);


    const reloadFunc = () => {
        setReload(!reload);
    }
   

    const Collapsable = (props) => {
        const handleClick = () => {
            props.setOpen(!props.open);
        }
        if (props.open) {
            return (
                <IconButton onClick={handleClick}>
                    <ExpandLessIcon />
                </IconButton>
            );

        } else {
            return (
                <IconButton onClick={handleClick}>
                    <ExpandMoreIcon />
                </IconButton>
            );
        }
    }

    var OTRtitle = (<Grid container spacing={4} mb={1}>
        <Grid item xs={12} sm={12} md={6}>
            <Typography variant={"h2"} mb={0}>{"Oyster Transfers (" + (props.inDoz ? "Doz" : "Pcs") + ")"}<Collapsable open={showOTR} setOpen={setShowOTR} /></Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <ToggleSel setSel={setOrganiseSel} sel={organiseSel} options={["By Batch", "By Grade"]} />
        </Grid>
    </Grid >)
    var OTRSpattitle = (transferOut) => (<Grid container spacing={4} mb={1}>
        <Grid item xs={12} sm={12} md={6}>
            <Typography variant={"h2"} mb={0}>{"Growout and Spat " + (transferOut ? "Transfers" : "Receivals") + " (" + (props.inDoz ? "Doz" : "Pcs") + ")"}<Collapsable open={showSpatOTR} setOpen={setShowSpatOTR} /></Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <ToggleSel setSel={setOrganiseSpatSel} sel={organiseSpatSel} options={["By Batch", "By Grade"]} />
        </Grid>
    </Grid >)
    var Totaltitle = (<Grid container spacing={4} mb={1}>
        <Grid item xs={12} sm={12} md={6}>
            <Typography variant={"h2"} mb={0}>{(props.newReport ? "Oyster Mate Totals" : "Oyster Cloud Totals In Water") + " (" + (props.inDoz ? "Doz" : "Pcs") + ")"}<Collapsable open={showOC} setOpen={setShowOC} /></Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <ToggleSel setSel={setOrganiseTotalsSel} sel={organiseTotalsSel} options={["By Year", 'By Batch', "With Grade"]} />
        </Grid>
    </Grid >)
    var Movementtitle = (<Grid container spacing={4} mb={1}>
        <Grid item xs={12} sm={12} md={6}>
            <Typography variant={"h2"} mb={0}>Oyster Cloud Movements (Units)<Collapsable open={showOCM} setOpen={setShowOCM} /></Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <ToggleSel setSel={setOrganiseTotalsMovSel} sel={organiseTotalsMovSel} options={["By Year", 'By Batch', "With Grade", "With Unit"]} />
        </Grid>
    </Grid >)
    var EquipmentTitle = (<Grid container spacing={4} mb={1}>
        <Grid item xs={12} sm={12} md={6}>
            <Typography variant={"h2"} mb={0}>Equipment Registry<Collapsable open={showER} setOpen={setShowER} /></Typography>
        </Grid>

    </Grid >)
    var sharedProps = {
        inDoz: props.inDoz,
        monthDate: props.monthDate,
        reportId: props.reportId,
        username: user?.userName,
        warehouseId: props.warehouseId,
        allFarms: props.allFarms,
        allowedFarmList: props.allowedFarmList,
    };
    return (
        <Grid container spacing={2} mb={1}>


            <Grid item xs={12} sm={12} md={12} lg={12}>

                <OTRTable {...sharedProps} YTD={props.YTD} ref={props.ref} sortBy={organiseSel} farmId={props.farmId} title={OTRtitle} show={showOTR} />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>

                <OTRSpatTable {...sharedProps} YTD={props.YTD} sortBy={organiseSpatSel} title={OTRSpattitle(true)} show={showSpatOTR} TransferOut />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>

                <OTRSpatTable {...sharedProps} YTD={props.YTD} sortBy={organiseSpatSel} title={OTRSpattitle(false)} show={showSpatOTR} />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>

                <OysterCloud newReport={props.newReport} {...sharedProps} sortBy={organiseTotalsSel} title={Totaltitle} show={showOC} />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>

                <OysterMovements newReport={props.newReport} units {...sharedProps} YTD={props.YTD} sortBy={organiseTotalsMovSel} title={<Typography variant={"h2"} mb={0}>{Movementtitle}</Typography>} show={showOCM} />

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>

                <EquipmentRegistry newReport={props.newReport} units {...sharedProps} sortBy={organiseTotalsMovSel} title={<Typography variant={"h2"} mb={0}>{EquipmentTitle}</Typography>} show={showER} />

            </Grid>
        </Grid >

    );
};

const ToggleSel = (props) => {
    return <ToggleButtonGroup
        color="primary"
        exclusive
        disabled={props.disabled}
        value={props.sel}
        onChange={(event, sel) => { props.setSel(sel) }}
        aria-label="text alignment"

    >{props.options.map((a) => {
        return <ToggleButton key={a} value={a} >
            <div style={{ fontSize: '80%' }} >{a}</div>
        </ToggleButton>
    })}

    </ToggleButtonGroup>
}

const CommentButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [comment, setComment] = React.useState(null);
    const [comments, setComments] = React.useState([]);
    const [reload, setReload] = React.useState([]);
    const uri = process.env.REACT_APP_BASE_URL;
    const handleClose = () => {
        setOpen(false);
        setCompleted(false);
        setLoading(false);
        setComment(null);
        setComments([]);
    }
    const addComment = () => {
        setOpen(true)
    }
    const submit = async () => {
        setLoading(true);
        var data = {
            ReportId: props.reportId,
            Type: props.type,
            User: props.username,
            Comment: comment
        }
        var response = await adalApiFetch(fetch, uri + '/Reports/FRM/Comments/Insert?' + new URLSearchParams({
            ...data
        }), {
            method: 'post',
        });

        setLoading(false);
        setCompleted(true)
        setReload(!reload);
    }
    var d = new Date();
    d.setDate(d.getDate() - 1);
    React.useEffect(() => {
        if (props.reportId && open) {
            getAPIData(('/Reports/FRM/Comments/GetForReport?' + new URLSearchParams({
                ReportId: props.reportId,

            })), setComments);
        }
    }, [props.reportId, open, reload])

    return <React.Fragment>
        <FormModal open={open} handleClose={handleClose} title={"Comment"} noWidth>
            <Grid container spacing={2} mb={1}>
                {Array.isArray(comments) ? comments?.filter((a) => (a.type == props.type)).sort((a, b) => { return a.date > b.date ? 1 : -1 }).map((c) => {
                    return <Grid item xs={12} >
                        <span><Typography variant={"h6"} mb={0}>{c.user + ' (' + dateFormat(c.date) + '): ' + c.comment}</Typography></span>
                    </Grid>
                }) : null}
                <br />
                <br />
                <Grid item xs={12} >
                    <TextField
                        disabled={completed}
                        id="outlined-multiline-static"
                        label="Comments"
                        value={comment}
                        onChange={(event) => { setComment(event.target.value) }}
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{ width: '100%' }}
                        rows={6} />
                </Grid>
                <Grid item xs={12} >
                    <SubmitButtonDisplayer completed={completed} loading={loading} allowed={true} submitFunc={submit} handleClose={handleClose} />
                </Grid>
            </Grid>
        </FormModal>
        <IconButton onClick={addComment}><MessageIcon /></IconButton>
    </React.Fragment>

}
const PDFButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [comment, setComment] = React.useState(null);
    const [comments, setComments] = React.useState([]);
    const [reload, setReload] = React.useState([]);
    const uri = process.env.REACT_APP_BASE_URL;
    const savePDF = () => {

        var line = 50;
        var doc = new jsPDF();
        const printPDF = (heading, array, doc) => {
            doc.autoTable({
                startY: (line),
                theme: 'striped',
                head: [heading],
                body: array,
            })
        }

        doc.setFontSize(20)
        doc.text(props.title, 75, 20);
        doc.setFontSize(10)
        printPDF(props.data?.heading, props.data?.data, doc);
        doc.save('MFMR-Report-' + new Date().toLocaleString() + '.pdf');
    }

    return <React.Fragment>

        <IconButton onClick={savePDF}><GetAppIcon /></IconButton>
    </React.Fragment>

}
const OTRTable = props => {
    const [loading, setLoading] = React.useState(false);
    const [receivals, setReceivals] = React.useState([]);

    React.useEffect(() => {
        const abortController = new AbortController();

        const handleAllFarms = async (start, end, fId, ytd, allFarm) => {
            if (allFarm) {
                var prmArr = [];
                for (var i = 0; i < props.allowedFarmList.length; i++) {
                    prmArr.push(getProcurementData(start, end, props.allowedFarmList[i].id, ytd, abortController));
                }
                Promise.all(prmArr).then((values) => {
                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                        data = data.concat(values[i]);
                    }
                    console.log(values, data);
                    setLoading(false);
                    setReceivals(data);
                });

            } else {
                setReceivals(await getProcurementData(start, end, fId, ytd, abortController));
                setLoading(false);
            }
        }
        if (props.farmId) {
            setLoading(true);
            handleAllFarms((startOfMonth(props.monthDate)), (startOfNextMonth(props.monthDate)), props.farmId, props.YTD, props.allFarms)
        }
        return () => {
            abortController.abort();
        };
    }, [props.YTD, props.location, props.monthDate, props.reload, props.farmId, props.allFarms]);



    var getProcurementData = async (startDate, endDate, farmId, ytd,abortController) => {
        var data = [];
        const returnData = async (sDate, eDate, fId) => {
            return await getAPIDataReturn(('/Receivals/Get/GetBySupplier?' + new URLSearchParams({
                supplierId: fId,
                startDate: sDate,
                endDate: eDate
            })), abortController);
        }
        if (ytd) {
            var now = new Date();
            now.setDate(15);
            var endLastMonth = new Date(now);
            var nextDate = shiftMonth(now, -1);
            while (nextDate.getMonth() !== 5) {
                console.log(nextDate);
                nextDate = shiftMonth(nextDate, -1);
            }
            data = await returnData(textfieldDateFormat(startOfMonth(nextDate)), textfieldDateFormat(startOfNextMonth(endLastMonth)), farmId);

        } else {
            data = await returnData(textfieldDateFormat(startDate), textfieldDateFormat(endDate), farmId);
        }
        console.log(data);
        return data;
    }

    var pdfTable = (dt) => {
        var newDt = [];
        var totalv = 0;
        for (var i = 0; i < dt.length; i++) {
            newDt.push([dt[i].group, ''].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))
            for (var j = 0; j < dt[i].grades.length; j++) {
                totalv = total + dt[i].grades[j].quantity;
                newDt.push([dt[i].grades[j].grade?.description, numberFormat(dt[i].grades[j].quantity)])
            }
        }
        newDt.push(['Total', totalv].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))

        return { data: newDt, heading: ['Batch', 'Total'] };
    }
    const organiseReceivalData = (dt, sel) => {
        var allRecLines = [];
        for (var i = 0; i < dt.length; i++) {
            if (dt[i]?.receivalLines) {
                for (var j = 0; j < dt[i].receivalLines.length; j++) {
                    allRecLines.push(dt[i].receivalLines[j]);
                }
            }
        }
        var redLines = [];
        if (sel === "By Batch") {
            for (var j = 0; j < allRecLines.length; j++) {
                var found = false;
                for (var i = 0; i < redLines.length; i++) {
                    if (redLines[i].group === allRecLines[j].cropYear) {
                        var foundGrade = false;
                        for (var k = 0; k < redLines[i].grades.length; k++) {
                            if (redLines[i].grades[k]?.grade?.id === allRecLines[j].stockItem?.grade?.id) {
                                redLines[i].grades[k].quantity = redLines[i].grades[k].quantity + (allRecLines[j].quantity);
                                foundGrade = true;
                            }

                        }
                        if (!foundGrade) {
                            redLines[i].grades.push({ grade: allRecLines[j].stockItem?.grade, quantity: allRecLines[j].quantity });
                        }
                        found = true;
                    }
                }
                if (!found) {
                    redLines.push({ group: allRecLines[j].cropYear, grades: [{ grade: allRecLines[j].stockItem?.grade, quantity: allRecLines[j].quantity }] });
                }
            }
        } else {
            redLines.push({ group: "Grades", grades: [] });
            for (var j = 0; j < allRecLines.length; j++) {
                var found = false;
                for (var i = 0; i < redLines[0].grades.length; i++) {
                    if (redLines[0].grades[i].grade?.id === allRecLines[j].stockItem?.grade?.id) {
                        redLines[0].grades[i].quantity = redLines[0].grades[i].quantity + (allRecLines[j].quantity);
                        found = true;
                    }

                }
                if (!found) {
                    redLines[0].grades.push({ grade: allRecLines[j].stockItem?.grade, quantity: allRecLines[j].quantity });
                }
            }
        }
        for (var i = 0; i < redLines.length; i++) {
            if (redLines[i].grades) {
                redLines[i].grades.sort((a, b) => {
                    const gradeRank = (grade) => {
                        if (grade === 'Cocktail') {
                            return 0;
                        } else if (grade === 'Bottle') {
                            return 1;
                        } else if (grade === 'Bistro') {
                            return 2;
                        } else if (grade === 'Plate') {
                            return 3;
                        } else {
                            return 10;
                        }
                    }

                    return gradeRank(a.grade?.description) > gradeRank(b.grade?.description) ? 1 : -1
                })
            }
        }
        var sortLines = redLines.sort((a, b) => {
            var aYear = a.group ? a.group.match(/[0-9][0-9][0-9][0-9]/) : null;
            var bYear = b.group ? b.group.match(/[0-9][0-9][0-9][0-9]/) : null;
            if (aYear && bYear) {
                if (Number(aYear[0]) > Number(bYear[0])) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (aYear) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });

        return sortLines;
    }

    var total = 0;
    var organisedData = organiseReceivalData(receivals, props.sortBy);
    organisedData?.map((d) => {
        d.grades.map((g) => {
            total = total + g.quantity;
        })
    });
    console.log(loading)
    return (
        <JumboCardQuick
            title={<span>{props.title}<div style={{ position: 'absolute', top: '2%', right: '1%' }}><CommentButton {...props} type={0} /><PDFButton title="OTR Receivals" data={pdfTable(organisedData)} /></div></span>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >

            {props.show ? (loading ? <CircularProgress /> : <TableContainer>
                <Table>{organisedData?.map((d) => {
                    return <React.Fragment>
                        <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.group) }} >
                            <TableCell sx={{ pl: 3 }}>
                                <Typography variant={"h4"} mb={0}>{d.group}</Typography>
                            </TableCell>
                            <TableCell sx={{ pl: 3 }}>
                            </TableCell>
                            <TableCell sx={{ pl: 3 }}>
                            </TableCell>
                        </TableRow>
                        {d.grades.map((g) => {
                            return <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.group) }} >
                                <TableCell>
                                    <Typography variant={"h6"} mb={0}>{g.grade?.description}</Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', }}>
                                    <Typography variant={"h6"} mb={0}>{numberFormat(dozToPiece(g.quantity, props.inDoz, true))}</Typography>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right', }}>
                                    <Typography variant={"h6"} mb={0}>{toPercFormat(g.quantity / total)}</Typography>
                                </TableCell>
                            </TableRow>

                        })}</React.Fragment>
                })}
                    <TableRow hover style={{ zIndex: 1000, }} >
                        <TableCell>
                            <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{numberFormat(dozToPiece(total, props.inDoz, true))}</Typography>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>) : ''}
        </JumboCardQuick>
    );
};

const OTRSpatTable = props => {
    const [data, setData] = React.useState(null);
    const [transfers, setTransfers] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    var pdfTable = (dt) => {
        var newDt = [];
        var totalv = 0;
        if (dt) {
            for (var i = 0; i < dt.length; i++) {
                newDt.push([dt[i].group, ''].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))
                for (var j = 0; j < dt[i].grades.length; j++) {
                    totalv = total + dt[i].grades[j].quantity;
                    newDt.push([dt[i].grades[j].grade?.description, numberFormat(dt[i].grades[j].quantity)])
                }
            }
        }
        newDt.push(['Total', totalv].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))

        return { data: newDt, heading: ['Batch', 'Total'] };
    }

    var total = 0;
    data?.map((d) => {
        d.grades.map((g) => {
            total = total + g.quantity;
        })
    });
    React.useEffect(() => {
        const handleAllFarms = async (start, end, wId, allFarm, ytd) => {
            if (allFarm) {
                var prmArr = [];
                for (var i = 0; i < props.allowedFarmList.length; i++) {
                    prmArr.push(getProcurementData(start, end, props.allowedFarmList[i].warehouseId, ytd));
                }
                Promise.all(prmArr).then((values) => {
                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                        data = data.concat(values[i]);
                    }
                    setLoading(false);
                    setTransfers(data);
                });

            } else {
                setTransfers(await getProcurementData(start, end, wId, ytd));
                setLoading(false);
            }
        }
        if (props.warehouseId) {
            setLoading(true);
            handleAllFarms((startOfMonth(props.monthDate)), (startOfNextMonth(props.monthDate)), props.warehouseId, props.allFarms, props.YTD)
        }
    }, [props.monthDate, props.reload, props.warehouseId, props.allFarms, props.YTD]);

    var getProcurementData = async (startDate, endDate, id, ytd) => {
        var promArr = [];


        if (ytd) {
            var now = new Date();
            now.setDate(15);
            var endLastMonth = new Date(now);
            var nextDate = shiftMonth(now, -1);
            while (nextDate.getMonth() !== 5) {
                console.log(nextDate);
                nextDate = shiftMonth(nextDate, -1);
            }
            startDate = startOfMonth(nextDate);
            endDate = startOfNextMonth(endLastMonth);
        }
        console.log(startDate, endDate, ytd)
        return await getAPIDataReturn(('/Transfers/Get?' + new URLSearchParams({
            WarehouseId: id,
            EndDate: textfieldDateFormat(endDate),
            StartDate: textfieldDateFormat(startDate),
            Source: !!props.TransferOut
        })));
    }
    React.useEffect(() => {
        if (transfers) {
            organiseData(transfers)
        }
    }, [transfers, props.sortBy]);
    const organiseData = (dt) => {
        dt = dt.filter((d) => { return d.destination.isFarm })
        var alltranLines = [];
        for (var i = 0; i < dt.length; i++) {
            if (dt[i]?.coreTransferLines) {
                for (var j = 0; j < dt[i].coreTransferLines.length; j++) {
                    alltranLines.push(dt[i].coreTransferLines[j]);
                }
            }
        }
        var redLines = [];
        if (props.sortBy === "By Batch") {
            for (var j = 0; j < alltranLines.length; j++) {
                var found = false;
                for (var i = 0; i < redLines.length; i++) {
                    if (redLines[i].group === alltranLines[j].group?.cropLabel) {
                        var foundGrade = false;
                        for (var k = 0; k < redLines[i].grades.length; k++) {
                            if (redLines[i].grades[k]?.grade?.id === alltranLines[j].group?.grade?.id) {
                                redLines[i].grades[k].quantity = redLines[i].grades[k].quantity + (alltranLines[j].group?.totalUnits * alltranLines[j].group?.average);
                                foundGrade = true;
                            }

                        }
                        if (!foundGrade) {
                            redLines[i].grades.push({ grade: alltranLines[j].group?.grade, quantity: (alltranLines[j].group?.totalUnits * alltranLines[j].group?.average) });
                        }
                        found = true;
                    }
                }
                if (!found) {
                    redLines.push({ group: alltranLines[j].group?.cropLabel, grades: [{ grade: alltranLines[j].group?.grade, quantity: (alltranLines[j].group?.totalUnits * alltranLines[j].group?.average) }] });
                }
            }
        } else {
            redLines.push({ group: "Grades", grades: [] });
            for (var j = 0; j < alltranLines.length; j++) {
                var found = false;
                for (var i = 0; i < redLines[0].grades.length; i++) {
                    if (redLines[0].grades[i].grade?.id === alltranLines[j].group?.grade?.id) {
                        redLines[0].grades[i].quantity = redLines[0].grades[i].quantity + (alltranLines[j].group?.totalUnits * alltranLines[j].group?.average)
                        found = true;
                    }

                }
                if (!found) {
                    redLines[0].grades.push({ grade: alltranLines[j].group?.grade, quantity: (alltranLines[j].group?.totalUnits * alltranLines[j].group?.average) });
                }
            }
        }
        for (var i = 0; i < redLines.length; i++) {
            if (redLines[i].grades) {
                redLines[i].grades.sort((a, b) => {
                    const gradeRank = (grade) => {
                        if (grade === 'Cocktail') {
                            return 0;
                        } else if (grade === 'Bottle') {
                            return 1;
                        } else if (grade === 'Bistro') {
                            return 2;
                        } else if (grade === 'Plate') {
                            return 3;
                        } else {
                            return 10;
                        }
                    }

                    return gradeRank(a.grade?.description) > gradeRank(b.grade?.description) ? 1 : -1
                })
            }
        }
        var sortLines = redLines.sort((a, b) => {
            var aYear = a.group ? a.group.match(/[0-9][0-9][0-9][0-9]/) : null;
            var bYear = b.group ? b.group.match(/[0-9][0-9][0-9][0-9]/) : null;
            if (aYear && bYear) {
                if (Number(aYear[0]) > Number(bYear[0])) {
                    return 1;
                } else {
                    return -1;
                }
            } else {
                if (aYear) {
                    return 1;
                } else {
                    return -1;
                }
            }
        });
        console.log(sortLines);
        setData(sortLines);
    }
    if (data && (data.length > 0) && (data[0].grades.length > 0)) {
        return (
            <JumboCardQuick
                title={<span>{props.title}<div style={{ position: 'absolute', top: '2%', right: '1%' }}><CommentButton {...props} type={0} /><PDFButton title="OTR Receivals" data={pdfTable(data)} /></div></span>}
                wrapperSx={{
                    p: 0,
                    backgroundColor: 'background.paper',
                    zIndex: 0,
                    '&:last-child': {
                        pb: 0
                    }
                }}
            >

                {props.show ? (loading ? <CircularProgress /> : <TableContainer>
                    <Table>{data?.map((d) => {
                        return <React.Fragment>
                            <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.group) }} >
                                <TableCell sx={{ pl: 3 }}>
                                    <Typography variant={"h4"} mb={0}>{d.group}</Typography>
                                </TableCell>
                                <TableCell sx={{ pl: 3 }}>
                                </TableCell>
                                <TableCell sx={{ pl: 3 }}>
                                </TableCell>
                            </TableRow>
                            {d.grades.map((g) => {
                                return <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.group) }} >
                                    <TableCell>
                                        <Typography variant={"h6"} mb={0}>{g.grade?.description}</Typography>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right', }}>
                                        <Typography variant={"h6"} mb={0}>{numberFormat(dozToPiece(g.quantity, props.inDoz, false))}</Typography>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right', }}>
                                        <Typography variant={"h6"} mb={0}>{toPercFormat(g.quantity / total)}</Typography>
                                    </TableCell>
                                </TableRow>

                            })}</React.Fragment>
                    })}
                        <TableRow hover style={{ zIndex: 1000, }} >
                            <TableCell>
                                <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{numberFormat(dozToPiece(total, props.inDoz, false))}</Typography>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableContainer>) : ''}
            </JumboCardQuick>
        );
    } else {
        return null;
    }
};

const dateColSet = (batchString) => {
    var opacity = 0.6
    var col = ["rgba(183, 207, 183," + opacity + ")", "rgba(199, 219, 218," + opacity + ")", "rgba(253, 215, 194," + opacity + ")", "rgba(246, 234, 194," + opacity + ")", "rgba(181, 234, 214," + opacity + ")", "rgba(154, 183, 211," + opacity + ")", "rgba(255, 225, 233," + opacity + ")"]
    var yr = Number(batchString.match(/[0-9][0-9][0-9][0-9]/));
    if (yr === 0) {
        return 'white';
    }
    return col[yr % 7];
}
const OysterCloud = props => {
    const [tableData, setTableData] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const [expand, setExpand] = React.useState(false);
    const [startData, setStartData] = React.useState(false);
    const [endData, setEndData] = React.useState(false);


    React.useEffect(() => {
        const abortController = new AbortController();

        const handleAllFarms = async (start, end, wId, allFarm, ytd) => {
            if (allFarm) {
                var prmArr = [];
                for (var i = 0; i < props.allowedFarmList.length; i++) {
                    prmArr.push(getProcurementData(start, end, props.allowedFarmList[i].warehouseId, ytd,abortController));
                }
                Promise.all(prmArr).then((values) => {
                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                        data = data.concat(values[i]);
                    }
                    setEndData(data.sort((a, b) => { return a.group1 > b.group1 ? 1 : -1 }));
                    setLoading(false);
                });

            } else {
                setEndData(await getProcurementData(start, end, wId, ytd, abortController));
                setLoading(false);
            }
        }
        if (props.warehouseId) {
            setStartData([]);
            setEndData([]);
            setLoading(true);
            handleAllFarms((startOfMonth(props.monthDate)), (endOfMonth(props.monthDate)), props.warehouseId, props.allFarms, props.YTD)
        }
        return () => {
            abortController.abort();
        };
    }, [props.monthDate, props.reload, props.warehouseId, props.allFarms, props.newReport]);
    //React.useEffect(() => {
    //    if (props.warehouseId) {

    //        getProcurementData(textfieldDateFormat(startOfMonth(props.monthDate)), textfieldDateFormat(endOfMonth(props.monthDate)), props.warehouseId)//props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
    //    }
    //}, [props.warehouseId, props.monthDate, props.newReport]);
    React.useEffect(() => {
        console.log(startData);
        console.log(endData);
        if (startData && endData) {
            setTableData(organiseData(startData, endData));
        }
    }, [props.sortBy, startData, endData]);
    var showGrade = props.sortBy === "With Grade";
    const organiseData = (longSt, data) => {


        var newData = [];
        for (var i = 0; i < data.length; i++) {
            var foundInd = newData.findIndex((nd) => { return (nd.group1 === data[i].cropLabel) && (nd.group2 === data[i].grade?.description) });
            var tu = data[i].totalUnits;
            var av = data[i].average;
            if (data[i].children && data[i].children.length > 0) {
                for (var k = 0; k < data[i].children.length; k++) {
                    var child = data[i].children[k];
                    var newVal = modifyTotalOysters({ totalUnits: tu, average: av }, { totalUnits: child.totalUnits, average: child.average });
                    tu = (newVal.totalUnits).toFixed(0);
                    av = newVal.average;
                }
            }
            var tO = tu * av;

            if (foundInd >= 0) {
                newData[foundInd].units = newData[foundInd].units + tu;
                newData[foundInd].count = newData[foundInd].count + tO;
            } else {
                newData.push({
                    group1: data[i].cropLabel,
                    group2: data[i].grade?.description,
                    count: tO,
                    units: tu
                });
            }
        }
        newData.sort((a, b) => { return a.group1 > b.group1 ? 1 : -1 });
        var st = longSt.map((s) => {
            return {
                group1: s.group1,
                group2: s.group2,
                count: s.count,
                units: s.units
            }
        });
        var ed = newData.map((s) => {
            return {
                group1: s.group1,
                group2: s.group2,
                count: s.count,
                units: s.units
            }
        });
        if (props.sortBy === 'By Year') {
            const reduceOptions = (list) => {
                var newList = [];
                for (var i = 0; i < list.length; i++) {
                    var existsInd = newList.findIndex((nl) => { return ((nl.group1 === list[i].group1) && (nl.group2 === list[i].group2)) });
                    if (existsInd >= 0) {
                        newList[existsInd].count = newList[existsInd].count + list[i].count;
                        newList[existsInd].units = newList[existsInd].units + list[i].units;
                    } else {
                        newList.push(list[i]);
                    }

                }
                return newList;
            }
            st = reduceOptions(st.map((s) => {
                var nS = { ...s };
                nS.group1 = nS.group1.substring(0, 4);
                return nS;
            }));
            ed = reduceOptions(ed.map((s) => {
                var nS = { ...s };
                nS.group1 = nS.group1.substring(0, 4);
                return nS;

            }));

        }
        var comboArr = [...st];
        for (var i = 0; i < st.length; i++) {
            for (var j = 0; j < ed.length; j++) {
                if ((ed[j].group1 === st[i].group1) && (ed[j].group2 === st[i].group2)) {
                    comboArr[i].end = ed[j].count;
                    comboArr[i].endUnits = ed[j].units;
                }
            }
        }
        for (var j = 0; j < ed.length; j++) {
            var found = false;
            for (var i = 0; i < comboArr.length; i++) {
                if ((ed[j].group1 == comboArr[i].group1) && (ed[j].group2 === comboArr[i].group2)) {
                    found = true;
                }
            }
            if (!found) {
                comboArr.push({ group1: ed[j].group1, group2: ed[j].group2, end: ed[j].count, endUnits: ed[j].units });
            }
        }
        var newArr = [];
        for (var i = 0; i < comboArr.length; i++) {
            var foundInd = newArr.findIndex((na) => {
                return na.batch === comboArr[i].group1;
            })
            var StartCount = comboArr[i].count;
            var EndCount = comboArr[i].end;
            if (comboArr[i].group2 === SLATS) {
                StartCount = comboArr[i].units * SLATS_PER_PACK * APPROX_OYSTER_PER_SLAT;
                EndCount = comboArr[i].endUnits * SLATS_PER_PACK * APPROX_OYSTER_PER_SLAT;
            }
            if (foundInd >= 0) {
                newArr[foundInd].grade.push({ description: comboArr[i].group2, start: StartCount, startUnits: comboArr[i].units, end: EndCount, endUnits: comboArr[i].endUnits });
            } else {
                newArr.push({ batch: comboArr[i].group1, grade: [{ description: comboArr[i].group2, start: StartCount, startUnits: comboArr[i].units, end: EndCount, endUnits: comboArr[i].endUnits }] },)
            }
        }
        var finalArr = newArr.map((na) => {
            var tStart = na.grade.reduce((prev, curr) => { return prev + (isNaN(curr.start) ? 0 : curr.start) }, 0)
            var tEnd = na.grade.reduce((prev, curr) => { return prev + (isNaN(curr.end) ? 0 : curr.end) }, 0)
            var tStartu = na.grade.reduce((prev, curr) => { return prev + isNaN((curr.startUnits) ? 0 : curr.startUnits) }, 0)
            var tEndu = na.grade.reduce((prev, curr) => { return prev + isNaN((curr.endUnits) ? 0 : curr.startUnits) }, 0)
            return {
                ...na,
                totalStart: tStart ? tStart : 0,
                totalEnd: tEnd ? tEnd : 0,
                totalStartu: tStartu ? tStartu : 0,
                totalEndu: tEndu ? tEndu : 0
            }
        })
        return finalArr;
    }
    var getProcurementData = async (startDate, endDate, id,ytd, abortController) => {
        var promArr = [];
        const convertData = (data) => {
            //loop through data and total count and units from duplicate croplabels
            return data;

        }
        return convertData(await getAPIDataParamsReturn('/Farms/Groups', {
            WarehouseId: id,
        }, abortController).then(async (data) => {
            return await getAPIDataParamsReturn('/Farms/Groups/OnShore', { //geting onshore so can see children to remove from total
                WarehouseId: id,
            }, abortController).then((onShore) => {
                for (var i = 0; i < onShore.length; i++) {
                    var found = data.findIndex((os) => { return os.id === onShore[i].id });
                    if (found >= 0) {
                        data[found] = { ...onShore[i] };
                    }
                }
                console.log(data);
                return data;
                //convertData(setEndData, data)
                //setStartData([]);
                //setLoading(false);
            })
        }));




    }

    const APPROX_UNICODE = "\u2245";
    const PrintCol = (props) => {
        return <React.Fragment>
            {props.data?.map((d) => {
                var vari = showGrade ? 'h6' : 'h6';
                var fw = showGrade ? 'bold' : 'normal';
                var approx = d.grade?.find((g) => { return g.description === SLATS });
                return <React.Fragment>
                    <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.batch) }} >
                        <TableCell sx={{ pl: 3 }}>
                            <Typography style={{ fontWeight: fw }} variant={vari} mb={0}>{((d.batch && (d.batch.trim() !== '')) ? d.batch : 'Unknown')}</Typography>
                        </TableCell>

                        <TableCell sx={{ pl: 3 }} style={{ textAlign: 'right' }} >
                            <Typography style={{ fontWeight: fw }} variant={vari} mb={0}>{(approx ? APPROX_UNICODE : '') + numberFormat(dozToPiece(d.totalEnd, props.inDoz, false))}</Typography>
                        </TableCell>

                    </TableRow>
                    {showGrade ? d.grade.map((g) => {
                        total = total + g.quantity;
                        return <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.batch) }} >
                            <TableCell >
                                <Typography style={{ paddingLeft: '30px', }} variant={"h6"} mb={0}>{g.description + (g.description === SLATS ? ' (' + (APPROX_OYSTER_PER_SLAT / 12) + ' Doz/Slat)' : '')}</Typography>
                            </TableCell>

                            <TableCell style={{ textAlign: 'right', }}>
                                <Typography variant={"h6"} mb={0}>{(g.description === SLATS ? APPROX_UNICODE : '') + numberFormat(dozToPiece(g.end, props.inDoz, false))}</Typography>
                            </TableCell>

                        </TableRow>

                    }) : null}</React.Fragment>
            })
            }</React.Fragment>
    }
    var total = 0;
    const calcTotal = (a, b) => {

    }
    var pdfTable = (d) => {
        var newDt = [];
        var totals = 0;
        var totale = 0;
        var totalse = 0;
        for (var i = 0; i < d.length; i++) {
            newDt.push([d[i].batch, '', '', ''].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))
            for (var j = 0; j < d[i].grade.length; j++) {
                var g = d[i].grade[j];
                totals = totals + g.start;
                totale = totale + g.end;
                totalse = totalse + (g.start ? g.start : 0) - (g.end ? g.end : 0);
                newDt.push([g.description, numberFormat(g.start), numberFormat(g.end), numberFormat((g.start ? g.start : 0) - (g.end ? g.end : 0))])
            }
        }
        newDt.push(['Total', numberFormat(totals), numberFormat(totale), numberFormat(totalse)].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))

        return { data: newDt, heading: ['', 'Last Month', 'This Month', 'Difference'] };
    }
    return (
        <JumboCardQuick
            title={<span>{props.title}<div style={{ position: 'absolute', top: '2%', right: '1%' }}><CommentButton {...props} type={1} /><PDFButton data={pdfTable(tableData ? tableData : [])} title={"Oyster Cloud Totals"} /></div></span>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >
            {props.show ? (loading ? <CircularProgress /> : <TableContainer>
                <Table>
                    <TableHead style={{ zIndex: 1000, }} >
                        <TableCell sx={{ pl: 3 }}>
                        </TableCell>
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                            Quantity
                        </TableCell>

                    </TableHead>
                    <PrintCol inDoz={props.inDoz} data={tableData} />
                    <TableRow hover style={{ zIndex: 1000, }} >
                        <TableCell>
                            <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                        </TableCell>

                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{numberFormat(dozToPiece(tableData?.reduce((prev, curr) => { return prev + curr.totalEnd }, 0), props.inDoz, false))}</Typography>
                        </TableCell>

                    </TableRow>
                </Table>
            </TableContainer>) : ''}
        </JumboCardQuick>
    );
};
const OysterMovements = props => {
    const [recData, setRecData] = React.useState(false);
    const [tableData, setTableData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    var showUnit = props.sortBy === "With Unit";
    var showGrade = (showUnit || (props.sortBy === "With Grade"));

    React.useEffect(() => {
        const abortController = new AbortController();
        const handleAllFarms = async (start, end, wId, allFarm, ytd) => {
            if (allFarm) {
                var prmArr = [];
                for (var i = 0; i < props.allowedFarmList.length; i++) {
                    prmArr.push(getProcurementData(start, end, props.allowedFarmList[i].warehouseId, ytd,abortController));
                }
                Promise.all(prmArr).then((values) => {
                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                        data = data.concat(values[i]);
                    }
                    setRecData(OrganiseMovements(data, start, end));
                    setLoading(false);
                });

            } else {
                setRecData(OrganiseMovements(await getProcurementData(start, end, wId, ytd, abortController), start, end));
                setLoading(false);
            }
        }
        if (props.warehouseId) {
            setLoading(true);
            handleAllFarms((startOfMonth(props.monthDate)), (endOfMonth(props.monthDate)), props.warehouseId, props.allFarms, props.YTD)
        }
        return () => {
            abortController.abort();
        };
    }, [props.monthDate, props.reload, props.warehouseId, props.allFarms, props.YTD]);
    //React.useEffect(() => {
    //    if (props.warehouseId) {
    //        getProcurementData((startOfMonth(props.monthDate)), (endOfMonth(props.monthDate)), props.warehouseId)//props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
    //    }
    //}, [props.warehouseId, props.monthDate]);
    React.useEffect(() => {
        if (recData) {
            setTableData(organiseData(recData));
        }
    }, [props.sortBy, recData]);
    const organiseData = (data) => {

        var dt = data.map((s) => {
            return {
                group1: s.crop,
                group2: s.grade,
                movement: s.type,
                count: s.total * s.average,
                units: s.total,
                unitType: s.unitType
            }
        });
        const reduceOptions = (list) => {
            var newList = [];
            for (var i = 0; i < list.length; i++) {
                var existsInd = newList.findIndex((nl) => { return ((nl.group1 === list[i].group1) && (nl.group2 === list[i].group2) && (nl.type === list[i].type) && (nl.movement === list[i].movement)) });
                if (existsInd >= 0) {
                    newList[existsInd].count = newList[existsInd].count + list[i].count;
                    newList[existsInd].units = newList[existsInd].units + list[i].units;
                } else {
                    newList.push(list[i]);
                }

            }
            return newList;
        }
        var redDT = null;
        if (props.sortBy === 'By Year') {
            redDT = reduceOptions(dt.map((s) => {
                var nS = { ...s };
                nS.group1 = nS.group1.substring(0, 4);
                return nS;
            }));

        } else {
            redDT = reduceOptions(dt);
        }

        var newArr = [];
        const attachDetail = (detList, obj) => {
            var PU = obj.movement === PICKEDUP;
            var foundGrade = detList.findIndex((na) => {
                return na.description === obj.group2;
            })
            if (foundGrade >= 0) {
                var foundUnit = detList[foundGrade].unitType.findIndex((na) => {
                    return na.description === obj.unitType;
                })
                if (foundUnit >= 0) {
                    if (PU) {

                        detList[foundGrade].unitType[foundUnit].PUcount = obj.count;
                        detList[foundGrade].unitType[foundUnit].PUunits = obj.units;
                    } else {
                        detList[foundGrade].unitType[foundUnit].POcount = obj.count;
                        detList[foundGrade].unitType[foundUnit].POunits = obj.units;
                    }
                } else {
                    detList[foundGrade].unitType.push({
                        description: obj.unitType,
                        POcount: PU ? 0 : obj.count,
                        POunits: PU ? 0 : obj.units,
                        PUcount: PU ? obj.count : 0,
                        PUunits: PU ? obj.units : 0
                    })
                }
            } else {
                detList.push(
                    {
                        description: obj.group2,
                        unitType: [
                            {
                                description: obj.unitType,
                                POcount: PU ? 0 : obj.count,
                                POunits: PU ? 0 : obj.units,
                                PUcount: PU ? obj.count : 0,
                                PUunits: PU ? obj.units : 0
                            }
                        ]
                    }
                )
            }
            return detList;
        }
        for (var i = 0; i < redDT.length; i++) {
            var foundInd = newArr.findIndex((na) => {
                return na.batch === redDT[i].group1;
            })
            if ((foundInd >= 0)) {
                newArr[foundInd].grades = attachDetail(JSON.parse(JSON.stringify(newArr[foundInd].grades)), redDT[i]);
            } else {
                var PU = redDT[i].movement === PICKEDUP;

                var newEnt =
                    [
                        {
                            description: redDT[i].group2,
                            unitType: [
                                {
                                    description: redDT[i].unitType,
                                    POcount: PU ? 0 : redDT[i].count,
                                    POunits: PU ? 0 : redDT[i].units,
                                    PUcount: PU ? redDT[i].count : 0,
                                    PUunits: PU ? redDT[i].units : 0
                                }
                            ]
                        }
                    ];
                newArr.push({ batch: redDT[i].group1, grades: newEnt, pickup: [] })

            }
        }
        var finalArr = newArr.map((na) => {
            var tPO = na.grades.reduce((prev, curr) => { return prev + curr.unitType.reduce((p, c) => { return p + c.POcount }, 0) }, 0)
            var tPOUnits = na.grades.reduce((prev, curr) => { return prev + curr.unitType.reduce((p, c) => { return p + c.POunits }, 0) }, 0)
            var tPU = na.grades.reduce((prev, curr) => { return prev + curr.unitType.reduce((p, c) => { return p + c.PUcount }, 0) }, 0)
            var tPUUnits = na.grades.reduce((prev, curr) => { return prev + curr.unitType.reduce((p, c) => { return p + c.PUunits }, 0) }, 0)
            return {
                ...na,
                grades: na.grades.map((it) => {
                    var gradePO = it.unitType.reduce((p, c) => { return p + c.POcount }, 0)
                    var gradePOu = it.unitType.reduce((p, c) => { return p + c.POunits }, 0)
                    var gradePU = it.unitType.reduce((p, c) => { return p + c.PUcount }, 0)
                    var gradePUu = it.unitType.reduce((p, c) => { return p + c.PUunits }, 0)
                    return {
                        ...it,
                        gradePO,
                        gradePOu,
                        gradePU,
                        gradePUu
                    }
                }),
                totalPO: tPO ? tPO : 0,
                totalPOu: tPOUnits ? tPOUnits : 0,
                totalPU: tPU ? tPU : 0,
                totalPUu: tPUUnits ? tPUUnits : 0
            }
        })
        return finalArr;
    }
    var getProcurementData = async (startDate, endDate, id, ytd,abortController) => {
        if (ytd) {
            var now = new Date();
            now.setDate(15);
            var endLastMonth = new Date(now);
            var nextDate = shiftMonth(now, -1);
            while (nextDate.getMonth() !== 5) {
                console.log(nextDate);
                nextDate = shiftMonth(nextDate, -1);
            }
            startDate = startOfMonth(nextDate);
            endDate = endOfMonth(endLastMonth);
        } else {
        }
        const data = await getAPIDataParamsReturn('/Farms/Groups/LocationHistory', { WarehouseId: id, StartDate: textfieldDateFormat(startDate), EndDate: textfieldDateFormat(endDate) }, abortController);
        return (data);

    }
    var pdfTable = (d) => {
        var newDt = [];
        var totalpo = 0;
        var totalpu = 0;
        if (d) {
            for (var i = 0; i < d.length; i++) {
                newDt.push([d[i].batch, '', ''].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))
                for (var j = 0; j < d[i].grades.length; j++) {
                    var g = d[i].grades[j];
                    totalpo = totalpo + g.gradePUu;
                    totalpu = totalpu + g.gradePOu;
                    newDt.push([(g.description + ' (' + g.unitType[0]?.description + ')'), numberFormat(g.gradePUu), numberFormat(g.gradePOu)])
                }
            }
        }
        newDt.push(['Total', numberFormat(totalpo), numberFormat(totalpu)].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))

        return { data: newDt, heading: ['(In Units)', 'Picked Up', 'Deployed'] };
    }
    var totalPU = 0;
    var totalPO = 0;
    return (
        <JumboCardQuick
            title={<span>{props.title}<div style={{ position: 'absolute', top: '2%', right: '1%' }}><CommentButton {...props} type={2} /><PDFButton title="Oyster Cloud Movements" data={pdfTable(tableData)} /></div></span>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >
            {props.show ? (loading ? <CircularProgress /> : <TableContainer>
                <Table>
                    <TableHead style={{ zIndex: 1000, }} >
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                        </TableCell>
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                            Picked Up
                        </TableCell>
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                            Deployed
                        </TableCell>

                    </TableHead>
                    {tableData?.sort((a, b) => a.batch > b.batch ? 1 : -1).map((d) => {
                        var fw = showGrade ? 'bold' : 'normal';
                        totalPU = totalPU + (props.units ? d.totalPUu : d.totalPU);
                        totalPO = totalPO + (props.units ? d.totalPOu : d.totalPO);

                        return <React.Fragment>
                            <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.batch) }} >
                                <TableCell sx={{ pl: 3 }}>
                                    <Typography variant={"h6"} sx={{ fontWeight: fw }} mb={0}>{d.batch}</Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right', pl: 3 }}>
                                    <Typography variant={"h6"} sx={{ fontWeight: fw }} mb={0}>{(props.units ? d.totalPUu : d.totalPU)}</Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right', pl: 3 }}>
                                    <Typography variant={"h6"} sx={{ fontWeight: fw }} mb={0}>{(props.units ? d.totalPOu : d.totalPO)}</Typography>
                                </TableCell>

                            </TableRow>
                            {showGrade ? d.grades?.map((g) => {
                                var unitInline = true;
                                if (showUnit) {
                                    if (g.unitType.length > 1) {
                                        unitInline = false;
                                    }
                                } else {
                                    unitInline = false;
                                }
                                return <React.Fragment><TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.batch) }} >
                                    <TableCell>
                                        <Typography variant={"h6"} style={{ paddingLeft: '30px', }} mb={0}>{g.description + (unitInline ? (' (' + g.unitType[0]?.description) + ')' : '')}</Typography>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right', }}>
                                        {(!showUnit || unitInline) ? <Typography variant={"h6"} mb={0}>{numberFormat(props.units ? g.gradePUu : g.gradePU)}</Typography> : null}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right', }}>
                                        {(!showUnit || unitInline) ? <Typography variant={"h6"} mb={0}>{numberFormat(props.units ? g.gradePOu : g.gradePO)}</Typography> : null}
                                    </TableCell>

                                </TableRow>
                                    {(!unitInline && showUnit) ? g.unitType?.map((u) => {
                                        //total = total + g.grades;
                                        return <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.batch) }} >
                                            <TableCell>
                                                <Typography variant={"body2"} style={{ paddingLeft: '60px', fontSize: '90%' }} mb={0}>{'- ' + u.description}</Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right', }}>
                                                <Typography variant={"body2"} mb={0}>{numberFormat(props.units ? u.PUunits : u.PUcount)}</Typography>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'right', }}>
                                                <Typography variant={"body2"} mb={0}>{numberFormat(props.units ? u.POunits : u.POcount)}</Typography>
                                            </TableCell>

                                        </TableRow>

                                    }) : null
                                    }</React.Fragment>
                            }) : null}

                        </React.Fragment>
                    })}
                    <TableRow hover style={{ zIndex: 1000, }} >
                        <TableCell>
                            <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{numberFormat(totalPU)}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{numberFormat(totalPO)}</Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>) : ''}
        </JumboCardQuick>
    );
};
const EquipmentRegistry = props => {
    const [tableData, setTableData] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const [expand, setExpand] = React.useState(false);


    React.useEffect(() => {
        const abortController = new AbortController();
        const handleAllFarms = async (start, end, wId, allFarm) => {
            if (allFarm) {
                var prmArr = [];
                for (var i = 0; i < props.allowedFarmList.length; i++) {
                    prmArr.push(getProcurementData(start, end, props.allowedFarmList[i].warehouseId, abortController));
                }
                Promise.all(prmArr).then((values) => {
                    var data = [];
                    for (var i = 0; i < values.length; i++) {
                        data = data.concat(values[i]);
                    }
                    setTableData(data.sort((a, b) => { return a.group1 > b.group1 ? 1 : -1 }));
                    setLoading(false);
                });

            } else {
                setTableData(await getProcurementData(start, end, wId, abortController));
                setLoading(false);
            }
        }
        if (props.warehouseId) {
            setLoading(true);
            handleAllFarms((startOfMonth(props.monthDate)), (endOfMonth(props.monthDate)), props.warehouseId, props.allFarms)
        }
        return () => {
            abortController.abort();
        };
    }, [props.monthDate, props.reload, props.warehouseId, props.allFarms, props.newReport]);




    var getProcurementData = async (startDate, endDate, id, abortController) => {
        const convertData = (data) => {
            //loop through data and total count and units from duplicate croplabels
            var newData = [];
            for (var i = 0; i < data.length; i++) {
                var foundInd = newData.findIndex((nd) => { return (nd.group1 === data[i].unitType?.description) });
                if (foundInd >= 0) {
                    newData[foundInd].units = newData[foundInd].units + data[i].totalUnits;
                    newData[foundInd].available = newData[foundInd].count + 0;
                } else {
                    newData.push({
                        group1: data[i].unitType?.description,
                        available: 0,
                        units: data[i].totalUnits
                    });
                }
            }
            return (newData);

        }
        return convertData(await getAPIDataReturn('/Farms/Groups?' + new URLSearchParams({
            WarehouseId: id,
        }),abortController));

    }

    const APPROX_UNICODE = "\u2245";
    const PrintCol = (props) => {
        console.log(props.data)
        return <React.Fragment>
            {props.data?.map((d) => {
                var vari = 'h6';
                var fw = 'normal';
                return <TableRow hover style={{ zIndex: 1000, backgroundColor: dateColSet(d.group1) }} >
                    <TableCell sx={{ pl: 3 }}>
                        <Typography style={{ fontWeight: fw }} variant={vari} mb={0}>{((d.group1 && (d.group1.trim() !== '')) ? d.group1 : 'Unknown')}</Typography>
                    </TableCell>
                    <TableCell sx={{ pl: 3 }} style={{ textAlign: 'right' }} >
                        <Typography style={{ fontWeight: fw }} variant={vari} mb={0}>{numberFormat(d.units, props.inDoz, false)}</Typography>
                    </TableCell>
                    <TableCell sx={{ pl: 3 }} style={{ textAlign: 'right' }} >
                        <Typography style={{ fontWeight: fw }} variant={vari} mb={0}>{numberFormat(d.available, props.inDoz, false)}</Typography>
                    </TableCell>
                </TableRow>

            })
            }</React.Fragment>
    }

    var pdfTable = (d) => {
        var newDt = [];
        var totals = 0;
        var totale = 0;
        for (var i = 0; i < d.length; i++) {
            newDt.push([d[i].group1, numberFormat(d[i].units), numberFormat(d[i].available)])
            totals = totals + d[i].units;
            totale = totale + d[i].available;
        }
        newDt.push(['Total', numberFormat(totals), numberFormat(totale)].map((it) => { return { content: it, styles: { fontStyle: 'bold' } } }))

        return { data: newDt, heading: ['Unit Type', 'On Water', 'On Shore'] };
    }
    return (
        <JumboCardQuick
            title={<span>{props.title}<div style={{ position: 'absolute', top: '2%', right: '1%' }}><CommentButton {...props} type={1} /><PDFButton data={pdfTable(tableData ? tableData : [])} title={"Oyster Cloud Totals"} /></div></span>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >
            {props.show ? (loading ? <CircularProgress /> : <TableContainer>
                <Table>
                    <TableHead style={{ zIndex: 1000, }} >
                        <TableCell sx={{ pl: 3 }}>
                            Unit Type
                        </TableCell>
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                            In Use
                        </TableCell>
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                            Available
                        </TableCell>
                    </TableHead>
                    <PrintCol inDoz={props.inDoz} data={tableData} />
                    <TableRow hover style={{ zIndex: 1000, }} >
                        <TableCell>
                            <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{numberFormat(tableData?.reduce((prev, curr) => { return prev + curr.units }, 0))}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>?</Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>) : ''}
        </JumboCardQuick>
    );
};

export default PurchaseOrders;
