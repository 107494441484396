import React from 'react';
import { startOfWeek, endOfWeek } from "../../../utils/dateFunctions";
import SalesOrders from '../Tables/SalesOrders';
import FormModal from '../../../components/FormModal';
import AddSalesOrder from '../Forms/AddSalesOrder';
import { checkAccess } from '../../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
const SalesOrdersComp = (props) => {
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };

    const handleClose = () => {
        setCompleted(false);
        setEdit(false);
        setData(null);
        setSeries(false);
        setOpen(false);
    }
    const reloadFunc = () => {
        setReload(!reload);
    }

    const createPO = (ser) => () => {
        setOpen(true);
        setEdit(false);
        setData(null);
        setSeries(ser);
    }
    const updatePO = (array, index) => (e) => {
        e.stopPropagation();
        setData(array[index]);
        setOpen(true);
        setEdit(true);
    }
    const updateSeries = (array, index) => (e) => {
        e.stopPropagation();
        setData(array[index]);
        setOpen(true);
        setSeries(true);
        setEdit(true);
    }
    const viewPO = (array) => (rowd, rowState) => {
        setData(array[rowState.dataIndex]);
        setOpen(true);
        setEdit(false);
        setCompleted(true);
    }
    var title = "Create Sales Order";
    if (series) {
        title = "Create Series of Sales Orders";
        if (edit) {
            title = "Edit Series of Sales Orders";
        }
    } else if (edit) {
        title = "Edit Sales Order";
    } else if (completed) {
        title = "View Sales Order";
    }
    return (
        <React.Fragment>
            {checkAccess(props.access, requiredEditAccess) ?
                <FormModal open={open} handleClose={handleClose} title={title} >
                <AddSalesOrder data={data} access={props.access} reload={reloadFunc} handleClose={handleClose} edit={edit} series={series} completed={completed} />
            </FormModal> : null}

            <SalesOrders reloadFunc={reloadFunc} reload={reload} { ...props} onClick={viewPO} editOne={updatePO} editAll={updateSeries}  dashboard />
        </React.Fragment >
    );
};


export default SalesOrdersComp;
