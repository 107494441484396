import React from 'react';
import Grid from "@mui/material/Grid";
import PurchaseOrders from '../../Procurement/Tables/PurchaseOrders';
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { yesterday, tomorrow } from "../../../utils/dateFunctions";
import ReceivalForm from './ReceivalForm';
import SelectWarehouse from '../../../components/SelectWarehouse';

const NewReceival = (props) => {
    const [startDate, setStartDate] = React.useState(yesterday(yesterday(new Date())));
    const [endDate, setEndDate] = React.useState((tomorrow(new Date())));
    const [warehouse, setWarehouse] = React.useState(null);
    
    const selectPO = (array) => (rowd, rowState) => {
        props.setPurchaseOrder(array[rowState.dataIndex]);
        props.handleClose();
    }
   
    return (
        <Container style={{paddingTop:'20px'} }>
            <Grid container spacing={2} mb={1}>
                <Typography  variant={'h2'}>Select Purchase Order</Typography>
                <Grid item xs={12} >
                    <SelectWarehouse useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} />
                </Grid>
                <Grid item xs={12} >
                    <PurchaseOrders pickupDate={props.pickupDate} warehouseFilter={warehouse?.description} location={0} access={props.access} onClick={selectPO} startDate={startDate} endDate={endDate} notReceived/>
                </Grid>
            </Grid>
        </Container>

    );
};

export default NewReceival;