import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, textfieldDateFormat, yesterday, dateFormat } from "../../../utils/dateFunctions";
import { getAPIData, getAPIDataParamsReturn } from "../../../utils/apiFunction";
import { textColorContrast } from "../../../utils/renderFunctions";
import DateSelector from "../../../shared/DateSelector";
import DataTableStandard from "../../../components/DataTableStandard";
import {OrganiseMovements} from "../../../utils/oysterMateFunctions";
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import SelectAOCFarmWarehouse from '../../../components/SelectAOCFarmWarehouse';

const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const Movements = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    const [forceTask, setForceTask] = React.useState(null);

    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }


    const createBatch = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);

    }
    //need a different date select that goes back 7 days from today
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SelectAOCFarmWarehouse setState={setFarm} state={farm} access={props.access} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                <Grid item xs={12} >
                    <MovementsTable warehouseId={farm?.warehouseId} startDate={startDate} endDate={endDate} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Movements;


const MovementsTable = (props) => {
    const [columns, setColumns] = React.useState([]);
    const [locHistory, setLocHistory] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const [cropCols, setCropCols] = React.useState([]);

    const batchtoCol = (batch) => {
        return cropCols?.find((cc) => { return cc.crop === batch })?.colour;
    }
    const reloadFunc = () => {
        setReload(!reload);
    }


    React.useEffect(() => {
        var cols = [
            //{
            //    name: 'id',
            //    label: 'ID',
            //},
            {
                name: 'date',
                label: 'Date',
            },
            {
                name: 'type',
                label: 'Type',
            },
            {
                name: 'crop',
                label: 'Crop',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        return <span style={{ background: batchtoCol(locHistory?.[dataIndex]?.crop), color: textColorContrast(batchtoCol(locHistory?.[dataIndex]?.crop), '#FFF', '#000'), padding: '5px' }} >
                            {locHistory?.[dataIndex]?.crop}
                        </span>
                    }

                }
            },
            {
                name: 'grade',
                label: 'Grade',
            },
            {
                name: 'unitType',
                label: 'Unit Type',
            },
            {
                name: 'total',
                label: 'Total',
            },
            {
                name: 'average',
                label: 'Average',
            },
            {
                name: 'lease',
                label: 'Lease',
            },
            {
                name: 'row',
                label: 'Row',
            },

            {
                name: 'start',
                label: 'Start No.',
            },
            {
                name: 'end',
                label: 'End No.',
            }];

        setColumns(cols);
    }, [cropCols, locHistory])
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '600px',
        responsive: 'standard',
        rowsPerPage: 1000,
        //onRowClick: props.onClick ? props.onClick(locHistory) : (() => { }),

    };
    React.useEffect(() => {
        const abortController = new AbortController();
        setLocHistory([]);
        if (props.warehouseId) {
            getData(props.warehouseId, props.startDate, props.endDate, abortController);

        }
        return () => {
            abortController.abort();
        };
    }, [props.warehouseId, props.startDate, props.endDate, reload, props.externalReload]);

    const getData = async (warehouseId, sDate, eDate, abortController) => {
        setLoading(true);

        const data = await getAPIDataParamsReturn('/Farms/Groups/LocationHistory', { WarehouseId: warehouseId, StartDate: textfieldDateFormat(sDate), EndDate: textfieldDateFormat(eDate) }, abortController);
        setLocHistory(OrganiseMovements(data, sDate, eDate));
        setLoading(false);
    }

    React.useEffect(() => {
        const getCC = async () => {
            await getAPIData('/Farms/Lookups/BatchColours', setCropCols);
        };
        getCC();
    }, []);


    
    return <DataTableStandard
        data={locHistory}
        title={"Movements"}
        columns={columns}
        options={options}
        returnSearch={!loading}
        reload={reloadFunc}
        shrink={props.shrinkTable}
    />
}