import React from 'react';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import Grid from "@mui/material/Grid";
import Agenda from '../Tables/Agenda';

const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };

const PurchaseOrders = (props) => {
    const [reload, setReload] = React.useState(false);


    const reloadFunc = () => {
        setReload(!reload);
    }
    return (
        <Grid container spacing={0} mb={1}>

             
            <Grid item xs={12}>

                <Agenda warehouseId={props.warehouseId} access={props.access} monthDate={props.monthDate} reload={reload} reloadFunc={reloadFunc} />
                   

            </Grid>
        </Grid >

    );
};

export default PurchaseOrders;
