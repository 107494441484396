import { Grid, ListItemText, Typography } from "@mui/material";
import React from 'react';
import { useSearchParams } from "react-router-dom";
import { startOfWeek, endOfWeek, tomorrow, dayCompare, dateFormat, yesterday } from "../../utils/dateFunctions";
import SelectTextField from '../../components/SelectTextField';
import { getAPIData } from '../../utils/apiFunction';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import QualityAssurance from '../Processing/Tables/QualityAssurance';
import SalesOrdersComp from './FunctionalComp/SalesOrders';
import SalesOrdersCreate from './FunctionalComp/SalesOrdersCreate';

const FarmReport = (props) => {
    const [reload, setReload] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [customer, setCustomer] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const [startDate, setStartDate] = React.useState(startOfWeek(yesterday(startOfWeek(new Date()))));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [location, setLocation] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [edit, setEdit] = React.useState(false);
    const [data, setData] = React.useState(null);
    const requiredPOAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const reloadFunc = () => {
        setReload(!reload);
    }
    const handleClose = () => setOpen(false);
    const createPO = () => {
        setOpen(true);
        setEdit(false);
        setData(null);
    }
    
    if (firstLoad) {
        getAPIData('/Customers/Get', setCustomers);
        setFirstLoad(false);
    }
    const setFirstFarm = (aFm) => {
        if (aFm) {
            if (!customer) {
                setCustomer(aFm[0]);
            }
        }
    }
    React.useEffect(() => {
        setFirstFarm(customers);
    }, [customers]);
    
    return (
        <React.Fragment>
            {/*<FormModal open={open} handleClose={handleClose} title={"Create Purchase Order"} >*/}
            {/*    <AddPurchaseOrder fillData={{ supplierId: farm?.id }} access={props.access} reload={reloadFunc} handleClose={handleClose} edit={edit} />*/}
            {/*</FormModal>*/}
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} >
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12} sm={12} md={5} lg={4}>
                            <SelectTextField style={{ minWidth: '250px' }} checkID={customer?.id} title="Customers" state={customer} setState={setCustomer} list={customers} nameFunc={(op) => { return op.name }} />

                        </Grid>
                       
                        {/*{checkAccess(props.access, requiredPOAccess) ? <Grid item xs={12}>*/}
                        {/*    <Button onClick={createPO} variant="contained" >Create New Purchase Order</Button>*/}
                        {/*</Grid> : null}*/}
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <SalesOrdersCreate access={props.access} reloadFunc={reloadFunc} reload={reload} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12}>
                            <SalesOrdersComp location={location} startDate={startDate} endDate={endDate} access={props.access} reloadFunc={reloadFunc} reload={reload} customerFilter={customer?.name} />
                        </Grid>
                       
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid container spacing={2} mb={1}>

                        {/*<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                        {/*    <RainfallCard farmName={farm?.name} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                        {/*    <TideCard farmName={farm?.name} />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <QualityAssurance  startDate={startDate} endDate={tomorrow(endDate)} {...props} access={props.access} title="Completed QAs" reload={reload} setReload={setReload} reloadFunc={reloadFunc} />
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                        {/*    <EstuaryStatus filterEst={farm?.estuaries} />*/}
                        {/*</Grid>*/}

                    </Grid>
                </Grid>


            </Grid>
        </React.Fragment >
    );
};

export default FarmReport;
