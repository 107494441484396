import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import Switch from '@mui/material/Switch';
import { getAPIData, getAPIDataParams, postApi } from '../../../utils/apiFunction';
import { textfieldDateFormat } from '../../../utils/dateFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import ControlledTextField from "../../../components/ControlledTextField";
import SupplierSelect from "../../../components/SupplierSelect";
import SelectAOCFarmWarehouse from '../../../components/SelectAOCFarmWarehouse';
import SelectTextField from "../../../components/SelectTextField";
import { getMapState, findLocation } from "../utils/mapFunction";
import { displayDozFormat } from "../../../utils/numberFunctions"
import { useJumboTheme } from "@jumbo/hooks";
import ChooseBatch from "./ChooseBatch";


const suppliers = [
    { id: 3, name: 'AOC Hastings' },
    { id: 23, name: 'AOC Hastings -2' },
    { id: 4, name: 'AOC Bermagui' },
    { id: 7, name: 'AOC Narooma' },
    { id: 13, name: 'AOC Bermagui Farm' },
    { id: 22, name: 'AOC Wallis' }];


const NewBatch = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [error, setError] = React.useState(null);
    //const [cropYearCode, setCropYearCode] = React.useState(props.crop);
    const [average, setAverage] = React.useState(null);
    const [total, setTotal] = React.useState(null);
    const [grade, setGrade] = React.useState(null);
    const [grades, setGrades] = React.useState(null);
    const [unitType, setUnitType] = React.useState(null);
    const [unitTypes, setUnitTypes] = React.useState(null);
    const [supplier, setSupplier] = React.useState(null);
    const [isHatched, setIsHatched] = React.useState(false);
    const [notWarehouse, setNotWarehouse] = React.useState(false);
    const [notEnoughUnits, setNotEnoughUnits] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [groups, setGroups] = React.useState(null);
    const [discussed, setDiscussed] = React.useState(null);
    const [outcome, setOutcome] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [accountable, setAccountable] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const [cropDate, setCropDate] = React.useState(new Date());
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    const oyPerSlat = 0;
    const slatsPerPack = 100;

    React.useEffect(() => {
        if (!isHatched) {
            setAverage(oyPerSlat * slatsPerPack);
        } else {
            setAverage(0);
        }
    }, [isHatched]);
    React.useEffect(() => {
        getAPIDataParams('/Farms/Lookups/Grades', setGrades);
        getAPIDataParams('/Farms/Lookups/UnitTypes', setUnitTypes);

    }, [])

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        //if (total <= 0) {
        //    errorMessages.push('Total must be more that 0')
        //}
        //if (!batch && !pickup) {
        //    errorMessages.push('You must select a group to deploy');
        //}

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }

    const submit = async () => {
        setLoading(true);
        setCompleted(true);


        var res = await postApi('/Farms/Groups/Create', { SupplierId: supplier?.id, UnitTypeId: unitType?.id, GradeId: grade?.id, Average: average, WarehouseId: props.farm?.warehouseId, CropDate: textfieldDateFormat(cropDate), isHatched: isHatched, TotalUnits: total, Date: textfieldDateFormat(date) });
        if (res.ok) {
            setCompleted(true);
        } else {
            setError("Error Text");
        }

        if (props.reload) {
            props.reload();
        }
        setLoading(false);

    }


    return <Box
        component="form"
        sx={{
            '& .MuiTextField-root': { m: 1, width: '24ch' },
        }}
        noValidate
        autoComplete="off"
    >
        <Div>
            {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
            {/*    <DeleteIcon />*/}
            {/*</IconButton> : ''}*/}

            {/*<Typography variant={"h3"} style={{ textAlign: 'center' }}>Pick up</Typography>*/}
            <Grid container spacing={0} mb={1}>


                <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <DatePicker date={cropDate} setDate={setCropDate} label="Crop Birth Date" disabled={completed} />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={9}>
                    <Typography variant={"h4"} >Batch Name</Typography>
                    <Typography variant={"h3"} >{cropDate?.getFullYear() + (isHatched?'-HAT ':'-WLD ') + (supplier ? supplier.name.replace(' Farm (Internal)', '') : '')}</Typography>
                </Grid>
                <Grid item xs={12} >
                    {/*<SelectAOCFarmWarehouse setState={setSupplier} state={supplier} access={props.access} />*/}
                    <SelectTextField title="Supplier" state={supplier} nameFunc={(gd) => { return gd.name.replace(' Farm (Internal)', '') }} list={suppliers} setState={setSupplier} disabled={completed} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <SelectTextField checkID={grades ? (grades.find((gd) => { return gd.description.includes('Slats') }).id) : null} title="Grade" state={grade} nameFunc={(gd) => { return gd.description }} list={isHatched ? grades.filter((g) => {return g.isHatchery}) :grades} setState={setGrade} disabled={!isHatched} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <SelectTextField checkID={unitTypes ? (unitTypes.find((gd) => { return gd.description.includes('Slats') }).id) : null} title="Unit Type" state={unitType} nameFunc={(gd) => { return gd.description }} list={unitTypes} setState={setUnitType} disabled={!isHatched} />
                </Grid>
                {/*<Grid item xs={12} sm={12} md={6} lg={4} xl={3} >*/}
                {/*    <Typography style={{ display: 'inline' }}>Wild</Typography>*/}
                {/*    <Switch checked={isHatched} onChange={(event) => setIsHatched(event.target.checked)} name="header-fixed" />*/}
                {/*    <Typography style={{ display: 'inline' }}>Hatched</Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                </Grid>
                <Grid item xs={12} sm={12} xl={3} >
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <ControlledTextField title="Total Units" state={total} setState={setTotal} disabled={completed} number />
                </Grid>
                {isHatched ? <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                    <ControlledTextField title="Average per Unit" state={average} setState={setAverage} disabled={completed} number />
                </Grid> : ''}
                <Grid item xs={12}  >
                    {/*<Typography style={{ display: 'inline' }}>Approximately {displayDozFormat(total*average)} Oysters</Typography>*/}
                </Grid>
                <Grid item xs={12}  >
                    <SubmitButtonDisplayer completed={completed} error={error} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                </Grid>
            </Grid>

        </Div>
    </Box >
}

export default NewBatch;
