import React from 'react';
import QAReport from './QAReport';
import ProcessStock from './ProcessStock';
import ConfirmReceival from './ConfirmReceival';
import NewReceival from './NewReceival';
import { useSearchParams } from "react-router-dom";
import Card from "@mui/material/Card";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import CardContent from "@mui/material/CardContent";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormModal from '../../components/FormModal';
const LandingPage = (props) => {
    const newRec = 'newReceival';
    const conRec = 'confirmReceival';
    const QARep = 'QAReport';
    const pStock = 'ProcessStock';
    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);
    const [searchParams, setSearchParams] = useSearchParams();
    var spID = searchParams.get("ReceivalId");
    const [forceTask, setForceTask] = React.useState(null);
    React.useEffect(() => {
        setOpen(true);
        console.log("rundur")
    }, [searchParams.get("task")])
    const SelectForm = () => {
        const task = searchParams.get("task")
        if ((forceTask === newRec) || ((!forceTask) && (task === newRec))) {
            return <FormModal open={open} handleClose={handleClose} title={"New Receival Form"} fixedWidth>
                <NewReceival access={props.access} handleClose={handleClose} />
            </FormModal >;
        } else if ((forceTask === conRec) || ((!forceTask) && (task === conRec))) {
            return <FormModal open={open} handleClose={handleClose} title={"Confirm Receival Form"} fixedWidth>
                <ConfirmReceival searchParamId={spID} access={props.access} handleClose={handleClose} />
            </FormModal >;
        } else if ((forceTask === QARep) || ((!forceTask) && (task === QARep))) {
            return <FormModal open={open} handleClose={handleClose} title={"QA Report Form"} fixedWidth>
                <QAReport access={props.access} handleClose={handleClose} />
            </FormModal >;
        } else if ((forceTask === pStock) || ((!forceTask) && (task === pStock))) {
            return <FormModal open={open} handleClose={handleClose} title={"Process Task"} fixedWidth>
                <ProcessStock access={props.access} handleClose={handleClose} />
            </FormModal >;
        }
        return <div></div>
    }
    const selectTask = (task) => () => {
        setForceTask(task);
        setOpen(true);
    }
    return (
        <div>
            <SelectForm />
            <Typography variant={"h1"} mb={3}>Select a Task</Typography>
            <Card>
                <CardContent>
                    <Grid container spacing={3.5}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <JumboCardQuick bgColor={'#6f42c1'} onClick={selectTask(newRec)}>
                                <Div sx={{ display: "flex", alignItems: 'center' }}>
                                    <Div sx={{ ml: 2, flex: 1 }}>
                                        <Typography color={'common.white'} variant={"h2"} mb={.5}>Input New Receival</Typography>
                                    </Div>
                                </Div>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <JumboCardQuick bgColor={'#23BCBA'} onClick={selectTask(QARep)}>
                                <Div sx={{ display: "flex", alignItems: 'center' }}>
                                    <Div sx={{ ml: 2, flex: 1 }}>
                                        <Typography color={'common.white'} variant={"h2"} mb={.5}>QA Report</Typography>
                                    </Div>
                                </Div>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <JumboCardQuick bgColor={'#E44A77'} onClick={selectTask(conRec)}>
                                <Div sx={{ display: "flex", alignItems: 'center' }}>
                                    <Div sx={{ ml: 2, flex: 1 }}>
                                        <Typography color={'common.white'} variant={"h2"} mb={.5}>Confirm Receival</Typography>
                                    </Div>
                                </Div>
                            </JumboCardQuick>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <JumboCardQuick bgColor={'rgb(231, 49, 69)'} onClick={selectTask(pStock)}>
                                <Div sx={{ display: "flex", alignItems: 'center' }}>
                                    <Div sx={{ ml: 2, flex: 1 }}>
                                        <Typography color={'common.white'} variant={"h2"} mb={.5}>Process Stock</Typography>
                                    </Div>
                                </Div>
                            </JumboCardQuick>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default LandingPage;