import { handlePagination } from "../../../utils/apiFunction";
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { salesOrderAndShipmentsTypes, statusList } from '../../../utils/constants/selectFields';
import { getOysterGrades, gradeSort } from '../../../definitions/oysters';

const CANCELLED = "Cancelled";
const CANCELED = "Canceled";
const CLOSED = "Closed";
const BACKORDER = "Back Order";
const COMPLETED = "Completed";
const SHIPPING = "Shipping";
const TRANSFERTYPE = 'TR';
const TRANSFERTYPELONG = 'Transfer';
const INVOICED = 'Invoiced';
const CONFIRMED = 'Confirmed';
const OPEN = 'Open';
const getShipSaleData = async (location, startDate, endDate, abortController, setLoading, setData) => {
    setLoading(true);
    const uri = process.env.REACT_APP_BASE_URL;

    const response = await handlePagination(fetch, uri + '/SalesOrder/Get', startDate, endDate, { ...location }, abortController);
    const shipsResponse = await handlePagination(fetch, uri + '/Shipment/Get', startDate, endDate, { ...location }, abortController);
    const data = response.filter((r) => ((r.status !== COMPLETED) && (r.status !== CLOSED) && (r.status !== CANCELED) && (r.status !== CANCELLED) && (r.status !== BACKORDER))); //same as weekly summary
    const grades  = gradeSort(await getOysterGrades());
        
    const ships = shipsResponse.filter((sr) => {
        if ((sr.status !== OPEN)) {
            var isCompleted = true;
            if (sr.status === CONFIRMED) {
                if (sr.salesOrder?.status !== COMPLETED) {
                    isCompleted = false;
                }
            }
            return isCompleted;
        }
    }); //same as weekly summary
    var tempD = [...data, ...ships.map((ship) => {
        var newS = { ...ship };
        newS.salesOrderLines = newS.shipmentLines.map((sL) => {
            var newSL = { ...sL };
            newSL.quantity = sL.shippedQty;
            return newSL;
        });
        newS.description = newS.salesOrder?.description;
        newS.customer = newS.salesOrder?.customer;
        newS.orderDate = newS.salesOrder?.orderDate;
        newS.orderType = newS.type;
        return newS;

    })];

    var sortData = tempD.sort((a, b) => {
        return new Date(a.orderDate) > new Date(b.orderDate) ? 1 : -1
    })

    var newData = sortData.map((po) => {
        var nObj = { ...po };
        nObj.name = nObj.customer?.name;
        nObj.orderDate = dateFormat(nObj.orderDate);
        nObj.shipmentDate = dateFormat(nObj.shipmentDate);
        nObj.orderTypeName = salesOrderAndShipmentsTypes.find((sot) => { return sot.value === nObj.orderType; })?.name;
        var oysterArr = [];
        var nonStockArr = [];
        if (nObj.salesOrderLines) {
            for (var i = 0; i < nObj.salesOrderLines.length; i++) {
                if (nObj.salesOrderLines[i].stockItemId) {
                    if (nObj.salesOrderLines[i].stockItem.isNonStockItem) {
                        nonStockArr.push(nObj.salesOrderLines[i]);
                    } else {
                        oysterArr.push(nObj.salesOrderLines[i]);

                    }
                } else {
                    nonStockArr.push(nObj.salesOrderLines[i])
                }
            }
        }
        nObj.nonStockQuantity = nonStockArr.reduce((a, c) => { return a + Number((c.quantity)) }, 0);
        //if (po.myobid === '035349') {
        //    console.log(oysterArr);
        //}
        grades.map((og) => {
            nObj[String(og.id)] = oysterArr.reduce((a, c) => { return a + ((c.stockItem.gradeId === og.id) ? Number(c.quantity) : 0) }, 0);
        });
        nObj.quantity = oysterArr.reduce((a, c) => { return a + Number((c.quantity)) }, 0)
        return nObj;
    })
    console.log(newData)
    setLoading(false);
    setData(newData.filter((nd) => (nd.orderTypeName !== TRANSFERTYPELONG)));
}

export default getShipSaleData;