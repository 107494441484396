import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import Inventory from './Tables/Inventory';
import FormModal from '../../components/FormModal';
import Button from "@mui/material/Button";
import InventoryForm from './Forms/InventoryForm';
import { adalApiFetch } from '../../../adalConfig';
import { dateFormat, textfieldDateFormat, yesterday, dayCompare } from "../../utils/dateFunctions";
import SelectInventoryForm from './Forms/SelectInventoryForm';
import SelectWarehouse from '../../components/SelectWarehouse';
import RegradeStockForm from './Forms/RegradeStockForm';
import TransferStockForm from './Forms/TransferStockForm';
import AdjustQuantityStockForm from './Forms/AdjustQuantityStockForm';
import CreateStockForm from './Forms/CreateStockForm';
import { CircularProgress, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

const InventoryDash = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [newForm, setNew] = React.useState(false);
    const [rowData, setRowData] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [processed, setProcessed] = React.useState(false);
    const [selectedLine, setSelectedLine] = React.useState(false);
    const [warehouse, setWarehouse] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const [formType, setFormType] = React.useState(0);
    const reloadFunc = () => {
        setReload(!reload);
    }
    const handleClose = () => {
        reloadFunc();
        setOpen(false);
    }
    const handleCloseCreate = () => {
        reloadFunc();
        setOpenCreate(false);
    }
    const openInvForm = (data) => (row, index) => {
        var selData = data[index.dataIndex];
        setProcessed(selData.stockItem?.isProcessed);
        setOpen(true);
        selData = {
            ...selData,
            processingStatus: 'Not started',
            stockItem: selData.stockItem?.description,
            stockItemId: selData.stockItem?.id,
            amount: selData.portalQuantity,
            supplier: selData.supplier?.name,
        };
        setSelectedLine(selData);
    }

    const openCreateFunc = () => {
        setOpenCreate(true);
    }
    return (
        <React.Fragment>
            {/*<FormModal open={open} handleClose={handleClose} title={"Log Daily Inventory"} >*/}
            {/*    <InventoryForm date={date} new={newForm} data={rowData} access={props.access} reload={reloadFunc} handleClose={handleClose} CustomerPricing />*/}
            {/*</FormModal>*/}
            <FormModal open={openCreate} handleClose={handleCloseCreate} title={""} fixedWidth>
                <CreateStockForm processed={true} access={props.access} handleClose={handleCloseCreate} selectedLine={selectedLine} warehouseId={warehouse?.id} /> 
            </FormModal >
            <FormModal open={open} handleClose={handleClose} title={""} fixedWidth>
                <ToggleButtonGroup value={formType} exclusive onChange={(e, v) => setFormType(v)}>
                    <ToggleButton value={0} >Regrade</ToggleButton>
                    <ToggleButton value={1}>Transfer</ToggleButton>
                    <ToggleButton value={2}>Adjust Quantity</ToggleButton>
                    {/*<ToggleButton value={3}>Create Stock</ToggleButton>*/}
                </ToggleButtonGroup>
                <Typography variant="h2" sx={{ mt: 2 }}>{formType === 0 ? 'Regrade' : (formType === 1 ? 'Transfer':'Adjust Quantity')}</Typography>
                {formType === 0 ? <RegradeStockForm processed={processed} access={props.access} handleClose={handleClose} selectedLine={selectedLine} warehouseId={props.location} /> : null}
                    { formType === 1 ? <TransferStockForm processed={processed} access={props.access} handleClose={handleClose} selectedLine={selectedLine} warehouseId={props.location} />:null}
                {formType === 2 ? <AdjustQuantityStockForm processed={processed} access={props.access} handleClose={handleClose} selectedLine={selectedLine} warehouseId={props.location} />:null}
            </FormModal >
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <SelectWarehouse access={props.access} enforceAccess={true} useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} all />
                        <Button onClick={openCreateFunc}  variant='outlined'>Create Stock</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {warehouse ? <SelectInventoryForm reload={reload} onClick={openInvForm} warehouseId={warehouse?.id} access={props.access} hideFrozenButton /> : <CircularProgress />}
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default InventoryDash;
