import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { locationMatch, buildColGroups } from '../../../utils/constants/orderItems';
import Div from "@jumbo/shared/Div";
import { getAPIData, getAPIDataReturn, getAPIDataParams, getAPIDataParamsReturn, postApi } from '../../../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
import { getOysterGrades, buildSpeciesList } from "../../../definitions/oysters";
//import 'jspdf-autotable'
import DateSelector from "../../../shared/DateSelector";
import { getPRNfrmLSN } from '../../../utils/fulfilment/lsnFunction';
import { adalApiFetch } from '../../../../adalConfig';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ArticleIcon from '@mui/icons-material/Article';
import { v4 as uuidv4 } from 'uuid';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import ControlledTextField from "../../../components/ControlledTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { CircularProgress } from '@mui/material';
import { numberFormat } from '../../../utils/numberFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import { saveLabel } from '../../../utils/fulfilment/labels';
import SelectInventoryForm from './SelectInventoryForm';
import { WAREHOUSEMATCHES } from '../../../utils/constants/warehouse';
const outputStruct = { prn: null, quantity: null, units: null };
const FulfilmentForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const Swal = useSwalWrapper();

    const [outputs, setOutputs] = React.useState([]);
    const [saleItem, setSaleItem] = React.useState(null);
    const [stockItems, setStockItems] = React.useState([]);
    const [date, setDate] = React.useState(new Date());
    const [grades, setGrades] = React.useState([]);
    const [speciesList, setSpeciesList] = React.useState([]);
    const [existingPRNs, setExistingPRNs] = React.useState([]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.EDIT };
    const [recs, setRec] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    //const [relevantInv, setRelInv] = React.useState(null);

    const doReload = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(data);
            var specs = buildSpeciesList(data);
            setSpeciesList(specs);
        });
    }, []);

    React.useEffect(() => {
        getAPIData('/StockItems/GetProcessed', setStockItems);
    }, []);
    React.useEffect(() => {
        if (props.id) {
            getAPIData('/Sales/SalesOrderItem/Get/' + props.id, setSaleItem);
        }
    }, [props.id]);
    React.useEffect(() => {
        if (props.id) {
            getAPIDataParamsReturn('/Fullfillment/Get', { SalesOrderLineId: props.id }).then((result) => {
                //var formatRes = result.map((r) => { return { ivn: r, quantity: (0 - r.quantity) } });
                var promARr = result.map((r) => {
                    return getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: r.warehouseId, IncludeMYOB: false, ExpiryDate: r.expiryDate, LotNbrSubString: r.lotSerialNbr, StockItemId: r.stockItemId, IncludeZero: true }).then((inv) => {
                        var matchInv = inv.find((i) => { return i.expiryDate === r.expiryDate });
                        return { ivn: r, quantity: (0 - r.quantity), newIvn: matchInv }
                    })
                });
                Promise.all(promARr).then((res) => {
                    setOutputs(res);
                    setExistingPRNs(JSON.parse(JSON.stringify(res)));
                });
            });
            //console.log(list)
        }
    }, [props.id, reload]);
    var codeInfo = React.useMemo(() => {
        var codeDeets = null;
        if (saleItem && stockItems && (stockItems.length > 0)) {

            for (var i = 0; i < stockItems.length; i++) {
                if ((stockItems[i].myobid === saleItem.code)) {
                    codeDeets = stockItems[i];
                    i = stockItems.length;
                }
            }
        }
        return codeDeets;
    });



    const selectInv = (inv) => (value, indexs) => {
        var dataIndex = indexs.dataIndex;
        var rowIndex = indexs.rowIndex;
        if (!outputs.find((o) => ((o.ivn.lotSerialNbr === inv[dataIndex].lotSerialNbr) && (o.ivn.stockItemId === inv[dataIndex].stockItemId) && (o.ivn.expiryDate === inv[dataIndex].expiryDate)))) {
            var cpyOuts = [...outputs];
            cpyOuts.push({ id: uuidv4(), ivn: inv[dataIndex], quantity: null, units: null });
            setOutputs(cpyOuts);
        }
    }

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        console.log('ping')
        var total = 0;
        var stockq = 0;
        var stockt = 0;
        for (var i = 0; i < outputs.length; i++) {

            if (!outputs[i].quantity) {
                errorMessages.push('Quantity is required for all outputs');
            }
            var existingOfThisOut = existingPRNs?.find((ri) => ((ri.ivn.lotSerialNbr === outputs[i].ivn.lotSerialNbr) && (ri.ivn.expiryDate === outputs[i].ivn.expiryDate) && (ri.ivn.stockItemId === outputs[i].ivn.stockItemId)))
            if (stockq === 0) {
                if (existingOfThisOut) {
                    if (outputs[i].quantity > (Number(outputs[i].newIvn?.portalQuantity ? outputs[i].newIvn?.portalQuantity : 0) + Number(existingOfThisOut.quantity))) {
                        errorMessages.push("You are using more stock then available in inventory. See a manager.");
                        stockq++;
                    }
                } else {
                    console.log(outputs[i].quantity, (outputs[i].ivn.portalQuantity))
                    if (outputs[i].quantity > (outputs[i].ivn.portalQuantity)) {
                        errorMessages.push("You are using more stock then available. See a manager.");
                    }
                }
            }
            if (stockt === 0) {
                if ((codeInfo.id !== outputs[i].ivn.stockItemId) && !(outputs[i].ivn.stockItem.description.includes('WIP'))) {
                    warningMessages.push('You are allocating a different stock to this sale order. Are you sure?');
                    stockt++;
                }
            }
            //if (!outputs[i].units) {
            //    errorMessages.push('Units is required for all outputs');
            //}
            total += Number(outputs[i].quantity);
        }
        if (total > saleItem.quantity) {
            warningMessages.push('Total quantity is greater than order quantity, are you sure this is correct?');
        }
        if (total > (saleItem.quantity * 2)) {
            errorMessages.push('Total quantity cannont be more than double the order quantity!');
        }

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    setLoading(true);
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            setLoading(true);
            //setCompleted(true);
            submit();
        }
    }

    const submit = async () => {
        //add new and update existing
        var newOuts = [];
        console.log(existingPRNs, outputs);
        for (var i = 0; i < outputs.length; i++) {
            var existing = existingPRNs.find((a) => (a.ivn.lotSerialNbr === outputs[i].ivn?.lotSerialNbr) && (a.ivn.stockItemId === outputs[i].ivn?.stockItemId) && (a.ivn.expiryDate === outputs[i].ivn?.expiryDate));
            if (!existing || (existing && (Number(existing.quantity) !== Number(outputs[i].quantity)))) {
                newOuts.push(outputs[i]);
                var params = {
                    SalesOrderLineId: props.id,
                    LotSerialNbr: outputs[i].ivn?.lotSerialNbr,
                    StockItemId: outputs[i].ivn?.stockItemId,
                    ExpiryDate: textfieldDateFormat(new Date(outputs[i].ivn?.expiryDate)),
                    Quantity: outputs[i].quantity
                }
                await postApi('/Fullfillment/AddUpdate', params);
            }
        }
        //remove missing
        for (var i = 0; i < existingPRNs.length; i++) {
            var removed = outputs.find((a) => (a.ivn.lotSerialNbr === existingPRNs[i].ivn?.lotSerialNbr) && (a.ivn.stockItemId === existingPRNs[i].ivn?.stockItemId) && (a.ivn.expiryDate === existingPRNs[i].ivn?.expiryDate));
            if (!removed) {
                var params = {
                    SalesOrderLineId: props.id,
                    LotSerialNbr: existingPRNs[i].ivn?.lotSerialNbr,
                    StockItemId: existingPRNs[i].ivn?.stockItemId,
                    ExpiryDate: textfieldDateFormat(new Date(existingPRNs[i].ivn?.expiryDate)),
                    Quantity: 0
                }
                await postApi('/Fullfillment/AddUpdate', params);
            }
        }
        saveLabel(newOuts, saleItem, codeInfo, codeInfo.isHalfshell, null, null,props.selSaleItem);
        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }

    }
    var selectedList = React.useMemo(() => {
        var sl = [];
        if (outputs) {
            for (var i = 0; i < outputs.length; i++) {
                sl.push({ lotSerialNbr: outputs[i].ivn.lotSerialNbr, stockItemId: outputs[i].ivn.stockItemId, expiryDate: outputs[i].ivn.expiryDate });
            }
        }
        return sl;
    }, [outputs]);
    var specCol = speciesList?.findIndex((sl) => (sl.name === codeInfo?.species?.description))
    var gradeCol = grades?.find((og) => ((og.Species === codeInfo?.species?.description) && (og.Grade === codeInfo?.grade?.description)))

    if (gradeCol < 0) {
        gradeCol = undefined;
    }
    if (specCol < 0) {
        specCol = undefined;
    }
    var typeCol = 0;
    if (codeInfo?.isHalfshell) {
        typeCol = 1;
    } else {
        typeCol = 0;
    }
console.log(codeInfo)
    var productCol = 0;
    if (typeCol === 0) {
        if (codeInfo?.isAppelation) {
            productCol = 0;
        } else if (codeInfo?.isAOC){
            productCol = 1;
        }  else if (codeInfo?.isOysterSmiths){
            productCol = 2;
        }
    } else {
        productCol = undefined;
    }
    var containerCol = 0;
    if (typeCol === 0) {
        if (codeInfo?.isBoxed) {
            containerCol = 1;
        } else {
            containerCol = 0;
        }
    } else {
        containerCol = undefined;
    }
    var defaultCode = [typeCol, productCol, containerCol, specCol, gradeCol];




    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >


            <Div>


                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} md={6} >
                        {saleItem && codeInfo ? <SelectInventoryForm reload={reload} onClick={selectInv} selectedList={selectedList} access={props.access} warehouseId={saleItem.warehouseId} typeDefault={defaultCode[0]} productDefault={defaultCode[1]} containerDefault={defaultCode[2]} speciesDefault={defaultCode[3]} gradeDefault={defaultCode[4]} hideHalfshell={!codeInfo?.isHalfshell} hideFrozen={!codeInfo?.isFrozen} hideSpecies={true} hideLive={codeInfo?.isFrozen || codeInfo?.isHalfshell} /> : null}
                    </Grid>
                    <Grid item xs={12} md={6} >
                        {/*<Div>*/}

                        {/*    <TextField*/}
                        {/*        disabled*/}
                        {/*        id="outlined-required"*/}
                        {/*        label="Date"*/}
                        {/*        value={dateFormat(date)}*/}
                        {/*        InputLabelProps={{*/}
                        {/*            shrink: true,*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Div>*/}
                        {saleItem ? <Grid container spacing={2} mb={1}>

                            <Grid item xs={12} md={6} >
                                <Typography variant={"h2"} mb={0}>{saleItem.customer?.name}</Typography>
                                <Typography variant={"h5"} mb={0}>Order No: {saleItem.orderNo}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Typography variant={"h4"} mb={0}>{codeInfo?.description}</Typography>
                                <Typography variant={"h4"} mb={0}>{codeInfo?.grade?.code + (codeInfo?.traySize ? (' (' + (codeInfo?.traySize) + ')') : '')}</Typography>
                                <Typography variant={"h5"} mb={0}>{saleItem.code}</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography variant={"h3"} mb={0}>Total: {saleItem.quantity}</Typography>
                            </Grid>
                            <Grid item xs={12} >
                                {outputs ? <PRNList selSaleItem={props.selSaleItem} doReload={doReload} access={props.access} outputs={outputs} setOutputs={setOutputs} disabled={completed} id={props.id} quantPerUnit={codeInfo?.bagCount} recs={recs} codeInfo={codeInfo} saleItem={saleItem} isHalfshell={codeInfo?.isHalfshell} existingPRNs={existingPRNs} /> : <CircularProgress />}
                            </Grid>

                            {/*<StaffMember staffId={loadedQA?.staffMemberId} driver={driver} setDriver={setDriver} disabled={completed} />*/}
                            <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />
                            </div>

                        </Grid>
                            : null}
                    </Grid>
                </Grid>


            </Div>
        </Box >
    );
};



const style = {
    position: 'absolute',
    top: '40%',
    left: '40%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    maxHeight: '90%',
    p: 4,
    overflow: 'auto',
    zIndex: 1000,
    padding: '20px'
};

const exitIcon = {
    position: 'absolute',
    right: '1%',
    top: '1%',
}

const PRNList = (props) => {
    //const [outputLines, setOutputLines] = React.useState((props.outputs && (props.outputs.length > 0)) ? props.outputs : [{ ...outputStruct }]);
    const [editUnitsStore, setEditUnitsStore] = React.useState(props.outputs.map((o) => null));
    const [openCB, setOpenCB] = React.useState(false);
    const [comboLabelUnits, setComboLabelUnits] = React.useState(props.quantPerUnit);
    const [comboLabelAllocations, setComboLabelAllocations] = React.useState([]);
    const [loadingOnCreate, setLoadingOnCreate] = React.useState(false);
    const [errorOnCreate, setErrorOnCreate] = React.useState(null);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.ADMIN };
    const Swal = useSwalWrapper();
    const updateLine = (index, field) => (val) => {
        var cpyPrns = [...props.outputs];
        if (field === 'units' && props.quantPerUnit) {
            cpyPrns[index].quantity = val * props.quantPerUnit;
        }
        cpyPrns[index][field] = val;
        props.setOutputs(cpyPrns);
    }

    const handleClose = () => {
        setOpenCB(false);
        setComboLabelAllocations([]);
    }


    const removeLine = (ind) => (e) => {
        var cpyPrns = [...props.outputs];
        cpyPrns.splice(ind, 1);
        props.setOutputs(cpyPrns);
    }
    const updateEditUnitsStore = (ind) => (val) => {
        var cpyPrns = [...editUnitsStore];
        cpyPrns[ind] = val;
        setEditUnitsStore(cpyPrns);
    }
    const openComboBuilder = () => {
        setOpenCB(true);
    }
    const addtoCombo = (pn) => () => {
        var cpyPrns = [...comboLabelAllocations];
        //check if already in list
        if (!cpyPrns.find((o) => (o.pn.ivn.stockItemId === pn.ivn.stockItemId) && (o.pn.ivn.lotSerialNbr === pn.ivn.lotSerialNbr) && (o.pn.ivn.expiryDate === pn.ivn.expiryDate))) {
            cpyPrns.push({ pn: pn, quantity: 1 });
        }
        var newTotals = Math.floor(comboLabelUnits / (cpyPrns.length));
        var remTotal = comboLabelUnits - (newTotals * (cpyPrns.length - 1));
        setComboLabelAllocations(cpyPrns.map((cp, ind) => {
            if (ind === cpyPrns.length - 1) {
                cp.quantity = remTotal;
            } else {
                cp.quantity = newTotals;
            }
            return cp;
        }));
    }
    const createStock = (details, inv, old, newA) => async () => {
        console.log(details, inv, old, newA)
        if (newA) {
            const params = {
                lotSerNbr: inv.lotSerialNbr,
                ExpiryDate: textfieldDateFormat(new Date(inv.expiryDate)),
                StockItemId: inv.stockItemId,
                NewAmount: newA,
                WarehouseId: inv.warehouseId,
                OldAmount: old,
                ReasonCode: 'ADJQTYVAR',
                Description: 'AOC Portal - Fulfilment Write Up - salesOrderLineId: ' + details.id
            }
            console.log(params)
            setLoadingOnCreate(true);
            var respArr = [await postApi('/Processing/AdjustQuantity', params)];

            if (respArr.reduce((p, c) => (p && c.ok), true)) {
                setLoadingOnCreate(false);
                props.doReload();
            } else {
                setErrorOnCreate("Failed to submit");
            }
        }
    }


    const printLabel = () => {
        const pLabel = () => {
            saveLabel([comboLabelAllocations[0].pn], props.saleItem, props.codeInfo, props.codeInfo.isHalfshell, comboLabelUnits, comboLabelAllocations,props.selSaleItem);
            setComboLabelAllocations([]);
            setOpenCB(false);
        };
        if (comboLabelAllocations.length > 0) {
            var warningMessages = [];
            var errorMessages = [];

            const formatErrorMessage = (array) => {
                var errString = '';
                array.map((a) => {
                    errString = errString + a + '<br/>';
                });
                return errString;
            }
            if (comboLabelAllocations.reduce((a, b) => a + Number(b.quantity), 0) !== Number(comboLabelUnits)) {
                warningMessages.push('Total of each PRN should equal total on label. Are you sure you want to contine?');
            }

            if (warningMessages.length > 0) {
                Swal.fire({
                    html: formatErrorMessage(warningMessages),
                    icon: 'warning',
                    title: 'Oops...',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Continue',
                    cancelButtonText: 'No, I\'ll edit it',

                }).then(result => {
                    if (result.value) {
                        pLabel();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {

                    }
                });
            } else {
                pLabel();
            }


        }
    }
    const addComboQuantity = (ind) => (val) => {
        var cpyPrns = [...comboLabelAllocations];
        cpyPrns[ind].quantity = val;
        setComboLabelAllocations(cpyPrns);
    }
    var totalQuant = 0;
    return <Div>{
        props.outputs ? props.outputs.map((pn, ind) => {
            //var invOfThisOut = props.relevantInv?.find((ri) => ((ri.lotSerialNbr === pn.ivn.lotSerialNbr) && (ri.expiryDate === pn.ivn.expiryDate) && (ri.stockItemId === pn.ivn.stockItemId)))?.portalQuantity;
            var existingOfThisOut = props.existingPRNs?.find((ri) => ((ri.ivn.lotSerialNbr === pn.ivn.lotSerialNbr) && (ri.ivn.expiryDate === pn.ivn.expiryDate) && (ri.ivn.stockItemId === pn.ivn.stockItemId)))?.quantity
            totalQuant += Number(pn?.quantity);
            var portalTotal = (pn.ivn.portalQuantity ? pn.ivn.portalQuantity : (Number(pn.newIvn?.portalQuantity ? pn.newIvn?.portalQuantity : 0) + Number(existingOfThisOut)))
            return (<Grid container spacing={2} mb={1} key={pn.id} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6} onClick={openCB ? addtoCombo(pn) : () => { }}>
                    <Typography variant={"h5"} mb={0}  >{getPRNfrmLSN(pn.ivn.lotSerialNbr) + '    (Use by: ' + dateFormat(pn.ivn.expiryDate) + ')'}</Typography>
                    <Typography variant={"h5"} mb={0}>{pn.ivn.stockItem.description + ' (' + portalTotal + ')'}</Typography>
                    {checkAccess(props.access, requiredAdminAccess) && (Number(portalTotal) < Number(pn.quantity)) ? (errorOnCreate ? errorOnCreate : (loadingOnCreate ? <CircularProgress /> : <Button onClick={createStock(props.saleItem, pn.ivn, Number(portalTotal), Number(pn.quantity))}>Create Additonal {Number(pn.quantity) - Number(portalTotal)} doz</Button>)) : null}
                </Grid>
                {/*<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>*/}
                {/*    <ControlledTextField key={'unit' + ind} title="Units" state={pn.units} setState={updateLine(ind, 'units')} disabled={props.disabled} number />*/}
                {/*</Grid>*/}
                <Grid item xs={11} sm={5} md={5} lg={4} xl={4}>
                    <ControlledTextField key={'qua' + ind} title="Quantity" state={pn.quantity} setState={updateLine(ind, 'quantity')} disabled={props.disabled} number width='150px' />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <IconButton aria-label="Label" onClick={() => { saveLabel([pn], props.saleItem, props.codeInfo, props.codeInfo.isHalfshell, editUnitsStore[ind],null,props.selSaleItem) }} >
                        <ArticleIcon />
                    </IconButton>
                    {editUnitsStore[ind] ? <ControlledTextField key={'equa' + ind} title="Label Oys/Unit" state={editUnitsStore[ind]} setState={updateEditUnitsStore(ind)} disabled={props.disabled} number width='100px' /> :
                        <IconButton aria-label="Label" onClick={() => { updateEditUnitsStore(ind)(props.quantPerUnit) }} >
                            <BorderColorIcon />
                        </IconButton>}
                    <IconButton aria-label="Label" onClick={removeLine(ind)} >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>)
        }) : null
    }

        <Grid item xs={12} >
            <Typography variant={"h3"} mb={0}>Total Allocated: {totalQuant ? numberFormat(totalQuant) : 0}</Typography>
            {props.isHalfshell ? <Button variant="contained" color="primary" onClick={openComboBuilder} disabled={props.disabled}>Combo Label</Button> : null}
        </Grid>
        {openCB ? <Div style={style} >
            <IconButton style={exitIcon} aria-label="close" onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h5" component="h2">
                Choose Allocations to Combine
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {comboLabelAllocations.map((o, ind) => <Grid container spacing={2} mb={1} >
                    <Typography style={{ padding: '10px', marginTop: '10px' }} >{o.pn.ivn.lotSerialNbr}</Typography>
                    <ControlledTextField key={ind} title="doz" state={o.quantity} setState={addComboQuantity(ind)} disabled={props.disabled} number width='70px' />
                </Grid>)}
            </Typography>
            <ControlledTextField key={'dfgdf'} title="Label Oys/Unit" state={comboLabelUnits} setState={setComboLabelUnits} disabled={props.disabled} number width='100px' />
            <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={printLabel} disabled={props.disabled}>Print Label</Button>

        </Div> : null}
    </Div>;
}

export default FulfilmentForm;
