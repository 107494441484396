import React from 'react';
import { Button, IconButton, FormControlLabel, TableBody, Table, TableRow, TableCell, TableHead, ToggleButtonGroup, ToggleButton, Grid, CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import FormModal from '../../../components/FormModal';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import LinearProgress from '@mui/material/LinearProgress';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import UploadIcon from '@mui/icons-material/Upload';
import Checkbox from '@mui/material/Checkbox';
import TooltipDescription from "../../../components/TooltipDescription";
import ReplayIcon from '@mui/icons-material/Replay';
import { textfieldDateFormat, dayCompare } from '../../../utils/dateFunctions';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { locationMatch, buildColGroups } from '../../../utils/constants/orderItems';
//import { topLevelList } from '../../../utils/constants/topLevelOrderItems';
import { checkAccess } from '../../../utils/roleFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import Link from "@mui/material/Link";
import { GetAllCacheData, addToCache } from "../../../utils/cacheFunctions";
import { getAPIDataParamsReturn, postApi } from '../../../utils/apiFunction';
import JumboCardQuick from '../../../../@jumbo/components/JumboCardQuick';
import { numberFormat } from '../../../utils/numberFunctions';
import downloadPackingSlip from '../../../utils/downloadPdf';
import downloadPOD from '../../../utils/downloadPOD';
import ArticleIcon from '@mui/icons-material/Article';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useJumboTheme } from "@jumbo/hooks";
import RefreshIcon from '@mui/icons-material/Refresh';
import { WAREHOUSEGROUPS, WAREHOUSESTRINGS } from "../../../utils/constants/warehouse";
import FulfilmentForm from "../Forms/FulfilmentFormLists";
import CompleteFulfilmentForm from "../Forms/CompleteFulfilmentForm";
import LayersIcon from '@mui/icons-material/Layers';
import HeadRows from '../../../components/CustomerOrder/HeadRows';
import { printColor, checkCols, shiftCol, findbyTopLevel, generateValues, getColFromCol, findbyName } from '../../../components/CustomerOrder/CustomerOrderFunctions';
const statusOpen = 'Open';
const statusOnHold = 'On Hold';
const statusReady = 'Ready';
const statusCancelled = 'Cancelled';
const statusShipping = 'Shipping';
const statusCompleted = 'Completed';




const CustomerOrder = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [tableData, setTableData] = React.useState([]);
    const [allData, setAllData] = React.useState([]);
    const [selectList, setSelectList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [display, setDisplay] = React.useState([0]);
    const [tempTitle, setTempTitle] = React.useState(null);
    const [selSaleItem, setSelSaleItem] = React.useState(null);
    const [minimise, setMinimise] = React.useState(false);
    const [fullScreen, setFullScreen] = React.useState(false);
    const [statusFilter, setStatusFilter] = React.useState([statusOpen, statusOnHold]);
    const [selIdList, setSelIdList] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [OrderColumns, setOrderColumns] = React.useState([]);
    const [displayStruc, setDisplayStruc] = React.useState([]);
    const [allCols, setAllCols] = React.useState([]);
    const [topLevelList, setTopLevelList] = React.useState([]);
    const [openCFF, setOpenCFF] = React.useState(false);
    const [completeRowData, setCompleteRowData] = React.useState(null);
    const [fulFilFinish, setFulFilFinish] = React.useState(false);
    const requiredViewAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.VIEW };
    const requiredEditAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.EDIT };
    const requiredMasterAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.MASTER };
    const requiredMYOBAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.MYOB };
    const [hasBeenCompleted, setHasBeenCompleted] = React.useState(false);
    const [forShipping, setForShipping] = React.useState(false);



    var displayOptions = [

        {
            cols: [...allCols],
            name: 'Summary'
        }
        ,
        {
            cols: [...findbyName("Bagged (BBay)", OrderColumns), ...findbyName("Boxed (BBay)", OrderColumns), ...findbyName("Half Shell (BBay)", OrderColumns), ...findbyName("Frozen (BBay)", OrderColumns)],
            name: 'BBay Summary',
            warehouse: WAREHOUSESTRINGS.BBayLive
        },
        {
            cols: [...findbyName("Bagged (BBay)", OrderColumns), ...findbyName("Boxed (BBay)", OrderColumns)],
            name: 'BBay Live',
            warehouse: WAREHOUSESTRINGS.BBayLive
        },
        {
            cols: [...findbyName("Half Shell (BBay)", OrderColumns)],
            name: 'BBay HalfShell',
            warehouse: WAREHOUSESTRINGS.BBayLive
        },
        {
            cols: [...findbyName("Frozen (BBay)", OrderColumns)],
            name: 'BBay Frozen',
            warehouse: WAREHOUSESTRINGS.BBayLive
        },
        {
            cols: [...findbyName("Bagged (Syd)", OrderColumns), ...findbyName("Boxed (Syd)", OrderColumns), ...findbyName("Half Shell (Syd)", OrderColumns), ...findbyName("Half Shell (Contractor)", OrderColumns), ...findbyName("Frozen (Syd)", OrderColumns), ...findbyName("Frozen (Contractor)", OrderColumns)],
            name: 'Syd Summary',
            warehouse: WAREHOUSESTRINGS.Sydney,
            //hide: (checkAccess(props.access, requiredMasterAccess)?false:true)
        },
        {
            cols: [...findbyName("Bagged (Syd)", OrderColumns), ...findbyName("Boxed (Syd)", OrderColumns)],
            name: 'Syd Live',
            warehouse: WAREHOUSESTRINGS.Sydney
        },
        {
            cols: [...findbyName("Half Shell (Syd)", OrderColumns), ...findbyName("Half Shell (Contractor)", OrderColumns)],
            name: 'Syd HalfShell',
            warehouse: WAREHOUSESTRINGS.Sydney
        },
        {
            cols: [...findbyName("Frozen (Syd)", OrderColumns), ...findbyName("Frozen (Contractor)", OrderColumns)],
            name: 'Syd Frozen',
            warehouse: WAREHOUSESTRINGS.Sydney
        },
    ];







    //



    const handleClose = () => {
        setOpen(false);
        setSelIdList(null);
    }
    const handleCloseCFF = () => {
        setOpenCFF(false);
        setForShipping(false);
        setCompleteRowData(null);
    }
    const handleSelectCustomerOrder = (id, comp, soId, selItem) => () => {
        setSelSaleItem(selItem);
        setOpen(true);
        setSelIdList(id);
        setFulFilFinish(comp);
        //postApi('/Fullfillment/CreateShipment', { SalesOrderId: soId });
    }
    const reloadFunc = () => {
        setReload(!reload);
    }
    const colorShift = (col, variation) => {
        var multiFactor = 10;
        var r = Number(col.substring(4, 7));
        var g = Number(col.substring(9, 12));
        var b = Number(col.substring(14, 17));
        console.log(r, g, b)
        if (r + (variation * multiFactor) >= 255) {
            r = r - (variation * multiFactor);
        } else {
            r = r + (variation * multiFactor);
        }
        if (g + (variation * multiFactor) >= 255) {
            g = g - (variation * multiFactor);
        } else {
            g = g + (variation * multiFactor);
        }
        if (b + (variation * multiFactor) >= 255) {
            b = b - (variation * multiFactor);
        } else {
            b = b + (variation * multiFactor);
        }

        var newcol = "rgb(" + r + "," + g + "," + b + ")";
        console.log(newcol)
        return newcol
    }
    const productPalette = theme.palette.oysterProducts;
    const { innerWidth: width, innerHeight: height } = window;
    React.useEffect(async () => {
        var getCols = await (await buildColGroups(true));
        setOrderColumns(getCols.sortList);
        setDisplayStruc(getCols.displayStruc);
        var cols = [productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F, colorShift(productPalette.A, 2), colorShift(productPalette.B, 2), colorShift(productPalette.C, 2), colorShift(productPalette.D, 2), colorShift(productPalette.E, 2), colorShift(productPalette.F, 2), colorShift(productPalette.A, 4), colorShift(productPalette.B, 4), colorShift(productPalette.C, 4), colorShift(productPalette.D, 4), colorShift(productPalette.E, 4), colorShift(productPalette.F, 4)];
        setTopLevelList(getCols?.displayStruc.map((tl, ind) => {

            return {
                name: tl.name,
                col: generateValues(cols[ind]),
                cols: findbyTopLevel(tl.name, getCols.sortList)
            }
        }));
    }, [])

    React.useEffect(() => {
        setAllCols(OrderColumns.map((oc, ind) => ind));
    }, [OrderColumns])

    React.useEffect(() => {
        if (props.location) {
            if (props.location === WAREHOUSEGROUPS.BBay) {
                setDisplay([1]);
            } else {
                setDisplay([4]);
            }
        } else {
            GetAllCacheData(setDisplay, 'customerorderselection');
        }
    }, [props.location])
    const combineCols = (selects) => {
        var cols = [];
        for (var i = 0; i < selects.length; i++) {
            cols = [...cols, ...displayOptions[selects[i]].cols];
        }
        return cols;
    }


    var stickyCol = {
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper
    }
    var stickyColON = {
        position: "sticky",
        left: "240px",
        background: theme.palette.background.paper
    }
    const [showColumns, setShowColumns] = React.useState(combineCols(display)); //only cahgne in geneterate body data
    const getData = async (start, abortController) => {
        const data = await getAPIDataParamsReturn('/Sales/GetForSOReport', {
            day: start
        }, abortController);
        return data;
    }
    React.useEffect(() => {
        generateBodyRows(allData, allCols);
    }, [allData, allCols])
    React.useEffect(() => {
        const abortController = new AbortController();
        //getData then
        setLoading(true);
        var d = props.startDate;
        if (d instanceof Date && !isNaN(d)) {
            getData(textfieldDateFormat(props.startDate), abortController).then((data) => {
                setAllData(data);
                setLoading(false);
            })
        }
        return () => {
            abortController.abort();
        };
    }, [props.startDate, reload, props.externalReload])
    React.useEffect(() => {
        //rebuild with full input data
        if (display.length > 0) {
            generateBodyRows(allData, allCols);
            setTempTitle(null);
        }

    }, [display, statusFilter])





    const reduceShowColumns = (cols, colList) => {
        var newCols = [...colList.filter((col) => {
            return cols.includes(col);
        })]
        return (newCols);
    }
    const selectCustomer = (custOrderNo) => () => {
        if (tempTitle) {
            setDisplay([0]);
        } else {
            var customer = allData.filter((ad) => { return ad.orderNo === custOrderNo })
            generateBodyRows(customer, allCols);
            setTempTitle("Order Filter: " + custOrderNo);
        }

    }


    const generateBodyRows = (recData, colList) => {
        var dataCols = [];
        var data = [];
        if (Array.isArray(recData)) {
            var data = [...recData];
        }
        var newData = [];
        var complOrderNo = [];
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < OrderColumns.length; j++) {
                if ((OrderColumns[j].code === data[i].code) && (OrderColumns[j].location === locationMatch(data[i].location))) {
                    data[i]["column"] = j;
                    if (statusFilter.includes(data[i].status)) {
                        dataCols.push(j);
                    }

                }
            }
        }
        for (var i = 0; i < data.length; i++) {

            if (statusFilter.includes(data[i].status)) {
                if (!complOrderNo.includes(data[i].orderNo)) {
                    complOrderNo.push(data[i].orderNo);
                    newData.push({
                        customer: data[i].customer,
                        orderNo: data[i].orderNo,
                        orderType: data[i].orderType,
                        comment: data[i].comment,
                        salesOrderId: data[i].salesorderid,
                        shipVia: data[i].shipVia,
                        items: [{
                            "code": data[i].code,
                            "id": data[i].id,
                            shipmentMYOBId: data[i].shipmentMYOBId,
                            "myobStatus": data[i].myobStatus,
                            "quantity": data[i].quantity,
                            "location": data[i].location,
                            "warehouseId": data[i].warehouseId,
                            "customerPO": data[i].customerPO,
                            "column": data[i].column,
                            "status": data[i].status,
                            "fullfilled": data[i].fullfilled
                        }]
                    })
                    for (var j = 0; j < data.length; j++) {
                        if ((data[i].orderNo === data[j].orderNo) && (j !== i)) {
                            if (statusFilter.includes(data[j].status)) {
                                newData[newData.length - 1].items.push({
                                    "id": data[j].id,
                                    "code": data[j].code,
                                    "quantity": data[j].quantity,
                                    shipmentMYOBId: data[j].shipmentMYOBId,
                                    "myobStatus": data[j].myobStatus,
                                    "location": data[j].location,
                                    "warehouseId": data[j].warehouseId,
                                    "customerPO": data[j].customerPO,
                                    "column": data[j].column,
                                    "status": data[j].status,
                                    "fullfilled": data[j].fullfilled

                                })
                            }
                        }
                    }
                }
            }

        }
        var newCols = reduceShowColumns(combineCols(display), reduceShowColumns(dataCols, colList));
        //var newCols = reduceShowColumns(combineCols(display),  colList); //show all cols
        var onlyDisplayCust = [];
        for (var i = 0; i < newData.length; i++) {
            var includeCustomer = false;
            for (var j = 0; j < newData[i].items.length; j++) {
                if (newCols.includes(newData[i].items[j].column)) {
                    includeCustomer = true;
                }
            }
            if (includeCustomer) {
                onlyDisplayCust.push(newData[i]);
            }
        }


        setShowColumns(newCols);
        setTableData(onlyDisplayCust);
    }

    const openCompleteFulfilForm = (rowData, allAl, completed) => () => {
        setOpenCFF(true);
        setCompleteRowData(rowData);
        if (completed) {
            setHasBeenCompleted(true);
        } else {
            setHasBeenCompleted(false);
        }
    }
    const createShipmentOne = (rowData, allAl, completed) => () => {
        setOpenCFF(true);
        setCompleteRowData(rowData);
        setForShipping(true);
        //if (completed) {
        //    setHasBeenCompleted(true);
        //} else {
        //    setHasBeenCompleted(false);
        //}
    }
    const createShipmentList = (rowData, allAl, completed) => () => {
        //setOpenCFF(true);
        //setCompleteRowData(rowData);
        //if (completed) {
        //    setHasBeenCompleted(true);
        //} else {
        //    setHasBeenCompleted(false);
        //}
    }
    const addToSelectList = (sel, add) => {
        var cpy = [...selectList];
        if (add) {
            cpy.push(sel);
        } else {
            cpy = cpy.filter((c) => c.salesOrderId !== sel.salesOrderId);
        }
        setSelectList(cpy);
    }
    const selectAllSelectList = (checked) => {
        if (!checked) {
            setSelectList([]);
        } else {
            setSelectList(tableData.filter((td) => td.markedComplete));
        }
    }
    const BodyRows = () => {

        var totalStyle = {
            fontWeight: 'bold'
        }

        const totalCol = (dt, col) => {
            var total = 0;
            for (var i = 0; i < dt.length; i++) {
                for (var j = 0; j < dt[i].items.length; j++) {
                    if (dt[i].items[j].column === col) {
                        total = total + dt[i].items[j].quantity;
                    }
                }
            }
            return total;
        }

        var totalStyleCellEmp = {
            fontWeight: 'bold',
        }

        var prevColB = {};
        var shiftColSaveB = {};
        var custSize = {
            width: '240px',
            maxHeight: '70px',
            overflow: 'auto'

        }
        var commSize = {
            fontSize: 'smaller',
            width: '240px',
            maxHeight: '70px',
            overflow: 'auto'

        }
        const markIncomplete = (rowData, allAl, warehouse) => () => {
            postApi('/Fullfillment/MarkIncomplete', { SalesOrderId: rowData.salesOrderId, WarehouseId: warehouse }).then((data) => {
                reloadFunc();
            })
        }
        const colorMixer = (base, added) => {
            var mix = { r: 0, g: 0, b: 0, a: 0 };
            mix.a = 1 - (1 - added.a) * (1 - base.a); // alpha
            mix.r = Math.round((added.r * added.a / mix.a) + (base.r * base.a * (1 - added.a) / mix.a)); // red
            mix.g = Math.round((added.g * added.a / mix.a) + (base.g * base.a * (1 - added.a) / mix.a)); // green
            mix.b = Math.round((added.b * added.a / mix.a) + (base.b * base.a * (1 - added.a) / mix.a)); // blue
            return 'rgba(' + mix.r + ',' + mix.g + ',' + mix.b + ',' + mix.a + ')';
        }
        return (<TableBody>
            {!props.summary ? tableData.map((td) => {
                var prevCol = {};
                var shiftColSave = {};
                var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                var allAllocated = true;
                var completedAllocation = false;
                var readyFulfillment = false;
                var completedFulfillment = false;
                var orderOnHold = false;
                td.items.map((item) => {
                    if (showColumns.includes(item.column)) {
                        if (item.fullfilled < item.quantity) {
                            allAllocated = false;
                        }
                    }
                });
                var firstOfWarehouse = td.items.find((item) => showColumns.includes(item.column));
                if ((firstOfWarehouse.status !== statusOpen) && (firstOfWarehouse.status !== statusOnHold) && (firstOfWarehouse.status !== statusReady)) {
                    completedFulfillment = true;
                }
                if (firstOfWarehouse.status === statusOnHold) {
                    orderOnHold = true;
                }
                if (firstOfWarehouse.status === statusReady) {
                    readyFulfillment = true;
                }
                var todayAtEleven = new Date();
                todayAtEleven.setHours(11, 30, 0, 0);
                //var passedShipmentTime = dayCompare(props.startDate, new Date()) ? (new Date() > todayAtEleven) : (props.startDate < new Date());
                //if (process.env.REACT_APP_MYOB_TENANT === 'AOCTest') {
                //    passedShipmentTime = true;
                //}
                var hasShipNumber = firstOfWarehouse.shipmentMYOBId !== null;
                var errorOnLine = false;
                var errorCol = 'indianred';
                var errorOnFFStyle = {
                    background: errorCol
                };
                if (errorOnLine) {
                    stickyCol.background = errorCol;
                    stickyColON.background = errorCol;
                }
                var shippingPages = !display.includes(0) && !display.includes(2) && !display.includes(3) && !display.includes(4) && !display.includes(6) && !display.includes(7) && !display.includes(8) && (checkAccess(props.access, requiredEditAccess)) && !completedFulfillment && !orderOnHold
                //console.log(td)
                var inProgress = firstOfWarehouse.myobStatus === 0;
                //nightmare inline fucntion
                return (<TableRow style={errorOnLine ? errorOnFFStyle : {}} >
                    {(readyFulfillment && shippingPages ? <TableCell >
                        <IconButton aria-label="complete" onClick={createShipmentOne(td, allAllocated, true)} >
                            {true ? null : <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox style={{ paddingLeft: '0px', paddingRight: '0px' }} checked={selectList.find((sl) => (sl.salesOrderId === td.salesOrderId))} onChange={(event) => { addToSelectList(td, event.target.checked); }} />} label="" disabled={props.disabled} labelPlacement="right" />}
                            <RocketLaunchIcon />
                        </IconButton>
                        <IconButton aria-label="complete" onClick={markIncomplete(td, allAllocated, firstOfWarehouse.warehouseId)} ><ReplayIcon /></IconButton>
                    </TableCell> :
                        (<TableCell >{shippingPages ?
                            (firstOfWarehouse.myobStatus === -1 ?
                                (<IconButton aria-label="complete" color={allAllocated ? "success" : 'error'} onClick={openCompleteFulfilForm(td, allAllocated)} ><CheckCircleIcon /></IconButton>)
                                : (inProgress ? <LinearProgress /> : (firstOfWarehouse.myobStatus === 2 ? <div style={{ color: 'red' }} >Error</div> : "")))
                            : (display.includes(0) ?
                                <IconButton aria-label="POD" onClick={downloadOnePOD(td.salesOrderId)} style={{ fontSize: '70%' }} >POD</IconButton>
                                : (firstOfWarehouse.myobStatus !== 2 ?
                                    (completedFulfillment ?
                                        <IconButton aria-label="complete" onClick={openCompleteFulfilForm(td, allAllocated, true)} ><LocalShippingIcon /></IconButton>
                                        : '')
                                    : <div style={{ color: 'red' }} >Error</div>))}</TableCell>))}
                    <TableCell style={stickyCol} onClick={selectCustomer(td.orderNo)}><div style={custSize}>{td.customer ? td.customer.name : ''}{(!display.includes(0) && (completedFulfillment)&&hasShipNumber) ? <IconButton style={{ padding: 0 }} onClick={downloadPackingSlip(td, ((completedFulfillment) && !orderOnHold), displayOptions[display]?.warehouse)}>
                        <ArticleIcon style={{ height: '19px' }} />
                    </IconButton> : null}</div></TableCell>
                    <TableCell style={stickyColON} onClick={selectCustomer(td.orderNo)} align="center"  ><Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=' + td.orderType + '&OrderNbr=' + td.orderNo} onClick={(e) => { e.stopPropagation(); }} target='_blank'>{td.orderNo}</Link></TableCell>
                    <TableCell ><div style={commSize}>{td.comment}</div></TableCell>
                    {showColumns.map((col) => {
                        //assumtion here that there will only be one sale item with the same product code
                        var ifItem = td.items.find((it) => {
                            return it.column === col;
                        });
                        var errorOnItem = false;
                        var countOfType = 0;
                        for (var i = 0; i < td.items.length; i++) {
                            if (td.items[i].column === col) {
                                if (countOfType > 0) {
                                    errorOnItem = true;
                                }
                                countOfType++;
                            }
                        }
                        var getItem = ifItem ? {
                            ...ifItem
                        } : null;


                        var colCol = (getColFromCol(col, topLevelList));
                        var pCol = printColor(colCol);
                        if (printColor(prevCol) === printColor(colCol)) {
                            pCol = printColor(shiftCol(shiftColSave));
                            shiftColSave = shiftCol(shiftColSave)
                        } else {
                            shiftColSave = colCol;
                        }
                        prevCol = colCol;
                        var isOnHold = false;
                        if (getItem) {

                            var statCol = productPalette.open
                            if (getItem.status === statusOpen) {
                                statCol = productPalette.open

                            } else if (getItem.status === statusOnHold) {
                                statCol = productPalette.onHold;
                                isOnHold = true;

                            } else if (getItem.status === statusCancelled) {
                                statCol = productPalette.cancelled

                            } else if (getItem.status === statusShipping) {
                                statCol = productPalette.shipping

                            } else if (getItem.status === statusReady) {
                                statCol = productPalette.completed

                            } else if (getItem.status === statusCompleted) {
                                statCol = productPalette.completed

                            }


                            var colObj = generateValues(errorOnLine ? errorCol : pCol);
                            var borderString = '1px solid ' + colorMixer({ ...colObj, a: 1 }, { r: 0, g: 0, b: 0, a: 0.1 });
                            var selectedStyle = {
                                background: errorOnLine || errorOnItem ? errorCol : statCol,
                                borderLeft: borderString,
                                borderRight: borderString,
                                borderBottom: borderString,
                                color: productPalette.selectedText
                            }
                            var compFul = false;
                            if (getItem.fullfilled >= getItem.quantity) {
                                compFul = true;
                            }
                            var cellValue = numberFormat(getItem.quantity) + ' (' + numberFormat(getItem.fullfilled) + ')';
                            return <TableCell align="center" style={selectedStyle} onClick={checkAccess(props.access, requiredViewAccess) && !isOnHold ? handleSelectCustomerOrder(getItem.id, completedFulfillment || inProgress || readyFulfillment, td.salesOrderId, getItem) : () => { }}>{errorOnItem ? <TooltipDescription title={'There is a duplicate entry for this line, please check this order on MYOB'} /> : null}{cellValue}</TableCell>

                        } else {

                            return <TableCell style={{
                                backgroundColor: errorOnLine ? errorCol : pCol,
                                borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                                borderRight: '1px solid rgba(0, 0, 0, 0.1)'
                            }}></TableCell>
                        }
                    })}
                </TableRow>)

            }) : null}
            <TableRow style={totalStyle}>
                <TableCell style={totalStyleCellEmp}></TableCell>
                <TableCell style={totalStyleCellEmp}>Totals</TableCell>
                <TableCell style={totalStyleCellEmp} align="center" >{numberFormat(showColumns.reduce((p, col) => { return p + totalCol(tableData, col) }, 0))}</TableCell>
                <TableCell style={totalStyleCellEmp}></TableCell>
                {showColumns.map((col) => {
                    var colCol = (getColFromCol(col, topLevelList));
                    var pCol = printColor(colCol);
                    if (printColor(prevColB) === printColor(colCol)) {
                        pCol = printColor(shiftCol(shiftColSaveB));
                        shiftColSaveB = shiftCol(shiftColSaveB)
                    } else {
                        shiftColSaveB = colCol;
                    }
                    prevColB = colCol;
                    var totalStyleCell = {
                        fontWeight: 'bold',
                        backgroundColor: pCol,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.1)'
                    }
                    return <TableCell style={totalStyleCell} align="center" >{numberFormat(totalCol(tableData, col))}</TableCell>
                })}
            </TableRow>
        </TableBody>)

    }

    var tableStyle = {
        overflow: "auto",
        maxHeight: "700px"
    }
    const changeFilter = (event, sel) => {
        setStatusFilter(sel);

    }
    const downloadAllPOD = () => {
        downloadPOD(null, props.startDate, displayOptions[display]?.warehouse);

    }
    const downloadOnePOD = (soId) => (e) => {
        downloadPOD(soId, props.startDate);

    }
    const changeCols = (event, newSelection) => {
        var holdSelect = newSelection;
        if (!Array.isArray(newSelection)) {
            holdSelect = [newSelection];
        }
        addToCache(holdSelect, 'customerorderselection');
        setDisplay(holdSelect);

    }
    const hoverStyle = {
        borderCollapse: 'separate',
        "& .MuiTableRow-root:hover": {
            backgroundColor: "#A67FFB !important",
            "& .MuiTableCell-root": {
                backgroundColor: "#A67FFB !important",
                color: "black",
                //fontWeight: "bold",

            }
        },
    }
    const fillScreen = {
        height: height,
        overflow: "auto",
        position: "absolute",
        left: 0,
        width: "100%",
        top: 0,
        zIndex: 10000
    }
    const expandToFull = () => {
        setFullScreen(!fullScreen)
    }
    var lgxlSpace = props.location ? 5 : 12;
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Fulfil Order"} >
                <FulfilmentForm id={selIdList} selSaleItem={selSaleItem} access={props.access} reload={reloadFunc} handleClose={handleClose} completed={fulFilFinish} />
            </FormModal>
            <FormModal open={openCFF} handleClose={handleCloseCFF} title={"Submit to MYOB"} >
                <CompleteFulfilmentForm forShipping={forShipping} completed={hasBeenCompleted} rowData={completeRowData} displayOptions={display.map((d) => (displayOptions[d]))} access={props.access} reload={reloadFunc} handleClose={handleCloseCFF} />
            </FormModal>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={12} lg={lgxlSpace} xl={lgxlSpace}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={props.disabled}
                        value={statusFilter}
                        onChange={changeFilter}
                        aria-label="text alignment"
                    >
                        <ToggleButton key={1} value={statusOnHold} >
                            <div  >On Hold</div>
                        </ToggleButton>
                        <ToggleButton key={0} value={statusOpen} >
                            <div  >Open</div>
                        </ToggleButton>
                        <ToggleButton key={4} value={statusReady} >
                            <div  >Ready</div>
                        </ToggleButton>
                        <ToggleButton key={2} value={statusShipping} >
                            <div  >Shipping</div>
                        </ToggleButton>
                        <ToggleButton key={3} value={statusCompleted} >
                            <div  >Completed</div>
                        </ToggleButton>


                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={lgxlSpace} xl={lgxlSpace}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={props.disabled}
                        value={display}
                        exclusive={true}
                        onChange={changeCols}
                        aria-label="text alignment"
                    >
                        {displayOptions.map((option, ind) => {
                            if ((!(props.location) || option.name.includes(props.location)) && (!option.hide)) {
                                return (
                                    <ToggleButton key={ind} value={ind} >
                                        <div>
                                            <div  >{option.name}</div>
                                        </div>
                                    </ToggleButton>
                                )
                            } else {
                                return null;
                            }
                        }
                        )}


                    </ToggleButtonGroup>
                    {!fullScreen ? <React.Fragment><IconButton onClick={expandToFull}>
                        <OpenInFullIcon />
                    </IconButton>
                        <IconButton onClick={reloadFunc}>
                            <RefreshIcon />
                        </IconButton>
                        <IconButton onClick={downloadAllPOD}>
                            <LayersIcon />
                        </IconButton>
                        {minimise ? <IconButton onClick={() => { setMinimise(!minimise) }}>
                            <ExpandMoreIcon />
                        </IconButton> : <IconButton onClick={() => { setMinimise(!minimise) }}>
                            <ExpandLessIcon />
                        </IconButton>}
                        {selectList.length > 0 ? <Button onClick={createShipmentList} variant='outlined'>Ship Selected Orders</Button> : null}
                    </React.Fragment> : ''}

                </Grid>
            </Grid>
            <JumboCardQuick style={fullScreen ? fillScreen : tableStyle}>
                {fullScreen ? <React.Fragment><IconButton onClick={expandToFull}>
                    <CloseFullscreenIcon />
                </IconButton>
                    <IconButton onClick={reloadFunc}>
                        <RefreshIcon />
                    </IconButton>
                </React.Fragment> : ''}

                <Table sx={hoverStyle}>
                    {!minimise ? <HeadRows toggleSelectList={tableData.filter((td) => (td.markedComplete)).length > 0 ? selectAllSelectList : null} summary={props.summary} OrderColumns={OrderColumns} showColumns={showColumns} tempTitle={tempTitle} topLevelList={topLevelList} /> : null}
                    {loading ? <CircularProgress /> : (!minimise ? <BodyRows /> : null)}
                </Table>
            </JumboCardQuick>
        </React.Fragment>

    );
};


export default CustomerOrder;
