import jsPDF from 'jspdf';
import Logo from '../../images/AOCLogo.png';
import { dateFormatZeros, isDate, undoDateFormat } from '../dateFunctions';
import { getAPIDataReturn } from '../apiFunction';
import { getPRNfrmLSN } from '../fulfilment/lsnFunction';

export const saveLabel = async (detailsList, saleItem, codeInfo, halfshell, forceUnits, comboPRN,extra) => {
    // var PAi = new Image();
    // PAi.src = PA;
    // var MPCi = new Image();
    // MPCi.src = MPC;
    const isFrozen = codeInfo.isFrozen;
    const isPacific = codeInfo.isPacific;
    console.log(detailsList, saleItem, codeInfo, halfshell, forceUnits, comboPRN,extra);
    var doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [160, 110]
    });
    const getProduct = (spec, halfshell) => {
        var out = '';
        switch (spec) {
            case 'PC':
                out = 'Pacific';
                break;
            case 'SR':
                out = 'Sydney Rock';
                break;
            case 'AG':
                out = 'Angasi';
                break;
            default:
                out = 'Oyster';
        }
        return out + ' ' + (halfshell ? 'Halfshell' : (isFrozen ? 'FROZEN' : 'Live'));
    }
    const ifUndef = (field) => {
        return field ? field : '';
    }
    var fontSml = 12;
    var fontOne = 20;//22
    var fontTwo = 20;//17
    var fontThree = 24;
    var Logoi = new Image();
    Logoi.src = Logo;
    var lineWidth = 8
    for (var j = 0; j < detailsList.length; j++) {
        var details = detailsList[j];
        console.log(details);
        var estuary = details?.ivn?.estuary;
        if (!estuary) {
            estuary = details?.newIvn?.estuary?.description;
        }
        var loops = details.units ? details.units : (details.quantity / codeInfo.bagCount);
        if (loops === 0) {
            loops = 1;
        }
        for (var i = 0; i < loops; i++) {
            var line = 5;
            if (!((i === 0) && (j === 0))) {
                doc.addPage();
            }
            doc.setTextColor('#000');
            doc.setFontSize(8)
            doc.addImage(Logoi, 'PNG', 120, line, 40, 35);
            doc.text('30 Cranbrook Rd, Batemans Bay NSW', 110, line + 37);
            doc.text('Lic: 51487', 145, line + 40);
            doc.setFontSize(fontSml)
            line += 5;
            doc.text('Deliver To:' + ((extra?.customerPO) ? ("  " + ifUndef(extra?.customerPO)+""):""), 2, line)
            doc.setFontSize(fontTwo)
            line += lineWidth;
            if (saleItem?.customer?.name.length>35) {
                doc.setFontSize(fontTwo-2)
            }
            doc.text(ifUndef(saleItem?.customer?.name), 2, line)
            doc.setFontSize(fontTwo)
            line += lineWidth;
            doc.text(ifUndef(saleItem?.customer?.shippingAddress?.line1), 2, line)
            line += lineWidth;
            doc.text(ifUndef(saleItem?.customer?.shippingAddress?.line2), 2, line)
            line += lineWidth;
            doc.text(ifUndef((ifUndef(saleItem?.customer?.shippingAddress?.city) + ' ' + ifUndef(saleItem?.customer?.shippingAddress?.state) + ' ' + ifUndef(saleItem?.customer?.shippingAddress?.postcode))), 2, line)
            line += lineWidth;
            line += lineWidth / 2;
            //Space
            doc.setFontSize(fontTwo);
            var xSecond = 60;
            doc.text('Product: ', 2, line);
            doc.text(getProduct(codeInfo.species.code, halfshell), xSecond, line);
            line += lineWidth;
            doc.setFontSize(fontOne);
            doc.text('Size: ', 2, line);
            doc.setFillColor('#000000');
            doc.setTextColor('#fff');
            doc.rect(xSecond, line - 7, 160 - xSecond, 9, 'F');
            if (saleItem?.customer?.name === 'Foodlink Australia PTY LTD') {
                var gradeFlip = {
                    'Cocktail': 'Bistro',
                    'Bistro': 'Large',
                    'Plate': 'Extra Large',
                    'Buffet': 'Large',
                    'Standard': 'Extra Large'
                }
                doc.text(gradeFlip[codeInfo.grade?.description] ? gradeFlip[codeInfo.grade?.description] : codeInfo.grade?.description, xSecond, line);
            } else {
                doc.text(codeInfo.grade?.description, xSecond, line);
            }
            doc.setTextColor('#000');
            line += lineWidth;
            doc.setFontSize(fontOne);
            doc.text('Estuary: ', 2, line);
            doc.setFontSize(fontOne)
            if (comboPRN) {
                var estuaryString = comboPRN.reduce((p, c) => {//check if exists
                    var currEst = c.pn.ivn?.estuary ? c.pn.ivn?.estuary: c.pn.newIvn?.estuary?.description;
                    if (p.includes(currEst)) {
                        return p;
                    } else {
                        return (p === '' ? p : (p + ', ')) + ifUndef(currEst);
                    }
                }, '')


                doc.setFontSize((fontThree + 10) - (estuaryString.length / 2));
                doc.text(ifUndef(estuaryString), xSecond, line);

            } else {
                doc.text(ifUndef(estuary), xSecond, line);
            }
            doc.setFontSize(fontTwo);
            line += lineWidth;
            doc.setFontSize(fontTwo);
            if (halfshell) {
                doc.text('Use By Date: ', 2, line);
                if (comboPRN) {
                    var oldest = comboPRN.reduce((a, b) => {
                        return new Date(a.pn.ivn?.expiryDate) < new Date(b.pn.ivn?.expiryDate) ? a : b;
                    });

                    doc.text(dateFormatZeros(oldest.pn.ivn?.expiryDate), xSecond, line);
                } else {
                    doc.text(dateFormatZeros(details.ivn?.expiryDate), xSecond, line);
                }
            } else {
                if (isFrozen) {
                    doc.text('Best Before: ', 2, line);
                    //best boefore is order date  + 12 months then beignign of next month
                    var bestBefore = new Date();
                    bestBefore.setMonth(bestBefore.getMonth() + 13);
                    bestBefore.setDate(1);
                    doc.text(dateFormatZeros(bestBefore), xSecond, line);
                } else {
                    doc.text('Harvest Date: ', 2, line);
                    //check harvest date is a date
                    console.log(details.ivn?.harvestDate);
                    doc.text((details.ivn?.harvestDate.includes('T') ? dateFormatZeros(details.ivn?.harvestDate) : details.ivn?.harvestDate), xSecond, line); //based on prn
                }
            }
            doc.setFontSize(fontTwo);
            line += lineWidth;
            doc.text('PRN: ', 2, line);
            if (comboPRN) {
                doc.setFontSize(fontTwo - (comboPRN.length + 1));
                if (comboPRN.length > 5) {
                    doc.setFontSize(fontTwo - (comboPRN.length + 2));
                }

                doc.text(String(comboPRN.reduce((p, c) => {
                    if (p === '') {
                        return getPRNfrmLSN(c.pn.ivn?.lotSerialNbr);
                    } else {
                        return p + '/' + getPRNfrmLSN(c.pn.ivn?.lotSerialNbr);
                    }
                }, '')), xSecond, line, { maxWidth: 99 }); //prn
            } else {
                doc.text(String(getPRNfrmLSN(details.ivn?.lotSerialNbr)), xSecond, line); //prn

            }
            doc.setFontSize(fontTwo);

            line += lineWidth + 2;
            doc.text('QTY:(DOZEN) ', 2, line);
            if (comboPRN) {
                var totalVal = 0;
                var calcString = comboPRN.reduce((p, c) => {//check if exists
                    console.log(c);
                    totalVal += Number(c.quantity);
                    return (p === '' ? p : (p + ' + ')) + ifUndef(String(c.quantity));
                }, '');
                if (calcString.length > 22) {
                    doc.setFontSize(fontTwo - Math.pow(1.9,calcString.length - 22) );
                }
                doc.text(calcString +' = '+ String(totalVal), xSecond, line);
            } else {
                doc.text(String(forceUnits ? forceUnits : codeInfo.bagCount), xSecond, line);
            }
            doc.setFontSize(fontSml);
            doc.text('Contains:', xSecond + 60, line);
            doc.setFont(undefined, 'bold');
            doc.text('Molluscs', xSecond + 79, line);
            doc.setFont(undefined, 'normal');
            line += lineWidth;
            line += 2;
            doc.setFontSize(fontThree);
            doc.setTextColor('#fff');
            doc.setDrawColor('#fff');
            if (halfshell || isPacific) {
                doc.rect(0, line - 8, 160, 10, 'F');
                doc.text('Please keep below 5 C', 30, line);
                doc.setLineWidth(0.7)
                doc.circle(110, line - 5, 1)
            } else if (isFrozen) {
                doc.setFontSize(10);
                doc.rect(0, line - 8, 160, 10, 'F');
                doc.text('Store frozen oysters below -18 C', 2, line - 5);
                doc.text('Refrigerate to thaw, do not refreeze, store below 4 C and consume within 3 days', 2, line - 1);
                doc.setLineWidth(0.2)
                doc.circle(50, line - 7, 0.4)
                doc.circle(81, line - 3.5, 0.4)
            }
            if (forceUnits) {
                i = loops;
            }
        }

    }
    //doc.save('Label-' + new Date().toLocaleString() + '.pdf');
    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank', "height=800,width=800");
}