import React from 'react';
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { getOysterGrades,gradeSort } from "../../../definitions/oysters";
import DataTableStandard from "../../../components/DataTableStandard";
import { numberFormat } from '../../../utils/numberFunctions';
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import Link from "@mui/material/Link";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { useJumboTheme } from "@jumbo/hooks";
import { adalApiFetch } from '../../../../adalConfig';
import { reverseSort,dateSort, colRemoval } from '../../../utils/sortFunctions';
import { getAPIDataParamsReturn } from '../../../utils/apiFunction';

const Transfers = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [columns, setColumns] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [grades, setGrades] = React.useState([]);
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, reload, props.externalReload]);
    React.useEffect(() => {
        var cols = [{
            name: 'myobid',
            label: 'Order No.',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                    var proc = props.filter ? props.filter(procurement) : procurement;
                    return <Link href={'https://aoc.myobadvanced.com/(W(2))/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=TR&OrderNbr=' + proc[dataIndex]?.myobid} target='_blank'>{proc[dataIndex]?.myobid}</Link>
                },
                sortCompare: reverseSort

            }
        },
        {
            name: 'orderDate',
            label: 'Date',
            options: {
                sortCompare: dateSort,
                sortDescFirst: true
            }
        },
        {
            name: 'status',
            label: 'Status'
        },
        {
            name: 'sourceWarehouse',
            label: 'Origin'
        },
        {
            name: 'destinationWarehouse',
            label: 'Destination'
        }];
        var oysterCols = grades.map((og) => {
            return {
                name: og.abv.toLowerCase(),
                label: og.abv,
                options: {
                    filter: false,
                    setCellProps: () => ({
                        align: "right"
                    }),
                    customBodyRenderLite: (dataIndex) => {
                        var proc = procurement;
                        if (proc) {
                            if (proc[dataIndex]) {
                                if (proc[dataIndex][og.abv.toLowerCase()]) {
                                    return numberFormat(proc[dataIndex][og.abv.toLowerCase()]);
                                }
                            }
                        }
                        return 0;
                    },
                    
                },
            }
        })
        cols = [...cols, ...oysterCols];
        cols.push(
            {
                name: 'comments',
                label: 'Comments'
            }
        );
        cols.push(
            {
                name: 'promisedDate',
                label: 'Promised',
                options: {
                    sortCompare: dateSort,
                    sortDescFirst: true
                }
            }
        );
        setColumns(colRemoval(procurement, cols,[]));
    }, [procurement,grades]);
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        tableBodyMaxHeight: '400px',
        fixedHeader: true,
        responsive: 'scroll',
        setRowProps: (row) => {
            if (row[2] === ("Completed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (row[2] === ("Shipping")) {
                return {
                    style: { background: theme.palette.oysterProducts.shipping }
                };
            } else if (row[2] === ("Open")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (row[2] === ("Canceled")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            }
        }

    };
    var getProcurementData = async (Location, StartDate, EndDate, abortController) => {
        setLoading(true);
        const data = await getAPIDataParamsReturn('/ProcurementDashboard/Transfers' ,{
            Location,
            StartDate,
            EndDate
        },abortController);
        var newData = data.map((po) => {
            var nObj = { ...po };
            nObj.orderDate = dateFormat(nObj.orderDate);
            nObj.promisedDate = dateFormat(nObj.promisedDate);
            return nObj;
        })
        setLoading(false);
        setProcurement(newData);
    }
    return (
        <DataTableStandard
            data={procurement}
            title={"Transfers"}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={reloadFunc}
            shrink={props.shrinkTable}

        />
    );
};

export default Transfers;
