import React from 'react';
import Grid from "@mui/material/Grid";
import FormModal from '../../../components/FormModal';
import Button from "@mui/material/Button";

import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';

import HistoryDisplay from './HistoryDisplay';
const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const OnShore = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    
    const handleCloseModal = () => {
        props.setOpen(false);

    }
    return (
        <FormModal open={props.open} handleClose={handleCloseModal} title={'History'} style={{ zIndex: 100000 }}>
            <HistoryDisplay GroupId={props.GroupId} />
        </FormModal>
    );
};

export default OnShore;
