import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import Inventory from './Tables/Inventory';
import FormModal from '../../components/FormModal';
import Button from "@mui/material/Button";
import InventoryForm from './Forms/InventoryForm';
import { adalApiFetch } from '../../../adalConfig';
import { dateFormat, textfieldDateFormat, yesterday, dayCompare } from "../../utils/dateFunctions";

const InventoryDash = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [open, setOpen] = React.useState(false);
    const [newForm, setNew] = React.useState(false);
    const [rowData, setRowData] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [showButton, setShowButton] = React.useState(false);
    const [showYest, setShowYest] = React.useState(false);
    const [showFri, setShowFri] = React.useState(false);
    const [showSat, setShowSat] = React.useState(false);
    const [showSun, setShowSun] = React.useState(false);
    const [date, setDate] = React.useState(new Date());
    const reloadFunc = () => {
        setReload(!reload);
    }
    const handleClose = () => setOpen(false);
    const openInvForm = (dataArr) => (rowD, rowState) => {
        setRowData(dataArr[rowState.dataIndex]);
        setOpen(true);
        setNew(false);
    }
    const createInvForm = (selDate) => () => {
        setOpen(true);
        setNew(true);
        setRowData(null);
        setDate(selDate);
    }


    React.useEffect(async () => {
        var isMonday = (new Date().getDay() === 1);
        const response = await adalApiFetch(fetch, uri + '/ManualInventory/Get?' + new URLSearchParams({
            startDate: textfieldDateFormat((isMonday) ? (yesterday(yesterday(yesterday(new Date())))) : yesterday(new Date())),
            endDate: textfieldDateFormat(new Date())
        }));
        const data = await response.json();
        console.log(data);
        var friInv = data.filter((dt) => { return dayCompare(dt.date, yesterday(yesterday(yesterday(new Date())))) });
        var satInv = data.filter((dt) => { return dayCompare(dt.date, yesterday(yesterday(new Date()))) });
        var yestInv = data.filter((dt) => { return dayCompare(dt.date, yesterday(new Date())) });
        var todayInv = data.filter((dt) => { return dayCompare(dt.date, (new Date())) });
        if (todayInv && todayInv.length > 1) {
            setShowButton(false);
        } else {
            setShowButton(true);
        }
        if (yestInv && yestInv.length > 1) {
            if (isMonday) {
                setShowSun(false)
            } else {
                setShowYest(false);
            }
        } else {
            if (isMonday) {
                setShowSun(true)
            } else {
                setShowYest(true);
            }
        }
        if (isMonday) {
            if (satInv && satInv.length > 1) {
                setShowSat(false);
            } else {
                setShowSat(true);
            }
            if (friInv && friInv.length > 1) {
                setShowFri(false);
            } else {
                setShowFri(true);
            }
        }
    }, [reload])

    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Log Daily Inventory"} >
                <InventoryForm date={date} new={newForm} data={rowData} access={props.access} reload={reloadFunc} handleClose={handleClose} CustomerPricing />
            </FormModal>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
            </Grid>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        {showFri ? <Button style={{ marginRight: '15px' }} onClick={createInvForm(yesterday(yesterday(yesterday(new Date()))))} variant="contained" >Log Friday's Inventory</Button> : ''}
                        {showSat ? <Button style={{ marginRight: '15px' }} onClick={createInvForm(yesterday(yesterday(new Date())))} variant="contained" >Log Saturday's Inventory</Button> : ''}
                        {showSun ? <Button style={{ marginRight: '15px' }} onClick={createInvForm(yesterday(new Date()))} variant="contained" >Log Sunday's Inventory</Button> : ''}
                        {showYest ? <Button style={{ marginRight: '15px' }} onClick={createInvForm(yesterday(new Date()))} variant="contained" >Log Yesterday's Inventory</Button> : ''}
                        <Button onClick={createInvForm(new Date())} variant="contained" disabled={!showButton}>{showButton ? 'Log Daily Inventory' : "Today's entry already added"}</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Inventory startDate={startDate} endDate={tomorrow(endDate)} onClick={openInvForm} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default InventoryDash;
