import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { dateTimeFormat } from "../../../utils/dateFunctions";
import { TableCell, TableRow } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import { reverseSort, dateSortLong } from '../../../utils/sortFunctions';
import DataTableStandard from "../../../components/DataTableStandard";

const EstuaryStatus = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);

    const [reload, setReload] = React.useState(true);
    const [estFilterOptions, setEstFilterOptions] = React.useState(null);
    const [columns, setColumns] = React.useState([{
        name: 'estuary',
        label: 'Estuary',
    },
    {
        name: 'harvestArea',
        label: 'Harvest Area'
    },
    {
        name: 'state',
        label: 'State'
    },
    {
        name: 'date',
        label: 'Date',
        options: {
            sortCompare: dateSortLong,
            sortDescFirst: true
        }
    },
    {
        name: 'open',
        label: 'Open'
    },
    {
        name: 'status',
        label: 'Status'
    }]);
    React.useEffect(() => {
        if (estFilterOptions) {
            var newCols = [...columns];
            newCols[0].options = estFilterOptions;
            setColumns(newCols);
        }
    }, [estFilterOptions]);
    const reloadFunc = () => {
        setReload(!reload);
    }
    const options = {
        selectableRowsHideCheckboxes: true,
        customRowRender: ((data, dataIndex, rowIndex) => {

            return (<TableRow key={Math.random()}>
                {data.map((c) => {
                    if (data[4] === "Yes") {
                        return <TableCell key={Math.random()} sx={{ color: 'notification.good' }}>{c}</TableCell>
                    } else if (data[4] === "No") {
                        return <TableCell key={Math.random()} sx={{ color: 'notification.error' }}>{c}</TableCell>
                    } else {
                        return <TableCell key={Math.random()} >{c}</TableCell>
                    }
                })}</TableRow>)
        })
    };
    const matchEsts = (ests) => {
        var estNameList = procurement.filter((pc) => {
            for (var i = 0; i < ests.length; i++) {
                if (ests[i]) {
                    if (pc.estuary.includes(ests[i])) {
                        return true;
                    }
                }
            }
            return false;
        }).map((fm) => { return fm.estuary })

        return estNameList.filter(function (item, pos) {
            return estNameList.indexOf(item) == pos;
        });
    }
    React.useEffect(() => {
        if (props.filterEst) {
            if (Array.isArray(props.filterEst)) {

                setEstFilterOptions({ filterList: matchEsts(props.filterEst) });
            } else {
                setEstFilterOptions({ filterList: matchEsts([props.filterEst]) });

            }
        }
    }, [procurement, props.filterEst]);


    React.useEffect(() => {
        setProcurement([]);
        getProcurementData();
    }, [reload]);

    var getProcurementData = async () => {
        const response = await adalApiFetch(fetch, uri + '/estuarystatus');
        const data = await response.json();
        var newData = data.harvestAreas.map((es) => {
            var statuses = es.harvestAreaStatuses ? es.harvestAreaStatuses.sort((a, b) => { return new Date(a.date) > new Date(b.date) ? -1 : 1 }) : [{}];
            var statusObj = statuses[0];
            return {
                estuary: es.estuary?.description,
                state: es.estuary?.state,
                harvestArea: es.description,
                date: statusObj?dateTimeFormat(new Date(statusObj.date)):'',
                open: statusObj?(statusObj.open ? "Yes" : "No"):"Unknown",
                status: statusObj ? statusObj.status :'Unknown'
            }
        });

        setProcurement(newData);
    }
    return (
        <DataTableStandard
            title={"Estuary Status"}
            data={procurement}
            columns={columns}
            options={options}
            reload={reloadFunc}
        />

    );
};

export default EstuaryStatus;
