import React from 'react';
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import StaffMember from "../../../components/StaffMember";
import ControlledTextField from '../../../components/ControlledTextField';
import { postApi } from "../../../utils/apiFunction";
import SubmitButtonDisplayer from '../../../components/SubmitButtonDisplayer';

const AddNewBench = (props) => {
    const Swal = useSwalWrapper();
    const [remaining, setRemaining] = React.useState(null);
    const [complete, setComplete] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [benchName, setBenchName] = React.useState(null);
    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)

        //output must be less than = to available stock
        if (benchName === null || benchName === '') {
            errorMessages.push('Bench Name/Number is required');
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const submit = async (finished) => {
        var allOk = false;
        var resp = null;
        setLoading(true);

        const endparams = {
            Description: benchName,
            WarehouseId: props.warehouseId,
            AllowMultiple: false,
            isCurrent: true
        }
        resp = await postApi('/ProcessLine/Add', endparams);
        setLoading(false);

        if (resp.ok) {
            props.handleClose();
        } else {
            setError("Failed to submit");
        }

    }
    return <Grid container spacing={2} mb={1}>
        <Grid item xs={12}>
            <ControlledTextField title="Bench Name/Number" name="add" state={benchName} setState={setBenchName}  />
        </Grid>
        <Grid item xs={12}>
            <SubmitButtonDisplayer loading={loading} allowed={true} complete={complete} error={error} submitFunc={validate} handleClose={props.handleClose} />
            {/*{loading ? <CircularProgress /> : <Button variant="contained" color="primary" style={{ whiteSpace: 'nowrap', height: '50px', margin: 0 }} onClick={validate} handleClose={props.handleClose} >Complete</Button>}*/}
        </Grid>
    </Grid>

}


export default AddNewBench;
