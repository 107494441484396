import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import DataTableStandard from "../../../components/DataTableStandard";
import { Button, IconButton, FormControlLabel, Switch } from "@mui/material";
import { dateTimeFormat, textfieldDateFormat } from '../../../utils/dateFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import Typography from '@mui/material/Typography';
import { getAPIData } from '../../../utils/apiFunction';
import FormModal from '../../../components/FormModal';
import { useJumboTheme } from "@jumbo/hooks";
import ConfirmReceivalForm from '../Forms/ConfirmReceivalForm';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import NewReceival from '../../Tasks/NewReceival';

const testData = [
    {
        live: true,
        supplier: { name: 'AOC Merimbula' },
        prn: '1234',
        staffMember:  'Macauley',
        date: '11/12/2022',
        inputLines: [],
        outputLines: [],
    }
]

const ProcessingLogs = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [tableData, setTableData] = React.useState([]);
    const [holdData, setHoldData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [suppliers, setSuppliers] = React.useState({});

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,

        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(tableData) : () => { },

        ...props.extraOptions
    };
   
    var columns = [
        {
            name: 'prn',
            label: 'PRN'
        },
        {
            name: 'supplier',
            label: 'Supplier'
        },
        {
            name: 'staffMember',
            label: props.live ? 'Staff Member' : 'Shucker'
        },
        {
            name: 'date',
            label: 'Date'
        }];

    React.useEffect(() => {
        getAPIData('/farmerssuppliers', setSuppliers);

    }, []);
    React.useEffect(() => {
        var newData = holdData.map((dt) => {
            var cpyDT = { ...dt };
            var supp = null;
            if (suppliers.farmers) {
                supp = suppliers.farmers.find((sp) => { return sp.id === cpyDT.supplierId });
            }
            cpyDT.pickupDate = dateTimeFormat(new Date(cpyDT.pickupDate));
            cpyDT.supplier = supp ? supp.name : cpyDT.supplierPredict;
            return cpyDT;
        })
        setTableData(newData);

    }, [holdData, suppliers]);
    React.useEffect(() => {
        setTableData([]);
        //getData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
        setHoldData([...testData]);
    }, [props.startDate, props.endDate, props.reload]);
    
    var getData = async (start, end) => {
        setLoading(true);
        const response = await adalApiFetch(fetch, uri + '/Process/Live' + '?' + new URLSearchParams({
            start,
            end
        }));
        if (response.ok) {
            const data = await response.json();
            var sortData = data.sort((a, b) => {
                return new Date(a.pickUpDate) > new Date(b.pickupDate) ? 1 : -1
            })
            setHoldData(props.filter ? props.filter(sortData) : sortData);
        } else {
            setHoldData([]);
        }
        setLoading(false);
    }
    return (

        <React.Fragment >
            <DataTableStandard
                title={props.title}
                data={props.filter ? props.filter(tableData) : tableData}
                columns={columns}
                options={options}
                returnSearch={!loading}
                reload={props.reloadFunc}
            />
        </React.Fragment>
    );
};

export default ProcessingLogs;
