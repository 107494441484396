import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { getAPIData, postApi, } from "../../../utils/apiFunction";
import { textfieldDateFormat } from "../../../utils/dateFunctions";
import { numberFormatwithNeg } from "../../../utils/numberFunctions";
import OystersGroup from "../../../components/OysterGroup";
import Div from "@jumbo/shared/Div";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { checkAccess } from '../../../utils/roleFunctions';
import SelectTextField from "../../../components/SelectTextField";

const ReceivalForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [updateComplete, setUpdateCompleted] = React.useState(false);
    const [edit, setEdit] = React.useState(props.edit);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [editInput, setEditInput] = React.useState(false);
    const [oystersOut, setOystersOut] = React.useState([{}]);
    const [outputsPassedGroup, setOutputsPassedGroup] = React.useState([]);
    const [stockItems, setStockItems] = React.useState(null);
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredAdminAccess = (props.frozenRegrade?{ key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT }:{ key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN });
    const CompletedAndEdit = edit && completed;
    const CompletedSoDisabled = !edit && completed;
    const supplier = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.receival?.supplier?.name : props.selectedLine.supplier) : '';
    const lotNbr = props.selectedLine ? (CompletedAndEdit ? props.selectedLine.coreInventory?.lotSerialNbr : props.selectedLine.lotSerialNbr) : '';
    const [comment, setComment] = React.useState(CompletedAndEdit ? props.selectedLine?.comment : null);
    const [outputStockItem, setOutputStockItem] = React.useState(null);
    const processRunId = CompletedAndEdit ? props.selectedLine.id : props.selectedLine.processRunId
    const [oystersIn, setOystersIn] = React.useState([{
        bags: props.selectedLine.bags, bins: props.selectedLine.bins, stockItemId: props.selectedLine.stockItemId, quantity: (CompletedAndEdit ? props.selectedLine.quantity : props.selectedLine.amount),
    }]);



    React.useEffect(() => {
        getAPIData('/StockItems/GetProcessed', setStockItems);
    }, []);


    const getVariance = (oysIn, oysOut, perc) => {
        var totalOystersOut = 0;
        for (var i = 0; i < oysOut.length; i++) {
            totalOystersOut += oysOut[i].quantity ? oysOut[i].quantity : 0;
        }
        var diff = oysIn[0]?.quantity - totalOystersOut;
        if (perc) {
            return Math.abs((diff) / oysIn[0]?.quantity);
        } else {
            return diff;
        }
    }


    const validate = () => () => {
        var warningMessages = [];
        var errorMessages = [];
        //console.log(finished)

        if (props.selectedLine.amount < oystersIn[0].quantity) {
            errorMessages.push("You have entered more oysters than are available in the Inventory");
        }

        for (var i = 0; i < oystersIn.length; i++) {
            if (!oystersIn[i].quantity || (oystersIn[i].quantity <= 0)) {
                errorMessages.push("You must enter a quantity of oysters for input");
            }
            if (!oystersIn[i].stockItem) {
                errorMessages.push("You must select a stockitem for input");
            }
        }


        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {

            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    const submit = async () => {
        var allOk = false;
        var respArr = [];
        setLoading(true);


        const params = {
            lotSerNbr: props.selectedLine.lotSerialNbr,
            ExpiryDate: textfieldDateFormat(new Date(props.selectedLine.expiryDate)),
            OriginalStockItemId: oystersIn[0].stockItem.id,
            NewStockItemId: outputStockItem.id,
            WarehouseId: props.selectedLine.warehouseId,
            Amount: oystersIn[0].quantity,
        }
        console.log(params);
        respArr.push(await postApi('/Processing/Regrade', params));

        if (respArr.reduce((p, c) => (p && c.ok), true)) {
            setLoading(false);
            setCompleted(true);
            setUpdateCompleted(true);
        } else {
            setError("Failed to submit");
        }

    }
    const enableInputEdit = () => {
        setEditInput(true);
    }
    const remSR = (name) => {
        if (name && name.description) {
            return name.description.replace('Sydney Rock', '');
        } else {
            return '';
        }
    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>

                <TextField
                    disabled
                    id="outlined-required"
                    label="Supplier"
                    value={supplier}
                />
                <TextField
                    disabled
                    id="outlined-required"
                    label="Lot Nbr"
                    value={lotNbr}
                />
            </Div>
            <Div>
                {/*//comments*/}
                {/*//processing time*/}
                {/*//no of staff on line*/}

                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} >
                        <TextField
                            disabled={CompletedSoDisabled}
                            id="outlined-multiline-static"
                            label="Comment"
                            value={comment}
                            onChange={(event) => { setComment(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                            rows={4} />
                    </Grid>
                    <Grid item xs={12} >
                        {/*Icon button edit */}


                        <Typography variant={'h3'}>{"Input Stock"}</Typography>
                        <OystersGroup processed={props.processed} disableStockItem oysters={oystersIn} starter={false} setOysters={setOystersIn} disabled={CompletedSoDisabled} single noTitle />
                    </Grid>

                    <Grid item xs={12} >
                        <Typography variant={'h3'}>{"Output Stock"}</Typography>
                        <SelectTextField key={'si' + props.index} title="Stock Item" state={outputStockItem} nameFunc={remSR} list={stockItems?.filter((si) => {
                            var spec = (si.species?.id === oystersIn?.[0]?.stockItem?.species?.id);
                            if (props.frozenRegrade) {
                                return (spec && (si.grade?.id === oystersIn?.[0]?.stockItem?.grade?.id) && (si.isFrozen));
                            } else {
                                return (spec);
                            }
                        })} setState={setOutputStockItem} disabled={props.disabled} />

                    </Grid>

                    {CompletedAndEdit ?
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/*<FormGroup>*/}
                            {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                            {/*</FormGroup>*/}
                            <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer title={'Update'} completed={updateComplete} error={error} loading={loading} allowed={checkAccess(props.access, requiredAdminAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                            </div>
                        </Grid>
                        : <React.Fragment>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/*<FormGroup>*/}
                                {/*    <FormControlLabel control={<Checkbox disabled={completed} checked={finished} onChange={(event) => {setFinished(event.target.checked);}} />} label="Process Completed" />*/}
                                {/*</FormGroup>*/}
                                <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer title={'Regrade'} completed={CompletedSoDisabled} error={error} loading={loading} allowed={checkAccess(props.access, requiredAdminAccess)} submitFunc={validate(false)} handleClose={props.handleClose} />
                                </div>
                            </Grid>
                        </React.Fragment>}
                </Grid>
            </Div>
        </Box >
    );
};


export default ReceivalForm;


