import React from "react";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import VerifiedIcon from '@mui/icons-material/Verified';
import SettingsIcon from '@mui/icons-material/Settings';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DvrIcon from '@mui/icons-material/Dvr';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import LinkIcon from '@mui/icons-material/Link';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SdCardAlertIcon from '@mui/icons-material/SdCardAlert';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import MapIcon from '@mui/icons-material/Map';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GetAppIcon from '@mui/icons-material/GetApp';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import PinIcon from '@mui/icons-material/Pin';
import { ReactComponent as ShareLogo } from '../../../../images/SharePoint-Logo.wine.svg';

import { ACCESS, ACCESS_SECTIONS } from '../../../../utils/constants/access';
import { FastRewind } from "@mui/icons-material";
const menus = [
    {
        label: 'Dashboards',
        type: "section",
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW },
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Dashboard' }
        },
        children: [
            {
                uri: "/procurement/dashboard",
                label: 'Procurement Summary',
                type: "nav-item",
                icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/procurement/POSummary",
                label: 'Purchase Order Summary',
                type: "nav-item",
                icon: <RequestQuoteIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/procurement/dashboard2",
                label: 'PO Summary',
                type: "nav-item",
                icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/procurement/POSummary2",
                label: 'PO Details',
                type: "nav-item",
                icon: <RequestQuoteIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/procurement/WeeklySummary",
                label: 'Weekly Summary',
                type: "nav-item",
                icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/processing/customerOrder",
                label: 'Customer Orders',
                type: "nav-item",
                icon: <DvrIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/reports/Farm",
                label: 'Farm Status',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT },
                type: "nav-item",
                icon: <DirectionsBoatIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/reports/MonthlyPro",
                label: 'Monthly Procurement Report',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.MASTER },
                type: "nav-item",
                icon: <AssessmentIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/reports/Fridges",
                label: 'Fridge Status',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW },
                type: "nav-item",
                icon: <AcUnitIcon sx={{ fontSize: 20 }} />
            },
            {
                label: 'Proccessing Report',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.MASTER },
                type: "collapsible",
                icon: <AssessmentIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/reports/ProcessingReport?type=Live",
                        label: 'Live',
                        type: "nav-item",
                    },
                    {
                        uri: "/reports/ProcessingReport?type=Halfshell",
                        label: 'Halfshell',
                        type: "nav-item",
                    },
                    {
                        uri: "/reports/ProcessingReport?type=ShuckerTotals",
                        label: 'Shucker Totals',
                        type: "nav-item",
                    }
                ]
            }
        ]
    },
    {
        label: 'Procurement',
        type: "section",
        access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW },
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Procurement' }
        },
        children: [
            {
                uri: "/processing/purchaseorders",
                label: 'Purchase Orders',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT },
                type: "nav-item",
                icon: < RequestQuoteIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/receivals?status=PickedUp",
                label: 'New Receivals',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT },
                type: "nav-item",
                icon: < MoveToInboxIcon sx={{ fontSize: 20 }} />

            },
            {
                uri: "/procurement/estuaries",
                label: 'Estuary Status',
                type: "nav-item",
                icon: <WaterOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/procurement/supplierPricing",
                label: 'Supplier Pricing',
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT },
                type: "nav-item",
                icon: <PriceChangeIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/procurement/farmers",
                label: 'Farmers / Suppliers',
                type: "nav-item",
                icon: <HomeWorkOutlinedIcon sx={{ fontSize: 20 }} />
            },

        ]
    },
    {
        label: 'Processing',
        type: "section",
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW },
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Processing' }
        },
        children: [
            {
                uri: "/processing/inventory",
                label: 'Inventory',
                type: "nav-item",
                icon: <InventoryOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/stocktake",
                label: 'StockTake',
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN },
                type: "nav-item",
                icon: <InventoryOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                label: 'Receivals',
                type: "collapsible",
                icon: <MoveToInboxIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/processing/receivals?status=PickedUp",
                        label: 'Picked Up',
                        type: "nav-item",
                    },
                    {
                        uri: "/processing/receivals?status=Open",
                        label: 'Open',
                        type: "nav-item",
                    },
                    {
                        uri: "/processing/receivals?status=MYOB",
                        label: 'Ready for MYOB',
                        type: "nav-item",
                    },
                    {
                        uri: "/processing/receivals?status=Completed",
                        label: 'Completed',
                        type: "nav-item",
                    }
                ]
            },
            {
                uri: "/processing/qualityAssurance",
                label: 'Quality Assurance',
                type: "nav-item",
                icon: <VerifiedIcon sx={{ fontSize: 20 }} />
            },
            //                        {
            //    uri: "/Factory/OTR",
            //    label: 'Transfers',
            //    type: "collapsible",
            //    access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MASTER },
            //    icon: <SyncAltIcon sx={{ fontSize: 20 }} />,
            //    children: [
            //        {
            //            uri: "/Factory/OTR?status=Ready",
            //            label: 'Ready for Pickup',
            //            type: "nav-item",
            //        },
            //        {
            //            uri: "/Factory/OTR?status=PickedUp",
            //            label: 'In Transit',
            //            type: "nav-item",
            //        },
            //        {
            //            uri: "/Factory/OTR?status=Received",
            //            label: 'Received',
            //            type: "nav-item",
            //        },
            //        {
            //            uri: "/Factory/OTR?status=Cancelled",
            //            label: 'Cancelled',
            //            type: "nav-item",
            //        }
            //    ]
            //}       
            //,
            {
                uri: "/reports/DailyPlan",
                label: 'Daily Plan',
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW },
                type: "nav-item",
                icon: <PlaylistAddCheckIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/halfshellroom",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW },
                label: 'Halfshell Room',
                type: "nav-item",
                icon: <PinIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/halfshellplan",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.VIEW },
                label: 'Halfshell Plan',
                type: "nav-item",
                icon: <PlaylistAddCheckIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/liveinventory",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN },
                label: 'Live Inventory',
                type: "nav-item",
                icon: <WorkspacesIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/inventoryhistory",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MASTER },
                label: 'Inventory History',
                type: "nav-item",
                icon: <WorkspacesIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/frozenregrade",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT },
                label: 'Frozen Regrade',
                type: "nav-item",
                icon: <AcUnitIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/processing/backtowater",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN },//MYOB
                label: 'Back To Water',
                type: "nav-item",
                icon: <FastRewind sx={{ fontSize: 20 }} />
            },
        ]
    },
    {
        label: 'Sales',
        type: "section",
        access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW },
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Sales' }
        },
        children: [
            //{
            //    uri: "/sales/summary",
            //    label: 'Sales Order Summary',
            //    access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW },
            //    type: "nav-item",
            //    icon: < RequestQuoteIcon sx={{ fontSize: 20 }} />
            //},
            {
                uri: "/sales/salesordersmanage",
                label: 'Sales Orders',
                access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW },
                type: "nav-item",
                icon: < RequestQuoteIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/sales/salesorders",
                label: 'Sales Orders Series',
                access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW },
                type: "nav-item",
                icon: < LinkIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/sales/report",
                label: 'Sales Volumes',
                access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.VIEW },
                type: "nav-item",
                icon: < AssessmentIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/sales/customerpricing",
                label: 'Customer Pricing',
                access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT },
                type: "nav-item",
                icon: < PriceChangeIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/sales/customerinfo",
                label: 'Customer Info',
                access: { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT },
                type: "nav-item",
                icon: <PersonSearchIcon sx={{ fontSize: 20 }} />
            }
            ,
            {
                uri: "/sales/shipmenterrors",
                label: 'Shipment Errors',
                access: { key: ACCESS_SECTIONS.MYOB_PROCUREMENT, value: ACCESS.ADMIN },
                type: "nav-item",
                icon: <SdCardAlertIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
    {
        label: 'Tasks',
        type: "section",
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT },
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Tasks' }
        },
        children: [
            {
                uri: "/tasks?task=newReceival",
                label: 'Input New Receival',
                type: "nav-item",
                icon: <DocumentScannerIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/tasks?task=QAReport",
                label: 'Quality Assurance Report',
                type: "nav-item",
                icon: <VerifiedIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/tasks?task=confirmReceival",
                label: 'Confirm Receival',
                type: "nav-item",
                icon: <LocalShippingIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/tasks?task=ProcessStock",
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.MASTER },
                label: 'Process Stock',
                type: "nav-item",
                icon: <PrecisionManufacturingIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/Factory/OTR?task=createFatoFa&status=Ready",
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.MASTER },
                label: 'Factory to Factory',
                type: "nav-item",
                icon: <SyncAltIcon sx={{ fontSize: 20 }} />,
            },
            {
                uri: "/Factory/OTR?task=createFatoF&status=Ready",
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.MASTER },
                label: 'Factory to Farm',
                type: "nav-item",
                icon: <SyncAltIcon sx={{ fontSize: 20 }} />,
            },

        ]
    },
    
    //{
    //    label: 'Demo',
    //    type: "section",
    //    access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MASTER },
    //    children: [
    //        {
    //            uri: "/Demo/intoShucking",
    //            label: 'Entering Shucking',
    //            type: "nav-item",
    //            icon: <DocumentScannerIcon sx={{ fontSize: 20 }} />,
    //        },
    //        {
    //            uri: "/Demo/PRNtoShucker",
    //            label: 'Match PRN to Shucker',
    //            type: "nav-item",
    //            icon: <VerifiedIcon sx={{ fontSize: 20 }} />,
    //        },
    //        {
    //            uri: "/Demo/Fullfilment",
    //            label: 'Fullfilment',
    //            type: "nav-item",
    //            icon: <LocalShippingIcon sx={{ fontSize: 20 }} />,
    //        },

    //    ]
    //},
    {
        label: 'Farms',
        type: "section",
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Farms' }
        },
        children: [
           
            
            {
                uri: "/Farmers/FarmManagerReport",
                label: 'Monthly Farm Managers Report',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
                icon: <DirectionsBoatIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/Farmers/Agenda",
                label: 'Agenda Items',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
                icon: <FormatListNumberedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/OysterM8",
                label: 'Oyster Mate',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.VIEW },
                icon: <MapIcon sx={{ fontSize: 20 }} />,
                onClick: {
                    onClick: () => { document.title = 'Oyster Mate' }
                },
            },
            {
                uri: "/OysterM8/OnShore",
                label: 'On Shore',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.VIEW },
                icon: <PresentToAllIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/Farmers/Sharepoint",
                label: 'Sharepoint Folders',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.VIEW },
                icon: <ShareLogo style={{ width: 20, height:20 }} />
            },
            //{
            //    uri: "/OysterM8/History",
            //    label: 'History',
            //    type: "nav-item",
            //    access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.MASTER },
            //    icon: <AccessTimeIcon sx={{ fontSize: 20 }} />
            //},
            {
                uri: "/Farmers/OTR",
                label: 'Transfers',
                type: "collapsible",
                access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
                icon: <SyncAltIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/Farmers/OTR?status=Ready",
                        label: 'Ready for Pickup',
                        type: "nav-item",
                    },
                    {
                        uri: "/Farmers/OTR?status=PickedUp",
                        label: 'In Transit',
                        type: "nav-item",
                    },
                    {
                        uri: "/Farmers/OTR?status=Received",
                        label: 'Received',
                        type: "nav-item",
                    },
                    {
                        uri: "/Farmers/OTR?status=Cancelled",
                        label: 'Cancelled',
                        type: "nav-item",
                    }
                ]
            }
            , {
                label: 'OM Logs',
                type: "collapsible",
                access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
                icon: <ReceiptLongIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/OysterM8/Logs/Movements",
                        label: 'Movements',
                        type: "nav-item",
                    },
                    {
                        uri: "/OysterM8/Logs/Deployments",
                        label: 'Deployments',
                        type: "nav-item",
                    },
                    {
                        uri: "/OysterM8/Logs/LeaseStats",
                        label: 'Lease Information',
                        type: "nav-item",
                    }
                ]
            }
            , 
            {
                uri: "/OysterM8/DataDownload",
                label: 'Data Download',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW },
                icon: <GetAppIcon sx={{ fontSize: 20 }} />
            }
            ,
            {
                uri: "/OysterM8/TotalsReport",
                label: 'Totals Report',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.VIEW },
                icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
            }
        ]
    },
    {
        label: 'Connect',
        type: "section",
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Connect' }
        },
        children: [
            {
                uri: "/Connect",
                label: 'Connect',
                type: "nav-item",
                icon: <AccessibilityIcon sx={{ fontSize: 20 }} />,
            }

        ]
    },
    {
        label: 'Farm Tasks',
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Farm Tasks' }
        },
        type: "section",
        access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.MASTER },
        children: [
            {
                uri: "/Farmers/OTR?task=createFtoFa&status=Ready",
                label: 'Farm to Factory',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
                icon: <DocumentScannerIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/Farmers/OTR?task=createFtoF&status=Ready",
                label: 'Farm to Farm',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.FARMS, value: ACCESS.VIEW },
                icon: <DocumentScannerIcon sx={{ fontSize: 20 }} />
            }
        ]
    },
    {
        label: 'Settings',
        onClick: {
            onClick: () => { document.title = 'AOC Production Portal - Settings' }
        },
        type: "section",
        access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT },
        children: [
            {
                label: 'Quality Assurance',
                type: "collapsible",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN },
                icon: <SettingsIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/settings/qualityAssurance/shell",
                        label: 'Shell Ratings',
                        type: "nav-item",
                    },
                    {
                        uri: "/settings/qualityAssurance/condition",
                        label: 'Condition Ratings',
                        type: "nav-item",
                    }
                ]
            },
            {
                uri: "/settings/UserRequests",
                label: 'User Requests',
                type: "nav-item",
                icon: <ContactSupportIcon sx={{ fontSize: 20 }} />,
            },
            
            {
                label: 'Oyster Mate Colours',
                type: "collapsible",
                access: { key: ACCESS_SECTIONS.OYSTERMATE, value: ACCESS.VIEW },
                icon: <SettingsIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/settings/OysterMateColours?type=BatchColours",
                        label: 'Crops',
                        type: "nav-item",
                    },
                    {
                        uri: "/settings/OysterMateColours?type=UnitTypes",
                        label: 'Unit Types',
                        type: "nav-item",
                    },
                    {
                        uri: "/settings/OysterMateColours?type=Grades",
                        label: 'Grades',
                        type: "nav-item",
                    }
                ]
            },
            {
                uri: "/settings/StaffMembers",
                label: 'Staff Members',
                type: "nav-item",
                access: { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN },
                icon: <SettingsIcon sx={{ fontSize: 20 }} />,
            }

        ]
    },

];

export default menus;
