var url = window.location.origin;

export const addToCache = (mode, cacheName) => {
    if ('caches' in window) {
        // Opening given cache and putting our data into it
        caches.open(cacheName).then((cache) => {
            cache.put(url, new Response(JSON.stringify({ mode })));
        });
    }
}
export const GetAllCacheData = async (setState, cacheName) => {


    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    if (cachedResponse) {
        var data = await cachedResponse.json();
        if (data && data.mode) {
            if (data.mode === 'dark') {
                setState(true);
            } else if (data.mode === 'light') {
                setState(false);
            } else {
                setState(data.mode);
            }
        }
    }
};


export const GetAllCacheDataReturn = async (cacheName) => {


    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);
    if (cachedResponse) {
        var data = await cachedResponse.json();
        if (data && data.mode) {
            return (data.mode);
        }
    }
};