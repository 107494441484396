import { dateFormat } from "./dateFunctions";

export const OrganiseMovements = (data, sDate, eDate) => {
    var dataSorted = data.sort((a, b) => {
        if (a.date === b.date) {
            return new Date(b.created) - new Date(a.created);
        } else {
            return new Date(b.date) - new Date(a.date);
        }
    })

    var dataFormatted = [];
    for (var i = 0; i < dataSorted.length; i++) {
        var item = dataSorted[i];
        var doNotShow = false;
        var onShoreLocation = { row: '', lease: '', start: '', end: '' };
        var thisGroupData = data.filter((d) => { return d.groupId === item.groupId });
        var sortData = thisGroupData.sort((a, b) => {
            return new Date(a.created) - new Date(b.created);
        });
        var itemIndex = sortData.findIndex((d) => { return d.id === item.id });
        if (itemIndex === 0) {
            //dont show if they are both shore or both location
            if (sortData[0].parent) {
                if (!sortData[0].parent?.locationId && sortData[0].isShore) {
                    doNotShow = true;
                } else if (sortData[0].parent?.locationId && !sortData[0].isShore) {
                    doNotShow = true;
                }
            } else if (item.isShore && !sortData[0].parent && (sortData.length === 1)) {
                doNotShow = true;
            }
            if (!doNotShow && item.isShore) {//diffrent group so start and end could be different
                onShoreLocation = {
                    row: sortData[0].parent?.location?.line?.lineNbr,
                    lease: sortData[0].parent?.location?.line?.lease?.name
                }
                //console.log(sortData[itemIndex].id, sortData);
                if (sortData.length === 1) { // if there is only one location in the group then it is the final location of the group
                    onShoreLocation.row = sortData[0].group?.previousLocation?.line?.lineNbr;
                    onShoreLocation.lease = sortData[0].group?.previousLocation?.line?.lease?.name;
                    onShoreLocation.start = sortData[0].group?.previousLocation?.start;
                    onShoreLocation.end = sortData[0].group?.previousLocation?.end;
                } else {//if index 0 is on shore then index 1 is off shore
                    onShoreLocation.start = sortData[1].start;
                    onShoreLocation.end = sortData[1].end;
                }
            }
        } else if (item.isShore) {
            if (itemIndex > 0) { //these are all same group Id so the start and end is all the same
                onShoreLocation = {
                    row: sortData[itemIndex - 1].line?.lineNbr,
                    lease: sortData[itemIndex - 1].line?.lease?.name,
                    start: sortData[itemIndex - 1].start,
                    end: sortData[itemIndex - 1].end
                }
            }
        }
        if (!doNotShow) {

            dataFormatted.push({
                id: item.id + ' (' + item.groupId + ')',
                rawDate: item.date,
                date: dateFormat(item.date),
                type: item.isShore ? 'Pickup' : 'Deploy',
                grade: item.group?.grade?.description,
                unitType: item.group?.unitType?.description,
                crop: item.group?.cropLabel,
                total: item.group?.totalUnits,
                average: item.group?.average,
                row: item.isShore ? onShoreLocation.row : item.line?.lineNbr,
                lease: item.isShore ? onShoreLocation.lease : item.line?.lease?.name,
                start: item.isShore ? onShoreLocation.start : item.start,
                end: item.isShore ? onShoreLocation.end : item.end
            });
        }
    }
    dataFormatted = dataFormatted.filter((a) => {
        //greater then start date and less than end date\
        var date = new Date(a.rawDate);
        var startDate = new Date(sDate);
        var endDate = new Date(eDate);
        return (date >= startDate && date <= endDate);
    });
    return dataFormatted;
}