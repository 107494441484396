import { Grid, ListItemText, Typography } from "@mui/material";
import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useSearchParams } from "react-router-dom";
import { startOfWeek, endOfWeek, tomorrow, dayCompare, dateFormat, yesterday } from "../../utils/dateFunctions";
import SelectTextField from '../../components/SelectTextField';
import EstuaryStatus from '../Procurement/Tables/EstuaryStatus';
import { checkAccess } from '../../utils/roleFunctions';
import FarmersSuppliers from '../Procurement/Tables/FarmersSuppliers';
import { getAPIData } from '../../utils/apiFunction';
import QAform from '../Processing/Forms/QAform';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import RainfallGraph from './Charts/RainfallGraph';
import TideGraph from './Charts/TideGraph';
import PurchaseOrders from "../Procurement/Tables/PurchaseOrders";
import FormModal from '../../components/FormModal';
import Button from '@mui/material/Button';
import QualityAssurance from '../Processing/Tables/QualityAssurance';
import AddPurchaseOrder from '../Procurement/Forms/AddPurchaseOrder';

const FarmReport = (props) => {
    const [reload, setReload] = React.useState(false);
    const [aocFarms, setAOCFarms] = React.useState([]);
    const [exFarms, setExFarms] = React.useState([]);
    const [farm, setFarm] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const [startDate, setStartDate] = React.useState(startOfWeek(yesterday(startOfWeek(new Date()))));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [location, setLocation] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [firstLoad, setFirstLoad] = React.useState(true);
    const [edit, setEdit] = React.useState(false);
    const [data, setData] = React.useState(null);
    const requiredPOAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const reloadFunc = () => {
        setReload(!reload);
    }
    const handleClose = () => setOpen(false);
    const createPO = () => {
        setOpen(true);
        setEdit(false);
        setData(null);
    }
    const setGroups = (inp) => {
        var aocfarmlist = inp.farmers?.filter((f) => { return f.isInternal });
        setFirstFarm(aocfarmlist);
        setAOCFarms(aocfarmlist);
        setExFarms(inp.farmers?.filter((f) => { return !f.isInternal }));
    }
    if (firstLoad) {
        getAPIData('/farmerssuppliers', setGroups);
        setFirstLoad(false);
    }
    const setFirstFarm = (aFm) => {
        if (aFm) {
            if (!farm) {
                console.log(aFm[0])
                setFarm(aFm[0]);
            }
        }
    }
    React.useEffect(() => {
        setFirstFarm(aocFarms);
        console.log(farm)
    }, [aocFarms])
    
    const checkFarmInList = (frm, frmList) => {
        if (farm && frmList) {
            return frmList.find((a) => { return a.id == frm.id }) ? true : false;
        } else {
            return false;
        }
    }
    console.log(farm)
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={"Create Purchase Order"} >
                <AddPurchaseOrder fillData={{ supplierId: farm?.id }} access={props.access} reload={reloadFunc} handleClose={handleClose} edit={edit} />
            </FormModal>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} >
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12} sm={12} md={5} lg={4}>
                            <SelectTextField style={{ minWidth: '250px' }} checkID={farm?.id} title="AOC Farm" state={farm} setState={setFarm} list={aocFarms} nameFunc={(op) => { return op.name }} />

                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={4}>
                            <SelectTextField style={{ minWidth: '250px' }} checkID={farm?.id} title="External Farm" state={farm} setState={setFarm} list={exFarms} nameFunc={(op) => { return op.name }} />

                        </Grid>
                        {checkAccess(props.access, requiredPOAccess) ? <Grid item xs={12}>
                            <Button onClick={createPO} variant="contained" >Create New Purchase Order</Button>
                        </Grid> : null}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid container spacing={2} mb={1}>
                        <Grid item xs={12}>
                            <PurchaseOrders sortOrderNo location={location} startDate={startDate} endDate={tomorrow(endDate)} long farmerFilter={farm?.name} externalReload={reload} />
                        </Grid>
                        <Grid item xs={12}>
                            <FarmersSuppliers filterFarm={farm?.name} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid container spacing={2} mb={1}>

                        {/*<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                        {/*    <RainfallCard farmName={farm?.name} />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>*/}
                        {/*    <TideCard farmName={farm?.name} />*/}
                        {/*</Grid>*/}
                        <Grid item xs={12}>
                            <QualityAssurance supplierFilter={farm?.name} startDate={startDate} endDate={tomorrow(endDate)} {...props} access={props.access} title="Completed QAs" reload={reload} setReload={setReload} reloadFunc={reloadFunc} />
                        </Grid>

                        <Grid item xs={12}>
                            <EstuaryStatus filterEst={farm?.estuaries} />
                        </Grid>

                    </Grid>
                </Grid>


            </Grid>
        </React.Fragment >
    );
};

export default FarmReport;


const RainfallCard = (props) => {
    const [date, setDate] = React.useState(new Date())
    const [mm, setMM] = React.useState(0)

    return (<JumboCardQuick
        title={"Latest Rainfall (Since 9am)"}
        wrapperSx={{ pt: 1 }}
    >
        <Grid container spacing={3.75}>

            <Grid item xs={6}>
                <RainfallGraph setMM={setMM} setDate={setDate} {...props} />
            </Grid>
            <Grid item xs={6}>
                <ListItemText
                    primary={<Typography variant={"h3"} mb={.5}>{mm + "mm"}</Typography>}
                    secondary={<Typography variant={'body1'} color={'text.secondary'}>{dayCompare(new Date(), date) ? 'Today' : (dayCompare(yesterday(new Date()), date) ? 'Yesterday' : dateFormat(date))}</Typography>}
                />
            </Grid>
        </Grid>
    </JumboCardQuick >)
}

const TideCard = (props) => {


    return (<JumboCardQuick
        title={"Tide"}
        wrapperSx={{ pt: 1 }}
    >
        <TideGraph {...props} />
    </JumboCardQuick>)
}
