import React from 'react';
import { getAPIDataReturn, getAPIData, getAPIDataParamsReturn } from "../../utils/apiFunction";
import DataTableStandard from "../../components/DataTableStandard";
import { getAOCWarehouses } from '../../components/SelectAOCFarmWarehouse';
import { displayDozFormat, numberFormat } from '../../utils/numberFunctions';
import { TableCell, TableRow, TableFooter } from "@mui/material";

const OysterTotals = props => {
    const [tableData, setTableData] = React.useState(null);
    const [loading, setLoading] = React.useState(null);
    const [expand, setExpand] = React.useState(false);
    const [startData, setStartData] = React.useState(false);
    const [endData, setEndData] = React.useState([]);
    const [reload, setReload] = React.useState(false);

    const reloadFunc = () => {
        setReload(!reload);
    }


    var columns = [
        {
            name: 'warehouse',
            label: 'Warehouse',
        },

        {
            name: 'cropLabel',
            label: 'Crop Label',
        },
        {
            name: 'crop',
            label: 'Crop',
        },
        {
            name: 'supplier',
            label: 'Supplier',
        },
        {
            name: 'unitType',
            label: 'UnitType',
        },
        {
            name: 'grade',
            label: 'Grade',
        },
        {
            name: 'average',
            label: 'Average',
        },
        {
            name: 'totalUnits',
            label: 'Total Units',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return numberFormat(endData[dataIndex]?.totalUnits)
                },
            }
        },
        {
            name: 'total',
            label: 'Total',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return displayDozFormat(endData[dataIndex]?.total)
                },
            }
        },
    ];


    React.useEffect(() => {
        const abortController = new AbortController();

        const handleAllFarms = async (allowFarmList) => {
            var prmArr = [];
            for (var i = 0; i < allowFarmList.length; i++) {
                prmArr.push(getProcurementData(allowFarmList[i].warehouseId, abortController));
            }
            Promise.all(prmArr).then((values) => {
                var data = [];
                for (var i = 0; i < values.length; i++) {
                    data = data.concat(values[i]);
                }
                setEndData(dataFormat(data, allowFarmList));
                setLoading(false);
            });
        }
        setStartData([]);
        setEndData([]);
        setLoading(true);
        getAOCWarehouses(props.access, props.showAll, handleAllFarms);

        return () => {
            abortController.abort();
        };
    }, [reload]);

    const dataFormat = (data, warehouses) => {
        return data.map((dt) => {
            return {
                warehouse: warehouses.find((wh) => { return wh.warehouseId === dt.warehouseId })?.warehouse,
                supplier: dt.supplier?.name,
                cropLabel: dt.cropLabel,
                crop: dt.crop?.description,
                unitType: dt.unitType?.description,
                grade: dt.grade?.description,
                totalUnits: dt.totalUnits,
                average: dt.average,
                total: dt.average * dt.totalUnits
            }
        });
    }
    var getProcurementData = async (id, abortController) => {
        var promArr = [];

        return await getAPIDataParamsReturn('/Farms/Groups', {
            WarehouseId: id,
        }, abortController).then(async (data) => {
            return await getAPIDataParamsReturn('/Farms/Groups/OnShore', { //geting onshore so can see children to remove from total
                WarehouseId: id,
            }, abortController).then((onShore) => {
                for (var i = 0; i < onShore.length; i++) {
                    var found = data.findIndex((os) => { return os.id === onShore[i].id });
                    if (found >= 0) {
                        data[found] = { ...onShore[i] };
                    }
                }
                return (data);

            })
        });
    }
    const options = {
        filterType: 'multiselect',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: '600px',
        responsive: 'standard',
        rowsPerPage: 100000,
        onRowClick: props.onClick ? props.onClick(endData) : (() => { }),

    };
    options.customTableBodyFooterRender = (opts) => {
        const paddColStyle = {
            color: '#000',
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '150%'
        }
        return (
            <TableFooter >
                <TableRow style={{ fontWeight: 'bold' }}>
                    {opts.columns.map((col, index) => {
                         if (index === 1) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                {displayDozFormat(opts.data.reduce((a, b) => a + (Number(endData[b.dataIndex].total) || 0), 0))}
                            </TableCell>
                        } else if (index ===0) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                            Total
                            </TableCell>
                        } else {
                            return (
                                <TableCell key={index} style={{
                                    ...paddColStyle
                                }}>

                                </TableCell>
                            );
                        }
                    })}
                </TableRow>
            </TableFooter>
        );
    }


    return (

        <DataTableStandard
            data={endData}
            title={'Oyster Mate Totals'}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={reloadFunc}
            shrink={true}
        />
    )
};

export default OysterTotals;


