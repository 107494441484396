import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { dateFormat, undoDateFormat } from "../../../utils/dateFunctions";
import { TableCell, TableRow } from "@mui/material";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from "@mui/material/ToggleButton";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import {  dateSort } from '../../../utils/sortFunctions';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { useJumboTheme } from "@jumbo/hooks";

const FarmersSuppliers = (props) => {
    const { theme } = useJumboTheme();
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [columns, setColumns] = React.useState([]);
    const [expired, setExpired] = React.useState(0);
    const [filterOptions, setFilterOptions] = React.useState(null);
    const reloadFunc = () => {
        setReload(!reload);
    }
    const options = {
        selectableRowsHideCheckboxes: true,
        customRowRender: ((data, dataIndex, rowIndex) => {

            if (procurement[dataIndex].expireDate < new Date()) {
                return <TableRow key={Math.random()} style={{
                    background: theme.palette.trafficLight.red,
                }} class="MuiTableRow-root css-16f542l-MuiTableRow-root MuiTableRow-hover tss-mwfche-MUIDataTableBodyRow-root tss-mwfche-MUIDataTableBodyRow-root">
                    {data.map((c) => {
                        return <TableCell key={Math.random()} >{c}</TableCell>
                    })}
                </TableRow>
            } else {
                return <TableRow key={Math.random()} class="MuiTableRow-root css-16f542l-MuiTableRow-root MuiTableRow-hover tss-mwfche-MUIDataTableBodyRow-root tss-mwfche-MUIDataTableBodyRow-root">
                    {data.map((c) => {
                        return <TableCell key={Math.random()} >{c}</TableCell>
                    })}
                </TableRow>
            }
        })
    };
    const uniqueList = (list) => {
        var newList = [];
        for (var i = 0; i < list.length; i++) {

            if (!newList.includes(list[i])) {
                newList.push(list[i]);
            }
        }
        return newList;
    }
    React.useEffect(() => {
        if (filterOptions) {
            var newCols = [...columns];
            newCols[0].options = filterOptions;
            setColumns(newCols);
        }
    }, [filterOptions]);
    React.useEffect(() => {
        if (props.filterFarm) {
            if (Array.isArray(props.filterFarm)) {
                setFilterOptions({ filterList: (props.filterFarm) });
            } else {
                setFilterOptions({ filterList: ([props.filterFarm]) });
            }
        }
    }, [props.filterFarm, procurement]);

    React.useEffect(() => {
        var tempColumns = [
            {
                name: 'farmer',
                label: 'Farmer / Supplier'
            },
            {
                name: 'state',
                label: 'State'
            },
            {
                name: 'estuaries',
                label: 'Estuaries'
            },
            {
                name: 'foodLicenceNo',
                label: 'Food Licence Number'
            },
            {
                name: 'expireDate',
                label: 'Food Licence Expiry',
                options: {
                    sortDescFirst: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return dateFormat(value);
                    },
                    filterList: expired === 1 ? ['Expired'] : ['All'],
                    filterOptions: {
                        names: ["Expired", "All", ...uniqueList(procurement.map((a) => a.licenceExpiry))],
                        logic(date, filters) {
                            if (filters[0] === "Expired") {
                                if (undoDateFormat(date) < new Date()) {
                                    return false;
                                } else {
                                    return true;
                                }
                            } else if (filters[0] === "All") {
                                return false;
                            }
                            return true;
                        }
                    }
                }
            }
        ];
        setColumns(tempColumns);
    }, [expired])

    React.useEffect(() => {
        setProcurement([]);
        getProcurementData();
    }, [reload]);

    var getProcurementData = async () => {
        const response = await adalApiFetch(fetch, uri + '/farmerssuppliers');
        const data = await response.json();
        var newData = data.farmers.map((fm) => {
            return {
                farmer: fm.name,
                state: fm.address ? fm.address.state : '',
                estuaries: fm.estuaries,
                foodLicenceNo: fm.foodLicenceNo,
                licenceExpiry: dateFormat(fm.foodLicenceExpiry),
                expireDate: new Date(fm.foodLicenceExpiry)

            }
        });

        setProcurement(newData);
    }
    return (

        <DataTableStandard
            title={<div>Farmers / Suppliers{props.toggleExpired ? <ToggleExpired expired={expired} setExpired={setExpired} /> : null}</div>}
            data={procurement}
            columns={columns}
            options={options}
            reload={reloadFunc}
        />

    );
};
const ToggleExpired = (props) => {
    const [selection, setSelection] = React.useState(props.expired);
    return <ToggleButtonGroup
        color="primary"
        disabled={props.disabled}
        exclusive
        value={selection}
        aria-label="text alignment"
        onChange={(event, val) => {
            props.setExpired(val);
            setSelection(val);
        }}
    >
        <ToggleButton key={0} value={0} >
            <div  >All</div>
        </ToggleButton>
        <ToggleButton key={1} value={1} >
            <div  >Expired</div>
        </ToggleButton>
    </ToggleButtonGroup>
}
export default FarmersSuppliers;
