import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const TooltipDescription = (props) => {
    const StyleTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }}  />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#fff',
            maxWidth: 350,
            fontSize: theme.typography.pxToRem(12),
        },
    }));
    return <StyleTooltip title={props.title} placement={props.placement} PopperProps={props.popperProps ? props.popperProps : {}} >
        {props.children}
    </StyleTooltip>
}

export default TooltipDescription;
