import React from 'react';
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Divider from "@mui/material/Divider";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Div from "@jumbo/shared/Div";
import { CardActions, ThemeProvider, Typography } from "@mui/material";
import { useJumboHeaderTheme, useJumboTheme } from "@jumbo/hooks";

var links = [
    
    {
        name: "Connect",
        url: "/Connect",
        popOut: false

    },
    {
        name: "ELMO",
        url: "https://aoc.elmotalent.com.au/",
        popOut: true
    },
    {
        name: "All Forms",
        url: "https://ausoyster.sharepoint.com/sites/aoc-internal-services/SitePages/Forms.aspx",
        popOut: true
    }
]

const NotificationsDropdown = () => {
    const { theme } = useJumboTheme();
    const { headerTheme } = useJumboHeaderTheme();
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <ThemeProvider theme={headerTheme}>
                        <JumboIconButton elevation={25}>
                            <OpenInNewIcon sx={{ fontSize: '1.25rem', pointerEvents: 'auto' }} />
                        </JumboIconButton>
                    </ThemeProvider>
                }
                disableInsideClick
            >
                <Div sx={{ width: 360, maxWidth: '100%' }}>
                    <CardHeader
                        title={"Quick Links"}
                    />
                    <List disablePadding>
                        {
                            links.map((item, index) => {
                                return (
                                    <Link underline="hover" color="inherit" href={item.url} target={item.popOut ? "_blank" : ''}>
                                        <ListItemButton  component={"li"} alignItems={"flex-start"}>
                                            <ListItemText>
                                                <Typography style={{ paddingLeft: '10px' }}>{item.name}</Typography>
                                            </ListItemText>
                                        </ListItemButton>
                                    </Link>
                                );
                            })
                        }
                    </List>
                    <Divider />
                    {/*<CardActions sx={{justifyContent: 'center'}}>*/}
                    {/*    <Button sx={{textTransform: "none", fontWeight: 'normal', '&:hover': {bgcolor: 'transparent'}}}*/}
                    {/*            size={"small"} variant="text" endIcon={<ArrowForwardIcon/>} disableRipple>*/}
                    {/*        View All*/}
                    {/*    </Button>*/}
                    {/*</CardActions>*/}
                </Div>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default NotificationsDropdown;
