import jsPDF from 'jspdf';
import 'jspdf-autotable'
import title from '../../images/OTRHeading.PNG';
import { numberFormat } from '../../utils/numberFunctions'
import { adalApiFetch } from '../../../adalConfig';

const OTRPdf = async (data, oys, tId) => {
    console.log(data, oys, tId);
    const uri = process.env.REACT_APP_BASE_URL;
    // var PAi = new Image();
    // PAi.src = PA;
    // var MPCi = new Image();
    // MPCi.src = MPC;
    var QRResponse = await adalApiFetch(fetch, uri + '/QR/OTR?TransferId=' + tId, {
        method: 'get',
    });
    const QR = await QRResponse.blob();
    console.log(data, oys)
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, 297]
    });
    //if bigger than 18 addPage
    var smallerText = 10;
    var fontbig = 15;
    var fontbiggerText = 11;
    var fontText = 7;

    const QRurl = URL.createObjectURL(QR);
    var QRi = new Image();
    QRi.src = QRurl;

    var titleI = new Image();
    titleI.src = title;

    var lineWidthTight = 5;
    var lineWidth = 6
    var max = 18;
    var titleBarX = 10;
    var line = 0;
    var tempLine = line;
    doc.addImage(titleI, 'PNG', 0, line, 210, 52.62);
    //doc.setFontSize(fontText);
    //doc.text('OTR:', 28, 20);
    doc.setFontSize(fontbig);
    doc.setTextColor('red');
    doc.text(data.OTR, 35, 21);
    doc.setTextColor(0, 0, 0);

    line = 60;
    doc.setFillColor(0, 0, 0);
    doc.setLineWidth(0.3)
    doc.rect(15, line, 180, 12, 'F');
    doc.rect(15, line, 180, 35);
    doc.setTextColor(255, 255, 255);
    line = line + 8;
    doc.setFontSize(fontbig);
    doc.text('Delivery Instructions ', 20, line);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(fontbiggerText);
    line = line + 9;
    doc.text('To: ' + data.to, 20, line);
    line = line + 7;
    doc.text('From: ' + data.from, 20, line);
    line = line + 7;
    doc.text('Date: ' + data.date, 20, line);
    //10 lines
    var FtoF = false;
    //if (data.destination?.isFarm && data.source?.isFarm) {
    //    FtoF = true;
    //}
    console.log(data);
    line = line + 15;
    doc.setFillColor(0, 0, 0);
    doc.setLineWidth(0.3);
    doc.rect(15, line, 180, 12, 'F');
    doc.rect(15, line, 180, 40);
    doc.setTextColor(255, 255, 255);
    line = line + 8;
    doc.setFontSize(fontbig);
    doc.text('Harvest Details', 20, line);
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(fontbiggerText);
    line = line + 9;
    doc.text('PRN: ' + data.PRN, 20, line);
    doc.text('IVR: ' + data.IVR, 100, line);
    line = line + 7;
    doc.text('Crop Year: ' + (data.CropYear ? data.CropYear : ''), 20, line);
    doc.text('Harvest Date: ' + data.HarvestDate, 100, line);
    line = line + 7;
    doc.setFontSize(smallerText);
    doc.text('Estuary: ' + data.Estuary, 20, line);
    doc.text('Harvest Area: ' + data.HarvestArea, 100, line);
    line = line + 7;
    if (data.Lease) {
        doc.text('Lease: ' + data.Lease, 20, line);
    }
    line = line + 11;
    var tenOys = [...oys];
    var oyList = [...oys];
    var extraOys = [];
    if (oyList.length > 10) {
        tenOys = [];
        tenOys = oyList.splice(0, 10);
        extraOys = [...oyList]
    }
    var headAr = ['Bags', 'Bins', 'Oyster Species and Grades', FtoF ? 'Total Quantity (Pcs)' : 'Total Quantity (Doz)']
    var footAr = [tenOys.reduce((p, c) => (p + (Number(c.bags) ? Number(c.bags) : 0)), 0), tenOys.reduce((p, c) => (p + ((Number(c.bins) ? Number(c.bins) : 0))), 0), '', numberFormat(tenOys.reduce((p, c) => (p + (Number(c.quantity) ? Number(c.quantity) : 0)), 0))];
    var bodyAr = [];
    if (data.multipleLeases && data.multipleCropYear) {
        headAr.push('Lease')
        footAr.push('')
        headAr.push('CropYear')
        footAr.push('')
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity), oy.lease?.displayName, oy.crop])
    } else if (data.multipleLeases) {
        headAr.push('Lease')
        footAr.push('')
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity), oy.lease?.displayName])
    } else if (data.multipleCropYear) {
        headAr.push('CropYear')
        footAr.push('')
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity), oy.crop])
    } else {
        bodyAr = tenOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity)])
    }
    doc.autoTable({
        startY: (line),
        theme: 'striped',
        headStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
        footStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
        head: [headAr],
        body: bodyAr,
        foot: [footAr]
    })
    line = 278;
    doc.setFontSize(smallerText);
    doc.text('Sender\'s Signature:  _______________________________________', 15, line);
    line = line + 10;
    doc.text('Transport Company: _______________________________________', 15, line);

    doc.addImage(QRi, 'PNG', 150, line - 44, 50, 50);


    if (extraOys.length > 0) {
        doc.addPage();
        doc.autoTable({
            startY: (45),
            theme: 'striped',
            headStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
            footStyles: { fillColor: [0, 0, 0], color: [255, 255, 255] },
            head: [['Bags', 'Bins', 'Oyster Species and Grades', FtoF ? 'Total Quantity (Pcs)' : 'Total Quantity (Doz)']],
            body: extraOys.map(oy => [oy.bags, oy.bins, buildDesc(oy), numberFormat(oy.quantity)]),
            foot: [[extraOys.reduce((p, c) => (p + (Number(c.bags) ? Number(c.bags) : 0)), 0), extraOys.reduce((p, c) => (p + ((Number(c.bins) ? Number(c.bins) : 0))), 0), '', numberFormat(extraOys.reduce((p, c) => (p + (Number(c.quantity) ? Number(c.quantity) : 0)), 0))]]
        })
        line = 278;
        doc.setFontSize(smallerText);
        doc.text('Sender\'s Signature:  _______________________________________', 15, line);
        line = line + 10;
        doc.text('Transport Company: _______________________________________', 15, line);
    }

    doc.save(data.OTR + '.pdf');
}



function buildDesc(oy) {
    console.log(oy)
    if (oy.stockItem) {
        if (oy.stockItem.species && oy.stockItem.grade) {
            return (oy.stockItem.species.description + ' ' + oy.stockItem.grade.description)
        } else {
            return oy.stockItem.description;
        }
    } else {
        return 'Unknown';
    }

}

export default OTRPdf;