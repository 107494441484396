import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Div from "@jumbo/shared/Div";
import DatePicker from "../../../shared/DatePicker";
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import { adalApiFetch } from '../../../../adalConfig';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import Autocomplete from '@mui/material/Autocomplete';
import { checkAccess } from '../../../utils/roleFunctions';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import OysterOrder from "../../../components/OysterOrder";
import { textfieldDateFormat, tomorrow } from "../../../utils/dateFunctions";
import SupplierSelect from "../../../components/SupplierSelect";
import { statusList } from '../../../utils/constants/selectFields';


const AddAgendaItems = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [item, setItem] = React.useState(null);
    const [discussed, setDiscussed] = React.useState(null);
    const [outcome, setOutcome] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [accountable, setAccountable] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const id = props.data ? (props.data.id ? props.data.id : '') : '';
    console.log(props.data)
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.FARMS, value: ACCESS.EDIT };
    const [returnData, setReturnData] = React.useState(null);
    console.log(props?.fillData)
    React.useEffect(() => {
        const checkArr = (data) => {
            var retData = data;
            console.log(data)
            if (Array.isArray(data)) {
                retData = data[0];
                console.log(retData)
            }
            setReturnData(retData);
        }
        if (props.edit || props.completed || props.duplicate) {
            getAPIData('/Reports/FRM/Agenda/Get?Id=' + id, checkArr);
        }

    }, [])
   
    React.useEffect(() => {
        console.log(returnData);

        if (returnData) {
            setDate(new Date(returnData.date));
            setItem(returnData.description)
            setDiscussed(returnData.discussion)
            setOutcome(returnData.outcome)
            setAccountable(returnData.accountable)
        }
    }, [returnData])

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        //if (!promisedDate) {
        //    errorMessages.push("You must enter a Promised Date");
        //}
      

        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            submit();
        }
    }
    
    const submit = async () => {
        setLoading(true);
        setCompleted(true);
        var data = {
            Date: textfieldDateFormat(date),
            Description: item,
            Discussion: discussed,
            Outcome: outcome,
            Accountable: accountable,
            WarehouseId: props.warehouseId,
            
        }
        console.log(data);
        if (props.edit) {
            var response = await adalApiFetch(fetch, uri + '/Reports/FRM/Agenda/Update?' + new URLSearchParams({
                ...data,
                Id: id
            }), {
                method: 'post',
            });
        } else {
            var response = await adalApiFetch(fetch, uri + '/Reports/FRM/Agenda/Insert?' + new URLSearchParams({
                ...data
            }), {
                method: 'post',
            });
        }
        var resp = await (response.json());
        
        setLoading(false);
        if (props.reload) {
            props.reload();
        }

    }
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <Div>
                {/*{props.edit ? <IconButton style={delIcon} aria-label="Delete" onClick={deleteAll}>*/}
                {/*    <DeleteIcon />*/}
                {/*</IconButton> : ''}*/}

                {/*<Typography variant={"h3"}>Confirm OTR Information</Typography>*/}
                <Grid container spacing={0} mb={1}>
                   
                    <Grid item xs={12}>

                        <DatePicker date={date} setDate={setDate} label="Date" disabled={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Item Name"
                            value={item}
                            onChange={(event) => { setItem(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                             />
                    </Grid>
                   
                        <Grid item xs={12}>
                            <TextField
                                disabled={completed}
                                id="outlined-multiline-static"
                                label="Discussed"
                                value={discussed}
                                onChange={(event) => { setDiscussed(event.target.value) }}
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{width:'100%'} }
                                rows={4} />
                    </Grid>
                        <Grid item xs={12} >
                            <TextField
                                disabled={completed}
                                id="outlined-multiline-static"
                                label="Outcome"
                                value={outcome}
                                onChange={(event) => { setOutcome(event.target.value) }}
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{width:'100%'} }
                                rows={4} />
                        </Grid>
                    <Grid item xs={12} >
                        <TextField
                            disabled={completed}
                            id="outlined-multiline-static"
                            label="Accountable"
                            value={accountable}
                            onChange={(event) => { setAccountable(event.target.value) }}
                            multiline
                            InputLabelProps={{
                                shrink: true,
                            }}
                             />
                    </Grid>
                    
                </Grid>

                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={props.handleClose} />

            </Div>
        </Box >
    );
};
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? c.quantity : 0) }, 0)
}
const StringSelect = (props) => {
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');

    React.useEffect(() => {
        if (props.input) {
            var est = props.options.find((dr) => { return props.labelFunc ? (props.labelFunc(props.input) === props.labelFunc(dr)) : ((props.input) === (dr)) });
            console.log(est, props.options)
            props.setState(est);
            setValue(est);
        } else if (props.default > -1) {

            var est = props.options[props.default];
            props.setState(est);
            setValue(est);
        }
    }, [props.input])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => { return props.labelFunc ? props.labelFunc(option) : option }}
        disablePortal
        id="stat"
        options={props.options}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}
    />);



}



export default AddAgendaItems;
