import React from 'react';
import Grid from "@mui/material/Grid";
import { Button } from '@mui/material';
import FormModal from '../../../components/FormModal';
import AddSalesOrder from '../Forms/AddSalesOrder';
const SalesOrdersPage = (props) => {
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const handleClose = () => {
        setCompleted(false);
        setEdit(false);
        setData(null);
        setSeries(false);
        setOpen(false);
    }
    

    const createPO = (ser) => () => {
        setOpen(true);
        setEdit(false);
        setData(null);
        setSeries(ser);
    }
    var title = "Create Sales Order";
    if (series) {
        title = "Create Series of Sales Orders";
        if (edit) {
            title = "Edit Series of Sales Orders";
        }
    } else if (edit) {
        title = "Edit Sales Order";
    } else if (completed) {
        title = "View Sales Order";
    }
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={title} >
                <AddSalesOrder data={data} access={props.access} reload={props.reloadFunc} handleClose={handleClose} edit={edit} series={series} completed={completed} />
            </FormModal>

            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    {props.createOne ? <Button onClick={createPO(false)} variant="contained" style={{ marginRight: '20px' }} >Create New Sales Order</Button> : null}
                    {props.createSeries ? <Button onClick={createPO(true)} variant="contained" >Create New Series of Sales Orders</Button> : null}
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default SalesOrdersPage;
