import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import ShipmentError from './Tables/ShipmentError';
import { checkAccess } from '../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
const SalesOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(false);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };

    const reloadFunc = () => {
        setReload(!reload);
    }

    const createPO = (ser) => () => {
        setOpen(true);
        setEdit(false);
        setData(null);
        setSeries(ser);
    }
    
    const viewPO = (array) => (rowd, rowState) => {
        setData(array[rowState.dataIndex]);
        setOpen(true);
        setEdit(false);
        setCompleted(true);
    }
   
    return (
        <React.Fragment>
            
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
               
                <Grid item xs={6} sm={6} md={6} lg={6}>
                </Grid>
            </Grid>
            
            <Grid container spacing={3.5}>

                <Grid item xs={12}>
                    <ShipmentError startDate={startDate} endDate={endDate} reload={reload} reloadFunc={reloadFunc} />
                </Grid>
        </Grid>
        </React.Fragment >
    );
};

//const RollOutButton = (props) => {
//    const [open, setOpen] = React.useState(false);
//    const [completed, setCompleted] = React.useState(false);

//    const openForm = () => {
//        setOpen(true);

//    }
//    const handleClose = () => {
//        setCompleted(false);
//        setOpen(false);
//    }


//    return <React.Fragment>
//        <FormModal open={open} handleClose={handleClose} title={"Project Sales"} >
//            <ProjectSales access={props.access} reload={props.reloadFunc} handleClose={handleClose} completed={completed} />
//        </FormModal>
//        <Button onClick={openForm} variant="contained" style={{ marginBottom: '5px' }}>{"Project Sales forward"}</Button>

//    </React.Fragment>
//}

export default SalesOrdersPage;
