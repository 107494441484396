import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, textfieldDateFormat, yesterday, dateFormat } from "../../../utils/dateFunctions";
import { getAPIData, getAPIDataParamsReturn } from "../../../utils/apiFunction";
import { textColorContrast } from "../../../utils/renderFunctions";
import DateSelector from "../../../shared/DateSelector";
import DataTableStandard from "../../../components/DataTableStandard";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { Table, TableBody, TableRow, TableCell, Avatar, TableHead } from "@mui/material";
import { OrganiseMovements } from "../../../utils/oysterMateFunctions";
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import SelectAOCFarmWarehouse from '../../../components/SelectAOCFarmWarehouse';
import { numberFormatErrorToZero as numberFormat, toPercFormat } from '../../../utils/numberFunctions';
import TableContainer from "@mui/material/TableContainer";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { displayDozFormat } from '../../../utils/numberFunctions';

const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const LeaseBreakDown = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    const [forceTask, setForceTask] = React.useState(null);

    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }


    const createBatch = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);

    }
    //need a different date select that goes back 7 days from today
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SelectAOCFarmWarehouse setState={setFarm} state={farm} access={props.access} />
                </Grid>
                <Grid item xs={12} >
                    <Leases warehouseId={farm?.warehouseId} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default LeaseBreakDown;
const Leases = props => {
    const [recData, setRecData] = React.useState(false);
    const [tableData, setTableData] = React.useState(null);
    const [loading1, setLoading1] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [leaseArr, setLeaseArr] = React.useState(null);
    const [leaseTotals, setLeaseTotals] = React.useState(null);
    var showUnit = props.sortBy === "With Unit";
    var showGrade = (showUnit || (props.sortBy === "With Grade"));


    React.useEffect(() => {
        const abortController = new AbortController();
        if (props.warehouseId) {
            setLoading1(true);
            setLoading2(true);
            getAPIDataParamsReturn('/Farms/Groups', {
                WarehouseId: props.warehouseId,
            }, abortController).then((res) => {
                setLoading1(false);
                return setLeaseArr(organiseData(res));
            });
            getAPIDataParamsReturn('/Farms/Lookups/Lines', {
                WarehouseId: props.warehouseId,
            }, abortController).then((res) => {
                setLoading2(false);
                return setLeaseTotals(getLeaseTotals(res));
            });
        }
        return () => {
            abortController.abort();
        };
    }, [props.warehouseId]);

    const getLeaseTotals = (data) => {
        var totals = [];
        for (var i = 0; i < data.length; i++) {
            var index = totals.findIndex((t) => t.leaseId === data[i].leaseId);
            if (index < 0) {
                totals.push({
                    lease: data[i].lease,
                    leaseId: data[i].leaseId,
                    total: data[i].length,
                });
            } else {
                totals[index].total += data[i].length;
            }
        }
        return totals;
    }


    const organiseData = (data) => {
        //group data by lease
        var leases = [];
        for (var i = 0; i < data.length; i++) {
            var leaseIndex = leases.findIndex((l) => l.leaseId === data[i].location?.line?.leaseId);
            if (leaseIndex < 0) {
                leases.push({
                    leaseId: data[i].location?.line?.leaseId,
                    leaseName: data[i].location?.line?.lease?.name,
                    leaseNo: data[i].location?.line?.lease?.number,
                    groups: [data[i]]
                });
            } else {
                leases[leaseIndex].groups.push(data[i]);
                //add to lease
            }
        }
        console.log(leases)
        for (var i = 0; i < leases.length; i++) {
            //get totals of all groups that have the same grade and croplabel and unittype
            var groups = leases[i].groups;
            var groupTotals = [];
            var used = 0;
            for (var j = 0; j < groups.length; j++) {
                used += groups[j].totalUnits;
                var groupIndex = groupTotals.findIndex((g) => g.gradeId === groups[j].gradeId && g.cropLabel === groups[j].cropLabel && g.unitTypeId === groups[j].unitTypeId);
                if (groupIndex < 0) {
                    groupTotals.push({
                        gradeId: groups[j].gradeId,
                        grade: groups[j].grade,
                        cropLabel: groups[j].cropLabel,
                        unitTypeId: groups[j].unitTypeId,
                        unitType: groups[j].unitType,
                        total: groups[j].totalUnits * groups[j].average,
                        totalUnits: groups[j].totalUnits
                    });
                } else {
                    groupTotals[groupIndex].total += groups[j].totalUnits * groups[j].average;
                    groupTotals[groupIndex].totalUnits += groups[j].totalUnits;
                }
            }
            leases[i].available = 0;
            leases[i].used = used;
            leases[i].groupTotals = groupTotals.sort((a, b) => {
                //sort by cropLabel, grade, unitType
                if (a.cropLabel !== b.cropLabel) {
                    return a.cropLabel > b.cropLabel ? 1 : -1;
                } else {
                    if (a.grade !== b.grade) {
                        return a.grade?.id > b.grade?.id ? 1 : -1;

                    } else {
                        return a.unitType?.description > b.unitType?.description ? 1 : -1;
                    }
                }


            });
        }


        return leases;
    }
    var totalUsed = 0;
    var totalAvailable = 0;
    return (
        <JumboCardQuick
            title={<span>Leases<div style={{ position: 'absolute', top: '2%', right: '1%' }}></div></span>}
            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
            }}
        >
            {(loading1 || loading2) ? <CircularProgress /> : <TableContainer>
                <Table>
                    <TableHead style={{ zIndex: 1000, }} >
                        <TableCell sx={{ pl: 3, }}>
                        </TableCell>
                        <TableCell sx={{ pl: 3, }}>
                            Name
                        </TableCell>
                        <TableCell sx={{ pl: 3, }}>
                            Number
                        </TableCell>
                        {/*<TableCell sx={{ pl: 3, }}>*/}
                        {/*    Area*/}
                        {/*</TableCell>*/}
                        <TableCell sx={{ pl: 3, textAlign: 'right' }}>
                            Avaliable Space 
                        </TableCell>

                    </TableHead>
                    {leaseTotals?.sort((a, b) => {return a.lease?.name>b.lease?.name?1:-1}).map((l) => {
                        var leaseStat = leaseArr?.find((lA) => lA.leaseId === l.leaseId);
                        var available = 0;
                        if (leaseStat) {
                            totalUsed += leaseStat.used;
                            totalAvailable += l.total;
                            available = l.total - leaseStat.used;
                        } else {
                            available = l.total;
                        }
                        return <Row lease={l} leaseStat={leaseStat} available={available<0?0:available} total={l.total} />
                    })}
                    <TableRow hover style={{ zIndex: 1000, }} >

                        <TableCell>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{((totalAvailable - totalUsed) / totalAvailable*100).toFixed(0)}%</Typography>
                        </TableCell>

                    </TableRow>
                </Table>
            </TableContainer>}
        </JumboCardQuick>
    );
};


function Row(props) {
    const { lease, leaseStat, available, total } = props;
    const [open, setOpen] = React.useState(false);
    var totalUnits = 0;
    var totalOysters = 0;
    console.log(lease)
    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {lease?.lease?.name}
                </TableCell>
                <TableCell >{lease?.lease?.number}</TableCell>
                {/*<TableCell >{lease?.lease?.size?.toFixed(2)} ha</TableCell>*/}
                <TableCell align="right"><span>{(available / total * 100).toFixed(0)}% </span><span style={{fontSize:'80%'}} >({numberFormat(available)}/{numberFormat(total)})</span></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                           
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Crop</TableCell>
                                        <TableCell>Grade</TableCell>
                                        <TableCell>Unit Type</TableCell>
                                        <TableCell align="right">Units</TableCell>
                                        <TableCell align="right">Total Oysters</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leaseStat?.groupTotals?.map((gt, k) => {
                                        totalUnits += gt.totalUnits;
                                        totalOysters += gt.total;
                                        return <TableRow key={k}>
                                            <TableCell component="th" scope="row">
                                                {gt.cropLabel}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {gt.grade.description}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {gt.unitType.description}
                                            </TableCell>
                                            <TableCell align="right">{gt.totalUnits}</TableCell>
                                            <TableCell align="right">{displayDozFormat(gt.total)}</TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow hover style={{ zIndex: 1000, }} >
                                        <TableCell>
                                            <Typography sx={{ fontWeight: 'bold' }} variant={"h5"} mb={0}>Total</Typography>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{totalUnits}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{ textAlign: 'right', fontWeight: 'bold' }} variant={"h5"} mb={0}>{displayDozFormat(totalOysters)}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
