import React from 'react';
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { numberFormat } from "../../../utils/numberFunctions";
import { handlePagination } from "../../../utils/apiFunction";
import MUIDataTable from "mui-datatables";
import { getOysterGrades,gradeSort } from "../../../definitions/oysters";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import WebhookIcon from '@mui/icons-material/Webhook';
import EditIcon from '@mui/icons-material/Edit';
import { useJumboTheme } from "@jumbo/hooks";
import { CircularProgress, Typography } from "@mui/material";
import { TableCell, TableRow, TableFooter } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { reverseSort } from '../../../utils/sortFunctions';
import { salesOrderAndShipmentsTypes, statusList } from '../../../utils/constants/selectFields';
import getShipSaleData from '../Function/GetShipSaleList';

const CANCELLED = "Cancelled";
const CANCELED = "Canceled";
const CLOSED = "Closed";
const BACKORDER = "Back Order";
const COMPLETED = "Completed";
const SHIPPING = "Shipping";
const TRANSFERTYPE = 'TR';
const TRANSFERTYPELONG = 'Transfer';
const INVOICED = 'Invoiced';
const CONFIRMED = 'Confirmed';
const OPEN = 'Open';



const SalesOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const { theme } = useJumboTheme();
    const quantityLabel = 'Total Oyster Quantity';
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);

    React.useEffect(() => {
        var cols = [
            {
                name: 'myobid',
                label: 'Order Number',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                        var proc = props.filter ? props.filter(procurement) : procurement;
                        return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=SO&OrderNbr=' + proc[dataIndex]?.myobid} target='_blank'>{proc[dataIndex]?.myobid}</Link>
                    },
                    sortCompare: reverseSort
                }
            },
            {
                name: 'orderDate',
                label: 'Order Date'
            },
            {
                name: 'name',
                label: 'Customer',
                options: props.customerFilter ? { filterList: [props.customerFilter] } : {}
            },

            {
                name: 'status',
                label: 'Status'
            },
            {
                name: 'shipmentDate',
                label: 'Shipment Date'
            },
            {
                name: 'orderTypeName',
                label: 'Order Type'
            },
            {
                name: 'description',
                label: 'Description'
            },
            //{
            //    name: 'quantity',
            //    label: quantityLabel
            //}, This is not showing the shipped quantity
             ...oysterCols,
            {
                name: 'nonStockQuantity',
                label: 'Non Stock Quantity'
            },


        ]
        if (props.editOne) {

            cols.push({
                name: 'editOrder',
                label: 'Edit Order',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        return <IconButton aria-label="cci" onClick={props.editOne() ? props.editOne(procurement, dataIndex) : () => { }}>
                            <EditIcon sx={{ fontSize: 10 }} />
                        </IconButton>
                    },
                }
            })
        }
        if (props.editAll) {
            cols.push({
                name: 'seriesLink',
                label: 'Edit Series',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        if (procurement[dataIndex]?.seriesId) {
                            return <IconButton aria-label="cci" onClick={props.editAll() ? props.editAll(procurement, dataIndex) : () => { }}>
                                <WebhookIcon sx={{ fontSize: 10 }} />
                            </IconButton>
                        } else {
                            return null;
                        }
                    },
                }
            });
        }
        setColumns(cols);
    }, [props.customerFilter, procurement,grades])
    var oysterCols = grades.map((og) => {
        return {
            name: String(og.id),
            label: og.abv,
            options: {
                filter: false
            }
        }
    })

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        rowsPerPage: 1000,
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),
        setRowProps: row => {
            var selRow = row[3];
            if (selRow === ("Completed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (selRow === ("Shipping")) {
                return {
                    style: { background: theme.palette.oysterProducts.shipping }
                };
            } else if (selRow === ("Invoiced")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (selRow === ("Confirmed")) {
                return {
                    style: { background: 'rgba(152, 251, 152,0.3)' }
                };
            } else if (selRow === ("Open")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (selRow === ("Awaiting Release")) {
                return {
                    style: { background: 'rgba(152, 251, 152,0.3)' }
                };
            } else if (selRow === ("Canceled")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            } else if (selRow === ("Closed")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            }
        }
    };
    const calculateTotalSum = (data, displayData) => {
        var totArr = [];
                console.log(displayData);
        if (data && data.length > 0) {
            for (var i = 0; i < columns.length; i++) {
                totArr.push(displayData.map((a) => {
                    var res = data[a.dataIndex][columns[i].name];
                    //if (columns[i].name === 'srsco') {
                    //    console.log(res);
                    //}
                    if (Number(res)) {
                        return res;
                    } else {
                        return '';
                    }
                }).reduce((a, b) => {
                    if (Number(b) || (Number(b) === 0)) {
                        return (a += Number(b))
                    } else {
                        return '';
                    }
                }, 0));
            }
        }
        return totArr;
    };
    if (props.summary) {
        options.customTableBodyFooterRender = (opts) => {
            var proc = props.filter ? props.filter(procurement) : procurement;
            console.log(opts.data);
            var openTotals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[3] !== 'Completed') && (dt.data[3] !== 'Closed') && (dt.data[3] !== 'Invoiced') && (dt.data[3] !== 'Canceled') && (dt.data[3] !== 'Back Order'))));
            var CompletedTotals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[3] === 'Completed') || (dt.data[3] === 'Invoiced') || (dt.data[3] === 'Closed'))));
            var totals = calculateTotalSum(proc, opts.data);
            var sumTotal = totals.reduce((p, c, ind) => { if (opts.columns[ind].label.length === 5) { return (p + c) } else { return p } }, 0);
            var sumCompletedTotal = CompletedTotals.reduce((p, c, ind) => { if (opts.columns[ind].label.length === 5) { return (p + c) } else { return p } }, 0);
            var sumOpenTotal = openTotals.reduce((p, c, ind) => { if (opts.columns[ind].label.length === 5) { return (p + c) } else { return p } }, 0);
            const paddColStyle = {
                color: '#000',
                paddingTop: '1px',
                paddingBottom: '1px'
            }
            return (
                <TableFooter >
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Active
                                </TableCell>
                            
                            } else if (index === 6) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumOpenTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 5) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(openTotals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Completed
                                </TableCell>
                            } else if (index === 6) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumCompletedTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 5) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(CompletedTotals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Total
                                </TableCell>
                            } else if (index === 6) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 5) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(totals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                </TableFooter>
            );
        }
    }
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        getShipSaleData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate),abortController,setLoading,setProcurement);
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, props.reload]);

    
    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={<Typography variant={'h4'} >Sales Orders <span style={{fontSize:'70%'}} >(If the order is completed the shipped QTY is shown)</span></Typography>}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reloadFunc}
            shrink={true}

        />
    );
};

export default SalesOrders;
