import React from 'react';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

const DateSelector = (props) => {
    const [value, setValue] = React.useState((props.date));
    const [open, setOpen] = React.useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} >
            <DatePicker
                views={['month', 'year']}
                label={props.title}
                maxDate={DateTime.now()}
                value={value}
                openTo="month"
                open={open}
                onChange={(newValue) => {
                    setValue(newValue);
                    props.setDate(newValue);
                }}
                onMonthChange={() => { setOpen(false) }}
                onOpen={() => { setOpen(true)} }
                onClose={() => { setOpen(false) }}
                inputFormat="MMM yyyy"
                renderInput={(params) => <TextField {...params} helperText={null} />}
            />
        </LocalizationProvider>
    )
}
export default DateSelector;