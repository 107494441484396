import React from 'react';
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { adalGraphFetch, authContext, RequestGraphData } from '../../../adalConfig';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import CallWindow from './CallWindow';
import FormModal from '../../components/FormModal';
import Link from '@mui/material/Link';
import TooltipStandard from '../../components/Tooltip';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useJumboTheme } from "@jumbo/hooks";
import { CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';


const Groups = [
    { id: 1, name: 'IT', users: [] },
    { id: 2, name: 'Finance', users: [] },
    { id: 3, name: 'Sales & Marketing', users: [] },
    { id: 5, name: 'Production', users: [] },
    { id: 6, name: 'Corporate', users: [] },
    { id: 7, name: 'Support', users: [] },
    { id: 8, name: 'Cranbrook', users: [] },
    { id: 9, name: 'Kingsgrove', users: [] },
    { id: 10, name: 'Farms', users: [] },
    { id: 11, name: 'Hastings', users: [] },
    { id: 12, name: 'Wallis Lake', users: [] },
    { id: 13, name: 'Shoalhaven', users: [] },
    { id: 14, name: 'Narooma Wagonga', users: [] },
    { id: 15, name: 'Bermagui', users: [] },
    { id: 16, name: 'Wapengo', users: [] },
    { id: 17, name: 'Merimbula', users: [] },
    { id: 18, name: 'Executives', users: [] },
    { id: 19, name: 'HR', users: [] },
    { id: 20, name: 'Payroll', users: [] },
    { id: 21, name: 'WHS', users: [] },

];
var GroupOrg = [
    { id: 1, name: 'IT', default: 1, groups: [], section: 1 },
    { id: 2, name: 'Finance', default: 2, groups: [], section: 1 },
    { id: 3, name: 'Sales & Marketing', default: 3, groups: [], section: 1 },
    { id: 5, name: 'Production', default: 5, groups: [], section: 1 },
    { id: 6, name: 'Corporate', default: 6, groups: [], section: 2 },
    { id: 7, name: 'Support', default: 7, groups: [], section: 1 },
    { id: 8, name: 'Cranbrook', default: 8, groups: [], section: 2 },
    { id: 9, name: 'Kingsgrove', default: 9, groups: [], section: 2 },
    { id: 10, name: 'Farms', default: 10, groups: [11, 12, 13, 14, 15, 16, 17], section: 1 },
    { id: 18, name: 'Executives', default: 18, groups: [], section: 0 },
    { id: 19, name: 'HR', default: 19, groups: [], section: 1 },
    { id: 20, name: 'Payroll', default: 20, groups: [], section: 1 },
    { id: 21, name: 'All', default: null, groups: [], section: 0 },
    { id: 22, name: 'WHS', default: 21, groups: [], section: 1 },
];

var userGroups = [
    { email: 'dwatson@aoc.group', groups: [2,18, 8, 6] },
    //{ email: 'swestbury@aoc.group', groups: [2, 8, 18, 6] },
    { email: 'dcharlesworth@aoc.group', groups: [3, 6, 18] },
    { email: 'pboyton@aoc.group', groups: [18, 10, 6, 8] },
    { email: 'jdixon@aoc.group', groups: [9, 18, 5, 6] },
    { email: 'schen@aoc.group', groups: [2, 9, 6] },
    { email: 'azhuo@aoc.group', groups: [2, 9, 6] },
    { email: 'tshilton@aoc.group', groups: [2, 8, 6,20] },
    //{ email: 'LMorley@aoc.group', groups: [6, 8, 20, 7, 2] },
    { email: 'lucasr@aoc.group', groups: [3, 6] },
    { email: 'marketing@aoc.group', groups: [3, 6, 8] },//gabe
    { email: 'bbrown@aoc.group', groups: [7, 8, 6] },
    //{ email: 'productionsupport@aoc.group', groups: [7, 5, 8, 6] }, //caryl-anne
    { email: 'blimon@aoc.group', groups: [10] },
    { email: 'edavey@aoc.group', groups: [5, 8, 6] },
    { email: 'Fulfilment@aoc.group', groups: [5] },
    { email: 'halfshell@aoc.group', groups: [5] },
    { email: 'jdunlop@aoc.group', groups: [1, 6, 8] },
    { email: 'srussell@aoc.group', groups: [1, 6, 8] },
    //{ email: 'pharlock@aoc.group', groups: [7, 6, 8, 21] },
    { email: 'jzappia@aoc.group', groups: [5, 9, 6] },
    { email: 'kyeung@aoc.group', groups: [9, 5] },
    { email: 'LLehideux@aoc.group', groups: [19, 8, 6,21] },
    { email: 'liveboxed@aoc.group', groups: [5] },
    { email: 'sydprocessing@aoc.group', groups: [5] },
    { email: 'klin@aoc.group', groups: [11, 10] },
    { email: 'hastings@aoc.group', groups: [11, 10] },
    { email: 'hatchery@aoc.group', groups: [11, 10] },
    { email: 'mcarter@aoc.group', groups: [11, 10] },
    { email: 'nharris@aoc.group', groups: [12, 10] },
    { email: 'rbryant@aoc.group', groups: [12, 10] },
    { email: 'wderwent@aoc.group', groups: [13, 10] },
    { email: 'shoalhaven@aoc.group', groups: [13, 10] },
    { email: 'tlimon@aoc.group', groups: [14, 10] },
    { email: 'narooma@aoc.group', groups: [14, 10] },
    { email: 'tandersen@aoc.group', groups: [14, 10] },
    { email: 'JHoyland@aoc.group', groups: [16, 10] },
    { email: 'wapengo@aoc.group', groups: [16, 10] },
    { email: 'acaves@aoc.group', groups: [15, 10] },
    { email: 'bermagui@aoc.group', groups: [15, 10] },
    { email: 'merimbula@aoc.group', groups: [17, 10] },
    { email: 'bjoiner@aoc.group', groups: [17, 10] },
];
let timer;

const Connect = (props) => {
    const { theme } = useJumboTheme();
    const [users, setUsers] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [selUser, setSelUser] = React.useState(null);
    const [photoArray, setPhotoArray] = React.useState(null);
    const [group, setGroup] = React.useState(null);
    const [orgGroup, setOrgGroup] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [exReload, setExReload] = React.useState(false);
    var screenWidth = window.innerWidth;
    //const [timer, setTimer] = React.useState(false);
    const onlineStatus = theme.palette.onlineStatus;
    const startStopTimer = (inUse) => {
        if (!inUse) {
            reloadFunc();
        } else {
            clearInterval(timer);
            timer = (null);
        }
    }

    const reloadFunc = () => {
        if (!timer) {
            timer = (setInterval(() => {
                getPrescence(users);
            }, 30000))
        }
    }

    const getUsers = async () => {


        RequestGraphData('https://graph.microsoft.com/v1.0/users?$top=999', null)
            .then(async (response) => {
                var data = await response.json();
                if (response.status === 200) {
                    var newUserList = data.value.filter((dv) => { return userGroups.find((ug) => (ug.email === dv.userPrincipalName)) });
                    getPrescence(newUserList);
                    getPhotos(newUserList);
                } else {
                    console.log('access not working yet');
                    //window.alert(JSON.stringify(data));

                }
            }).catch(async (e) => {
                //await authContext.acquireTokenRedirect({});
                //adalGraphFetch2(fetch, 'https://graph.microsoft.com/v1.0/users?$top=999', {}, authContext)
                //    .then(async (response) => {
                //        var data = await response.json();
                //        //window.alert("Second chance Win" + JSON.stringify(data));
                //    }).catch((e) => {
                //        //window.alert("Second chance FAil" + JSON.stringify(e));
                //        console.log(e);
                //    });
                ////window.alert(JSON.stringify(e));
                console.log(e);
            });
    }
    const getPhotos = async (userList) => {
        var promArr = [];
        for (var i = 0; i < userList.length; i++) {
            promArr.push(RequestGraphData('https://graph.microsoft.com/v1.0/users/' + userList[i].id + '/photo/$value?$top=999', null)
                .then(async (response) => {
                    var data = await response.blob();
                    if (response.status === 200) {
                        return URL.createObjectURL(data);
                    }
                }).catch((e) => {
                    //window.alert(JSON.stringify(e));
                    console.log(e);
                }));
        }
        var data = await Promise.all(promArr);
        setPhotoArray(data.map((dt, i) => ({ id: userList[i], image: dt })));
    }
    const getPrescence = async (userList) => {

        RequestGraphData('https://graph.microsoft.com/beta/communications/getPresencesByUserId?$top=999', {
            method: 'POST', headers: { "Content-Type": "application/json" }, body: JSON.stringify({ "ids": userList.map((ul) => ul.id) })
        })
            .then(async (response) => {
                var data = await response.json();
                if (response.status === 200) {
                    setUsers(userList.map((ul) => {
                        return { ...ul, ...data.value.find((d) => d.id === ul.id) }
                    }));
                    setLoading(false);
                } else {
                    //window.alert(JSON.stringify(data));
                }
            }).catch((e) => {
                //window.alert(JSON.stringify(e));
                console.log(e);
            });
    }
    React.useEffect(() => {
        setLoading(true);
        getUsers();
            
        return () => {
            clearInterval(timer);
        }
    }, []);


    if (loading) {
        return <CircularProgress />
    } else {
        return (
            <Box onMouseOver={() => { startStopTimer(true) }} onMouseLeave={() => { startStopTimer(false) }}>

                <div style={{ marginBottom: '30px' }} >
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={orgGroup}
                        onChange={(event, sel) => { setOrgGroup(sel); setGroup(Groups.find((gr) => { return gr.id === sel.default })) }}
                        aria-label="text alignment"
                        style={{ paddingRight: '40px' }}
                    >{GroupOrg.filter((gr) => gr.section === 0).map((group) => {
                        return <ToggleButton key={group.id} value={group} >
                            <div style={{ fontSize: '80%' }} >{group.name}</div>
                        </ToggleButton>
                    })}

                    </ToggleButtonGroup>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={orgGroup}
                        onChange={(event, sel) => { setOrgGroup(sel); setGroup(Groups.find((gr) => { return gr.id === sel?.default })) }}
                        aria-label="text alignment"
                        style={{ paddingRight: '40px' }}

                    >{GroupOrg.filter((gr) => gr.section === 1).map((group) => {
                        return <ToggleButton key={group.id} value={group} >
                            <div style={{ fontSize: '80%' }} >{group.name}</div>
                        </ToggleButton>
                    })}
                    </ToggleButtonGroup>

                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={orgGroup}
                        onChange={(event, sel) => { setOrgGroup(sel); setGroup(Groups.find((gr) => { return gr.id === sel.default })) }}
                        aria-label="text alignment"

                    >{GroupOrg.filter((gr) => gr.section === 2).map((group) => {
                        return <ToggleButton key={group.id} value={group} >
                            <div style={{ fontSize: '80%' }} >{group.name}</div>
                        </ToggleButton>
                    })}
                    </ToggleButtonGroup>
                    {orgGroup && (orgGroup.groups.length > 0) ? <ToggleButtonGroup
                        color="primary"
                        exclusive
                        disabled={props.disabled}
                        value={group}
                        onChange={(event, sel) => { if (sel) { setGroup(sel) } else { setGroup(orgGroup ? Groups.find((gr) => { return gr.id === orgGroup.default }) : null) } }}
                        aria-label="text alignment"
                        style={{ display: 'block', paddingTop: '3px' }}

                    >{orgGroup?.groups.map((grp) => {
                        var groupDetail = Groups.find((gr) => { return gr.id === grp })
                        return <ToggleButton key={grp} value={groupDetail} >
                            <div style={{ fontSize: '80%' }} >{groupDetail?.name}</div>
                        </ToggleButton>
                    })}

                    </ToggleButtonGroup> : null}
                </div>
                <Grid container spacing={3.5} >

                    {users.sort((a, b) => { return a.availability > b.availability ? 1 : -1 }).filter((user) => {
                        if (group) {
                            return userGroups.find((ug) => ug.email === user.userPrincipalName)?.groups.includes(group.id);
                        } else {
                            return userGroups.find((ug) => ug.email === user.userPrincipalName);
                        }
                    }).map((user) => {
                        var statusCol = 'white';
                        if ((user.availability === 'Available')) {
                            statusCol = onlineStatus.online;
                        }
                        if ((user.availability === 'Busy')) {
                            statusCol = onlineStatus.busy;
                        }
                        if (user.availability === 'DoNotDisturb') {
                            statusCol = onlineStatus.busy;
                        }
                        if (user.availability === 'BeRightBack') {
                            statusCol = onlineStatus.away;
                        }
                        if ((user.availability === 'Away') || (user.availability === 'BusyIdle') || (user.availability === 'AvailableIdle')) {
                            statusCol = onlineStatus.away;
                        }
                        if (user.availability === 'Offline') {
                            statusCol = onlineStatus.offline;
                        }
                        var offsetAdjust = (1920 - screenWidth)/10;
                        console.log(offsetAdjust);
                        if ((user.availability !== 'PresenceUnknown')) {
                            var url = 'https://teams.microsoft.com/l/call/0/0?users=' + user.userPrincipalName;
                            return <Grid item xs={6} sm={4} md={3} lg={2} xl={1.6} style={{ position: 'relative' }}>
                                <TooltipStandard popperProps={{
                                    modifiers: [
                                        {
                                            name: "offset",
                                            options: {
                                                offset: [0, (-35 - offsetAdjust)],
                                            },
                                        },
                                    ]
                                }} title={
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { width: '24ch', },
                                            margin: 1
                                        }}
                                        noValidate
                                        autoComplete="off">
                                        <Typography variant={"h5"} style={{ textAlign: 'left', marginBottom: '7px' }}> <Link underline="hover" color="inherit" href={"msteams:/l/call/0/0?users=" + user.userPrincipalName}  ><CallIcon style={{ fontSize: '110%', paddingRight: '5px' }} />Call {user.givenName}</Link></Typography>
                                        <Typography variant={"h5"} style={{ textAlign: 'left', }}> <Link underline="hover" color="inherit" href={"msteams:/l/chat/0/0?users=" + user.userPrincipalName}  ><ModeCommentIcon style={{ fontSize: '110%', paddingRight: '5px' }} />Message {user.givenName}</Link></Typography>
                                        {user.userPrincipalName ? <Typography variant={"h5"} style={{ textAlign: 'left', }}> <Link underline="hover" color="inherit" href={"mailto:" + user.userPrincipalName}  ><AlternateEmailIcon style={{ fontSize: '110%', paddingRight: '5px' }} />{user.userPrincipalName}</Link></Typography> : null}
                                        {user.mobilePhone ? <Typography variant={"h5"} style={{ textAlign: 'left', }}> <CallIcon style={{ fontSize: '110%', paddingRight: '5px' }} />{user.mobilePhone}</Typography> : null}
                                        {user?.outOfOfficeSettings?.isOutOfOffice ? <Typography variant={"h5"} style={{ textAlign: 'left', }}> <ErrorIcon style={{ fontSize: '110%', paddingRight: '5px' }} />{user?.outOfOfficeSettings?.message}</Typography> : null}
                                    </Box>
                                } placement={"right"} >
                                    <Card style={{ backgroundColor: statusCol, color: 'black', height: '90px', width: '180px', position: 'relative' }}  >
                                            {user?.outOfOfficeSettings?.isOutOfOffice ? <ErrorIcon style={{ color: 'red', top: '1%', left: '1%', position: 'absolute' }} /> : null}
                                         <CardContent  >

                                            <Grid container spacing={3.5} >
                                                <Grid item xs={4} >
                                                    <Avatar
                                                        src={photoArray?.find((pA) => (pA?.id?.id === user.id))?.image}
                                                        sizes={"small"}
                                                        sx={{ boxShadow: 25, cursor: 'pointer', pointerEvents: 'auto' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={8} >
                                                    <Typography variant={"h6"} style={{ textAlign: 'left', marginBottom: '7px' }}>{user.displayName}</Typography>
                                                </Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>
                                </TooltipStandard>
                            </Grid>

                        } else {
                            return null;
                        }


                    })}

                </Grid>
            </Box >
        );
    }
};


export default Connect;
