import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import { Locations } from "../../utils/constants/locations";
import PurchaseOrders from '../Procurement/Tables/PurchaseOrders';
import FormModal from '../../components/FormModal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import AddPurchaseOrder from '../Procurement/Forms/AddPurchaseOrder';
const PurchaseOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [open, setOpen] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [duplicate, setDuplicate] = React.useState(false);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const handleClose = (date) => () => {
        if (date) {
            setStartDate(startOfWeek(new Date(date)));
            setEndDate(endOfWeek(new Date(date)));
        }
        setOpen(false);
        setDuplicate(false);
    }
    const reloadFunc = () => {
        setReload(!reload);
    }

    const createPO = () => {
        setOpen(true);
        setCompleted(false);
        setEdit(false);
        setData(null);
    }
    const updatePO = (array) => (rowd, rowState) => {
        console.log(array, rowState)
        setData(array[rowState.dataIndex]);
        setOpen(true);
        if (array[rowState.dataIndex].status === 'Closed' || array[rowState.dataIndex].status === 'Completed') {
            setCompleted(true);
        } else {
            if (edit) {
                setCompleted(false);
            } else {
                setCompleted(true);
            }
        }
    }
    const handleDuplicate=(array, index) => (e) => {
        e.stopPropagation();
        console.log(array,index)
        setData(array[index]);
        setOpen(true);
        setDuplicate(true);
        setCompleted(false);
    }
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose(null)} title={edit || duplicate ? duplicate ? "Duplicate Purchase Order" : "Update Purchase Order" : (data?"View Purchase Order":"Create Purchase Order")} >
                <AddPurchaseOrder duplicate={duplicate} data={data} access={props.access} reload={reloadFunc} returnDate={true} handleClose={handleClose} edit={edit} completed={completed} />
            </FormModal>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <WarehouseSelector location={location} setLocation={setLocation} />
                </Grid>
            </Grid>

            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Button onClick={createPO} variant="contained" >Create New Purchase Order</Button>
                    {checkAccess(props.access, requiredAdminAccess) ? <FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={edit} onChange={(event) => { setEdit(event.target.checked) }} />} label={edit ? 'Edit Enabled' : 'Edit Disabled'} /> : ''}

                </Grid>
                <Grid item xs={12}>
                    <PurchaseOrders handleDuplicate={handleDuplicate} duplicatePO sortOrderNo  startDate={startDate} endDate={endDate} access={props.access} onClick={updatePO} long warehouseFilter={Locations[location] ? Locations[location] : null} externalReload={reload} />
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default PurchaseOrdersPage;
