import React, { useEffect, useState } from 'react';
import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";
import DndWrapper from "../extensions/DndWrapper";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {  useMediaQuery } from "@mui/material";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const DzPreviews = (props) => {
    const mobile = useMediaQuery('(max-width:575px)');
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            props.setFiles(
                [...props.files,...acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    }),
                )],
            );
        },
    });
    const removeFile = (remFile) => () => {
        console.log(remFile);
        var cpyFiles = [...props.files];
        for (var i = 0; i < props.files.length; i++) {
            if (props.files[i].name === remFile.name) {
                //splice
                cpyFiles.splice(i, 1);
                i = props.files.length;
            }
        }
        props.setFiles(cpyFiles);
    }
    const thumbs = props.files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner} onClick={removeFile(file)}>
                <img src={file.preview} style={img} alt="" />
            </div>
        </div>
    ));

    useEffect(
        () => () => {
            props.files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [props.files],
    );
    return (
        <JumboCardQuick
            title={props.files.length>0?"Click Image to Remove":(props.disabled?"Image":"Add Image")}
            demoCode={"Drop Image here"}
        >
            <Div>
                {((props.files.length > 0)&&!props.multiple) ?'':props.disabled ? '' :
                    <DndWrapper >
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                            <Typography variant={"body1"}>{mobile ? "CLICK HERE" : "Drag 'n' drop an image here, or click HERE to select files"}</Typography>
                    </div>
                </DndWrapper>}
                <aside style={thumbsContainer}>{thumbs}</aside>
            </Div>
        </JumboCardQuick>
    );
};

export default DzPreviews;