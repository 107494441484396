import React, { useMemo } from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow, textfieldDateFormat } from "../../utils/dateFunctions";
import { getAPIDataParams, getAPIDataParamsReturn } from "../../utils/apiFunction";
import { numberFormat } from '../../utils/numberFunctions';
import DatePicker from "../../shared/DatePicker";
import { useSearchParams } from "react-router-dom";
import { locationMatch, buildColGroups } from '../../utils/constants/orderItems';
import CustomerOrder from '../Processing/Tables/CustomerOrder';
import DraggablePurchaseOrder from '../Procurement/Tables/DraggablePurchaseOrders';
import CompletedRuns from '../Procurement/Tables/CompletedRuns';
import SelectWarehouse from '../../components/SelectWarehouse';
import { WAREHOUSEGROUPS, WAREHOUSEMATCHES } from "../../utils/constants/warehouse";
import { useJumboTheme } from "@jumbo/hooks";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { TableRow, Table, TableCell, TableHead, TableBody, Typography } from '@mui/material';
import HeadRows from '../../components/CustomerOrder/HeadRows';
import { checkCols, shiftCol, findbyTopLevel, generateValues, printColor, getColFromCol, findbyName } from '../../components/CustomerOrder/CustomerOrderFunctions';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const DailyPlan = (props) => {
    const { theme } = useJumboTheme();
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [location, setLocation] = React.useState(null);
    const [display, setDisplay] = React.useState(0);
    const [dailyGoals, setDailyGoals] = React.useState(null);
    const [tomorrowGoals, setTomorrowGoals] = React.useState(null);
    const [OrderColumns, setOrderColumns] = React.useState(null);
    const [showTomorrow, setShowTomorrow] = React.useState(false);

    var showColumns = []; //only cahgne in geneterate body data
    const [topLevelList, setTopLevelList] = React.useState([]);
    const productPalette = theme.palette.oysterProducts;


    //use memo to save startdate tomorrow
    var tomorrowDate = useMemo(() => { return tomorrow(startDate) }, [startDate]);

    const reloadPage = () => {
        setReload(!reload);
    }
    const getOrderCols = async () => {
        var getCols = await (await buildColGroups(true));
        setOrderColumns(getCols.sortList);
        var cols = [productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F, productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F, productPalette.A, productPalette.B, productPalette.C, productPalette.D, productPalette.E, productPalette.F];
        setTopLevelList(getCols?.displayStruc.map((tl, ind) => {

            return {
                name: tl.name,
                col: generateValues(cols[ind]),
                cols: findbyTopLevel(tl.name, getCols.sortList)
            }
        }));
    }
    React.useEffect(() => {
        if (location && startDate && !isNaN(startDate.valueOf())) {
            getAPIDataParamsReturn('/Processing/GetDailyGoals', { Date: textfieldDateFormat(startDate), WarehouseId: location?.id }).then((dgs) => {
                setDailyGoals(dgs);
            });
            getAPIDataParamsReturn('/Processing/GetDailyGoals', { Date: textfieldDateFormat(tomorrow(startDate)), WarehouseId: location?.id }).then((dgs) => {
                setTomorrowGoals(dgs);
            });
            getOrderCols();
        }
    }, [location, startDate, reload]);
    console.log(OrderColumns);
    if ((OrderColumns) && (dailyGoals) && (tomorrowGoals) && location) {
        for (var i = 0; i < OrderColumns.length; i++) {
            if (OrderColumns[i].location === locationMatch(location.myobid)) {
                if (dailyGoals.find((dg) => (dg.stockItem?.myobid === OrderColumns[i].code))) {
                    showColumns.push(i);
                } else if (showTomorrow && (tomorrowGoals.find((dg) => (dg.stockItem?.myobid === OrderColumns[i].code)))) {
                    showColumns.push(i);
                }
            }
        }
        var liveCols = [...findbyName("Bagged (BBay)", OrderColumns), ...findbyName("Boxed (BBay)", OrderColumns), ...findbyName("Bagged (Syd)", OrderColumns), ...findbyName("Boxed (Syd)", OrderColumns)];
        //filter showcoloumns by livecols
        console.log(liveCols);
        showColumns = showColumns.filter((sc) => (liveCols.includes(sc)));

        console.log(showColumns)
    }



    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DatePicker date={startDate} setDate={setStartDate} label="Date" />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <SelectWarehouse access={props.access} enforceAccess={true} state={location} setState={setLocation} label="Date" all useCache selectOne={(inv) => { return inv === 'LIVEPRODCT' }} />
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <HeadRows summary={true} OrderColumns={OrderColumns ? OrderColumns : []} showColumns={showColumns} tempTitle={''} topLevelList={topLevelList} />
                        <BodyRows showColumns={showColumns} OrderColumns={OrderColumns} dailyGoals={dailyGoals} topLevelList={topLevelList} tomorrowGoals={tomorrowGoals} showTomorrow={showTomorrow} setShowTomorrow={setShowTomorrow} location={location} />

                    </Table>
                </Grid>
                {location ? <React.Fragment> <Grid item xs={12}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={display}
                        onChange={(event, sel) => { setDisplay(sel) }}
                        aria-label="text alignment"
                    >
                        <ToggleButton key={0} value={0} >
                            <div style={{ whiteSpace: 'nowrap' }}>To Do</div>
                        </ToggleButton>
                        <ToggleButton key={1} value={1} >
                            <div style={{ whiteSpace: 'nowrap' }}>Completed</div>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                    {display === 0 ?
                        <Grid item xs={12}>
                            <DraggablePurchaseOrder startDate={startDate} endDate={tomorrowDate} access={props.access} location={location?.id} reloadPage={reloadPage} />
                        </Grid>
                        : <Grid item xs={12}>
                            <CompletedRuns startDate={startDate} endDate={tomorrowDate} access={props.access} location={location?.id} reloadPage={reloadPage} />
                        </Grid>}
                </React.Fragment> : <Typography variant={'h1'} style={{ padding: '50px' }}><ArrowUpwardIcon />Please Choose a Warehouse<ArrowUpwardIcon /></Typography>}
            </Grid>
        </React.Fragment >
    );
};

const BodyRows = ({ showColumns, OrderColumns, dailyGoals, tomorrowGoals, topLevelList, showTomorrow, setShowTomorrow, location }) => {
    const { theme } = useJumboTheme();
    const productPalette = theme.palette.oysterProducts;

    var stickyCol = {
        position: "sticky",
        left: 0,
        background: theme.palette.background.paper
    }

    var custSize = {
        width: '240px',
        maxHeight: '70px',
        overflow: 'auto',
    }

    const colorMixer = (base, added) => {
        var mix = { r: 0, g: 0, b: 0, a: 0 };
        mix.a = 1 - (1 - added.a) * (1 - base.a); // alpha
        mix.r = Math.round((added.r * added.a / mix.a) + (base.r * base.a * (1 - added.a) / mix.a)); // red
        mix.g = Math.round((added.g * added.a / mix.a) + (base.g * base.a * (1 - added.a) / mix.a)); // green
        mix.b = Math.round((added.b * added.a / mix.a) + (base.b * base.a * (1 - added.a) / mix.a)); // blue
        return 'rgba(' + mix.r + ',' + mix.g + ',' + mix.b + ',' + mix.a + ')';
    }

    const MakeRow = (props) => {
        var prevCol = {};
        var shiftColSave = {};
        return <TableRow  >
            <TableCell style={{ ...stickyCol, borderTop: '1px solid black', borderBottom: '1px solid black' }} ><div style={custSize}>{props.title}</div></TableCell>

            {showColumns.map((col) => {
                var ifItem = props.goals?.find((it) => {
                    //console.log(OrderColumns.findIndex((oc) => { return oc.code === it.stockItem?.myobid }),col);
                    var index = OrderColumns.findIndex((oc) => { return (oc.code === it.stockItem?.myobid) && (oc.location === locationMatch(location.myobid))});
                    return index === col;
                });
                //console.log(ifItem, props.goals, showColumns);
                var getItem = ifItem ? {
                    ...ifItem
                } : null;
                var colCol = (getColFromCol(col, topLevelList));
                var pCol = printColor(colCol);
                if (printColor(prevCol) === printColor(colCol)) {
                    pCol = printColor(shiftCol(shiftColSave));
                    shiftColSave = shiftCol(shiftColSave)
                } else {
                    shiftColSave = colCol;
                }
                prevCol = colCol;
                var tomOrders = 0;
                var getTItem = null;
                if (showTomorrow && (props.objKey === 'calc')) {
                    var tItem = props.tomGoals?.find((it) => {
                        var index = OrderColumns.findIndex((oc) => { return (oc.code === it.stockItem?.myobid) && (oc.location === locationMatch(location.myobid)) });
                        return index === col;
                    });
                    getTItem = tItem ? {
                        ...tItem
                    } : null;
                    if (getTItem) {
                        tomOrders = getTItem.orders;
                    }
                }
                if (getItem || getTItem) {
                    var statCol = productPalette.onHold

                    var colObj = generateValues(pCol);
                    var borderString = '1px solid ' + colorMixer({ ...colObj, a: 1 }, { r: 0, g: 0, b: 0, a: 0.1 });
                    var selectedStyle = {
                        background: pCol,
                        borderLeft: borderString,
                        borderRight: borderString,
                        borderBottom: borderString,
                        color: productPalette.selectedText
                    }
                    var value = 0;
                    if (props.objKey === 'calc') {
                        value = tomOrders + (getItem?.orders ? getItem.orders : 0) - (getItem?.processed ? getItem.processed : 0);// - (getItem?.stockOnHand ? getItem.stockOnHand : 0);
                        if (value < 0) {
                            value = 0;
                        }
                    } else {
                        value = getItem?.[props.objKey];
                    }
                    return <TableCell align="center" style={selectedStyle} >{numberFormat(value)}</TableCell>

                } else {

                    return <TableCell style={{
                        backgroundColor: pCol,
                        borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.1)'
                    }}></TableCell>
                }
            })}
        </TableRow>

    }
    const DropButton = () => {
        return <Button
            style={{ marginLeft: '5px' }}
            aria-label="expand row"
            size="small"
            variant='outlined'
            onClick={() => setShowTomorrow(!showTomorrow)}
        >
            {showTomorrow ? 'Hide Tomorrow' : 'Show Tomorrow'}
        </Button>
    }

    return (<TableBody>
        <MakeRow title={<span>Today's Orders<DropButton /></span>} objKey={'orders'} goals={dailyGoals} />
        {showTomorrow ? <MakeRow title="Tomorrow's Orders" objKey={'orders'} goals={tomorrowGoals} /> : null}
        {/*<MakeRow title="Stock On Hand" objKey={'stockOnHand'} goals={dailyGoals} />*/}
        <MakeRow title="Stock On Hand" objKey={'stockOnHandNo'} goals={dailyGoals} />
        <MakeRow title="Processed" objKey={'processed'} goals={dailyGoals} />
        <MakeRow title="Remaining" objKey={'calc'} goals={dailyGoals} tomGoals={tomorrowGoals} />
    </TableBody>)

}
export default DailyPlan;


