
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

// MSAL configuration
const configuration: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : "76ad5fc5-f4d4-45ec-b03a-a3b41c81ca66",
        authority: 'https://login.microsoftonline.com/' + (process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : "bf6a0e22-99c4-4ddf-96e3-8e2575d41787"),
        knownAuthorities: ['https://login.microsoftonline.com/' + (process.env.REACT_APP_TENANT ? process.env.REACT_APP_TENANT : "bf6a0e22-99c4-4ddf-96e3-8e2575d41787")],
        scopes: ["user.read", "user.readbasic.all", "presence.read.all"]
    }
};
var apiScope = "api://" + configuration.auth.clientId + "/SPA";
console.log(configuration.auth.clientId, apiScope)

export const MSALinstance = new PublicClientApplication(configuration);
export const userInfo = () => {
    const MSALinst = new PublicClientApplication(configuration);
    return { ...MSALinst.getAllAccounts()?.[0], userName: MSALinst.getAllAccounts()?.[0]?.username };
}
const callMsGraph = async (token, uri, options) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    // Add additional fields here
    const fields = ["department"]
    if (options) {
        options.headers = { ...options.headers, Authorization: `Bearer ${token}` };
    }
    return fetch(uri, (options ? options : {
        method: "GET",
        headers,
    })).then((response) => response)
}
export const RequestGraphData = async (uri, options) => {

    return MSALinstance
        .acquireTokenSilent({
            scopes: configuration.auth.scopes,
            authority: configuration.auth.authority,
            account: MSALinstance.getAllAccounts()[0]
        })
        .then((response) => {
            return callMsGraph(response.accessToken, uri, options).then((data) => {
                // Do what you want with the data, such as putting it in localstorage
                return data;
            });
        });
}

const callMsAPI = async (token, uri, options) => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    // Add additional fields here
    if (options) {
        options.headers = { ...options.headers, Authorization: `Bearer ${token}` };
    }
    return fetch(uri, (options ? options : {
        method: "GET",
        headers,
    })).then((response) => response)
}
export const RequestAPIData = async (uri, options) => {

    return MSALinstance
        .acquireTokenSilent({
            scopes: [apiScope],
            account: MSALinstance.getAllAccounts()[0]
        })
        .then((response) => {
            return callMsAPI(response.accessToken, uri, options).then((data) => {
                // Do what you want with the data, such as putting it in localstorage
                return data;
            });
        });
}


export const adalApiFetch = (fetch, url, options) =>
    RequestAPIData(url, options);

