import React from 'react';
import { GetAllCacheData, addToCache } from "../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../utils/apiFunction';
import { textfieldDateFormat, startOfWeek,shiftWeek } from '../utils/dateFunctions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import POSelectForm from '../pages/Processing/Forms/POSelectForm';
import FormModal from './FormModal';
import Button from "@mui/material/Button";


const PurchaseOrderSelect = (props) => {
    const [poid, setPoid] = React.useState(props.state);
    const [pos, setPOs] = React.useState([]);
    const [rpos, setRPOs] = React.useState([]);
    const [selPO, setSelPO] = React.useState([]);
    const [openPOModal, setOpenPOModal] = React.useState(null);
    const start = textfieldDateFormat(props.startDate ? new Date(props.startDate) : startOfWeek(new Date()));
    const end = textfieldDateFormat(props.endDate ? new Date(props.endDate) : shiftWeek(new Date(), 1).end);
    const handleChange = (value) => {
        setPoid(value)
        props.setState(value);
    }
    console.log(props.pickupDate)
    React.useEffect(() => {
        const addNoneVal = (res) => {
            setRPOs([{ myobid: 'None' }, ...res]);
        }
        getAPIDataParams('/PurchaseOrder/GetNotReceived', addNoneVal, props.pickupDate ? { UsePickupDate:true} :{ start, end });
    }, []);
    React.useEffect(() => {
        if (props.POID) {
            getAPIData('/PurchaseOrder/Get/' + props.POID, setSelPO);
        }
    }, [props.POID]);
    React.useEffect(() => {
        if (selPO.length > 0) {
            setPOs([...rpos, selPO[0]]);
        } else {
            setPOs(rpos)
        }
    }, [selPO, rpos]);
    React.useEffect(() => {
        if (props.POID) {
            var selPo = pos.find((dr) => { return (dr.id === props.POID) });
            setPoid(selPo);
            props.setState(selPo);
        }
    }, [pos, props.POID])
    const updatePO = (clickPO) => {
        setPoid(clickPO);
        props.setState(clickPO);
    }
    const checkForNull = (op) => {
        if (!op.id) {
            return null;
        } else {
            return op;
        }
    }
    const handleClosePOModal = () => {
        setOpenPOModal(false);
    }
    console.log(props.POID, poid, pos)
    return <React.Fragment>
        <FormModal open={openPOModal} handleClose={handleClosePOModal} title={"New Recieval Form"} >
            <POSelectForm pickupDate={props.pickupDate} access={props.access} handleClose={handleClosePOModal} setPurchaseOrder={updatePO} />
        </FormModal>
        <Button onClick={() => { setOpenPOModal(true) }} disabled={props.disabled} color="secondary" variant="outlined" style={{
            width: '24ch', margin: '8px', height: '6ch', color: '#475259', border: '1px solid rgba(71, 82, 89, 0.3)'
        }}> {poid ? ('PO: ' + poid.myobid) : 'Click to select PO'}</Button >
    </React.Fragment>

    //return <SelectTextField checkID={props.POID} title="Purchase Order ID" state={poid} setState={handleChange} disabled={props.disabled} list={pos} valueFunc={checkForNull} nameFunc={(op) => { return op.myobid }} />
}
export default PurchaseOrderSelect;