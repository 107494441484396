import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DatePicker from "../../shared/DatePicker";
import CustomerOrder from './Tables/CustomerOrder';
const CustomerOrderPage = (props) => {
    const [startDate, setStartDate] = React.useState((new Date()));
    const startDateMemo = React.useMemo(() => (startDate));
    const [exReload, setExReload] = React.useState(false);
    const [inUse, setInUse] = React.useState(false);
    let timer;
    React.useEffect(() => {
        if (!inUse) {
            reloadFunc();
        } else {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [inUse]);

    const reloadFunc = () => {
        timer = !timer && setInterval(() => {
            setExReload(stat => !stat);
        }, 120000)
    }
    return (
        <div onMouseOver={() => { return !inUse ? setInUse(true) : null; }} onMouseLeave={() => { return inUse ? setInUse(false) : null; }}>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                <DatePicker date={startDate} setDate={setStartDate} label="Date" />
                </Grid>
            </Grid>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <CustomerOrder startDate={startDateMemo} access={props.access} externalReload={exReload }/>
                </Grid>
        </Grid>
        </div >
    );
};


export default CustomerOrderPage;
