
export const buildSummary = (transfer) => {
    var quickSummary = '';
    if (transfer) {
        for (var i = 0; i < transfer.coreTransferLines.length; i++) {
            if (transfer.coreTransferLines[i].stockItem) {
                if (i === 0) {
                    quickSummary = transfer.coreTransferLines[i].stockItem.myobid + ':' + transfer.coreTransferLines[i].quantity;
                } else {

                    quickSummary = quickSummary + ', ' + transfer.coreTransferLines[i].stockItem.myobid + ':' + transfer.coreTransferLines[i].quantity;
                }
            }
        }
    }
    return quickSummary;
}