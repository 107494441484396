import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { GetAllCacheData } from "../utils/cacheFunctions";
const Bbay = "LIVEPRODCT";
const Sydney = "SYDNEY";
const LocationSelector = (props) => {
    const [cache, setCache] = React.useState(null);

    React.useEffect(() => {
        GetAllCacheData(setCache, 'warehouse')
    }, [])
    React.useEffect(() => {
        if (cache) {
            if (cache.myobid === Bbay) {
                props.setLocation([1]);
            } else {
                props.setLocation([0]);

            }
        }
    }, [cache])
    return (<ToggleButtonGroup
        color="primary"
        disabled={props.disabled}
        value={props.location}
        onChange={(ev, val) => props.setLocation(val)}
        aria-label="text alignment"
    >
        <ToggleButton key={0} value={0} >
            <div  >Sydney</div>
        </ToggleButton>
        <ToggleButton key={1} value={1} >
            <div  >Batemans Bay</div>
        </ToggleButton>
    </ToggleButtonGroup>);
}
export default LocationSelector;