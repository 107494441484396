import React, { useMemo } from 'react';
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
    AzureCommunicationTokenCredential,
    CommunicationUserIdentifier
} from '@azure/communication-common';
import {
    CallComposite,
    fromFlatCommunicationIdentifier,
    useAzureCommunicationCallAdapter
} from '@azure/communication-react';
import { CommunicationIdentityClient } from '@azure/communication-identity';

const USER_ID = '8:acs:17969cc0-6efa-43a6-b795-8aebc47c87b4_0000001b-2c5e-1def-4606-6b3a0d003de4';
const TOKEN = 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjVFODQ4MjE0Qzc3MDczQUU1QzJCREU1Q0NENTQ0ODlEREYyQzRDODQiLCJ4NXQiOiJYb1NDRk1kd2M2NWNLOTVjelZSSW5kOHNUSVEiLCJ0eXAiOiJKV1QifQ.eyJza3lwZWlkIjoiYWNzOjE3OTY5Y2MwLTZlZmEtNDNhNi1iNzk1LThhZWJjNDdjODdiNF8wMDAwMDAxYi0yYzVlLTFkZWYtNDYwNi02YjNhMGQwMDNkZTQiLCJzY3AiOjE3OTIsImNzaSI6IjE2OTQ1NzQwODIiLCJleHAiOjE2OTQ2NjA0ODIsInJnbiI6ImF1IiwiYWNzU2NvcGUiOiJjaGF0LHZvaXAiLCJyZXNvdXJjZUlkIjoiMTc5NjljYzAtNmVmYS00M2E2LWI3OTUtOGFlYmM0N2M4N2I0IiwicmVzb3VyY2VMb2NhdGlvbiI6ImF1c3RyYWxpYSIsImlhdCI6MTY5NDU3NDA4Mn0.FxP3V78qVh5kl9DtPg9aId_srCSn1YLOmKKXZGy7VRs9FPJDZhHX0CDmKuJbe7Tiz20MFAj76mlQh8lFQHkDXoMOJWR9L67JnbjzQyrAbziFT73e-mseMFuasP7zomM4Acv75tWlYaDjS3hotTxhPpX8vyjrvpYkp5fAUtzT9WkycmLtWikt9LNMfN-RpOL2F1W1dny8xlyb5a2hfJSxbsTaS0gHxYrPrjSMOfxoHnvWq6fhg-X0H_CZVHvFrJ5dE3DAxOd3qCXZZxv6gVDjmdY4Z3mn3f7Zq1V65r047mnCtYeqakEV4xNndHyYBzzZXG1ZdlhBJNLoqUDnhdTRiQ';


const DISPLAY_NAME = 'James Dunlop';

const ACS_USER_IDS = ['19:22e8fa57-a973-41e1-ab41-ad1d8b3eec68_e53930ee-f757-44d1-9240-1b40e7997194'];


const CallWindow = (props) => {
    const { callee } = props;
    //const { userId, token, displayName, participantIds } = useAzureCommunicationServiceArgs();
    const [userId, setUserId] = React.useState('');
    const [token, setToken] = React.useState(null);
    const [displayName, setDisplayName] = React.useState(DISPLAY_NAME);
    const [participantIds, setParticipantIds] = React.useState(ACS_USER_IDS);


    React.useEffect(() => {
        const connectionString = 'endpoint=https://connectteams.australia.communication.azure.com/;accesskey=ueuiVpcQodkPFvRBMCjdO9XEbFVxO5AeDfbNZmDN7WYCbp3BGdUgxXoC+V7RXBUhunGocKexCMB9SCTC7rCBHw==';
        let tokenClient = new CommunicationIdentityClient(connectionString);
        tokenClient.createUser().then((user) => {
            setUserId(user.communicationUserId);
            tokenClient.getToken(user, ["voip"]).then((token) => {
                setToken(token.token);
            });
        });

    }, []);


    // A well-formed token is required to initialize the chat and calling adapters.
    const credential = useMemo(() => {
        try {
            return new AzureCommunicationTokenCredential(token);
        } catch {
            console.error('Failed to construct token credential');
            return undefined;
        }
    }, [token]);

    // Memoize arguments to `useAzureCommunicationCallAdapter` so that
    // a new adapter is only created when an argument changes.

    const callAdapterArgs = useMemo(
        () => ({
            userId: fromFlatCommunicationIdentifier(userId),
            displayName,
            credential,
            locator: { participantIds }
        }),
        [userId, credential, displayName, participantIds]
    );
    const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);

    if (callAdapter ) {
        return (
            <div style={{ height: '100vh', display: 'flex' }}>
                <CallComposite adapter={callAdapter} />
            </div>
        );
    }
    if (credential === undefined) {
        return <h3>Failed to construct credential. Provided token is malformed.</h3>;
    }
    return <h3>Initializing...</h3>;
};

function useAzureCommunicationServiceArgs() {
    return {
        userId: USER_ID,
        token: TOKEN,
        displayName: DISPLAY_NAME,
        participantIds: ACS_USER_IDS
    };
}

export default CallWindow;
