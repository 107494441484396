import React from 'react';

import { getAPIData } from "../../utils/apiFunction"
import EditableTable from '../../components/EditableTable';
import { adalApiFetch } from '../../../adalConfig';

const SettingsQA = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [conditions, setConditions] = React.useState([]);
    const [condList, setCondList] = React.useState([]);
    const [columns, setColumns] = React.useState([{ label: "ID", key: 'id', list: [] },
    { label: "Rating", key: 'rating', list: [], controller: true },
    { label: "Description", key: 'description', list: [] },
    { label: "Action", key: 'action', list: [] },
    { label: "Colour", key: 'colour', list: [] }]);
    React.useEffect(() => {
        getAPIData('/QAMaintenance/GetConditionRatings', setConditions);
    }, []);
    React.useEffect(() => {
        var cpyCols = [...columns];
        var ratAr = [];
        var shellAr = [];
        if (conditions) {
            for (var i = 0; i < conditions.length; i++) {
                ratAr.push({ ...conditions[i], matchID: conditions[i].id });
                for (var j = 0; j < conditions[i].qaConditionReasons.length; j++) {
                    shellAr.push({ ...conditions[i].qaConditionReasons[j], matchID: conditions[i].qaConditionReasons[j].conditionRatingId, rating: {} });
                }
            }
            for (var i = 0; i < cpyCols.length; i++) {
                cpyCols[i].list = ratAr;
            }
        }
        setCondList(shellAr);
        setColumns(cpyCols);
    }, [conditions]);

   
    var types = {
        rating: "select",
        description: "text",
        name: "text",
        action: "disabled",
        colour: "disabledColor",
    }

    const addNewShell = async (item) => {
        console.log(item)
        var data = {
            RatingId: item.rating?.id,
            Description: item.description,
        }
        var response = await adalApiFetch(fetch, uri + '/QAMaintenance/AddConditionRating?' + new URLSearchParams({
            ...data
        }), {
            method: 'post',
        });
        return response;
    }

    const updateChanges = async (item) => {
        console.log(item)
        var data = {
            Id: item.id,
            RatingId: item.rating && item.rating.id ? item.rating.id : item.conditionRatingId,
            Description: item.description,
        }
        var response = await adalApiFetch(fetch, uri + '/QAMaintenance/UpdateConditionRating?' + new URLSearchParams({
            ...data
        }), {
            method: 'post',
        });
        return response;

    }
    return <EditableTable title={"Condition Ratings"} data={condList} setData={setCondList} columns={columns} type={types} addNew={addNewShell} updateChanges={updateChanges} />
};



export default SettingsQA;
