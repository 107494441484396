import React from 'react';
import Grid from "@mui/material/Grid";
import FarmersSuppliers from './Tables/FarmersSuppliers';
const FarmersSuppliersDash = () => {
    return (
        <React.Fragment>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <FarmersSuppliers toggleExpired />
                </Grid>
        </Grid>
        </React.Fragment >
    );
};



export default FarmersSuppliersDash;
