import React from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useJumboTheme } from "@jumbo/hooks";
import CardContent from "@mui/material/CardContent";
import { isWithinMinDifference, dateTimeFormat, startOfDay, endOfDay, textfieldDateFormat, tomorrow, shortFormat, dayCompare, shiftMins } from "../../utils/dateFunctions";
import DatePicker from "../../shared/DatePicker";
import { useSearchParams } from "react-router-dom";
import CustomerOrder from '../Processing/Tables/CustomerOrder';
import DraggablePurchaseOrder from '../Procurement/Tables/DraggablePurchaseOrders';
import WarehouseToggle from '../../components/WarehouseToggle';
import { WAREHOUSEGROUPS, WAREHOUSEMATCHES } from "../../utils/constants/warehouse";
import Typography from "@mui/material/Typography";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend } from 'recharts';
import { getAPIDataParamsReturn } from "../../utils/apiFunction";
import Div from "@jumbo/shared/Div";
const FridgeStatus = (props) => {
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [location, setLocation] = React.useState([WAREHOUSEGROUPS.BBay, WAREHOUSEGROUPS.Sydney]);//, WAREHOUSEGROUPS.Sydney put back in when sydney is ready
    const [warehouses, setWarehouses] = React.useState([]);
    const [fridges, setFridges] = React.useState([]);
    const [fridgeTemps, setFridgeTemps] = React.useState([]);
    const [warehousesSelected, setWarehousesSelected] = React.useState(location.reduce((p, c) => [...p, ...WAREHOUSEMATCHES[c]], []));

    React.useEffect(() => {
        getAPIDataParamsReturn('/Warehouses/Get', {}).then((whs) => {
            var promArr = [];
            var factorys = whs.filter((wh) => { return wh.isFactory });
            setWarehouses(factorys);
            for (var i = 0; i < factorys.length; i++) {
                promArr.push(getAPIDataParamsReturn('/Warehouses/GetFridges', {
                    WarehouseId: factorys[i].id,
                }));
            }
            Promise.all(promArr).then((res) => {
                setFridges(res.reduce((p, c) => [...p, ...c], []));
            });
        });

    }, []);
    React.useEffect(() => {
        getAPIDataParamsReturn('/Warehouses/GetFridgeData', {
            StartDate: textfieldDateFormat((startDate)),
            EndDate: textfieldDateFormat(tomorrow(startDate)),
        }).then((res) => {
            setFridgeTemps(res);
        });
    }, [startDate]);

    const setLocationFunc = (loc) => {
        var currSelWH = (loc.reduce((p, c) => [...p, ...WAREHOUSEMATCHES[c]], []));
        setWarehousesSelected(currSelWH);
        setLocation(loc);
    }
    var currFridge = '';
    var filteredFridges = fridges.filter((fridge) => { return warehousesSelected.includes(fridge.warehouse?.myobid) });
    console.log(fridgeTemps, fridgeTemps.sort((a, b) => { return new Date(a.dateTime) > new Date(b.dateTime) ? -1 :1 }));
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                    <DatePicker date={startDate} setDate={setStartDate} label="Date" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                    <WarehouseToggle multipleChoices location={location} setLocation={setLocationFunc} label="Date" />
                </Grid>
                <Grid item xs={12}>
                    {filteredFridges.sort((a, b) => a.warehouseId-b.warehouseId) ? filteredFridges.map((fridge) => {
                        var newTitle = false;
                        if (currFridge !== fridge.warehouseId) {
                            currFridge = fridge.warehouseId;
                            newTitle = true;
                        }
                       return  <React.Fragment>
                           {newTitle ? <Typography variant="h6" style={{ paddingTop: 5 }} >{fridge.warehouse?.description}</Typography> : null}
                           <FridgeStatusDisplay isToday={dayCompare(startDate, new Date())} key={fridge.id} fridge={fridge} fridgeTemp={fridgeTemps.filter((ft) => { return ft.fridgeId === fridge.id }).sort((a, b) => { return new Date(a.dateTime) > new Date(b.dateTime) ? -1 : 1 })?.[0]} />
                        </React.Fragment>
                    }
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    {fridges && fridges.length > 0 ? <TempChart date={startDate} fridges={filteredFridges} fridgeTemp={fridgeTemps} /> : null}
                </Grid>
            </Grid>
        </React.Fragment >
    );
};

const FridgeStatusDisplay = (props) => {
    const { theme } = useJumboTheme();
    const onlineStatus = theme.palette.onlineStatus;
    var cardStyle = {
        marginBottom: 3,
        padding: 1,
        backgroundColor: props.fridgeTemp?.temperature ? (props.isToday ? (props.fridgeTemp && isWithinMinDifference(new Date(props.fridgeTemp?.dateTime), 65, new Date()) ? onlineStatus.online : onlineStatus.busy) : 'white') : onlineStatus.busy

    }
    var cardContentStyle = {
        padding: '1px 10px 1px 20px'
    }
    return <Card style={cardStyle} >
        <CardContent style={cardContentStyle} >
            <Grid container spacing={0} mb={0} p={0} >
                <Grid item xs={12} sm={4}>
                    <Typography variant={"h4"} m={1}>{props.fridge.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant={"h4"} m={1}>{props.fridgeTemp ? dateTimeFormat(props.fridgeTemp?.dateTime) : 'No Records for Today'}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant={"h3"} m={1} >{props.fridgeTemp?.temperature}&deg;C</Typography>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};


const TempChart = (props) => {
    const [selectedLine,setSelectedLine] = React.useState(null);
    var chartData = [];
    //group props.fridgeTemps by dateTime and set each fridges name as temp for that dateTime
    if (props.fridgeTemp) {
        //loop thorough a date starting at 00:00 and ending at current time in 30 min intervals
        var currDate = new Date(props.date);
        currDate.setHours(0, 0, 0, 0);
        for (var i = 0; i < 24; i++) {
            var tempObj = { date: new Date(currDate) };
            props.fridgeTemp.forEach((ft) => {
                if (isWithinMinDifference(new Date(ft.dateTime),29,currDate)) {
                    tempObj[ft.fridge?.name] = ft.temperature;
                }
            });
            console.log(currDate);
            chartData.push({ ...tempObj });
            currDate = shiftMins(currDate, 60);
            if (currDate > new Date()) {
                break;
            }
        }

    }
    console.log(chartData)
    const chartColors = [
        'rgba(255, 99, 132, 1)', // Red
        'rgba(54, 162, 235, 1)', // Blue
        'rgba(255, 205, 86, 1)', // Yellow
        'rgba(75, 192, 192, 1)', // Teal
        'rgba(153, 102, 255, 1)', // Purple
        'rgba(255, 159, 64, 1)', // Orange
        'rgba(50, 205, 50, 1)', // Lime Green
        'rgba(255, 0, 0, 1)', // Bright Red
        'rgba(0, 0, 255, 1)', // Bright Blue
        'rgba(255, 140, 0, 1)', // Dark Orange
        'rgba(0, 128, 0, 1)', // Green
        'rgba(128, 0, 128, 1)', // Purple
        'rgba(0, 255, 255, 1)', // Cyan
        'rgba(255, 0, 255, 1)', // Magenta
        'rgba(128, 128, 128, 1)', // Gray
        'rgba(255, 255, 0, 1)', // Yellow
        'rgba(210, 105, 30, 1)', // Chocolate
        'rgba(139, 69, 19, 1)', // Saddle Brown
        'rgba(0, 128, 128, 1)', // Teal
        'rgba(220, 20, 60, 1)' // Crimson
    ];
    const handleMouseEnter = (o) => {
        setSelectedLine(o.dataKey);
    };

    const handleMouseLeave = (o) => {
        setSelectedLine(null);


    };

    return (
        <ResponsiveContainer height={400}>
            <LineChart data={chartData} className={"mx-auto"}>
                <CartesianGrid strokeDasharray="6 1 2" horizontal={false} strokeOpacity={0.5} />
                <Tooltip
                    cursor={true}
                    content={({ active, label, payload }) => {
                        return active ? (
                            <Div sx={{ color: "common.white" }}>
                                <div>{dateTimeFormat(label)}</div>
                                {payload ? payload.map((row, index) => (
                                    <div key={index}>{`${row.name}: ${row.value}`}&deg;C</div>
                                )) : null}
                            </Div>
                        ) : null;
                    }}
                    wrapperStyle={{
                        backgroundColor: 'rgba(0,0,0,.8)',
                        padding: '5px 8px',
                        borderRadius: 4,
                        overflow: 'hidden',
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                />
                <XAxis dataKey="date" tickFormatter={shortFormat}  />
                <YAxis />
                {props.fridges ? props.fridges.map((fridge, index) => {
                    return <Line dataKey={fridge.name} type="monotone" dot={null} strokeWidth={3} stackId="2"
                        stroke={chartColors[index]} strokeOpacity={selectedLine ? ((selectedLine === fridge.name)?1:0.1):1} />
                }) : null}
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />

            </LineChart>
        </ResponsiveContainer>
    );
};

export default FridgeStatus;


