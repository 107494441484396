import React from 'react';
import OnShoreDisplay from '../components/OnShoreDisplay';
import { GetAllCacheData, addToCache } from "../../../utils/cacheFunctions";
import { getAPIData, getAPIDataParams } from '../../../utils/apiFunction';
import { textfieldDateFormat, startOfWeek, shiftWeek } from '../../../utils/dateFunctions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import FormModal from '../../../components/FormModal';
import Button from "@mui/material/Button";


const ChooseBatch = (props) => {
    const [batch, setBatch] = React.useState(props.state);
    const [openModal, setOpenModal] = React.useState(null);
    
    const selectBatch = (batch) => {
        setBatch(batch);
        props.setState(batch);
    }
    const checkForNull = (op) => {
        if (!op.id) {
            return null;
        } else {
            return op;
        }
    }
    const handleCloseModal = () => {
        setOpenModal(false);
    }
    return <React.Fragment>
        <FormModal open={openModal} handleClose={handleCloseModal} title={"Select Group"} style={{zIndex:100000} }>
            <OnShoreDisplay select access={props.access} handleClose={handleCloseModal} setBatch={selectBatch} farm={{ warehouseId: props.warehouseId }}/>
        </FormModal>
        <Button onClick={() => { setOpenModal(true) }} disabled={props.disabled} color="secondary" variant="outlined" style={{
            width: '24ch', margin: '8px', minHeight: '6ch', color: '#475259', border: '1px solid rgba(71, 82, 89, 0.3)'
        }}>{batch ? <div style={{fontSize:'90%'} }><div>{batch.cropLabel}</div><div>{batch.grade?.description}</div></div> : 'Select Group'}</Button >
    </React.Fragment>

    //return <SelectTextField checkID={props.POID} title="Purchase Order ID" state={poid} setState={handleChange} disabled={props.disabled} list={pos} valueFunc={checkForNull} nameFunc={(op) => { return op.myobid }} />
}




export default ChooseBatch;
