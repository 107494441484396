import React from 'react';
import { TableContainer, Table, TableBody, TableCell, TableRow, TableHead, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {getOysterGrades,gradeSort} from "../../../definitions/oysters";
import MUIDataTable from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { dateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";

const Summary = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [grades, setGrades] = React.useState([]);

    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(data);
        });
    }, []);

    const reloadFunc = () => {
        setReload(!reload);
    }
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { })

    };
    var columns = [
        {
            name: 'dateFormat',
            label: 'Date'
        },
        {
            name: 'warehouseName',
            label: 'Warehouse'
        },
        {
            name: 'staffMemberName',
            label: 'Staff Member'
        }];
    var oysterCols = gradeSort(grades)?.map((og) => {
        return {
            name: og.abv.toLowerCase(),
            label: og.abv,
            options: {
                filter: false
            }
        }
    })
    columns = [...columns, ...oysterCols];
    columns.push({
        name: 'notes',
        label: 'Notes'
    })
    React.useEffect(() => {
        setProcurement([]);
        getProcurementData(textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
    }, [props.startDate, props.endDate, reload]);

    var getProcurementData = async (startDate, endDate) => {
        const response = await adalApiFetch(fetch, uri + '/ManualInventory/Get?' + new URLSearchParams({
            startDate,
            endDate
        }));
        const data = await response.json();
        const sortData = data.sort((a,b) => {
            return a.date > b.date ? -1 : 1;
        });
            const organiseData = sortData.map((dt) => {
            var newO = { ...dt };
            newO.dateFormat = dateFormat(dt.date);
            newO.warehouseName = dt.warehouse?.description;
            newO.staffMemberName = (dt.staffMember?.firstname) + " " + (dt.staffMember?.surname);
            return newO;
        })
        setProcurement(organiseData);
    }
    return (

        <DataTableStandard
            title={"Inventory"}
            data={procurement}
            columns={columns}
            options={options}
            reload={reloadFunc}
        />

    );
};

export default Summary;
