import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import MonthSelector from "../../shared/MonthSelector";
import { DateTime } from 'luxon'
import { useSearchParams } from "react-router-dom";
import { checkAccess } from '../../utils/roleFunctions';

const MonthlyReport = (props) => {
    const [reload, setReload] = React.useState(false);
    const [startDate, setStartDate] = React.useState(DateTime.now());
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    console.log(startDate)
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <MonthSelector date={startDate} setDate={setStartDate } />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default MonthlyReport;


