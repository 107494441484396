


export const modifyTotalOysters = (current, adjustment) => {
    var totalCurrent = current.totalUnits * current.average;
    var totalAdjustment = adjustment.totalUnits * adjustment.average;
    var newTotal = totalCurrent - totalAdjustment;
    if (newTotal < 0) {
        newTotal = 0;
    }
    var newTotalUnits = newTotal / current.average;

    return { totalUnits: newTotalUnits, average: current.average };

}