import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeek, endOfWeek, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import Inventory from './Tables/Inventory';
import FormModal from '../../components/FormModal';
import Button from "@mui/material/Button";
import InventoryForm from './Forms/InventoryForm';
import { adalApiFetch } from '../../../adalConfig';
import { getAPIDataParams } from "../../utils/apiFunction";
import { dateFormat, textfieldDateFormat, yesterday, dayCompare } from "../../utils/dateFunctions";
import SelectInventoryForm from './Forms/SelectInventoryForm';
import StockTakeTable from './Tables/StockTake';
import SelectWarehouse from '../../components/SelectWarehouse';
import StockTakeForm from './Forms/StocktakeForm';
import RegradeStockForm from './Forms/RegradeStockForm';
import TransferStockForm from './Forms/TransferStockForm';
import AdjustQuantityStockForm from './Forms/AdjustQuantityStockForm';
import CreateStockForm from './Forms/CreateStockForm';
import { CircularProgress, ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';

const InventoryDash = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [newForm, setNew] = React.useState(false);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [rowData, setRowData] = React.useState(null);
    const [reload, setReload] = React.useState(true);
    const [processed, setProcessed] = React.useState(false);
    const [selectedLine, setSelectedLine] = React.useState(false);
    const [warehouse, setWarehouse] = React.useState(null);
    const [date, setDate] = React.useState(new Date());
    const [formType, setFormType] = React.useState(0);
    const [selStocktake, setSelStocktake] = React.useState(null);
    const [loadingStocktake, setLoadingStocktake] = React.useState(true);
    const [currentStocktake, setCurrentStocktake] = React.useState(null);
    const reloadFunc = () => {
        setReload(!reload);
    }
  
    const handleCloseCreate = () => {
        reloadFunc();
        setOpenCreate(false);
        setSelStocktake(null);
    }

    React.useEffect(() => {
        if (warehouse) {
            console.log(warehouse)
            setLoadingStocktake(true);
            getAPIDataParams('/Inventory/CurrentStocktakeExists', setCurrentStocktake, { WarehouseId: warehouse?.id }).then(() => setLoadingStocktake(false));
        }
    }, [warehouse,reload])
   
    const openInvForm = (data) => (row, index) => {
        var selData = data[index.dataIndex];
        setOpenCreate(true);
        setSelStocktake(selData?.id);
    }

    const openCreateFunc = () => {
        setOpenCreate(true);
    }
    return (
        <React.Fragment>
            {/*<FormModal open={open} handleClose={handleClose} title={"Log Daily Inventory"} >*/}
            {/*    <InventoryForm date={date} new={newForm} data={rowData} access={props.access} reload={reloadFunc} handleClose={handleClose} CustomerPricing />*/}
            {/*</FormModal>*/}
            <FormModal open={openCreate} handleClose={handleCloseCreate} title={""} fixedWidth>
                <StockTakeForm processed={true} access={props.access} handleClose={handleCloseCreate} selectedLine={selectedLine} warehouseId={warehouse?.id} currentStocktakeId={currentStocktake?.id} selStocktakeId={selStocktake} />
            </FormModal >

            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                        <DateSelector startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: '8px' }} >
                        <SelectWarehouse access={props.access} enforceAccess={true} useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} all />
                        {loadingStocktake ? <CircularProgress /> : (currentStocktake ? <Button onClick={openCreateFunc} variant='outlined'>Complete StockTake</Button> : <Button onClick={openCreateFunc} variant='outlined'>Record StockTake</Button>)}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {warehouse ? <StockTakeTable reload={reload} onClick={openInvForm} warehouseId={warehouse?.id} access={props.access} startDate={startDate} endDate={endDate}  /> : <CircularProgress />}
                </Grid>
            </Grid>
        </React.Fragment >
    );
};


export default InventoryDash;
