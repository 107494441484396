import React from 'react';
import Grid from "@mui/material/Grid";
import FormModal from '../../../components/FormModal';
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, tomorrow, yesterday, dateFormat } from "../../../utils/dateFunctions";
import { getAPIDataParamsReturn, postApi } from "../../../utils/apiFunction";
import ReactFlow, { Controls, Background, useReactFlow } from 'reactflow';
import { Handle, Position } from 'reactflow';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import 'reactflow/dist/style.css';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { IconButton, Typography } from '@mui/material';
import ELK from 'elkjs/lib/elk.bundled.js';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
//edge Types
//Grade
//Split
//Combine
//PickUp
//Deploy
//Transfer

//      position: { x: 100, y: 100 },
const elk = new ELK();

// Elk has a *huge* amount of options to configure. To see everything you can
// tweak check out:
//
// - https://www.eclipse.org/elk/reference/algorithms.html
// - https://www.eclipse.org/elk/reference/options.html
const elkOptions = {
    'elk.algorithm': 'layered',
    'elk.layered.spacing.nodeNodeBetweenLayers': '200',
    'elk.spacing.nodeNode': '150',
};




const HistoryDisplay = (props) => {
    const [edit, setEdit] = React.useState(true);
    const ref = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const [nodes, setNodes] = React.useState([]);
    const [edges, setEdges] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [updateNode, setUpdateNode] = React.useState(true);
    const [selShoreId, setSelShoreId] = React.useState(null);
    const [selLocId, setSelLocId] = React.useState(null);
    const [selId, setSelId] = React.useState(null);

    const [selected, setSelected] = React.useState(-1);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    const [forceTask, setForceTask] = React.useState(null);
    const nodeTypes = React.useMemo(() => ({ displayNode: DisplayNode }), [updateNode]);
    //const { fitView } = useReactFlow();

    const deriveEdges = (nodes) => {
        var newEdges = [];
        for (var j = 0; j < nodes.length; j++) {
            var node = nodes[j];
            if (node?.parents.length > 0) {
                for (var i = 0; i < node.parents.length; i++) {
                    newEdges.push({
                        id: node.id + '-' + node.parents[i].id,
                        source: String(node.parents[i].id),
                        target: String(node.id),
                        //type: 'smoothstep',
                        animated: true,
                        label: node.parents[i].type + ' (' + node.parents[i].date + ')',
                        style: { stroke: 'black', strokeWidth: '3px' },
                    });
                }
            }
        }
        //remove duplicates in edges based on id
        newEdges = newEdges.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.id === thing.id
            ))
        );

        return newEdges;
    }
    const getLayoutedElements = (nodes, edges, options = {}) => {
        const isHorizontal = options?.['elk.direction'] === 'RIGHT';
        const graph = {
            id: 'root',
            layoutOptions: options,
            children: nodes.map((node) => ({
                ...node,
                // Adjust the target and source handle positions based on the layout
                // direction.
                targetPosition: isHorizontal ? 'left' : 'top',
                sourcePosition: isHorizontal ? 'right' : 'bottom',

                // Hardcode a width and height for elk to use when layouting.
                width: 150,
                height: 50,
            })),
            edges: edges,
        };

        return elk
            .layout(graph)
            .then((layoutedGraph) => ({
                nodes: layoutedGraph.children.map((node) => ({
                    ...node,
                    // React Flow expects a position property on the node instead of `x`
                    // and `y` fields.
                    position: { x: node.x, y: node.y },
                })),

                edges: layoutedGraph.edges,
            }))
            .catch(console.error);
    };
    const handleRollback = (nodeData) => {
        setOpen(true);
        setSelLocId(nodeData.locId);
        setSelShoreId(nodeData.shoreId);
        setSelId(nodeData.nodeId);
    }
    const onLayout = (direction, useInitialNodes = false, initialNodes, initialEdges) => {
        const opts = { 'elk.direction': direction, ...elkOptions };
        const ns = useInitialNodes ? initialNodes : nodes;
        const es = useInitialNodes ? initialEdges : edges;
        getLayoutedElements(ns, es, opts).then(({ nodes: layoutedNodes, edges: layoutedEdges }) => {
            setNodes(layoutedNodes);
            setEdges(layoutedEdges);

            //window.requestAnimationFrame(() => fitView());
        });
    };
    React.useEffect(() => {
        const getNodes = async () => {
            var nods = null;
            if (props.Children) {
                nods = await getAPIDataParamsReturn('/Farms/Groups/GetChildren', { GroupId: props.GroupId, OneLevel: false });
            } else {
                nods = await getAPIDataParamsReturn('/Farms/Groups/GetHistory', { GroupId: props.GroupId,OneLevel:false });
            }
            if (props.selectSub) {
                var selNode = nods.find((x) => {
                    if (x.data.id === props.GroupId) {
                        if (x.data.locationId === props.locId) {
                            if (x.data.shoreId === props.shoreId) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
                var subNode = { ...selNode };
                var nodesToRem = [];
                while (subNode) {
                    subNode = nods.find(x => x.parents[0]?.id === subNode.id);
                    if (subNode) {
                        nodesToRem.push(subNode.id)
                    }
                }
                nods = nods.filter(x => {
                    return !nodesToRem.includes(x.id)
                });
            }
            //map all node ids to string
            nods = nods.map(x => {
                x.type = 'displayNode';
                x.id = String(x.id);
                x.data.rollback = !props.noRollBack;
                x.data.openForm = handleRollback;
                if (x.id === (props.selectSub ? String(selNode.id) : String(props.GroupId))) {
                    x.data["selected"] = true;
                } else {
                    x.data["selected"] = false;
                }
                x.data["Children"] = props.Children;
                return x;
            });
            //count number of duplicates
            //var counts = {};
            //nods.forEach(function (x) { counts[x.id] = (counts[x.id] || 0) + 1; });
            //console.log(counts);
            ////remove duplicates
            //nods = nods.filter((thing, index, self) =>
            //    index === self.findIndex((t) => (
            //        t.id === thing.id
            //    ))
            //);

            if (props.selectSub) {
                var subNode = nods.find((x) => {
                    if (x.data.id === props.GroupId) {
                        if (x.data.locationId === props.locId) {
                            if (x.data.shoreId === props.shoreId) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
                var nodesToRem = [];
                while (subNode) {
                    subNode = nods.find(x => x.parents[0]?.id === subNode.id);
                    if (subNode) {
                        nodesToRem.push(subNode.id)
                    }
                }
                nods = nods.filter(x => {
                    return !nodesToRem.includes(x.id)
                });
            }
            var initEdges = deriveEdges(nods);
            onLayout((props.Children ? 'UP' : 'DOWN'), true, nods, initEdges);
        }
        getNodes();
    }, [reload]);
    React.useEffect(() => {
        console.log('width', ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);
    const handleClose = () => {
        console.log('jere');
        setOpen(false);
    }
    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }
    const handleClick = (e, node) => {
        console.log(node);
        setUpdateNode(!updateNode);
    }
    return (
        <div style={{ height: '1000px' }}>
            {nodes.length > 0 ?
                <ReactFlow ref={ref} nodes={nodes} edges={edges} nodeTypes={nodeTypes} onNodeClick={handleClick} >
                    <Background />
                </ReactFlow> :
                <CircularProgress />
            }
            <FormModal open={open} handleClose={handleClose} title={"Roll back point"} >
                <ChildrenForm nodeId={selId} locId={selLocId} shoreId={selShoreId} toRemId={props.GroupId} />
            </FormModal>
        </div>
    );
};


const DisplayNode = (props) => {
    var nodeStyle = {
        border: '1px solid black',
        padding: '5px',
        borderRadius: '5px',
        background: props.selected ? 'lightblue' : 'white',
        color: 'black'
    }
    if (props.data.selected) {
        nodeStyle.background = 'rgba(0,0,255,0.8)';
    }
    if (props.selected) {
        nodeStyle.background = 'rgba(5,213,250,0.8)';
    }

    const showImage = (id) => (e) => {
        e.stopPropagation();
    }
    const rollBack = (e) => {
        console.log(props)
        e.stopPropagation();
        props.data.openForm({ nodeId: props.data.id, locId: props.data.locationId, shoreId: props.data.shoreId });
    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <div style={nodeStyle}  >

            {(props.data.ocGroupImages && (props.data.ocGroupImages.length > 0)) ? <LightTooltip title={<React.Fragment>{props.data.ocGroupImages.map((im) => <img alt='oysters' src={im.imageURL} width={'200%'} />)}</React.Fragment>} >
                <IconButton style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: 0,
                    right: 0
                }} aria-label="delete" onClick={showImage(props.data.id)}>
                    <InsertPhotoIcon />
                </IconButton>
            </LightTooltip> : null}

            <Handle type="target" position={props.data.Children ? Position.Bottom : Position.Top} />
            <div>
                <Typography style={{ textAlign: 'center' }} >{dateFormat(props.data?.location ? props.data?.location?.deployed : props.data?.shore?.date)}</Typography>
                <Typography>Id:{props.data.id}, LocId:{props.data.locationId}, ShoreId:{props.data.shoreId}</Typography>
                <Typography>{props.data.cropLabel}</Typography>
                <Typography>{props.data.grade.description}</Typography>
                <Typography>{props.data.unitType.description}</Typography>
                <Typography>{props.data.totalUnits} Units ({props.data.average} Oys/Unit)</Typography>
                {props.data?.location ? <Typography>{props.data?.location?.line?.lease?.name} - Row {props.data?.location?.line?.lineNbr}</Typography> : <Typography>On Shore</Typography>}
                {/*{props.selected && props.data.rollback ? <Button onClick={rollBack} variant="contained">Roll Back</Button> : null}*/}
            </div>
            <Handle type="source" position={props.data.Children ? Position.Top : Position.Bottom} />
        </div>
    );
}

const ChildrenForm = (props) => {
    const Swal = useSwalWrapper();

    const rollback = (e) => {
        Swal.fire({
            html: 'These changes are permanent',
            icon: 'warning',
            title: 'Are you sure you want to rollback?',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue',
            cancelButtonText: 'No',

        }).then(result => {
            //if (result.value) {
            //    postApi('/Farms/Groups/Undo', { GroupId: props.toRemId, OldGroupId: props.nodeId, ShoreId: props.shoreId, LocationId: props.locId }).then((result) => {
            //    });
            //} else if (result.dismiss === Swal.DismissReason.cancel) {
            //}
        });
    }
    return <Grid container spacing={2} mb={1} >
        <Grid item xs={12} sm={12} md={6} >
            <Typography variant={"h4"} mb={0}>If you roll back to the selected (dark blue) group eveything below it will be deleted</Typography>
            <Button onClick={rollback} variant="contained" color="error">Roll Back not yet available (Coming Soon)</Button>
        </Grid>
        <Grid item xs={12} sm={12} md={6} >
            <HistoryDisplay GroupId={props.nodeId} locId={props.locId} shoreId={props.shoreId} selectSub Children={true} noRollBack />
        </Grid>

    </Grid>
}



export default HistoryDisplay;
