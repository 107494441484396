import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from "./app/App";
import './app/config/i18n';
import { MSALinstance } from './adalConfig';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";


const DO_NOT_LOGIN = false;
const LoginPage = () => {

    const initializeSignIn = () => {
        MSALinstance.loginRedirect();
    };
    MSALinstance.loginRedirect();

    return (
        <>
            <div onClick={initializeSignIn}>...Logging In</div>
        </>
    );
};

const AppTop = () => {
    const { accounts } = useMsal();

    return <React.StrictMode>
        <MsalProvider instance={MSALinstance}>
            <AuthenticatedTemplate>
                <App accounts={accounts} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <LoginPage />
            </UnauthenticatedTemplate>
        </MsalProvider>
    </React.StrictMode>

}



ReactDOM.render(
    <AppTop/>,
    document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

