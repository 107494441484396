import React from 'react';
import { getAPIData } from '../utils/apiFunction';
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';

const CropYearSelect = (props) => {
    const [ests, setEsts] = React.useState([]);
    const [tempEst, setTempEst] = React.useState([]);
    const [value, setValue] = React.useState(props.state);
    const [inputValue, setInputValue] = React.useState('');
    const filterVals = (list) => {
        console.log(list)
        setEsts(list.sort((a, b) => {
            return a.code > b.code ? 1 : -1;
        }))

    }
   
    React.useEffect(() => {
        if (props.estId && ests) {
            var est = ests.find((dr) => { return (dr.code === props.estId) });
            props.setState(est);
            setValue(est);
        }
    }, [ests, props.estId])
    React.useEffect(() => {
        getAPIData('/Transfers/GetCrops/' + props.supplier?.warehouseId, filterVals);
    }, [props.supplier])
    return (<Autocomplete
        value={value}
        disabled={props.disabled}
        onChange={(event, newValue) => {
            props.setState(newValue);
            setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => option}
        disablePortal
        id="cropbox"
        options={ests}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Crop Year" />}
    />);



}
export default CropYearSelect;