


export const printColor = (colorObj) => {
    return "rgb(" + colorObj.r + ',' + colorObj.g + ',' + colorObj.b + ')';
}

export const checkCols = (colArr, showColumns) => {
    var show = false;
    for (var i = 0; i < colArr.length; i++) {
        if (showColumns.includes(colArr[i])) {
            show = true;
        }
    }

    return show;
}

export const shiftCol = (colr, shift) => {
    if (!shift) {
        shift = 1;
    }
    var multiplier = 8;
    var newCol = { r: colr.r + multiplier * shift, g: colr.g + multiplier * shift, b: colr.b + multiplier * shift };
    return newCol;
}

export const generateValues = (rgbStr) => {
    var rgbAr = rgbStr.substring(4, rgbStr.length - 1)
        .replace(/ /g, '')
        .split(',');
    return { r: Number(rgbAr[0]), g: Number(rgbAr[1]), b: Number(rgbAr[2]) }

}
export const findbyTopLevel = (tl, OrderColumns) => {
    var cols = [];
    for (var i = 0; i < OrderColumns.length; i++) {
        if (OrderColumns[i].topLevel === tl) {
            cols.push(i);
        }
    }
    return cols;
}

export const getColFromCol = (col, tll) => {
    var selTLL = tll.find((tl) => {
        return tl.cols.includes(col);
    });
    if (selTLL) {
        return selTLL.col;
    } else {
        return { r: 255, g: 255, b: 255 };
    }
}

export const findbyName = (name, OrderColumns) => {
    var cols = [];
    if (OrderColumns) {
        for (var i = 0; i < OrderColumns.length; i++) {
            if (OrderColumns[i].name.includes(name)) {
                cols.push(i);
            }
        }
        return cols;
    }
}