import React from 'react';
import TextField from "@mui/material/TextField";


const ControlledTextField = (props) => {
    const handleChange = (event) => {
        props.setState(event.target.value);
    }
    var style = {};
    if (props.width) {
        style = { width: props.width }
    }
    const numberInputOnWheelPreventChange = (e) => {
         //Prevent the input value change
        e.target.blur()

        // Prevent the page/container scrolling
        e.stopPropagation()

        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    return <TextField
        type={props.number ? "number" : 'text'}
        inputProps={props.noDecimal ? {
            onWheel: numberInputOnWheelPreventChange,
            onKeyDown: (e) => { if (e.key === '.') { e.preventDefault(); } },
        } : { onWheel: numberInputOnWheelPreventChange}}
        disabled={props.disabled}
        id="outlined-required"
        key={props.key}
        label={props.title}
        value={props.state}
        onChange={handleChange}
        style={style}
        InputLabelProps={{
            shrink: true,
        }}
    />
}
export default ControlledTextField;