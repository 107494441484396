import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek, textfieldDateFormat, yesterday, dateFormat } from "../../../utils/dateFunctions";
import { getAPIData, getAPIDataParamsReturn } from "../../../utils/apiFunction";
import { textColorContrast } from "../../../utils/renderFunctions";
import { numberFormat } from "../../../utils/numberFunctions";
import DateSelector from "../../../shared/DateSelector";
import { TableCell, TableRow, TableFooter } from '@mui/material';

import DataTableStandard from "../../../components/DataTableStandard";
import { useSearchParams } from "react-router-dom";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import SelectAOCFarmWarehouse from '../../../components/SelectAOCFarmWarehouse';

const READY = 'Ready';
const PICKEDUP = 'PickedUp';
const RECEIVED = 'Received';
const CANCELLED = 'Cancelled';



const Movements = (props) => {
    const [edit, setEdit] = React.useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [farm, setFarm] = React.useState(null);
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [reload, setReload] = React.useState(true);
    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.EDIT };
    const [searchParams, setSearchParams] = useSearchParams(); // searchParams.get("status")
    const status = (searchParams.get("status"));
    const [forceTask, setForceTask] = React.useState(null);

    //toggle reload function
    const toggleReload = () => {
        setReload(!reload);
    }


    const createBatch = () => {
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);

    }
    //need a different date select that goes back 7 days from today
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                    <SelectAOCFarmWarehouse setState={setFarm} state={farm} access={props.access} />
                </Grid>
                <Grid item xs={12} >
                    <MovementsTable warehouseId={farm?.warehouseId} startDate={startDate} endDate={endDate} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Movements;


const MovementsTable = (props) => {
    const [columns, setColumns] = React.useState([]);
    const [deployments, setDeployments] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(true);
    const [cropCols, setCropCols] = React.useState([]);

    const batchtoCol = (batch) => {
        return cropCols?.find((cc) => { return cc.crop === batch })?.colour;
    }
    const reloadFunc = () => {
        setReload(!reload);
    }


    React.useEffect(() => {
        var cols = [
            //{
            //    name: 'id',
            //    label: 'ID',
            //},
            {
                name: 'date',
                label: 'Date',
            },

            {
                name: 'crop',
                label: 'Crop',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        return <span style={{ background: batchtoCol(deployments?.[dataIndex]?.crop), color: textColorContrast(batchtoCol(deployments?.[dataIndex]?.crop), '#FFF', '#000'), padding: '5px' }} >
                            {deployments?.[dataIndex]?.crop}
                        </span>
                    }

                }
            },
            {
                name: 'grade',
                label: 'Grade',
            },
            {
                name: 'unitType',
                label: 'Unit Type',
            },
            {
                name: 'total',
                label: 'Total',
            },
            {
                name: 'average',
                label: 'Average',
            },
            {
                name: 'lease',
                label: 'Lease',
            },
            {
                name: 'row',
                label: 'Row',
            },

            {
                name: 'start',
                label: 'Start No.',
            },
            {
                name: 'end',
                label: 'End No.',
            }];

        setColumns(cols);
    }, [cropCols, deployments])
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '600px',
        responsive: 'standard',
        rowsPerPage: 1000,
        //onRowClick: props.onClick ? props.onClick(locHistory) : (() => { }),

    };

    options.customTableBodyFooterRender = (opts) => {
        var proc = props.filter ? props.filter(deployments) : deployments;
        console.log(opts.data);
        var totalUnits = opts.data.reduce((a, b) => a + (Number(b.data[4]) || 0), 0);
        var totalOysters = opts.data.reduce((a, b) => a + ((Number(b.data[5]) * Number(b.data[4])) || 0), 0);
        const paddColStyle = {
            color: '#000',
            paddingTop: '1px',
            paddingBottom: '1px'
        }
        return (
            <TableFooter >
                <TableRow style={{ fontWeight: 'bold' }}>
                    {opts.columns.map((col, index) => {
                        if (index === 0) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                Total
                            </TableCell>
                        } else if (index === 4) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                {numberFormat(totalUnits)}
                            </TableCell>
                        } else if (index === 5) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                Total Oysters
                            </TableCell>
                        } else if (index === 6) {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                                {numberFormat(totalOysters)}
                            </TableCell>
                        } else {
                            return <TableCell key={index} style={{
                                fontWeight: 'bold', ...paddColStyle
                            }}>
                            </TableCell>
                        }
                    })}
                </TableRow>
            </TableFooter>
        );
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        setDeployments([]);
        if (props.warehouseId) {
            getData(props.warehouseId, abortController);

        }
        return () => {
            abortController.abort();
        };
    }, [props.warehouseId, reload, props.externalReload]);
    const OrganiseMovements = (data) => {
        return data.filter((a) => { return a.locationId != null }).map((group) => {
            return {
                id: group.id,
                rawDate: group.location?.deployed,
                date: dateFormat(group.location?.deployed),
                grade: group.grade?.description,
                unitType: group.unitType?.description,
                crop: group.cropLabel,
                total: group.totalUnits,
                average: group.average,
                row: group.location?.line?.lineNbr,
                lease: group.location?.line?.lease?.name,
                start: group.location?.start,
                end: group.location?.end
            }
        }).sort((a, b) => { return new Date(b.rawDate) - new Date(a.rawDate) });
    }
    const getData = async (warehouseId, abortController) => {
        setLoading(true);

        const data = await getAPIDataParamsReturn('/Farms/Groups', { WarehouseId: warehouseId }, abortController);
        setDeployments(OrganiseMovements(data));
        setLoading(false);
    }

    React.useEffect(() => {
        const getCC = async () => {
            await getAPIData('/Farms/Lookups/BatchColours', setCropCols);
        };
        getCC();
    }, []);



    return <DataTableStandard
        data={deployments}
        title={"Deployments"}
        columns={columns}
        options={options}
        returnSearch={!loading}
        reload={reloadFunc}
        shrink={props.shrinkTable}
    />
}